import TaskModel, {TaskConstraintType} from "../../../models/responses/task.model";
import TaskStatusModel from "../../../models/responses/task-status.model";
import {TaskPredType} from "../../../models/task-pred-type.enum";
import {CalendarModel} from "../../../models/responses/calendar.model";
import {TaskType} from "../../../models/task-type";

export default async function calculateDCMA(taskList: TaskModel[],
                                            cpmMap: Map<string, any>,
                                            calendars: CalendarModel[],
                                            targetFronts: number) {

    let fsTaskMap = new Map()
    taskList.forEach(task => fsTaskMap.set(task.task_id, task))
    let calendarMap = new Map()
    calendars.forEach(cal => calendarMap.set(cal.uId, cal))
    let hasBaseline = false

    const dataDate = new Date().getTime() / 1000
    const dataDateStartOfDay = dataDate - (dataDate % 86400)
    const hardConstraints = [
        TaskConstraintType.FINISH_ON,
        TaskConstraintType.START_ON,
        TaskConstraintType.MANDATORY_FINISH,
        TaskConstraintType.MANDATORY_START,
    ]

    let output: any = {}
    output.missingPredecessors = []
    output.missingSuccessors = []
    output.leads = []
    output.lags = []
    output.invalidDates = []
    output.hardConstraints = []
    output.SF = []
    output.FF = []
    output.SS = []
    output.highFloat = []
    output.negativeFloat = []
    output.highDuration = []
    output.missedActivities = []
    output.resources = []
    output.outOfSequence = []
    output.mergeHotspot = []
    output.totalTasks = 0
    output.totalCompletedTasks = 0
    output.totalLinks = 0
    output.totalCompletedLinks = 0
    output.totalInProgressTasks = 0
    output.totalMilestones = 0
    output.totalCompletedMilestones = 0
    output.baselineExecutionIndex = null

    let tasksDue = 0

    taskList.forEach(task => {
       const cpmMapTask = cpmMap.get(task.task_id)
        output.totalTasks += 1
        if (task.task_type === TaskType.TT_FIN_MILE || task.task_type === TaskType.TT_MILE) {
            output.totalMilestones += 1
            if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                output.totalCompletedMilestones += 1
            }
        }
        if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
            output.totalCompletedTasks += 1
            if (task.blEarlyEndDate && task.blEarlyEndDate.seconds < dataDateStartOfDay) {
                hasBaseline = true
                tasksDue += 1
            }
            if (task.act_end_date) {
                if (task.act_end_date!.seconds > dataDate || task.act_start_date!.seconds > dataDate) {
                    output.invalidDates.push(task)
                }
            } else if (task.declaredCompleteTimestamp) {
                if (task.declaredCompleteTimestamp!.seconds > dataDate) {
                    output.invalidDates.push(task)
                }
            }
            let outOfSequence = false
            task.relations.forEach(rel => {
                if (rel.task_id === task.task_id) {
                    output.totalLinks += 1
                    if (outOfSequence) {
                        return
                    }
                    const predTask = fsTaskMap.get(rel.pred_task_id)
                    if (predTask.status !== TaskStatusModel.COMPLETE && predTask.status !== TaskStatusModel.DECLARED_COMPLETE) {
                        output.outOfSequence.push(task)
                        outOfSequence = true
                    }
                } else {
                    output.totalCompletedLinks += 1
                }
              })
            return
        }

        if (task.status === TaskStatusModel.IN_PROGRESS) {
            output.totalInProgressTasks += 1
        }
        const calendar = calendarMap.get(cpmMapTask.cal_id)
        let preds: number = 0
        let succs: number = 0
        task.relations.forEach(rel => {
            if (rel.task_id === task.task_id) {
                output.totalLinks += 1
                preds += 1
                const linkOut = {...rel, lagDays: (rel.lag / 2) / calendar.working_hours_per_day}
                const predTask = cpmMap.get(rel.pred_task_id)
                if (predTask.status_code === TaskStatusModel.COMPLETE || predTask.status_code === TaskStatusModel.DECLARED_COMPLETE) {
                    return
                }
                if (rel.lag < 0) {
                    output.leads.push(linkOut)
                }
                if (rel.lag > 0) {
                    output.lags.push(linkOut)
                }
                if (rel.pred_type === TaskPredType.SS) {
                    if (predTask.status !== 'not started') {
                        output.totalCompletedLinks += 1
                    }
                    output.SS.push(linkOut)
                }
                if (rel.pred_type === TaskPredType.FF) {
                    output.FF.push(linkOut)
                }
                if (rel.pred_type === TaskPredType.SF) {
                    output.SF.push(linkOut)
                }
                return
            }
            succs += 1
        })
        if (preds === 0) {
            output.missingPredecessors.push(task)
        }
        if (succs === 0) {
            output.missingSuccessors.push(task)
        }
        if (preds > 2) {
            output.mergeHotspot.push(task)
        }

        if (task.constraint_type !== TaskConstraintType.NONE) {
            if (task.constraint_type! in hardConstraints) {
                output.hardConstraints.push(task)
            }
        }

        if (task.float > 88 * calendar.working_hours_per_day) {
            output.highFloat.push(task)
        }
        // if (task.float < 0) {
        //     output.negativeFloat.push(task)
        // }
        if (task.targetDuration > 88 * calendar.working_hours_per_day) {
            output.highDuration.push(task)
        }
        if (task.blEarlyEndDate && task.blEarlyEndDate.seconds < dataDateStartOfDay) {
            hasBaseline = true
            tasksDue += 1
            output.missedActivities.push(task)
        }
    })

    if (hasBaseline) {
        if (tasksDue === 0) {
            output.baselineExecutionIndex = 1
        } else {
            output.baselineExecutionIndex = (output.totalCompletedTasks / tasksDue).toFixed(2)
        }
    }

    output.logicDensity = ((output.totalLinks - output.totalCompletedLinks) / (output.totalTasks - output.totalCompletedTasks)).toFixed(2)
    output.inefficiency = (output.totalInProgressTasks - targetFronts) / targetFronts
    output.targetFronts = targetFronts

    return output
}