import FirebaseUsage from "../firebase/firebase.usage";

export default async function logError(type: string, error: any, userId: string, projectId: string) {
    await FirebaseUsage.addDocument('error-logs',
        {
            type,
            error,
            userId,
            projectId,
            timestamp: FirebaseUsage.timestamp()
        })
}