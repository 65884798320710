import {CalculationsState} from "./../../models/calculations-state.enum";
import FirebaseProjectFunctions from "./../../firebase/firebase-functions/project-service";
import * as projectActions from "../actions/project.actions";
import * as taskActions from "../actions/task.actions";
import {ThunkResult} from "../store.types";
import ProjectTransactions from "./transactions/project.transactions";
import FirebaseUsage from "../../firebase/firebase.usage";
import {COLLECTIONS} from "../../firebase/constants";
import {UserDocModel} from "../../models/responses/user.model";

import { subsManager } from "../middleware/subs-manager/subs-manager";
import cpmFunctionsController from "../../utils/cpm-functions/cpm-functions.controller";

export class ProjectThunk {
  static setActiveProjectId(
    projectId: string | undefined | null
  ): ThunkResult<Promise<void>> {
    return async (dispatch) => {
      dispatch(projectActions.Actions.setActiveProjectId(projectId));
    };
  }

  static uploadStrategy(
    activeProjectId: string,
    callback: () => void,
    navigationFunction: (projectId: string) => void
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      const user = getState().authorization.user;
      const listener = await FirebaseUsage.queryListener(COLLECTIONS.PROJECTS, [
          ["projectId", "==", activeProjectId],
          [`grade.${user?.userId}`, "!=", "undefined"]], (data) => {
            const setActiveProjectPromise = async (projectId) => {
                return dispatch(ProjectThunk.setActiveProjectId(projectId));
            }
            const projects: any[] = data.docs.map((e) => e.data());
            if (projects.length > 0) {
              const firstProject = projects[0];
              if (
                firstProject.updateState &&
                firstProject.updateState === CalculationsState.DONE
              ) {
                  listener();
                  setActiveProjectPromise(firstProject.projectId).then(() => {
                    subsManager.subscribeProjectList(user!.userId)
                    callback();
                    dispatch(projectActions.Actions.uploadingNewProjectFinished());
                    navigationFunction(firstProject.projectId);
                  });
              }
            }
      });
    };
  }

    static updateStrategy(
        data: any[],
        activeProjectId: string,
        callback: () => void,
        navigationFunction: (projectId: string) => void,
        clientId: string
    ): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                ProjectThunk.runRacingLines(data, dispatch).then(async (resp) => {
                    if (resp) {
                        data.map(async (el) => {
                            await FirebaseProjectFunctions.confirmUpload(el.id, true, el.name, activeProjectId, clientId)
                        //     await fetch(`http://34.159.104.116/confirm/update/${el.id}/${el.name}/${activeProjectId}`, {
                        //         method: 'GET',
                        //         headers: {
                        //             'Content-Type': 'application/json',
                        //             'Access-Control-Allow-Origin': '*',
                        //             'Access-Control-Allow-Credentials': 'true',
                        //             'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                        //             'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                        //         }
                        //     })
                        })
                        const user = getState().authorization.user;
                        const setActiveProjectPromise = async (projectId) => {
                            return dispatch(ProjectThunk.setActiveProjectId(projectId));
                        }
                        const firstProject = data[0];
                        setActiveProjectPromise(firstProject.id).then(() => {
                            subsManager.subscribeProjectList(user!.userId)
                            subsManager.subscribeProjectPermission(user!.userId)
                            subsManager.subscribeMemberList(firstProject.id)
                            callback();
                            dispatch(projectActions.Actions.uploadingNewProjectFinished());
                            navigationFunction(firstProject.id);
                        });
                    }}).catch((e) => {
                    console.log(e);
                    dispatch(projectActions.Actions.uploadingNewProjectFinished());
                    dispatch(
                        projectActions.Actions.uploadingNewProjectFail(
                            "Something went wrong with transforming XER to JSON"
                        )
                    );
                })
            } catch (e) {
                console.log(e);
                dispatch(projectActions.Actions.uploadingNewProjectFinished());
                dispatch(
                    projectActions.Actions.uploadingNewProjectFail(
                        "Something went wrong with transforming XER to JSON"
                    )
                );
            }
        };
    }

  static async runRacingLines (data: any[], dispatch) {
      try {
          for (const el of data) {
              const projectId = el.id
              await FirebaseProjectFunctions.fullCPMCalc(projectId, "true", dispatch, true)
          }
          return true
      } catch (e) {
          return false
      }
  }

    static uploadProject2(
        data: any[],
        callback: () => void,
        navigationFunction: (projectId: string) => void
    ): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            window.onbeforeunload = function (e) {
                e = e || window.event;
                if (e) {
                    e.returnValue = "Sure?";
                }
                return "Sure?";
            };
            dispatch(taskActions.Actions.taskListLoading());
            const user = getState().authorization.user;

            if (!user) {
                return console.error("No authorized user");
            }

            try {
                ProjectThunk.runRacingLines(data, dispatch).then(async (resp) => {
                    const setActiveProjectPromise = async (projectId) => {
                        return dispatch(ProjectThunk.setActiveProjectId(projectId));
                    }
                    if (resp) {
                        const firstProject = data[0];
                        data.map(async (el) => {
                            // await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, el.id, {
                            //     uploadState: CalculationsState.DONE,
                            //     name: el.name
                            // })
                          await FirebaseProjectFunctions.confirmUpload(
                            el.id,
                            false,
                            el.name,
                            null,
                            el.clientId
                          );
                            // await fetch(`http://34.159.104.116/confirm/${el.id}/${el.name}`, {
                            //     method: 'GET',
                            //     headers: {
                            //         'Content-Type': 'application/json',
                            //         'Access-Control-Allow-Origin': '*',
                            //         'Access-Control-Allow-Credentials': 'true',
                            //         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                            //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                            //     }
                            // })
                        })
                        setActiveProjectPromise(firstProject.id).then(() => {
                            subsManager.subscribeProjectList(user.userId)
                            subsManager.subscribeProjectPermission(user.userId)
                            subsManager.subscribeMemberList(firstProject.id)
                            callback();
                            dispatch(projectActions.Actions.uploadingNewProjectFinished());
                            navigationFunction(firstProject.id);
                        });
                    } else {
                        dispatch(projectActions.Actions.uploadingNewProjectFinished());
                        dispatch(
                            projectActions.Actions.uploadingNewProjectFail(
                                "Something went wrong with transforming XER to JSON"
                            )
                        );
                    }
                }).catch((e) => {
                        console.log(e);
                        dispatch(projectActions.Actions.uploadingNewProjectFinished());
                        dispatch(
                            projectActions.Actions.uploadingNewProjectFail(
                                "Something went wrong with transforming XER to JSON"
                            )
                        );
                    })
                console.log("Uploaded project(s) ===> ", data);
            } catch (e) {
                console.log(e);
                dispatch(projectActions.Actions.uploadingNewProjectFinished());
                dispatch(
                    projectActions.Actions.uploadingNewProjectFail(
                        "Something went wrong with transforming XER to JSON"
                    )
                );
            }
            window.onbeforeunload = null;
        };
    }


    static async runMonteCarlo(projectId: string, dispatch, trackedMilestones: any[] = [], upperLimit, lowerLimit, iterations) {
        try {
            await cpmFunctionsController.runMonteCarlo(trackedMilestones, upperLimit, lowerLimit, projectId, dispatch, iterations)
            return true
        } catch (e) {
            return false
        }
    }

  static projectDeletion(ids: string[], user: UserDocModel) {
    return async () => {
      try {
        await ProjectTransactions.projectDeletion(ids, user);
      } catch (e) {
        console.error("projectDeletion Error: ", e);
      }
    };
  }

  static sendMails(
    email: string,
    firstName: string,
    lastName: string,
    otherData?: {
      phoneNumber?: string;
      jobTitle?: string;
      company?: string;
    }
  ) {
    return async () => {
      try {
        await ProjectTransactions.sendMails(
          email,
          firstName,
          lastName,
          otherData
        );
      } catch (e) {
        console.error("sendMails Error: ", e);
      }
    };
  }
  static splitAtIndex(string) {
    const jsonStringArr: string[] = [];
    function split(string, index = Math.floor(string.length / 2)) {
      if (Buffer.byteLength(string) < 1000000) {
        jsonStringArr.push(string);
        return;
      }
      const dividedString = string.substring(0, index);
      const restString = string.substring(index);

      jsonStringArr.push(dividedString);

      split(restString);
    }
    split(string);
    return jsonStringArr;
  }
}
