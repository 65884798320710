import {Maybe} from "@martin_hotell/rex-tils";
import FirebaseProjectFunctions from "../firebase/firebase-functions/project-service";
import {CalendarModel} from "../models/responses/calendar.model";
import {Omit} from "redux-form";
import firebase from "firebase/compat/app";
import fs from "fs";
import {useUserSelector} from "../store/selectors/authorization.selectors";
// import HttpsError = firebase.functions.HttpsError;
// import axios from "axios";

interface TransformXerToJsonResponse {
    data: {
        project: any,
        calendar?: Omit<CalendarModel, 'project_id'>
    },
    errorMessage?: string
}

const toBase64 = file => new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export class FileApi {
    static async transformXerToJson(files: FileList | Maybe<File>, isUpdate: boolean | undefined, projectId: string | null, user): Promise<TransformXerToJsonResponse[]> {
        let response: TransformXerToJsonResponse[] = [];

        const uploadFile = async (files: FileList | Maybe<File>, index = 0) => {
            const file = files ? files[index] || files : null;
            if (file) {
                // let data: TransformXerToJsonResponse;
                //
                // const base64 = await toBase64(file);
                let uploadResp: any = null;
                // dataURLtoFile(base64, file.name);
                try {
                    const content = await file.text();
                    if (isUpdate) {
                        uploadResp = await FirebaseProjectFunctions.xerToJson(
                            content,
                            user!.userId,
                            projectId,
                            true
                        );
                        uploadResp = uploadResp.data;
                        // uploadResp = await fetch(`http://34.159.104.116/xer-update`, {
                        //     method: 'POST',
                        //     headers: {
                        //         'Content-Type': 'application/json',
                        //         'Access-Control-Allow-Origin': '*',
                        //         'Access-Control-Allow-Credentials': 'true',
                        //         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                        //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                        //     },
                        //     body: JSON.stringify({
                        //         content: content,
                        //         user_id: user!.userId,
                        //         project_id: projectId,
                        //         isUpdate: isUpdate
                        //     })
                        // })
                        //     .then(res => res.json())
                        //     .catch(err => console.log(err))
                    } else {
                        uploadResp = await FirebaseProjectFunctions.xerToJson(
                            content,
                            user!.userId,
                            "none",
                            false
                        );
                        uploadResp = uploadResp.data;
                        // uploadResp = await fetch(`http://34.159.104.116/xer-json/test`, {
                        //     method: 'POST',
                        //     headers: {
                        //         'Content-Type': 'application/json',
                        //         'Access-Control-Allow-Origin': '*',
                        //         'Access-Control-Allow-Credentials': 'true',
                        //         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                        //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                        //     },
                        //     body: JSON.stringify({
                        //         content: content,
                        //         user_id: user!.userId,
                        //         projectId: null,
                        //         isUpdate: isUpdate
                        //     })
                        // })
                        //     .then(res => res.json())
                        //     .catch(err => console.log(err))
                    }

                    // const l_d: any = await FirebaseProjectFunctions.transformXerToJson({
                    //     file: base64,
                    //     project_name: file.name
                    // });
                    // data = l_d.data.errorMessage
                    //     ? {
                    //         data: {
                    //             project: {
                    //                 name: file.name
                    //             }
                    //         },
                    //         errorMessage: l_d.data.errorMessage
                    //     } : l_d;
                    console.log("upload resp", uploadResp)
                } catch (e: any) {
                    uploadResp = {errorMessage: e.message}
                }
                response = uploadResp ? [...response, uploadResp] : response;
            }

            if (files && files[index + 1]) {
                await uploadFile(files, index + 1);
            }
        };

        await uploadFile(files);


        console.log(response)
        return response;
    }















    static async transformXerToJsonFromURL(filename): Promise<TransformXerToJsonResponse[]> {
        const response: TransformXerToJsonResponse[] = [];

        const uploadFile = async (files: FileList | Maybe<File>, index = 0) => {
            const file = files ? files[index] || files : null;
            if (file) {
                let data: TransformXerToJsonResponse;

                try {
                    const l_d: any = await FirebaseProjectFunctions.transformXerToJsonFromURL({
                        fileName:filename
                    });
                    data = l_d.data.errorMessage
                        ? {
                            data: {
                                project: {
                                    name: file.name
                                }
                            },
                            errorMessage: l_d.data.errorMessage
                        } : l_d;
                } catch (e: any) {
                    data = {
                        data: {
                            project: {
                                name: file.name
                            }
                        },
                        errorMessage: e.message
                    };
                }

                response.push(data as TransformXerToJsonResponse);
            }
            // if (files && files[index + 1]) {
            //     await uploadFile(files, index + 1);
            // }
        };

        await uploadFile(filename);


        return response;
    }





    static async getGoogleCloudSignedURL(files: FileList | Maybe<File>) {
        const file = files ? files[0] || files : null;
        if (file) {
            const {data}:any = await FirebaseProjectFunctions.getGoogleCloudSignedURL({
                fileName: file.name
            });
            const url = data.url;
            console.log(data);

            try {
                // let header = new Headers();

                // header.append("Access-Control-Allow-Origin", "http://localhost:3000")
                // header.append("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS")
                // header.append("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
                const response = await fetch(url, {
                  method: 'PUT',
                  body: file,
                });
                console.log(response);
                return file.name
              } catch (error) {
                console.error('Error:', error);
                return null
              }
              
        }
    }


    static fileSizeChekcer(files: FileList | Maybe<File>):boolean {

        const file:File = files ? files[0] || files : null;
        if (file) {
            return file.size < 6437184;
        }
        return false
    }


}
