import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Modal, Header } from "semantic-ui-react";
import { CheckList } from "../../../../../../../models/checklist-model";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import TaskModel from "../../../../../../../models/responses/task.model";
import TaskStatusModel from "../../../../../../../models/responses/task-status.model";
import {
  useUserAsMemberForActiveProjectSelector,
  useUserSelector,
} from "../../../../../../../store/selectors/authorization.selectors";
import { useTypedSelector } from "../../../../../../../store/selectors/selectors.utils";
import { GateKeeperEum } from "../../../../../../../models/gate-keeper.enum";

interface InjectedProps {
  isChecklistOpened: boolean;
  setIsChecklistOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isLockOpened: boolean;
  setIsLockOpened: React.Dispatch<React.SetStateAction<boolean>>;
  taskChecklist: CheckList[];
  addChecklistHandler: () => void;
  addImportedChecklistHandler: () => void;
  lockChecklist: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.MutableRefObject<any>;
  activeTask: TaskModel;
  disabled: boolean;
}

const TaskAddChecklist: React.FC<InjectedProps> = ({
  isChecklistOpened,
  setIsChecklistOpened,
  isLockOpened,
  setIsLockOpened,
  taskChecklist,
  addChecklistHandler,
  addImportedChecklistHandler,
  lockChecklist,
  inputValue,
  setInputValue,
  inputRef,
  activeTask,
  disabled
}) => {
  const allChecked: boolean = Array.isArray(activeTask.checklist)
    ? activeTask.checklist.every((el) => el.isChecked)
    : false;
  const user = useUserSelector();
  const activeProject = useTypedSelector(
    (state) => state.project.activeProject
  );
  const userAsMember = activeProject
    ? useUserAsMemberForActiveProjectSelector()
    : null;

  return (
    <>
      {activeTask.importedChecklist &&
        activeTask.importedChecklist.length > 0 &&
        activeTask.checklist.length === 0 && (
          <div className="checklist-option">
            <Button
              className="add-checklist import"
              onClick={() => addImportedChecklistHandler()}
              disabled={disabled}
            >
              Import checklist
            </Button>
          </div>
        )}
      {!isChecklistOpened && (
        <div className="checklist-option">
          {taskChecklist && taskChecklist.length > 0 ? (
            <>
              <Button
                className="add-checklist"
                style={{ marginLeft: "40px" }}
                onClick={() => setIsChecklistOpened(true)}
                disabled={disabled}
              >
                Add
              </Button>
              <Button
                className={
                  "add-checklist " +
                  (activeTask.checklist &&
                  activeTask.checklist.length > 0 &&
                  allChecked &&
                  activeTask.status !== TaskStatusModel.DECLARED_COMPLETE &&
                  user &&
                  userAsMember &&
                  (userAsMember.gateKeeper === GateKeeperEum.Both ||
                    userAsMember.gateKeeper === GateKeeperEum.LockChecklist)
                    ? "lock"
                    : "lock-disabled")
                }
                onClick={() => setIsLockOpened(true)}
                disabled={disabled}
              >
                Lock
              </Button>
            </>
          ) : (
            <Button
              className="add-checklist"
              onClick={() => setIsChecklistOpened(true)}
              disabled={disabled}
            >
              Create checklist
            </Button>
          )}
        </div>
      )}
      {isChecklistOpened && (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            addChecklistHandler();
          }}
        >
          <div className="checklist-option">
            <Input
              ref={inputRef}
              onChange={(_, data) => setInputValue(data.value)}
              placeholder="Add an item..."
              value={inputValue}
            />
          </div>
          <div className="checklist-option">
            <Button onClick={addChecklistHandler} className="btn-add" disabled={disabled}>
              Add
            </Button>
            <span
              className="close-icon"
              onClick={() => setIsChecklistOpened(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
        </form>
      )}
      <Modal
        closeIcon
        style={{ animationDuration: "1s" }}
        dimmer="blurring"
        className="modal-event"
        open={isLockOpened}
        onClose={() => setIsLockOpened(false)}
      >
        <Header
          textAlign="center"
          content={
            "Are you sure you want to lock the checklist? This cannot be undone."
          }
        />
        <Modal.Actions>
          <Button primary onClick={() => lockChecklist()}>
            Lock
          </Button>
          <Button basic onClick={() => setIsLockOpened(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TaskAddChecklist;
