import React, {useEffect, useState} from 'react'
import {Modal, Loader, Table} from 'semantic-ui-react'
import {useProjectMemberListSelector} from "../../../../store/selectors/team.selectors";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import {useActiveProjectSelector} from "../../../../store/selectors/project.selectors";
import {MessageType} from "../../../../models/responses/message.model";
import UseageChart from './components/UsageChart';
import LedgerEntry from "../../../../models/responses/ledger-entry.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import styles from './UsageModal.module.scss';

export default function UsageModal(props) {
    const { showUsageModal, setShowUsageModal } = props;
    const [usageData, setUsageData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [timeData, setTimeData] = useState<any>(null)
    const [showChart, setShowChart] = useState<any>("all")

    const projectMembers = useProjectMemberListSelector()
    const activeProject = useActiveProjectSelector()

    const modalWidth = (window.innerWidth * 0.9) - 40

    const randomGauss = () => {
        const theta = 2 * Math.PI * Math.random();
        const rho = Math.sqrt(-2 * Math.log(1 - Math.random()));
        return (rho * Math.cos(theta)) / 10.0 + 0.5;
    };

    console.log(randomGauss())

    const labels = {
        tasksJoined : "Tasks Joined",
        tasksLeft: "Tasks Left",
        tasksStarted: "Tasks Started",
        tasksCompleted: "Tasks Completed",
        tasksSuspended: "Tasks Suspended",
        tasksEvaluated: "Tasks Evaluated",
        comments: "Comments Added",
        other: "Other Events"
    }

    const keys = [
        "tasksJoined",
        "tasksLeft",
        "tasksStarted",
        "tasksCompleted",
        "tasksSuspended",
        "tasksEvaluated",
        "comments",
        "other"
    ]

    async function generateUsageData(projectId) {
        const timeNow = new Date().getTime()
        let data: any[] = []
        const ledgerEntries: LedgerEntry[] | any = await FirebaseUsage.getQuery(COLLECTIONS.LEDGER_ENTRY, ["projectId", "==", projectId])
            .then((snapshot) => snapshot.docs.map((doc) =>
                ({...doc.data(),
                    logTimestamp: doc.data().logTimestamp ? doc.data().logTimestamp : doc.data().timestamp
                })).sort((a, b) => a.logTimestamp.seconds - b.logTimestamp.seconds))
        const start = Math.floor(ledgerEntries[0].logTimestamp.seconds / 604800) * 604800
        const end = new Date().getTime() / 1000

        const userEntryModel = {all:
                {
                tasksJoined: 0,
                tasksLeft: 0,
                tasksStarted: 0,
                tasksCompleted: 0,
                tasksSuspended: 0,
                tasksEvaluated: 0,
                comments: 0,
                other: 0}
        }
        projectMembers.forEach((member) => {
            userEntryModel[member.userId] = {
                userId: member.userId,
                tasksJoined: 0,
                tasksLeft: 0,
                tasksStarted: 0,
                tasksCompleted: 0,
                tasksSuspended: 0,
                tasksEvaluated: 0,
                comments: 0,
                other: 0
            }
        })
        let dateRangeMap = new Map()
        for (let i = start; i < end; i += 604800) {
            dateRangeMap.set(i, {...userEntryModel})
        }

        for (const member of projectMembers) {
            let tasksJoined = 0
            let tasksLeft = 0
            const userEntries = ledgerEntries.filter((doc: LedgerEntry) => {
                const date = Math.floor(doc.logTimestamp!.seconds / 604800) * 604800
                let weekData = dateRangeMap.get(date)
                if (doc.userId === member.userId && weekData && doc.logTimestamp) {
                    switch (doc.type) {
                        case MessageType.TFJ:
                            tasksJoined += 1
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksJoined: weekData[member.userId].tasksJoined + 1},
                                all: {...weekData.all,
                                    tasksJoined: weekData.all.tasksJoined + 1}
                                })
                            break
                        case MessageType.TFL:
                            tasksLeft += 1
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksLeft: weekData[member.userId].tasksLeft + 1},
                                all: {...weekData.all,
                                    tasksLeft: weekData.all.tasksLeft + 1}
                            })
                            break
                        case MessageType.STD:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksStarted: weekData[member.userId].tasksStarted + 1},
                                all: {...weekData.all,
                                    tasksStarted: weekData.all.tasksStarted + 1}
                            })
                            break
                        case MessageType.COM:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksEvaluated: weekData[member.userId].tasksEvaluated + 1},
                                all: {...weekData.all,
                                    tasksEvaluated: weekData.all.tasksEvaluated + 1
                                }
                            })
                            break
                        case MessageType.DEC:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksCompleted: weekData[member.userId].tasksCompleted + 1},
                                all: {...weekData.all,
                                    tasksCompleted: weekData.all.tasksCompleted + 1
                                }
                            })
                            break
                        case MessageType.SUS:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksSuspended: weekData[member.userId].tasksSuspended + 1},
                                all: {...weekData.all,
                                    tasksSuspended: weekData.all.tasksSuspended + 1
                                }
                            })
                            break
                        case MessageType.EVL:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksEvaluated: weekData[member.userId].tasksEvaluated + 1},
                                all: {...weekData.all,
                                    tasksEvaluated: weekData.all.tasksEvaluated + 1
                                }
                            })
                            break
                        case MessageType.REJ:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    tasksEvaluated: weekData[member.userId].tasksEvaluated + 1},
                                all: {...weekData.all,
                                    tasksEvaluated: weekData.all.tasksEvaluated + 1
                                }
                            })
                            break
                        case MessageType.MSG:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    comments: weekData[member.userId].comments + 1},
                                all: {...weekData.all,
                                    comments: weekData.all.comments + 1
                                }
                            })
                            break
                        default:
                            dateRangeMap.set(date, {...weekData,
                                [member.userId]: {...weekData[member.userId],
                                    other: weekData[member.userId].other + 1},
                                all: {...weekData.all,
                                    other: weekData.all.other + 1
                                }
                            })
                            break
                    }
                    return true
                }
                return false
            })

            data.push({
                member: {...member,
                    tasksJoined: tasksJoined,
                    tasksLeft: tasksLeft
                },
                ledgerEntries: userEntries
            })
        }

        return {data, dateRangeMap}
    }

    useEffect(() => {
        if (activeProject) {
            setLoading(true)
            generateUsageData(activeProject.projectId).then((data) => {
                setUsageData(data.data)
                setTimeData(data.dateRangeMap)
                setLoading(false)
            })
        }
    }, [activeProject])


    return (
        <Modal
            open={showUsageModal}
            onClose={() => setShowUsageModal(false)}
            closeIcon
            style={{minWidth: "90%"}}
        >
            <Modal.Header>Project Member Engagement</Modal.Header>
            <Modal.Content>
                    {loading ? <Loader active inline='centered' /> :
                    <div>
                        <div className={styles.usageTable}>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Member</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Weeks Active</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Last Viewed</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Total Views</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Last Entry</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Number of Entries</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Tasks Joined</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Tasks Left</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center"><FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => setShowChart("all")} icon={faChartSimple}/></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {usageData.map((entry, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>{entry.member.userEmail}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                {
                                                    entry.ledgerEntries.length === 0 ? 0 :
                                                ((entry.ledgerEntries[entry.ledgerEntries.length - 1].logTimestamp.seconds -
                                                entry.ledgerEntries[0].logTimestamp.seconds) / 604800).toFixed(0)
                                                }
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">{entry.member.lastViewed ? entry.member.lastViewed.toDate().toDateString() : "Unknown"}</Table.Cell>
                                            <Table.Cell textAlign="center">{entry.member.totalViews ? entry.member.totalViews : 0}</Table.Cell>
                                            <Table.Cell textAlign="center">{entry.ledgerEntries.length > 0 ?
                                                entry.ledgerEntries[entry.ledgerEntries.length - 1].logTimestamp.toDate().toDateString() : "N/A"}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">{entry.ledgerEntries.length}</Table.Cell>
                                            <Table.Cell textAlign="center">{entry.member.tasksJoined}</Table.Cell>
                                            <Table.Cell textAlign="center">{entry.member.tasksLeft}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <FontAwesomeIcon
                                                    icon={faChartSimple}
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => setShowChart(entry.member.userId)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                        </div>
                        {
                            showChart && timeData &&
                        <UseageChart
                            width={modalWidth}
                            height={400}
                            userId={showChart}
                            timeData={timeData}
                            keys={keys}
                            labels={labels}
                            margin={{left: 10, top: 20, bottom: 0, right: 0}}
                        />
                        }
                    </div>}
            </Modal.Content>
        </Modal>
    )
}