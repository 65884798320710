
const getPredStatus = (task, scoreList)  => {
    let predStatus= 0;
    switch (true) {
        case task.status_code === 'TK_Complete':
            predStatus = 4;
            break;
        case scoreList.every(el => el === 1) || !scoreList.length:
            predStatus = 1;
            break;
        case scoreList.every(el => el === 3):
            predStatus = 3;
            break;
        default:
            predStatus = 2;
            break;
    }
    return predStatus;
};

const getScoreList = (closestPredList, relations) => {
    const scoreList: any[] = [];

    closestPredList.forEach(pred => {
        relations.forEach(relatedPred => {
            if ((relatedPred.pred_type === 'FS' || relatedPred.pred_type === 'SS')
                && pred.status_code === 'TK_NotStart'
            ) {
                scoreList.push(3);
            } else if (((relatedPred.pred_type === 'SF' || relatedPred.pred_type === 'SS') && pred.status_code === 'TK_Active') ||
                pred.status_code === 'TK_Complete'
            ) {
                scoreList.push(1);
            } else if (((relatedPred.pred_type === 'FF' || relatedPred.pred_type === 'SF') && pred.status_code === 'TK_NotStart') ||
                ((relatedPred.pred_type === 'FS' || relatedPred.pred_type === 'FF') && pred.status_code === 'TK_Active')
            ) {
                scoreList.push(2);
            }
        })
    });

    return scoreList;
};

export default function checkPredStatus (task, taskMap) {
    const predTasks = task.relations ? task.relations
        .filter(relation => relation.task_id === task.task_id)
        .map(relation => taskMap.get(relation.pred_task_id).data) : [];
    return getPredStatus(task, getScoreList(predTasks, task.relations));
};