import * as ledgerActions from '../../actions/ledger.actions';
import MessageModel from "../../../models/responses/message.model";

export class StateModel {
    ledgerList: MessageModel[] = [];
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: ledgerActions.Actions) {
    switch (action.type) {
        case ledgerActions.ActionNames.SET_LEDGER_LIST: {
            // console.log('🏭 state in SET_LEDGER_LIST', state)
            // console.log('🏭 action in SET_LEDGER_LIST', action)
            return {
                ...state,
                ledgerList: action.payload.ledgerList,
            }
        }
        default:
            return state;
    }
}
