import * as React from 'react';
import styled from "styled-components";


const LoaderWrap = styled.div`
 position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
`;



const LoaderPage: React.FunctionComponent = () => (
    <LoaderWrap>
        <div className="lds-roller">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </LoaderWrap>
);

export default LoaderPage;
