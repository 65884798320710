
import React, {useEffect, useRef, useState} from "react";
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import {useActiveProjectSelector} from "../../../../store/selectors/project.selectors";
import {
    Button, Dimmer,
    Form,
    FormField,
    FormGroup,
    FormInput,
    FormRadio,
    FormTextArea,
    Label, Loader,
    Modal,
    Select
} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/pro-solid-svg-icons";
import {handleImage} from "../../../../utils/image.utils";
import createJiraIssue from "../../../../utils/suportRequest.utils";


export default function CustomerRequestModal(props: any) {
    const user = useUserSelector();
    const activeProject = useActiveProjectSelector();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [attachments, setAttachments] = useState<any[]>([])
    const [attachmentLoading, setAttachmentLoading] = useState(false)
    const [type, setType] = useState("feature")
    const [submitting, setSubmitting] = useState(false)
    const [description, setDescription] = useState("")
    const [about, setAbout] = useState("")
    const [priority, setPriority] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [supportRef, setSupportRef] = useState<any>(null)
    const [submissionAttempted, setSubmissionAttempted] = useState(false)

    useEffect(() => {
        if (submitting) {
            if (description === "") {
                setSubmitting(false)
                return
            }
            if (about === "") {
                setSubmitting(false)
                return
            }
            if (priority === "") {
                setSubmitting(false)
                return
            }
            const request = {
                description,
                about,
                priority,
                attachments,
                type,
                userEmail: user?.userEmail || "No user email",
                project: activeProject,
                status: "open"
            }
            setLoading(true)
            createJiraIssue(request).then((resp) => {
                console.log(resp)
                setSupportRef(resp.data)
                setSubmitted(true)
                setSubmitting(false)
                setLoading(false)
            })
        }

    }, [submitting]);

    return (
        <Modal dimmer={"blurring"} open onClose={props.onClose} closeIcon closeOnDimmerClick={false}>
            <Modal.Header>Make a request or log an issue</Modal.Header>
            <Modal.Content>
                {submitted ? <div>
                    <h4>Request submitted successfully.</h4>
                        <h4>Your support reference is <b>{supportRef}</b>. Please make a note of this for future reference.</h4>
                    <h4>Thank you for your feedback. We will get back to you as soon as possible.</h4>
                </div> :
                <div>
                <Form>
                    <FormInput error={submissionAttempted && description === ""} label={"Description"} placeholder={"Summary of request..."} content={description} onChange={(e) => setDescription(e.target.value)} />
                    <FormTextArea error={submissionAttempted && about === ""} label={"About"} placeholder={"Tell us more about your request..."} content={about} onChange={(e) => setAbout(e.target.value)} />
                    <input type="file"
                           ref={fileInputRef}
                           style={{display: "none"}}
                           accept={"image/*"}
                           multiple
                           onChange={(e) => {
                               console.log(e)
                               setAttachmentLoading(true)
                               handleImage(e,
                                   (attachment: any) => setAttachments(prevState => [...prevState, attachment]),
                                   (status: boolean) => setAttachmentLoading(status))
                                   .catch(error => console.log(error))}}
                    />
                    <Button onClick={() => fileInputRef.current?.click()} size={"tiny"}
                            style={{marginBottom: "10px"}}
                    >
                        <FontAwesomeIcon icon={faImage} style={{marginRight: "8px"}} />
                        Attach an image</Button>
                    {attachmentLoading && "Uploading..."}
                    {attachments.length > 0 && !attachmentLoading &&
                        <Label style={{marginBottom: "10px"}}>
                            Attachments: {attachments.map(attachment => attachment.filename).join(", ")}
                        </Label>}
                    <FormGroup inline>
                        <label>Category:</label>
                        <FormRadio checked={type === "feature"} label={"Feature request"} onClick={() => setType("feature")} />
                        <FormRadio checked={type === "bug"} label={"Bug report"} onClick={() => setType("bug")} />
                        <FormRadio checked={type === "other"} label={"Other"} onClick={() => setType("other")} />
                    </FormGroup>
                    <FormField error={submissionAttempted && priority === ""} label={"Priority"}
                               control={Select}
                                 options={type === "bug" ? [
                                      {key: "medium", text: "This is a minor inconvenience", value: "Medium"},
                                      {key: "high", text: "This is a significant inconvenience", value: "High"},
                                     {key: "highest", text: "This is stopping me completing my work now", value: "Highest"},
                                 ] : type === "feature" ? [
                                        {key: "low", text: "This would be nice to have", value: "Low"},
                                        {key: "medium", text: "This would be useful", value: "Medium"},
                                        {key: "high", text: "This would be very useful", value: "High"},
                                    ] : [
                                        {key: "low", text: "This is not important", value: "Low"},
                                        {key: "medium", text: "This is important", value: "Medium"},
                                        {key: "high", text: "This is very important", value: "High"},
                                    ]}
                               value={priority}
                               onChange={(e: any, {value}) => setPriority(value as string)}
                    />
                </Form>
                <Dimmer active={loading} inline="true" inverted>
                    <Loader active={loading} inline inverted />
                </Dimmer>
                </div>}
            </Modal.Content>
            <Modal.Actions>
                {!submitted && <Button positive onClick={() => {
                    setSubmitting(true)
                    setSubmissionAttempted(true)
                }}>Submit</Button>}
                <Button onClick={props.onClose}>{submitted ? "Close" : "Cancel"}</Button>
            </Modal.Actions>
        </Modal>
    )
}