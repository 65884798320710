import {useTypedSelector} from "../selectors.utils";
import TaskModel from "../../../models/responses/task.model";
import {Maybe} from "@martin_hotell/rex-tils";
import { CheckList } from "../../../models/checklist-model";
import {createSelector} from "reselect";

export const useInProgressTaskListSelector = () => useTypedSelector<TaskModel[]>(state => state.task.tasks.inProgress);
export const useCompleteTaskListSelector = () => useTypedSelector<TaskModel[]>(state => state.task.tasks.confirmedComplete);
export const useDeclaredCompleteTaskListSelector = () => useTypedSelector<TaskModel[]>(state => state.task.tasks.declaredComplete);
export const useQueuedTaskListSelector = () => useTypedSelector<TaskModel[]>(state => state.task.tasks.queued);
export const useAllTaskListSelector = () => useTypedSelector<TaskModel[]>(state => [...state.task.tasks.inProgress, ...state.task.tasks.confirmedComplete, ...state.task.tasks.declaredComplete, ...state.task.tasks.queued, ...state.task.tasks.pending]);
export const usePendingTaskListSelector = () => useTypedSelector<TaskModel[]>(state => state.task.tasks.pending);

export const useTaskListLoadCountSelector = () => useTypedSelector<number>(state => state.task.taskListLoadCount);
export const useActiveTaskSelector = () => useTypedSelector<Maybe<TaskModel>>(state => state.task.activeTask);

export const useLightSelectedTaskSelector = () => useTypedSelector<Maybe<TaskModel>>(state => state.task.lightSelectedTask);

const activeTaskListSelector = createSelector(
    [state => state.activeTask], (activeTask) => activeTask ? activeTask.checklist: []
)

export function useActiveTaskChecklistSelector () {
    const activeTask = useTypedSelector(state => state.task.activeTask);
    return activeTaskListSelector({activeTask: activeTask});
}

export const useCompletedTasksCountSelector = () => useTypedSelector<number>(state => state.project.activeProject ? state.project.activeProject.completedTaskCount : 0);


const completedTasksAsPercentSelector = createSelector(
    [state => state.activeProject], (activeProject) => {
        const completedTasksCount: number = activeProject ? activeProject.completedTaskCount : 0;
        const totalTaskCount: number = activeProject ? activeProject.totalTaskCount : 0;

        return totalTaskCount !== 0 ? (Math.round((completedTasksCount / totalTaskCount) * 100) || 0) : 0;
    })

export function useCompletedTasksAsPercentSelector() {
    const activeProject = useTypedSelector(state => state.project.activeProject)
    return completedTasksAsPercentSelector({activeProject: activeProject});
}
export const usePredeccessorsViewSelector = () => useTypedSelector<boolean>(state => state.task.predecessors.open);
export const useSuccessorsViewSelector = () => useTypedSelector<boolean>(state => state.task.successors.open);
