import store from "../../../../store/store";

export default function generateDAGData (taskId) {
    const cpmMap = store.getState().project.cpmMap

    let data = new Map()
    let dataArr: any[] = []
    function getParents (id: string) {
        const task = cpmMap.get(id)
        let parents = cpmMap.get(`${id}:preds`).map((pred: string) => {
            const parentLink = cpmMap.get(pred)
            return {parentId: parentLink.pred_task_id, ad: parentLink.ad}
        })
        let parentData: string[] = []
        parents.forEach(parent => {
            if (parent.ad === task.ad) {
                parentData.push(parent.parentId)
                if (!data.has(parent.parentId)) {
                     getParents(parent.parentId)
                }
            }
        })
        data.set(id, {id: id, parentIds: parentData})
        dataArr.push({id: id, parentIds: parentData})
    }

    getParents(taskId)

    return dataArr

}