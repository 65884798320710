import TaskModel from "../../../models/responses/task.model";
import { UserDocModel } from "../../../models/responses/user.model";
import { MessageType } from "../../../models/responses/message.model";
import { SuspendReasons } from "../../../models/suspend-reason.enum";
import firebase from "firebase/app";
import { Maybe } from "@martin_hotell/rex-tils";
import FirebaseTaskFunctions from "../../../firebase/firebase-functions/task-service";
import { Timestamp } from "firebase/firestore";

export default abstract class TaskTransactions {
  public static async evaluateCompletionTask(
    taskId: string,
    projectId: string,
    user: UserDocModel,
    accepted: boolean,
    userEmail: string,
    type: MessageType,
    eventTimestamp: Timestamp
  ) {
    return FirebaseTaskFunctions.evaluateCompletionTask({
      taskId,
      projectId,
      user,
      accepted,
      userEmail,
      type,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
    });
  }

  public static async evaluateCompletionTasks(user: Maybe<UserDocModel>, projectId: string) {
    return FirebaseTaskFunctions.evaluateCompletionTasks({ user, projectId });
  }

  public static async completeChecklistNotification(
    taskId: string,
    projectId: string,
    user: UserDocModel
  ) {
    return FirebaseTaskFunctions.completeChecklistNotification({
      taskId,
      projectId,
      user,
    });
  }

  public static async taskBehindScheduleNotification(
    taskId: string,
    projectId: string,
    user: UserDocModel
  ) {
    return FirebaseTaskFunctions.taskBehindScheduleNotification({
      taskId,
      projectId,
      user,
    });
  }

  public static async assignedToTaskforceNotification(
    taskId: string,
    projectId: string,
    user: UserDocModel
  ) {
    return FirebaseTaskFunctions.assignedToTaskforceNotification({
      taskId,
      projectId,
      user,
    });
  }

  public static async declareCompleteTask(
    taskId: string,
    projectId: string,
    user: UserDocModel,
    userEmail: string,
    type: MessageType,
    eventTimestamp: Timestamp
  ) {
    return FirebaseTaskFunctions.declareCompleteTask({
      taskId,
      projectId,
      user,
      userEmail,
      type,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
    });
  }

  public static async runActiveTask(
    taskId: string,
    projectId: string,
    user: UserDocModel,
    userEmail: string,
    type: MessageType,
    eventTimestamp: Timestamp
  ) {
    return FirebaseTaskFunctions.runActiveTask({
      taskId,
      projectId,
      user,
      userEmail,
      type,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
    });
  }

  public static async setBlockTask(
    taskId: string,
    projectId: string,
    suspendReason: string,
    blocked: boolean
  ) {
    return FirebaseTaskFunctions.setBlockTask({
      taskId,
      projectId,
      suspendReason,
      blocked,
    });
  }

  public static async suspendActiveTask(
    taskId: string,
    projectId: string,
    suspendReason: SuspendReasons | string,
    user: UserDocModel,
    userEmail: string,
    msgType: MessageType,
    eventTimestamp: Timestamp
  ) {
    return FirebaseTaskFunctions.suspendActiveTask({
      taskId,
      projectId,
      suspendReason,
      user,
      userEmail,
      msgType,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
    });
  }

  public static async changeRemainingDurationOfActiveTask(
    taskId: string,
    projectId: string,
    forcastDate: Date,
    user: UserDocModel,
    userEmail: string
  ) {
    return FirebaseTaskFunctions.changeRemainingDurationOfActiveTask({
      taskId,
      projectId,
      forcastDate,
      user,
      userEmail,
    });
  }

  public static sendPost(
    taskId: string,
    text: string,
    user: UserDocModel,
    projectId: string
  ) {
    return FirebaseTaskFunctions.sendPost({
      taskId,
      projectId,
      text,
      user,
    });
  }

  public static updateUserTaskSeen(
    taskId: string,
    user: UserDocModel,
    seen: boolean
  ) {
    return FirebaseTaskFunctions.updateUserTaskSeen({
      taskId,
      user,
      seen,
    });
  }

  public static joinTaskForce(
    taskId: string,
    projectId: string,
    userId: string,
    type: MessageType,
    eventTimestamp: Timestamp,
    user: Maybe<UserDocModel>,
    memberId: string
  ) {
    return FirebaseTaskFunctions.joinTaskForce({
      taskId,
      projectId,
      user,
      userId,
      type,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
      memberId,
    });
  }

  public static addTaskForce(
    taskId: string,
    projectId: string,
    memberId: string,
    whoAdded: string,
    type: string,
    groupId: string,
    user: Maybe<UserDocModel>
  ) {
    return FirebaseTaskFunctions.addTaskForce({
      taskId,
      projectId,
      memberId,
      whoAdded,
      type,
      groupId,
      user,
    });
  }

  public static removeTaskForce(
    taskId: string,
    projectId: string,
    userId: string,
    type: string,
    groupId: string
  ) {
    return FirebaseTaskFunctions.removeTaskForce({
      taskId,
      projectId,
      userId,
      type,
      groupId,
    });
  }

  public static leaveTaskForce(
    taskId: string,
    projectId: string,
    userId: string,
    type: MessageType,
    eventTimestamp: Timestamp,
    user: Maybe<UserDocModel>,
    memberId: string
  ) {
    return FirebaseTaskFunctions.leaveTaskForce({
      taskId,
      projectId,
      memberId,
      userId,
      type,
      eventTimestamp: eventTimestamp.toDate().toISOString(),
      user,
    });
  }

  public static async addNewTaskList(
    body: Partial<TaskModel>[],
    projectId: string
  ) {
    return FirebaseTaskFunctions.addNewTaskList({
      body,
      projectId,
    });
  }

  public static async addCompletedTaskCount(
    projectId: string,
    defaultCompletedTaskCount: number
  ) {
    return FirebaseTaskFunctions.addCompletedTaskCount({
      projectId,
      defaultCompletedTaskCount,
    });
  }

  public static async refreshAfterUndo(task: TaskModel, projectId: string) {
    return FirebaseTaskFunctions.refreshAfterUndo({
      task,
      projectId,
    });
  }

  public static async addEventLog(
    projectId: string,
    taskId: string,
    type: MessageType,
    user: UserDocModel
  ) {
    return FirebaseTaskFunctions.addEventLog({
      projectId,
      taskId,
      type,
      user,
    });
  }

  public static async updateCheckListLock(
    taskId: string,
    memberId: string,
    checkListLock: boolean
  ) {
    return FirebaseTaskFunctions.updateCheckListLock({
      taskId,
      memberId,
      checkListLock,
    });
  }
}
