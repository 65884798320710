export default class Validations {


    public static email(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public static sameEmailDomain(checkingEmail: string, baseEmail: string) {
        const index = checkingEmail.indexOf('@');
        return baseEmail.includes(checkingEmail.slice(index));
    }


    public static required(value: any): boolean {
        return !value;
    };

    public static emailInversion(email: string): boolean {
        return !Validations.email(email);
    }
}


export const existNestedFields = <T = any>(obj: T, fields: any): boolean => {
    return Boolean(fields.reduce((a: any, v) => a ? a[v] : obj[v], null))
};
