import React, { useRef, useCallback, useEffect, useState } from 'react';
import { UploadProjectData } from './UploadProjectData';
import { ProjectThunk } from '../../../../../../store/thunk/project.thunk';
import { useDispatch } from 'react-redux';
import { useActiveProjectSelector } from '../../../../../../store/selectors/project.selectors';
import { useTypedSelector } from '../../../../../../store/selectors/selectors.utils';
// import { CalendarModel } from '../../../../../../models/responses/calendar.model';
// import { Omit } from 'redux-form';
import * as projectActions from '../../../../../../store/actions/project.actions';
// import { FirebaseProjectFunctions } from '../../../../../../firebase/firebase-functions/project-service';
import { useNavigate } from "react-router-dom";
import RouteNameConstants from "../../../../../../constants/route-name.constants";
// import store from '../../../../../../store/store';

interface InjectProps {
  open: boolean;
  onClose: () => void;
  title: string;
  //handleInput: () => void;
  uploadStrategy?: boolean;
  setUploadStrategyModalOpen: (value: boolean) => void;
}

type PropsType = InjectProps;

export const UploadProjectDataModal: React.FC<PropsType> = (props) => {
  const dispatch = useDispatch();
  const activeProject = useActiveProjectSelector();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const uploadingError = useTypedSelector(
    (state) => state.project.uploadingError
  );
  const user = useTypedSelector((state) => state.authorization.user);
  const isMountedRef = useRef(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      setErrorMessage(uploadingError || '');
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [uploadingError]);

  const createNewProject = useCallback(
    async (
      projects:any[],
      callback: () => void
    ) => {
      const startTime = new Date().getTime();
      console.log('createNewProject started');
      try {
        if (isMountedRef.current) {
            // @ts-ignore
          dispatch(ProjectThunk.uploadProject2(projects, callback,
              (projectId) => {
                navigate(`/${projectId}/${RouteNameConstants.FLOW}`)
              }
              ))
        }
      } catch (e) {
        console.log(e);
        if (isMountedRef.current) {
          dispatch(projectActions.Actions.uploadingNewProjectFinished());
        }
      }
      console.log(
        `createNewProject Took ${(new Date().getTime() - startTime) / 1000} s`
      );
    },
    [dispatch]
  );

  const updateStrategy = useCallback(
    async (
      projects: any[],
      callback: () => void
    ) => {
      console.log('update project started');
      try {
          // @ts-ignore
          dispatch(ProjectThunk.updateStrategy(projects, activeProject.projectId, callback,
              (projectId) => {
                navigate(`/${projectId}/${RouteNameConstants.FLOW}`)

              }, activeProject!.clientId? activeProject!.clientId : ""
          ));
      } catch (e) {
        setErrorMessage('Something went wrong with updating Strategy');
      }
    },
    [activeProject, user, dispatch]
  );

  return (
    <UploadProjectData
      {...props}
      errorMessage={errorMessage}
      multiFile={!props.uploadStrategy}
      setErrorMessage={setErrorMessage}
      isUpdate = {props.uploadStrategy ? true : false}
      uploadHandler={props.uploadStrategy ? updateStrategy : createNewProject}
      // setUploadStrategyModalOpen={props.setUploadStrategyModalOpen}
    />
  );
};
