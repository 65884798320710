import parseXERContent from "./functions/parseXERContent";
import runUpdates from "./functions/updateStrategy";
// import {uploadFile} from "../../services/transformXerToJson/uploadFiletoBucket";
import firebaseConfig from "../../config/firebase.config";
import {COLLECTIONS} from "../../firebase/constants";
import {ClientDocModel} from "../../models/responses/client.model";
import FirebaseUsage from "../../firebase/firebase.usage";
import { Buffer } from 'buffer';

class XerToJsonController {
    static xerToJson = xerToJson;
    static confirmXerToJson = confirmXER;
    static updateXerToJson = updateXER;
    static confirmUpdateXerToJson = confirmUpdateXER;
    static confirmUpload = confirmUpload;
}
export default XerToJsonController;

async function xerToJson(req: any) {
    try {
        const { content, userId, isUpdate, projectId } = req;
        let xerJsonResult;
        if (isUpdate) {
            xerJsonResult = await XerToJsonController.updateXerToJson({
                content: content,
                user_id: userId,
                project_id: projectId,
                hostName: req.hostname,
            });
        } else {
            xerJsonResult = await parseXER({
                content: content,
                user_id: userId,
            });
        }
        console.log(xerJsonResult, "xerJsonResult");
        return {data: xerJsonResult};
    } catch (e: any) {
        console.log(e);
        return {
            data: {
                result: false,
                errorMessage: e.message
                    ? e.message
                    : "Something went wrong updating task",
            },
        };
    }
}

async function confirmUpload(req: any) {
    try {
        const { project_id, is_update, project_name, old_project_id ,clientId} = req;
        let host:any = "";
        // if(clientId && authUser.isProjectsAdmin){
        if (clientId){
            const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["clientId", "==", clientId])
                .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);
            host = client.domain;
        }
        if(!host){
            const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", "localhost"])
                .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);

            if(client){
                host = client.domain;
            }
            else{
                const clientRef = FirebaseUsage.getBlankDoc(COLLECTIONS.CLIENTS)
                await FirebaseUsage.setDocumentWithDoc(clientRef, {
                    domain:"localhost",
                    clientId:clientRef.id,
                    clientName:"localhost"
                })
            }
            host = "localhost"
        }
        console.log("host", host);
        let uploadResult;
        if (is_update) {
            uploadResult = await XerToJsonController.confirmUpdateXerToJson({
                project_id: project_id,
                project_name: project_name,
                old_project_id: old_project_id,
                hostName: host,
            });
        } else {
            uploadResult = await XerToJsonController.confirmXerToJson({
                project_id: project_id,
                project_name: project_name,
                hostName: host,
            });
        }
        console.log(uploadResult, "uploadResult");
        return {
            data: uploadResult,
        };
    } catch (e: any) {
        console.log(e);
        return {
            data: {
                result: false,
                errorMessage: e.message
                    ? e.message
                    : "Something went wrong updating task",
            },
        };
    }
}

async function parseXER(req: {content: string, user_id: string}) {
    try {
        console.log('Fetching Data...');
        const { content, user_id } = req; // get the username from the request
        const db = FirebaseUsage.database()
        const data = await parseXERContent(content, user_id)
        let buffer = Buffer.from(content, 'utf-8');
        console.log(data);
        // uploadFile(data[0][0][1], buffer, firebaseConfig.xerUploadBucketName).catch(e => console.error(e))
        FirebaseUsage.uploadFileToStorage(`${firebaseConfig.xerUploadBucketName}/${data[0][0][1]}`, buffer).catch(e => console.error(e))
        // res.send(JSON.stringify(Object.fromEntries(data)))
        return data[0]
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function confirmXER(req: {project_id: string, project_name: string, hostName: string}) {
    try {
        const { project_id,
            project_name ,
            hostName
        } = req;
        const db = FirebaseUsage.database()

        const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", hostName])
            .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);

        if(!client){
            console.error("issue  with getting client info");
            return "issue  with getting client info"
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, project_id, {
            uploadState: 'done',
            name: project_name,
            clientId: client.clientId
        });
        return "done"
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function updateXER (req: {content: string, user_id: string, project_id: string,hostName:string}) {
    try {
        const { content, user_id, project_id } = req;
        const db = FirebaseUsage.database()
        const data = await parseXERContent(content, user_id)
        await runUpdates(project_id, data[0][0][1], data[1], user_id)
        return data[0]
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function confirmUpdateXER (req: {project_id: string,
    project_name: string,
    old_project_id: string,
    hostName:string
}) {
    try {
        const {
            project_id,
            old_project_id,
             hostName
        } = req;
        const oldProject = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, old_project_id).then(doc => doc.data())
        const oldProjectData = oldProject;

        const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", hostName])
            .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);
        if(!client || !oldProjectData){
            console.error("issue  with getting client info or previous project data");
            return "issue  with getting client info"
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, project_id, {
            uploadState: 'done',
            name: oldProjectData.name,
            grade: oldProjectData.grade,
            clientId: client.clientId,
        })
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, old_project_id, {
            uploadState: 'archived',
            archived: true,
            supersededBy: project_id
        })
        return "done"
    }
    catch (err) {
        console.error(err);
        return err
    }
}