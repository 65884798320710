import * as teamActions from '../../actions/team.actions';
import ProjectMembersModel from "../../../models/responses/project-members.model";
import {PendingMembershipsModel} from "../../../models/pending-memberships.model";

export class StateModel {
    memberList: ProjectMembersModel[] = [];
    pendingMemberList: PendingMembershipsModel[] = [];
    loadingSidebar = true;
    loadingMemberGrade = false;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: teamActions.Actions) {
    switch (action.type) {
        case teamActions.ActionNames.SET_PROJECTS_MEMBER_LIST: {
            return {
                ...state,
                memberList: action.payload.memberList,
                loadingSidebar: false
            }
        }
        case teamActions.ActionNames.SET_PENDING_MEMBER_LIST: {
            return {
                ...state,
                pendingMemberList: action.payload.memberList,
                loadingSidebar: false
            }
        }
        case teamActions.ActionNames.LOADING_TEAM_SIDEBAR: {
            return {
                ...state,
                loadingSidebar: !state.loadingSidebar
            }
        }
        case teamActions.ActionNames.LOADING_MEMBER_GRADE: {
            return {
                ...state,
                loadingMemberGrade: true
            }
        }
        case teamActions.ActionNames.LOAD_MEMBER_GRADE: {
            return {
                ...state,
                loadingMemberGrade: false
            }
        }
        default:
            return {...state};
    }
}
