import {useTypedSelector} from "./selectors.utils";
import {Chip, ReportItem} from "../store.types";
import { SearchParametersModel } from "../reducers/search";
import {createSelector} from "reselect";

// Search
export const useSearchLoadingSelector = () => useTypedSelector<boolean>(state => state.search.loading);
export const useSearchChipsSelector = () => useTypedSelector<Chip[]>(state => state.search.chipsList);
export const useReportListSelector = () => useTypedSelector<ReportItem[]>(state => state.search.reportList);

const statusListSelector = createSelector([state => state.chipsList], (chipsList) =>
    chipsList.filter(chip => chip.type === 'status')
);

export function useStatusListSelector () {
    const chipList = useTypedSelector<Chip[]>(state => state.search.chipsList)
    return chipList.length > 0 ? statusListSelector({chipsList: chipList}) : [];
}
export const useSearchParamatersSelector = () => useTypedSelector<SearchParametersModel>(state => state.search.parameters);

export const useMapSearchTextSelector = () => useTypedSelector<string>(state => state.search.mapSearchText);


