import { SearchParametersModel } from "../../store/reducers/search"
import FirebaseUsage from "../firebase.usage"
import { ReportItem } from "../../store/store.types"
import { ReportFunctions } from "../../models/firebase-functions.model"

export class FirebaseReportFunctions {
    
    public static async addReportItem(args: {
        projectId: string, 
        name: string, 
        params: SearchParametersModel
    }) {
        return await FirebaseUsage.functions(ReportFunctions.ADD_REPORT_ITEM,{ timeout: 10000000 })({
            ...args
        })
    }

    public static async removeReportItem(args: {
        reportId: string
    }) {
        return await FirebaseUsage.functions(ReportFunctions.REMOVE_REPORT_ITEM,{ timeout: 10000000 })({
            ...args
        })
    }

    public static async editReportItem(args: {
        report: ReportItem
    }) {
        return await FirebaseUsage.functions(ReportFunctions.EDIT_REPORT_ITEM,{ timeout: 10000000 })({
            ...args
        })
    }
}