import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProjectModel from "../../../../../models/responses/project.model";
import {
  useUserAsMemberMapSelector,
  useUserSelector,
} from "../../../../../store/selectors/authorization.selectors";
import { useDispatch } from "react-redux";
import { useProjectMemberListSelector } from "../../../../../store/selectors/team.selectors";
import {
  useActiveProjectSelector,
  useProjectListSelector,
  useProjectLoadingStageSelector,
} from "../../../../../store/selectors/project.selectors";
import { MemberOrganizationEnum } from "../../../../../models/member-organization.enum";
import { TeamThunk } from "../../../../../store/thunk/team.thunk";
import { Accordion, AccordionTitleProps, List } from "semantic-ui-react";
import { ProjectThunk } from "../../../../../store/thunk/project.thunk";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassEnd,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import { faGears } from "@fortawesome/pro-solid-svg-icons";
import { NavigationBarContent } from "./NavigationBarContent";
import { useNavigate } from "react-router-dom";
import FirebaseProjectFunctions from "../../../../../firebase/firebase-functions/project-service";
import fetch from "node-fetch";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import FirebaseUsage from "../../../../../firebase/firebase.usage";

interface InjectedProps {
  projects: ProjectModel[];
  setOpenUpdateStrategyModal(val: boolean): void;
}

export const NavigationProjectList: React.FC<InjectedProps> = (props) => {
  const userAsMemberMap = useUserAsMemberMapSelector();
  const projectLoadingStage = useProjectLoadingStageSelector();
  const dispatch = useDispatch();
  const user = useUserSelector();
  const projectMembersList = useProjectMemberListSelector();
  const activeProject = useActiveProjectSelector();
  const history = useNavigate();
  const [nominatedMembersList, setNominatedMembersList] = useState<
    { userEmail: string; id: string }[]
  >([]);
  const [projectIndex, setProjectIndex] = useState<number>(-1);
  const projectId: any = localStorage.getItem("projectId");
  const projects = useProjectListSelector();
  const [largeProjectNameWidth, setLargeProjectNameWidth] = useState(0);
  useEffect(() => {
    setLargeProjectNameWidth(
      projects.reduce((acc, project) => {
        if (project.name.length > acc) {
          return project.name.length;
        }
        return acc;
      }, 0)
    );
  }, []);
  const [activeDropdown, setActiveDropdown] = useState<{
    projectId: string | undefined;
    isActive: boolean;
  }>({
    projectId: projectId,
    isActive: false,
  });
  const lastSuperActor = useMemo(() => {
    return Boolean(
      user &&
        projectMembersList
            // @ts-ignore
          .filter((member) => member.userId !== user.userId)
          .every(
            (member) => member.grade !== MemberOrganizationEnum.SUPER_ACTOR
          )
    );
  }, [user, projectMembersList]);

  const leaveProjectHandler = useCallback(
    (projectId: string) => {
      const member = userAsMemberMap[projectId];
      if (member) {
        dispatch(
          // @ts-ignore
          TeamThunk.removeMember(
            member.memberId,
            projectId,
            member.grade === MemberOrganizationEnum.GROUPS ? "group" : "user",
            nominatedMembersList,
            lastSuperActor
          )
        );
      }
    },
    [userAsMemberMap, lastSuperActor, nominatedMembersList, dispatch]
  );

  const handleProjectClick = useCallback(
    async (titleProps: AccordionTitleProps, project: ProjectModel) => {
      const { index } = titleProps;
      const newIndex = projectIndex === index ? -1 : index;
      if (project.projectId !== activeDropdown.projectId) {
        setActiveDropdown({
          projectId: project.projectId,
          isActive: true,
        });
      } else {
        setActiveDropdown({
          ...activeDropdown,
          isActive: !activeDropdown.isActive,
        });
      }
      history(`/${project.projectId}/flow`);

        const formerProject = activeProject ? activeProject.projectId : null;
        // @ts-ignore
      dispatch(ProjectThunk.setActiveProjectId(project.projectId));
      // try {
      //     if (formerProject !== project.projectId) {
      //         FirebaseProjectFunctions.fullCPMCalc(project.projectId, "false", dispatch)
      //             .then(() => {
      //                 console.log("cpm run successful")
      //             })
      //     }
      // } catch (e) {
      //     console.log(e);
      // }
      setProjectIndex(newIndex as number);
    },
    [projectIndex, history, dispatch, activeDropdown]
  );

  return props.projects.length ? (
    <List.List className="inner-list">
      {props.projects.map((project, i) => {
        const member = userAsMemberMap[project.projectId];
        return (
          <List.Item key={project.projectId}>
            <Accordion styled className="navigation-accordion-project">
              <Accordion.Title
                index={i}
                active={projectIndex === i}
                onClick={
                  projectLoadingStage.projectStopped &&
                  projectLoadingStage.projectNames.some(
                    (el) => el === project.name
                  )
                    ? undefined
                    : (_, titleProps) => handleProjectClick(titleProps, project)
                }
                className={classNames("navigation-accordion-item", {
                  disabled:
                    projectLoadingStage.projectStopped &&
                    projectLoadingStage.projectNames.some(
                      (el) => el === project.name
                    ),
                  "navigation-accordion-item-active-project":
                    activeProject &&
                    activeProject.projectId === project.projectId,
                })}
              >
                <div className="list-item">
                  {project.archived ? (
                    <FontAwesomeIcon icon={faHourglassEnd} />
                  ) : Object.keys(project.suspended).length ? (
                    <FontAwesomeIcon icon={faGears} />
                  ) : Object.keys(project.blocked).length ? (
                    <FontAwesomeIcon icon={faGears} />
                  ) : (
                    <FontAwesomeIcon icon={faGears} />
                  )}
                  {Object.keys(project.suspended).length > 0 ? (
                    <div
                      className={classNames("notifications-label")}
                      style={{
                        width: "15px",
                        height: "15px",
                        left: "14px",
                        top: "-12px",
                      }}
                    >
                      {Object.keys(project.suspended).length}
                    </div>
                  ) : Object.keys(project.blocked).length > 0 ? (
                    <div
                      className={classNames("notifications-label")}
                      style={{
                        width: "15px",
                        height: "15px",
                        left: "14px",
                        top: "-12px",
                      }}
                    >
                      {Object.keys(project.blocked).length}
                    </div>
                  ) : null}
                  <span
                    className="project-name"
                    style={{ width: "75%" }}
                  >
                    {project.name}
                  </span>
                  {projectLoadingStage.projectStopped &&
                    projectLoadingStage.projectNames.some(
                      (el) => el === project.name
                    ) && <div className="blinking deleting-label">Del</div>}
                </div>
                {!project.archived &&
                activeDropdown.projectId === project.projectId ? (
                  <FontAwesomeIcon
                    icon={
                      activeDropdown.projectId === project.projectId &&
                      activeDropdown.isActive
                        ? faAngleUp
                        : faAngleDown
                    }
                    className="chevron-icon"
                  />
                ) : null}
              </Accordion.Title>
              <Accordion.Content active={projectIndex === i}>
                {member ? (
                  <NavigationBarContent
                    project={project}
                    userGrade={member.grade}
                    leaveProject={() => leaveProjectHandler(project.projectId)}
                    updateStrategy={() =>
                      props.setOpenUpdateStrategyModal(true)
                    }
                    setNominatedMembersList={setNominatedMembersList}
                    nominatedMembersList={nominatedMembersList}
                  />
                ) : null}
              </Accordion.Content>
            </Accordion>
          </List.Item>
        );
      })}
    </List.List>
  ) : null;
};
