import styles from "../HeaderCalendar.module.scss";
import TaskModel from "../../../../../../../models/responses/task.model";
import {Maybe} from "@martin_hotell/rex-tils";

export const getPredStatusClass = (task: Maybe<TaskModel>): string => {
    if(task) {
        switch (task.predStatus) {
         
            case 2: {
                return styles.YellowStatus;
            }
            default: {
                return styles.RedStatus;
            }
        }
    }
    return '';
};

export const getClassForStartedTask = (task: Maybe<TaskModel>): string => {
    if (task) {
        switch (task.predStatus) {
         
            case 2: {
                return styles.YellowClass;
            }
            default: {
                return styles.RedClass;
            }
        }
    }
    return '';
}