import { SafeMap } from "../../../utils/safe-map";
import ProjectModel from "../../../models/responses/project.model";
import store from "../../store";
import * as projectsActions from "../../actions/project.actions";
import * as teamActions from "../../actions/team.actions";
import * as tasksActions from "../../actions/task.actions";
import * as wbsActions from "../../actions/wbs.actions";
import * as ledgerActions from "../../actions/ledger.actions";
import * as relationshipsActions from "../../actions/relationships.actions";
import * as authorizationActions from "../../actions/authorization.actions";
import * as searchActions from "../../actions/search.actions";
import * as eventLogActions from "../../actions/event-log.actions";
import { ProjectSubscriptions } from "../../../firebase/subscriptions/projectSubscriptions";
import { AvailableSubscriptions } from "./subs-manage.types";
import TaskModel from "../../../models/responses/task.model";
import {
  TaskListSubscriptionParameters,
  TaskSubscriptions,
} from "../../../firebase/subscriptions/taskSubscriptions";
import { WBSSubscriptions } from "../../../firebase/subscriptions/wbsSubscriptions";
import { WbsModel } from "../../../models/responses/wbs.model";
import { MemberSubscriptions } from "../../../firebase/subscriptions/memberSubscriptions";
import ProjectMembersModel from "../../../models/responses/project-members.model";
import MessageModel from "../../../models/responses/message.model";
import { LedgerSubscriptions } from "../../../firebase/subscriptions/ledgerSubscriptions";
import { ReportItem } from "../../store.types";
import { RelationshipsSubscriptions } from "../../../firebase/subscriptions/relationshipsSubscriptions";
import { RelationshipsModel } from "../../../models/responses/relationships.model";
import EventLog from "../../../models/responses/event-log-model";
import { EventLogSubscriptions } from "../../../firebase/subscriptions/eventLogSubscriptions";
import { Omit } from "redux-form";
import { CalendarModel } from "../../../models/responses/calendar.model";
import { CalendarSubscriptions } from "../../../firebase/subscriptions/calendarSubscriptions";
import { TaskListSectionModel } from "../../../models/task-list-section.model";
import TaskStatusModel from "../../../models/responses/task-status.model";
import TrackedMilestoneModel from "../../../models/responses/tracked-milestone.model";
import {PendingMembershipsModel} from "../../../models/pending-memberships.model";
import {CpmTaskModel} from "../../../models/responses/cpm-task.model";
import {convertDateToIndex} from "../../../utils/cpm-functions/cpm-app/functions/handleEvent";

class SubsManager {
  unsubscribe: SafeMap<AvailableSubscriptions, () => void> = new SafeMap();
  // use multiUnsubscribe in case you need multiple subscriptions of the same type
  // multiUnsubscribe = new SafeMap<AvailableSubscriptions, Array<() => void>>();

  private static handlerProjectList = (projectList: ProjectModel[]) => {
    store.dispatch(projectsActions.Actions.setProjectList(projectList.filter(el => !el.archived)));
    store.dispatch(projectsActions.Actions.setArchiveProjectList(projectList.filter(el => el.archived)));
  };


  private static handlerInProgressTaskList = (taskList: TaskModel[]) => {
    // let notStartedTasksSort = taskList
    //   // .filter((e) => e.status === TaskStatusModel.NOT_STARTED)
    //   .sort((a, b) => a.late_start_date.seconds - b.late_start_date.seconds);
    // const checkDates = (a: TaskModel) => {
    //   if (a.expiryDate) {
    //     return a.expiryDate.seconds
    //       ? a.expiryDate.seconds * 1000 - new Date().getTime()
    //       : 0;
    //   }
    //   return 0;
    // };
    // let startedTasksSort = taskList
    //   .filter(
    //     (e) =>
    //       e.status === TaskStatusModel.IN_PROGRESS ||
    //       e.status === TaskStatusModel.SUSPENDED ||
    //       e.status === TaskStatusModel.BLOCK
    //   )
    //   .sort(
    //     (a, b) =>
    //       a.late_end_date!.seconds * 1000 -
    //       checkDates(a) -
    //       (b.late_end_date!.seconds * 1000 - checkDates(b))
    //   );
    // taskList = [...notStartedTasksSort, ...startedTasksSort];

    // taskList = taskList.sort((a, b) => a.predStatus - b.predStatus)
    //     .sort((a, b) => a.late_end_date!.seconds - b.late_end_date!.seconds)
    //     .sort((a, b) => a.late_start_date.seconds - b.late_start_date.seconds)
    //     .sort((a, b) => {
    //       if (a.flow && !b.flow) {
    //         return -1;
    //       }
    //       if (!a.flow && b.flow) {
    //             return 1;
    //       }
    //       return 0;
    //     })

      taskList = taskList.sort((a, b) => a.index! - b.index!)

    // taskList.forEach((el, i) => {
    //   el.index = i + 1;
    // });
    // if (store.getState().task.tasks.inProgress.length) {
    //   // update index of taskList based on store.getState().task.tasks.inProgress index
    //   taskList.forEach((el, i) => {
    //     const index = store
    //       .getState()
    //       .task.tasks.inProgress.findIndex((e) => e.task_id === el.task_id);
    //     if (index !== -1) {
    //       el.index = index + 1;
    //     } else {
    //       el.index = i + 1;
    //     }
    //   });
    // }
    store.dispatch(tasksActions.Actions.setInProgressTaskList(taskList));
  };
  private static handlerCalendar = (calendar: CalendarModel | null) =>
    store.dispatch(tasksActions.Actions.setCalendar(calendar));
  private static handlerConfirmedCompleteTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setConfirmedCompleteTaskList(taskList));
  private static handlerDeclaredCompleteTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setDeclaredCompleteTaskList(taskList));
  private static handlerPendingTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setPendingTaskList(taskList));

  private static handlerTrackedMilestones = (milestones: any[]) => {
      let outMilestones: TrackedMilestoneModel[] = milestones.filter((el) => {
          if (el.visibleToAll) {
              return true;
          } else if (el.userId === store.getState().authorization.user?.userId) {
              return true;
          }
          return false;
      });
      store.dispatch(projectsActions.Actions.setTrackedMilestones(outMilestones));
  }

  private static handlerGeoData = (geoData: any[]) => {
      store.dispatch(projectsActions.Actions.setGeoData(geoData));
  }

  private static handlerQueuedTaskList = (taskList: TaskModel[]) => {
    // const inProgressLength = store.getState().task.tasks.inProgress.length;
    // if (store.getState().task.tasks.queued.length) {
    //   taskList.forEach((el) => {
    //     const item = store
    //       .getState()
    //       .task.tasks.queued.find((e) => e.task_id === el.task_id);
    //     if (item) {
    //       el.index = item.index;
    //     }
    //   });
    // } else {
    //   taskList.forEach((el, i) => {
    //     el.index = inProgressLength + i + 1;
    //   });
    // }
      taskList = taskList.sort((a, b) => a.index! - b.index!)

    store.dispatch(tasksActions.Actions.setQueuedTaskList(taskList));
  };

  private static handlerPredecessorsList = (taskList: TaskModel[]) =>
    store.dispatch(
      tasksActions.Actions.setRelatedTaskList(taskList, "predecessors")
    );
  private static handlerSuccessorsList = (taskList: TaskModel[]) =>
    store.dispatch(
      tasksActions.Actions.setRelatedTaskList(taskList, "successors")
    );
  private static handlerWbsList = (wbsList: WbsModel[]) => {

      let outWbsList: WbsModel[] = []
      function addChildren(wbs: WbsModel) {
          if (!wbs) return
          let children = wbs.children
          if (children.length) {
              children = children.sort((a, b) => {
                    const wbsA = wbsMap.get(a)
                    const wbsB = wbsMap.get(b)
                    if (wbsA && wbsB) {
                        return wbsA.wbs_short_name.localeCompare(wbsB.wbs_short_name)
                    }
                    return 0
              })
              for (const child of children) {
                  const childWbs = wbsMap.get(child)
                  if (childWbs) {
                      outWbsList.push(childWbs)
                      addChildren(childWbs)
                  }
              }
          }
      }
      let wbsMap = new Map()
      wbsList = wbsList.sort((a, b) => {
          wbsMap.set(b.wbs_id, b)
          wbsMap.set(a.wbs_id, a)
          return a.level - b.level
      })
      for (const wbs of wbsList) {
          if (wbs.level !== 0) {
              break
          }
          outWbsList.push(wbs)
          let children = wbs.children
          // sort children by wbs_short_name alphabetically
          children = children.sort((a, b) => {
                const wbsA = wbsMap.get(a)
                const wbsB = wbsMap.get(b)
                if (wbsA && wbsB) {
                    return wbsA.wbs_short_name.localeCompare(wbsB.wbs_short_name)
                }
                return 0
          })
          if (wbs.children.length) {
              for (const child of children) {
                  const childWbs = wbsMap.get(child)
                  outWbsList.push(childWbs)
                  addChildren(childWbs)
              }
          }
      }

    store.dispatch(wbsActions.Actions.setWBSList(outWbsList))
  };
  private static handlerLedgerList = (ledgerList: MessageModel[]) =>
    store.dispatch(ledgerActions.Actions.setLedgerList(ledgerList));
  private static handlerRelationshipsList = (
    relationshipsList: RelationshipsModel[]
  ) =>
    store.dispatch(
      relationshipsActions.Actions.setRelationshipsList(relationshipsList)
    );
  private static handlerProjectPermissions = (members: ProjectMembersModel[]) =>
    store.dispatch(authorizationActions.Actions.setMemberPermissions(members));
  private static handlerMemberList = (memberList: ProjectMembersModel[]) =>
    store.dispatch(teamActions.Actions.setMemberList(memberList));
    private static handlerPendingMemberList = (memberList: PendingMembershipsModel[]) =>
    store.dispatch(teamActions.Actions.setPendingMemberList(memberList));
  private static handlerReportList = (reportList: ReportItem[]) =>
    store.dispatch(searchActions.SearchActions.setReportList(reportList));
  private static handlerEventLogList = (eventLogList: EventLog[]) =>
    store.dispatch(eventLogActions.Actions.setEventLogList(eventLogList));

  private safeSubscribe(type: AvailableSubscriptions, unsubscribe) {
    this.unsubscribe.forEachKey(type, (u) => u);
    this.unsubscribe.set(type, unsubscribe);
  }

  async subscribeProjectList(userId: string | null) {
    this.safeSubscribe(
      "project-list",
      await ProjectSubscriptions.projectList(
        userId,
        SubsManager.handlerProjectList
      )
    );
  }

  subscribeWbsList(project_id: string) {
    this.safeSubscribe(
      "wbs-list",
      WBSSubscriptions.wbsList(project_id, SubsManager.handlerWbsList)
    );
  }

  async subscribeInProgressTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
      if (params.alert) {
        this.safeSubscribe(
          "in-progress-task-list",
          await TaskSubscriptions.taskList(
            {
              ...params,
              taskListType: TaskListSectionModel.WORK_IN_PROCESS,
            },
            SubsManager.handlerInProgressTaskList
          )
        );
      } else {
        this.safeSubscribe(
            "in-progress-task-list",
            await TaskSubscriptions.taskList(
                {
                  ...params,
                  taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                },
                SubsManager.handlerInProgressTaskList
            )
        );
      }
  }

  subscribeCalendar(projectId: string, calendarId: string) {
    this.safeSubscribe(
      "calendar",
      CalendarSubscriptions.calendar(
        projectId,
        calendarId,
        SubsManager.handlerCalendar
      )
    );
  }

  async subscribeConfirmedCompleteTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
      this.safeSubscribe(
        "declared-complete-task-list",
        await TaskSubscriptions.taskList(
          {
            ...params,
            taskListType: TaskListSectionModel.CONFIRMED_COMPLETE,
          },
          SubsManager.handlerConfirmedCompleteTaskList
        )
      );
  }

  async subscribeDeclaredCompleteTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
    this.safeSubscribe(
      "confirmed-complete-task-list",
      await TaskSubscriptions.taskList(
        {
          ...params,
          taskListType: TaskListSectionModel.DECLARED_COMPLETE,
        },
        SubsManager.handlerDeclaredCompleteTaskList
      )
    );
  }

  async subscribeQueuedTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
    // console.log('params subscribeQueuedTaskList from sub-manger', params)
      this.safeSubscribe(
          "queued-task-list",
          await TaskSubscriptions.taskList(
              {
                ...params,
                taskListType: TaskListSectionModel.QUEUED,
              },
              SubsManager.handlerQueuedTaskList
          )
      );
  }

    async subscribePendingTaskList(
        params: Omit<TaskListSubscriptionParameters, "taskListType">
    ) {
        this.safeSubscribe(
            "pending-task-list",
            await TaskSubscriptions.taskList(
                {
                    ...params,
                    taskListType: TaskListSectionModel.PENDING,
                },
                SubsManager.handlerPendingTaskList
            )
        );
    }

  subscribeSuccessorsList(project_id: string, taskId: string) {
    this.safeSubscribe(
      "successors-list",
      TaskSubscriptions.successorsList(
        {
          project_id,
          taskId,
        },
        SubsManager.handlerSuccessorsList
      )
    );
  }

  subscribePredecessorsList(project_id: string, taskId: string) {
    this.safeSubscribe(
      "predecessors-list",
      TaskSubscriptions.predecessorsList(
        {
          project_id,
          taskId,
        },
        SubsManager.handlerPredecessorsList
      )
    );
  }

  subscribeLedgerList(taskId: string) {
    this.safeSubscribe(
      "ledger-list",
      LedgerSubscriptions.ledgerList(taskId,
          store.getState().project.activeProject!.projectId,
          SubsManager.handlerLedgerList)
    );
  }

  subscribeTrackedMilestones(projectId: string) {
    this.safeSubscribe(
      "tracked-milestones",
      ProjectSubscriptions.trackedMilestones(
        projectId,
        SubsManager.handlerTrackedMilestones
      )
    );
  }

  subscribeGeoData(projectId: string) {
    this.safeSubscribe(
      "geo-data",
      ProjectSubscriptions.getGeoData(
        projectId,
        SubsManager.handlerGeoData
      )
    );
  }

  subscribeRelationshipsList(projectId: string) {
    this.safeSubscribe(
      "relationships-list",
      RelationshipsSubscriptions.relationshipsList(
        projectId,
        SubsManager.handlerRelationshipsList
      )
    );
  }

  subscribeProjectPermission(userId: string | null) {
    this.safeSubscribe(
      "project-permissions",
      MemberSubscriptions.projectPermission(
        userId,
        SubsManager.handlerProjectPermissions
      )
    );
  }

  subscribeMemberList(projectId: string) {
    this.safeSubscribe(
      "member-list",
      MemberSubscriptions.memberList(projectId, SubsManager.handlerMemberList)
    );
  }

    subscribePendingMemberList(projectId: string) {
        this.safeSubscribe(
            "member-list",
            MemberSubscriptions.pendingMembers(projectId, SubsManager.handlerPendingMemberList)
        );
    }

  subscribeReportList(projectId: string) {
    this.safeSubscribe(
      "report-list",
      MemberSubscriptions.reportList(projectId, SubsManager.handlerReportList)
    );
  }

  subscribeEventLogList(taskId: string) {
    this.safeSubscribe(
      "event-log-list",
      EventLogSubscriptions.eventLogList(
        taskId,
        store.getState().project.activeProject?.projectId,
        SubsManager.handlerEventLogList
      )
    );
  }

  removeSubscription(type: AvailableSubscriptions) {
    const unsubscribe = this.unsubscribe.get(type);
    if (unsubscribe) {
      this.unsubscribe.delete(type);
    }
  }

  removeTaskListSubscription(type: AvailableSubscriptions) {
    const unsubscribe = this.unsubscribe.get(type);
    if (unsubscribe) {
      unsubscribe()
      this.unsubscribe.delete(type);
    }
  }

  removeAllSubscriptions() {
      console.log(this.unsubscribe)
    this.unsubscribe.forEach((u, key) => this.removeSubscription(key));
  }
}

export const subsManager = new SubsManager();
