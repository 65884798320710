import ImageModel from "../../../../models/responses/image.model";
import {Dimmer, Loader, Modal} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import GeoModel from "../../../../models/responses/geo.model";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../store/actions/task.actions";
import TaskModel from "../../../../models/responses/task.model";


export default function ImageModal (props: {
    imageId: string,
    setModalOpen: (image: ImageModel | null) => void,
    geoData: GeoModel,
    task: any,
    allTasks: TaskModel[]
}) {
    const {imageId, setModalOpen, geoData, task } = props;
    const [modalImage, setModalImage] = useState<ImageModel | null >(null);
    const [imageSrc, setImageSrc] = useState<any>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        FirebaseUsage.getDoc(COLLECTIONS.IMAGES, imageId).then(async (doc) => {
            setModalImage(doc.data() as ImageModel)
            setImageSrc((await FirebaseUsage.downloadImageAsBlob(doc.data() as ImageModel)))
        });
    }, []);

    return (
        <Modal open onClose={() => setModalOpen(null)} closeIcon
               style={{width: "auto", height: "auto"}}>
            <Modal.Content>
                    {modalImage && imageSrc ?
                    <div className="modal-image-container">
                        <img src={imageSrc} alt={modalImage?.fileName} className={"ledger-image-large"}/>
                        <span>
                            <p>{`${geoData?.dateCaptured?.toDate().toDateString()} - "${modalImage?.relatedText}"`}</p>
                            {task && <p style={{color: "blue", cursor: "pointer"}}
                                        onClick={() => {
                                            const activeTask = props.allTasks.find((t: TaskModel) => t.task_id === task.task_code)
                                            dispatch(taskActions.Actions.setActiveTask(activeTask))
                                            dispatch(
                                                taskActions.Actions.openRelatedTaskList(
                                                    activeTask!,
                                                    "predecessors"
                                                )
                                            );
                                            setModalOpen(null)
                                        }}
                            ><u><b>{`${task.task_name}`}</b></u></p>}
                        </span>
                    </div>
                        :
                        <Dimmer inline="true" active>
                            <Loader inline size={"large"} inverted/>
                        </Dimmer>
                    }
            </Modal.Content>
        </Modal>
    )
}