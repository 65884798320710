import React from 'react';
import {UserDocModel} from "../models/responses/user.model";

export default function (props: {user: UserDocModel, width: number, height: number}) {

    const {user, width, height} = props;

    return (
      <div>
      {user && user.photoURL ?
          <img src={user.photoURL} alt="user" className="user-image"
                style={{width: width, height: height}}
          />
          : <div className="user-image-div"
                 style={{backgroundColor: user?.userColour ? user?.userColour : '#0d71bb',
                     width: width,
                     height: height,
                     fontSize: `${width / 2}px`,
                 }}>
              {user && user.displayName ? user.displayName[0].toUpperCase() :
              user?.userEmail[0].toUpperCase()}</div>}
      </div>
  );

}
