import FirebaseUsage from "../firebase/firebase.usage";

export default async function batchUpdate(batch: any[]) {
    const commitBatch = (batchArray: any[]) => {
        let batchObj = FirebaseUsage.batch()
        batchArray.forEach((doc) => {
            if (doc.type === 'update') {
                batchObj.update(doc.ref, doc.data)
            } else if (doc.type === 'set') {
                batchObj.set(doc.ref, doc.data)
            } else if (doc.type === 'delete') {
                batchObj.delete(doc.ref)
            }
        })
        batchObj.commit()
    }

    if (batch.length < 500) {
        commitBatch(batch)
    } else {
        for (let i = 0; i < batch.length; i += 500) {
            console.log('running update batch ' + (i + 1) + ' to ' + Math.ceil(batch.length / 500))
            commitBatch(batch.slice(i, i + 500))
        }
    }
}