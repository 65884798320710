import styled from "styled-components";

export const ProjectMainContainerUI = styled.div`
display: flex;
flex-direction: column;
flex: 1;
position: relative;
`;


export const ProjectWrapUI = styled.div`
display: flex;
flex: 1;
position: relative;
`;

export const ProjectContentUI = styled.div`
flex: 1;
transition: .2s width;
padding: 0;
display: flex;
flex-direction: column;
max-height: 100%;
overflow: auto;
`;
