import React from 'react'
import {
    SidebarPusher,
    SidebarPushable,
    MenuItem,
    Menu,
    Sidebar,
} from 'semantic-ui-react'
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-light-svg-icons";
import {useDispatch} from "react-redux";
import {Actions} from "../../../../store/actions/authorization.actions";
import AuthorizationThunk from "../../../../store/thunk/authorization.thunk";

const SidebarExampleSidebar = (props) => {
    const { visible, setVisible } = props;
    const user = useUserSelector();
    const dispatch = useDispatch();

    return (<SidebarPushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    // inverted
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width={"thin"}
                    direction={"right"}
                >
                    {user && user.photoURL ?
                        <img src={user?.photoURL} alt="user"
                             style={{width: "60px", height: "60px", borderRadius: "50%", margin: "20px auto"}}/>
                        : <div className="user-image-div-sidebar"
                               style={{backgroundColor: user?.userColour ? user?.userColour : '#0d71bb',
                                   width: "60px", height: "60px", borderRadius: "50%", margin: "20px auto"
                               }}>{user && user.displayName ? user.displayName[0].toUpperCase() :
                            user?.userEmail[0].toUpperCase()}</div>}
                        <h5 className={"user-name-sidebar"}
                        style={{marginTop: "-10px"}}
                >{user?.displayName ? user.displayName : user?.userEmail}</h5>

        <MenuItem as='a' onClick={() => {
            dispatch(Actions.setUserSidebarOpen(false))
            // @ts-ignore
            dispatch(AuthorizationThunk.logOut())
        }}>
                        <FontAwesomeIcon icon={faSignOut} />
                        <br/>
                        Sign Out
                    </MenuItem>
                    {/*<MenuItem as='a'>*/}
                    {/*    <Icon name='gamepad' />*/}
                    {/*    Games*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem as='a'>*/}
                    {/*    <Icon name='camera' />*/}
                    {/*    Channels*/}
                    {/*</MenuItem>*/}
                </Sidebar>
                <SidebarPusher style={{minWidth: "100%"}}>
                    {props.children}
                </SidebarPusher>
            </SidebarPushable>
    )
}

export default SidebarExampleSidebar