import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faHandshake,
} from "@fortawesome/pro-light-svg-icons";
import {
  faGear,
  faPlay,
  faPause,
  faRotate,
  faCheck,
  faScaleUnbalancedFlip,
  faCalendarWeek,
  faClipboardList,
  faCalendar,
  faUserGear,
    faMapPin,
} from "@fortawesome/pro-solid-svg-icons";
import styles from "./notification-accordion.module.scss";
import classNames from "classnames";
import { useTypedSelector } from "../../../../../../store/selectors/selectors.utils";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import { COLLECTIONS } from "../../../../../../firebase/constants";

const NotificationModal: React.FC = () => {
  const user = useTypedSelector((state) => state.authorization.user);
  const [preferences, setPreferences] = useState<boolean>(
    user!.settings.notifications.preferences
  );
  const [waitingToStart, setWaitingToStart] = useState<boolean>(
    user!.settings.notifications.waitingToStart
  );
  const [started, setStarted] = useState<boolean>(
    user!.settings.notifications.started
  );
  const [resumed, setResumed] = useState<boolean>(
    user!.settings.notifications.resumed
  );
  const [reforecasted, setReforecasted] = useState<boolean>(
    user!.settings.notifications.reforecasted
  );
  const [suspended, setSuspended] = useState<boolean>(
    user!.settings.notifications.suspended
  );
  const [declaredComplete, setDeclaredComplete] = useState<boolean>(
    user!.settings.notifications.declaredComplete
  );
  const [evaluatedComplete, setEvaluatedComplete] = useState<boolean>(
    user!.settings.notifications.evaluatedComplete
  );
  const [weeklyInprogressTasks, setWeeklyInprogressTasks] = useState<boolean>(
    user!.settings.notifications.weeklyInprogressTasks || false
  );
  const [completedChecklist, setCompletedChecklist] = useState<boolean>(
    user!.settings.notifications.completedChecklist || false
  );
  const [taskBehindSchedule, setTaskBehindSchedule] = useState<boolean>(
    user!.settings.notifications.taskBehindSchedule || false
  );
  const [assignedToTaskforce, setAssignedToTaskforce] = useState<boolean>(
    user!.settings.notifications.assignedToTaskforce || false
  );

  useEffect(() => {
    if (user) {
      user.settings.notifications.preferences = preferences;
      user.settings.notifications.waitingToStart = waitingToStart;
      user.settings.notifications.started = started;
      user.settings.notifications.resumed = resumed;
      user.settings.notifications.suspended = suspended;
      user.settings.notifications.declaredComplete = declaredComplete;
      user.settings.notifications.evaluatedComplete = evaluatedComplete;
      user.settings.notifications.weeklyInprogressTasks = weeklyInprogressTasks;
      user.settings.notifications.completedChecklist = completedChecklist;
      user.settings.notifications.taskBehindSchedule = taskBehindSchedule;
      user.settings.notifications.assignedToTaskforce = assignedToTaskforce;
      user.settings.notifications.reforecasted = reforecasted
      FirebaseUsage.updateDoc(COLLECTIONS.USERS, user.userId, {
        settings: {
          ...user.settings,
          notifications: {
            preferences,
            waitingToStart,
            started,
            resumed,
            suspended,
            reforecasted,
            declaredComplete,
            evaluatedComplete,
            weeklyInprogressTasks,
            completedChecklist,
            taskBehindSchedule,
            assignedToTaskforce,
          },
        },
      });
    }
  }, [
    user,
    preferences,
    waitingToStart,
    started,
    resumed,
    suspended,
    declaredComplete,
    evaluatedComplete,
      reforecasted,
    weeklyInprogressTasks,
    completedChecklist,
    taskBehindSchedule,
    assignedToTaskforce,
  ]);

  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.NotificationAccordion}>
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="navigation-bar-notifications-item-icon">
          <div className="notification-header">
            <h4>Notifications</h4>
          </div>
          <FontAwesomeIcon
            icon={isActive ? faAngleUp : faAngleDown}
            className="chevron-icon"
          />
        </div>
      </div>
      {isActive && (
          <div className="accordion-content">
            <div className={styles.ContentItem}>
              <h4 className={classNames(styles.Title)}>
                <FontAwesomeIcon icon={faHandshake}/>
                <span>notify my tasks only</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={preferences}
                    onChange={() => {
                      setPreferences(!preferences);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faGear}/>
                <span>task is waiting to start</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={waitingToStart}
                    onChange={() => {
                      setWaitingToStart(!waitingToStart);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faPlay}/>
                <span>task has started</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={started}
                    onChange={() => {
                      setStarted(!started);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faPause}/>
                <span>task has been put on hold</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={suspended}
                    onChange={() => {
                      setSuspended(!suspended);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faMapPin}/>
                <span>task has been reforecast</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={reforecasted}
                    onChange={() => {
                      setReforecasted(!reforecasted);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faRotate}/>
                <span>task has resumed</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={resumed}
                    onChange={() => {
                      setResumed(!resumed);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faScaleUnbalancedFlip}/>
                <span>task is ready for approval</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={evaluatedComplete}
                    onChange={() => {
                      setEvaluatedComplete(!evaluatedComplete);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faCheck}/>
                <span>task is approved</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={declaredComplete}
                    onChange={() => {
                      setDeclaredComplete(!declaredComplete);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faCalendarWeek}/>
                <span>weekly in progress tasks</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={weeklyInprogressTasks}
                    onChange={() => {
                      setWeeklyInprogressTasks(!weeklyInprogressTasks);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faClipboardList}/>
                <span>checklist complete</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={completedChecklist}
                    onChange={() => {
                      setCompletedChecklist(!completedChecklist);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faCalendar}/>
                <span>task behind schedule</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={taskBehindSchedule}
                    onChange={() => {
                      setTaskBehindSchedule(!taskBehindSchedule);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
            <div className={styles.ContentItem}>
              <h4 className={styles.Title}>
                <FontAwesomeIcon icon={faUserGear}/>
                <span>assigned to a taskforce</span>
              </h4>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={assignedToTaskforce}
                    onChange={() => {
                      setAssignedToTaskforce(!assignedToTaskforce);
                    }}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>
      )}
    </div>
  );
};

export default NotificationModal;
