
export default function generateWBSPath(wbsMap, wbsId) {
    let wbsItem = wbsMap.get(wbsId)
    if (!wbsItem) {
        return {wbsPath: ''}
    }
    wbsItem = wbsMap.get(wbsItem.parent_wbs_id)
    if (!wbsItem) {
        return {wbsPath: ''}
    }
    let wbsPath = wbsItem.wbs_name

    while (true) {
        wbsItem = wbsMap.get(wbsItem.parent_wbs_id)
        if (!wbsItem) {
            return {wbsPath: wbsPath}
        }
        wbsPath = wbsItem.wbs_name + ' / ' + wbsPath
    }
}