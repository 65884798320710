export function debounce(inner, ms = 0) {
    let timer: any;
    let resolves: any[] = [];

    return function (...args) {

        clearTimeout(timer);

        timer = setTimeout(() => {
            let result = inner(...args);

            resolves.forEach(r => r(result));
            resolves = [];
        }, ms);


        return new Promise(r => resolves.push(r));
    };
}
