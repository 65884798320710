import React, { useState, useMemo } from 'react';
import AreaClosed from '@visx/shape/lib/shapes/AreaClosed';
import { curveMonotoneX } from '@visx/curve';
import { scaleUtc, scaleLinear, scaleLog, scaleBand, ScaleInput, coerceNumber } from '@visx/scale';
import { Axis, Orientation, SharedAxisProps, AxisScale } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { AnimatedAxis, AnimatedGridRows, AnimatedGridColumns } from '@visx/react-spring';
import { getSeededRandom } from '@visx/mock-data';
import { LinearGradient } from '@visx/gradient';
import { timeFormat } from '@visx/vendor/d3-time-format';
import { GridRowsProps } from '@visx/grid/lib/grids/GridRows';
import { GridColumnsProps } from '@visx/grid/lib/grids/GridColumns';

export const backgroundColor = '#f6f5f6';
const axisColor = '#030303';
const tickLabelColor = '#030303';
export const labelColor = '#340098';
const gridColor = '#6e0fca';
const seededRandom = getSeededRandom(0.5);
const margin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

const tickLabelProps = {
    fill: tickLabelColor,
    fontSize: 12,
    fontFamily: 'sans-serif',
    textAnchor: 'middle',
} as const;

const getMinMax = (vals: (number | { valueOf(): number })[]) => {
    const numericVals = vals.map(coerceNumber);
    return [Math.min(...numericVals), Math.max(...numericVals)];
};

export type AxisProps = {
    width: number;
    height: number;
    showControls?: boolean;
};

type AnimationTrajectory = 'outside' | 'center' | 'min' | 'max' | undefined;

type AxisComponentType = React.FC<
    SharedAxisProps<AxisScale>
    // & {
    // animationTrajectory: AnimationTrajectory;
// }
>;
type GridRowsComponentType = React.FC<
    GridRowsProps<AxisScale> & {
    animationTrajectory: AnimationTrajectory;
}
>;
type GridColumnsComponentType = React.FC<
    GridColumnsProps<AxisScale> & {
    animationTrajectory: AnimationTrajectory;
}
>;

export default function TimeScale({width: outerWidth = 800,
                                      height: outerHeight = 800,
                                      showControls = true,
                                      start: startDate = new Date(),
                                      finish: finishDate = new Date(),
                                      setStartDate, setFinishDate}: any) {
    // use non-animated components if prefers-reduced-motion is set
    const prefersReducedMotionQuery =
        typeof window === 'undefined' ? false : window.matchMedia('(prefers-reduced-motion: reduce)');
    const prefersReducedMotion = !prefersReducedMotionQuery || !!prefersReducedMotionQuery.matches;
    // const [useAnimatedComponents, setUseAnimatedComponents] = useState(!prefersReducedMotion);
    const [useAnimatedComponents, setUseAnimatedComponents] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [initialX, setInitialX] = useState(0);

    // in svg, margin is subtracted from total width/height
    const width = outerWidth - margin.left - margin.right;
    const height = outerHeight - margin.top - margin.bottom;
    const [animationTrajectory, setAnimationTrajectory] = useState<AnimationTrajectory>('center');

    // define some types
    interface AxisDemoProps<Scale extends AxisScale> extends SharedAxisProps<Scale> {
        values: ScaleInput<Scale>[];
    }

    const AxisComponent: AxisComponentType = useAnimatedComponents ? AnimatedAxis : Axis;
    const GridRowsComponent: GridRowsComponentType = useAnimatedComponents
        ? AnimatedGridRows
        : GridRows;
    const GridColumnsComponent: GridColumnsComponentType = useAnimatedComponents
        ? AnimatedGridColumns
        : GridColumns;

    const numYears = parseInt(((finishDate.getTime() - startDate.getTime()) / (86400000 * 365.25)).toFixed(0))
    const numDays = parseInt(((finishDate.getTime() - startDate.getTime()) / 86400000).toFixed(0))
    const numWeeks = parseInt((numDays / 7).toFixed(0))
    const numQuarters = parseInt((numDays / 90).toFixed(0))
    const numMonths = parseInt((numDays / 30).toFixed(0))
    const timeSpace = outerWidth / numDays
    const secondRow = timeSpace < 0.2 ? null : timeSpace < 1 ? "quarter" : timeSpace < 2.5 ? "month" : "week"

    const axes: AxisDemoProps<AxisScale<number>>[] = useMemo(() => {
        // toggle between two value ranges to demo animation
        const timeValues = [startDate, finishDate];

        let axesArray = [
            {
                scale: scaleUtc({
                    domain: getMinMax(timeValues),
                    range: [0, width],
                }),
                values: timeValues,
                numTicks: secondRow ? secondRow !== "week" ? numYears : numMonths : numYears,
                tickFormat: (v: Date, i: number) => secondRow ? secondRow !== "week" ? timeFormat('%Y')(v) : timeFormat('%b %Y')(v) : timeFormat('%Y')(v),
                label: 'time',
            },
        ]

        if (secondRow) {
            axesArray.push({
                scale: scaleUtc({
                    domain: getMinMax(timeValues),
                    range: [0, width],
                }),
                values: timeValues,
                numTicks: secondRow === "quarter" ? numQuarters : secondRow === "month" ? numMonths : numWeeks,
                tickFormat: (v: Date, i: number) => secondRow === "quarter" ?
                    `Q${timeFormat('%q')(v)}` : secondRow === "month" ? timeFormat('%b')(v) : timeFormat('%d')(v),
                    // timeFormat('%b')(v),
                // tickFormat: (v: Date, i: number) =>
                //     i === 3 ? '🎉' : width > 400 || i % 2 === 0 ? timeFormat('%b %d')(v) : '',
                label: 'time',
            })
        }

        return axesArray

    }, [width, startDate, finishDate]);

    if (width < 10) return null;

    const scalePadding = 20;
    const scaleHeight = (height / axes.length) - scalePadding;

    const yScale = scaleLinear({
        domain: [100, 0],
        range: [scaleHeight, 0],
    });

    function handleMouseMove(e: any) {
        if (resizing) {
            // estimate date clicked on
            const x = e.clientX;
            const timeSpan = finishDate.getTime() - startDate.getTime();
            const timePerPixel = timeSpan / width;
            const dateOfInitialX = startDate.getTime() + ((initialX - width) * timePerPixel);
            const timeBefore = dateOfInitialX - startDate.getTime();
            const timeAfter = finishDate.getTime() - dateOfInitialX;
            const distanceMoved = (x - initialX)
            const newScale = 1 + ((distanceMoved / width) * 2)
            const newStartDate = new Date(dateOfInitialX - (timeBefore / newScale))
            const newFinishDate = new Date(dateOfInitialX + (timeAfter / newScale))
            setStartDate(newStartDate)
            setFinishDate(newFinishDate)
        }
    }

    document.addEventListener('mouseup', (e) => {
        if (resizing) {
            setResizing(false);
            document.removeEventListener('mousemove', handleMouseMove, true)
        }
    }, true)

    document.addEventListener('mousemove', handleMouseMove, true)

    return (
        <div style={{cursor: "ew-resize"}}
             onMouseDown={(e) => {
                 setInitialX(e.clientX)
                 setResizing(true)
             }}
        >
            <svg width={outerWidth} height={outerHeight}>
                <LinearGradient
                    id="visx-axis-gradient"
                    from={backgroundColor}
                    to={backgroundColor}
                    toOpacity={1}
                />
                <rect
                    x={0}
                    y={0}
                    width={outerWidth}
                    height={outerHeight}
                    fill={'url(#visx-axis-gradient)'}

                />
                <g>
                    {axes.map(({ scale, values, label, tickFormat, numTicks }, i) => (
                            <AxisComponent
                                // force remount when this changes to see the animation difference
                                key={`axis-${animationTrajectory}-${i}`}
                                orientation={Orientation.bottom}
                                top={outerHeight * ((1 / axes.length) * (i))}
                                scale={scale}
                                tickFormat={tickFormat}
                                stroke={axisColor}
                                tickStroke={"black"}
                                tickLabelProps={tickLabelProps}
                                tickValues={label === 'log' || label === 'time' ? undefined : values}
                                numTicks={numTicks}
                                // label={label}
                                labelProps={{
                                    x: width + 30,
                                    y: -10,
                                    fill: labelColor,
                                    fontSize: 18,
                                    strokeWidth: 0,
                                    stroke: '#030303',
                                    paintOrder: 'stroke',
                                    fontFamily: 'sans-serif',
                                    textAnchor: 'start',
                                }}
                                // no animation
                                // animationTrajectory={animationTrajectory}
                            />

                    ))}
                </g>
            </svg>
        </div>
    );
}