import {useActiveProjectSelector} from "../../../../store/selectors/project.selectors";
import React, {useEffect} from "react";
import {Button, Label, Modal} from "semantic-ui-react";
import DatePicker from "react-datepicker"
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";


export default function TimeViewModal(props) {
    const activeProject = useActiveProjectSelector()
    const [startDate, setStartDate] = React.useState(activeProject?.customViewStart ? new Date(activeProject?.customViewStart.toDate()) : activeProject?.earliestDate ? new Date(activeProject?.earliestDate) : new Date())
    const [finishDate, setFinishDate] = React.useState(activeProject?.customViewFinish ? new Date(activeProject?.customViewFinish.toDate()) : activeProject?.latestDate ? new Date(activeProject?.latestDate) : new Date())

       const startDateHandler = async (date) => {
          await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {customViewStart: FirebaseUsage.timestamp(date)})
              .catch(err => console.log(err))
            setStartDate(date)
       }

       const finishDateHandler = async (date) => {
            await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {customViewFinish: FirebaseUsage.timestamp(date)})
                .catch(err => console.log(err))
                setFinishDate(date)
       }

       const clearDateHandler = async () => {
           setStartDate(activeProject?.earliestDate ? new Date(activeProject?.earliestDate) : new Date())
           setFinishDate(activeProject?.latestDate ? new Date(activeProject?.latestDate) : new Date())
            await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {customViewStart: null})
                .catch(err => console.log(err))
            await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {customViewFinish: null})
                .catch(err => console.log(err))
       }


    return (
        <Modal open={props.showModal} size={"tiny"} style={{maxWidth: "350px"}} onClose={() => props.setShowModal(false)}>
            <Modal.Header>
                Customise view dates
            </Modal.Header>
            <Modal.Content>
                <Label
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "100%",
                        minWidth: "100%"
                }}
                >View start
                <DatePicker
                    className={"view-date-picker"}
                    dateFormat="dd-MMM-yyyy"
                    width={1}
                    selected={startDate}
                    onChange={date => startDateHandler(date)}
                />
                </Label>
                <Label
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "100%",
                        minWidth: "100%"
                    }}
                >View finish
                <DatePicker
                    className={"view-date-picker"}
                    dateFormat="dd-MMM-yyyy"
                    enableTabLoop={false}
                    selected={finishDate}
                    onChange={date => finishDateHandler(date)}
                />
                </Label>

            </Modal.Content>
            <Modal.Actions>
                <Button disabled={!activeProject?.customViewStart && !activeProject?.customViewFinish} negative onClick={() => clearDateHandler()}>Clear dates</Button>
                <Button onClick={() => props.setShowModal(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}