import { Maybe } from "@martin_hotell/rex-tils";
import moment from "moment";
import FirebaseUsage from "../firebase/firebase.usage";
import {
  CalendarDaysOfWeekModel,
  CalendarModel,
} from "../models/responses/calendar.model";
import TaskStatusModel from "../models/responses/task-status.model";
import TaskModel from "../models/responses/task.model";
import { date2Excel, getIOSDate } from "./calculations.utils";

export const DIR = { FORWARD: 1, BACKWARD: -1 };

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const dateTime = (date, time) => {
  const dateCopy = new Date(date.getTime());
  dateCopy.setHours(0, 0, 0, 0);
  const timeInMins =
    Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]) * 1000;
  dateCopy.setMinutes(dateCopy.getMinutes() + timeInMins);
  return dateCopy;
};

export function transformDateAsLastUpdate(date: Date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date.getDate();
  const monthName = monthNames[date.getMonth()];
  const year = `${date.getFullYear()}`.slice(2, 4);

  return `last update on ${day} ${monthName} ${year}`;
}

export const isNonWorkingDay = (date: Date, calendar: Maybe<CalendarModel>) => {
  const dateFormat = "YYYY:MM:DD";
  const curDayName = moment(date).format("dddd").toLowerCase();
  let value = false;
  if (calendar) {
    const exceptions = calendar.exceptionsArr
    const exception = exceptions ? exceptions.get(date2Excel(date)) : null;
    for (const key in calendar.working_days_of_the_week) {
      if (
        calendar.working_days_of_the_week[key] === null &&
        key === curDayName
      ) {
        value = true;
        break;
      }
    }
    if (exception && !exception.periods[0].start) {
      value = true;
    }
  }
  return value;
};

export const getDates = (task: TaskModel) => {
  switch (task.status) {
    case TaskStatusModel.COMPLETE: {
      return {
        late_end_date: null,
        early_end_date: null,
        early_start_date: null,
        late_start_date: null,
      };
    }
    case TaskStatusModel.IN_PROGRESS: {
      return {
        late_end_date: task.late_end_date.seconds
          ? getIOSDate(task.late_end_date.seconds)
          : null,
        early_end_date: task.early_end_date.seconds
          ? getIOSDate(task.early_end_date.seconds)
          : null,
        early_start_date: null,
        late_start_date: null,
      };
    }
    default: {
      return {
        late_end_date: task.late_end_date.seconds
          ? getIOSDate(task.late_end_date.seconds)
          : null,
        early_end_date: task.early_end_date.seconds
          ? getIOSDate(task.early_end_date.seconds)
          : null,
        early_start_date: task.early_start_date.seconds
          ? getIOSDate(task.early_start_date.seconds)
          : null,
        late_start_date: task.late_start_date.seconds
          ? getIOSDate(task.late_start_date.seconds)
          : null,
      };
    }
  }
};

export const getExpiryDate = (
  task: TaskModel,
  calendar: Maybe<CalendarModel>
) => {
  let workingHoursPerDay = calendar ? calendar.working_hours_per_day : 8;
  let expiryDate =
    task.status === TaskStatusModel.IN_PROGRESS && task.act_start_date
      ? moment(new Date(task.act_start_date.seconds * 1000))
          .add(
            assignDuration(
              (task.remainingDuration / 2) / workingHoursPerDay,
              new Date(task.act_start_date.seconds * 1000),
              calendar as CalendarModel,
              DIR.FORWARD
            ),
            "hours"
          )
          .toDate()
      : null;
  if (!expiryDate) {
    return null;
  }
  return FirebaseUsage.timestamp(expiryDate).seconds;
};

export const formatDayToWeekday = (day: Date): string => {
  return moment(day).format("dddd").toLowerCase();
};

export const formatDaysToWeekday = (days: Date[]): string[] => {
  return days.map((day) => formatDayToWeekday(day));
};

export const isWorkingDay = (
  day: Date,
  workingDays: {
    [key: string]: CalendarDaysOfWeekModel | null;
  }
): boolean => {
  let value = false;
  for (const key in workingDays) {
    if (workingDays[key] !== null && formatDayToWeekday(day) === key) {
      value = true;
      break;
    }
  }
  return value;
};

export const addDaysToDate = (startDate: Date, amountOfDays: number): Date => {
  return moment(startDate).add(amountOfDays, "days").toDate();
};

// export const addDaysWithoutWeekends = (duration: number, startDate: Date, calendar: CalendarModel, previousNonWorkingDays?: number): number => {
//     const { workday_exceptions, working_days_of_the_week } = calendar;
//     const dateFormat = 'YYYY:MM:DD';
//     let exceptions = typeof workday_exceptions === 'string' ? JSON.parse(workday_exceptions) : workday_exceptions;
//     exceptions = (exceptions)
//         .map(el => excel2date(+el.date))
//         .map(date => moment(date).format(dateFormat));
//     const days = daysArrayFromDuration(startDate, addDaysToDate(startDate, duration)) as Date[];
//     const nonWorkingDays = days
//         .filter(
//             day =>
//                 exceptions.some(exception => moment(day).format(dateFormat) === exception) ||
//                 !isWorkingDay(day, working_days_of_the_week)
//         ).length;
//     let newDuration = duration;
//     if ((previousNonWorkingDays !== 0 && previousNonWorkingDays !== nonWorkingDays) || previousNonWorkingDays === undefined) {
//         newDuration = previousNonWorkingDays
//             ? duration + nonWorkingDays - previousNonWorkingDays
//             : duration + nonWorkingDays;
//         return addDaysWithoutWeekends(newDuration, startDate, calendar, nonWorkingDays);
//     }
//     // console.log(newDuration, 'dur');
//     return newDuration;
// }

export const assignDuration = (
  duration,
  requestedDate,
  selectedCalendar,
  dir
) => {
  const exceptions = selectedCalendar.exceptionsArr
  duration = duration * selectedCalendar.working_hours_per_day * 60 * 60 * 1000;

  if (!(requestedDate instanceof Date)) {
    requestedDate = new Date(requestedDate);
  }

  const checkPeriod = (period, periodDate, requestedDate, dir) => {
    const periodDateCopy = new Date(periodDate.getTime());
    periodDateCopy.setHours(0, 0, 0, 0);
    const periodStartTimeinMs =
      Number(period.start.split(":")[0]) * 60 +
      Number(period.start.split(":")[1]);
    const periodEndTimeinMs =
      Number(period.finish.split(":")[0]) * 60 +
      Number(period.finish.split(":")[1]);
    let periodStart = new Date(periodDateCopy.getTime());
    periodStart.setMinutes(periodStart.getMinutes() + periodStartTimeinMs);

    let periodFinish = new Date(periodDateCopy.getTime());
    periodFinish.setMinutes(periodFinish.getMinutes() + periodEndTimeinMs);
    if (periodFinish.getTime() < periodStart.getTime())
      periodFinish = new Date(
        periodFinish.getTime() +
          selectedCalendar.working_hours_per_day * 60 * 60 * 1000
      );
    if (dir === DIR.FORWARD) {
      if (requestedDate.getTime() < periodStart.getTime())
        return [-1, periodFinish.getTime() - periodStart.getTime()];
      if (requestedDate.getTime() <= periodFinish.getTime())
        return [0, periodFinish.getTime() - requestedDate.getTime()];
    } else {
      if (requestedDate.getTime() <= periodStart.getTime())
        return [-1, periodFinish.getTime() - periodStart.getTime()];
      if (requestedDate.getTime() <= periodFinish.getTime())
        return [0, requestedDate.getTime() - periodStart.getTime()];
    }
    return [1, periodFinish.getTime() - periodStart.getTime()];
  };

  let testDate = new Date(requestedDate);
  let periodDate: any = null;
  let periodEndDate: any = null;
  let sc = 0;

  while ((!periodDate || duration > 0) && sc++ < 10000) {
    // eslint-disable-next-line
    const exception = exceptions ? exceptions.get(date2Excel(testDate)) : null;
    // const exception = exceptions[+testDate.getFullYear()]?.find(
    //   // eslint-disable-next-line
    //   (e) => e.date === date2Excel(testDate).toString()
    // );
    const periodArray = exception ? exception.periods :
        selectedCalendar.working_days_of_the_week[days[testDate.getDay()]] ?
            selectedCalendar.working_days_of_the_week[days[testDate.getDay()]] : [];
    // const periods = ((exception || {}).periods || (exception && []) || selectedCalendar.working_days_of_the_week[days[testDate.getDay()]] || []).sort((a, b) =>
    //   dir === DIR.FORWARD
    //     ? a.periodRank - b.periodRank
    //     : b.periodRank - a.periodRank
    // );
    const periods = periodArray.sort((a, b) =>
          dir === DIR.FORWARD
            ? a.periodRank - b.periodRank
            : b.periodRank - a.periodRank
        );

    // eslint-disable-next-line
    for (const period of periods) {
      if (periodEndDate || !period.start || !period.finish) return;
      const [result, periodDuration] = checkPeriod(
        period,
        testDate,
        requestedDate,
        dir
      );
      if (!periodDate) {
        if (result === 0) {
          duration -= periodDuration;
          periodDate = testDate;
        } else if (dir === DIR.FORWARD && result === -1) {
          duration -= periodDuration;
          periodDate = dateTime(testDate, period.start);
        } else if (dir === DIR.BACKWARD && result === 1) {
          duration -= periodDuration;
          periodDate = dateTime(testDate, period.finish);
        }
      } else {
        duration -= periodDuration;
      }
      if (duration <= 0 && periodDate) {
        if (dir === DIR.FORWARD)
          periodEndDate = new Date(
            Math.max(
              periodDate.getTime(),
              dateTime(testDate, period.start).getTime()
            ) +
              (periodDuration + duration)
          );
        else
          periodEndDate = new Date(
            Math.min(
              periodDate.getTime(),
              dateTime(testDate, period.finish).getTime()
            ) -
              (periodDuration + duration)
          );
      }
    };
    testDate = new Date(
      testDate.getFullYear(),
      testDate.getMonth(),
      testDate.getDate() + dir,
      3
    );
  }

  const [taskStartDate, taskFinishDate] = [periodDate, periodEndDate].sort(
    (a, b) => a.getTime() - b.getTime()
  );
  const durationFromRequestedHrs =
    (dir === DIR.FORWARD
      ? taskFinishDate.getTime() - requestedDate.getTime()
      : requestedDate.getTime() - taskStartDate.getTime()) /
    1000 /
    60 /
    60;
  return durationFromRequestedHrs;
};

export const getSecsDuration = (
  startDate: Date,
  endDate: Date,
  calendar: CalendarModel
) => {
  if (startDate === endDate) return 0;
  const exceptions = calendar.exceptionsArr
  let allTime: number = 0;
  let start = new Date(startDate);
  start.setHours(0, 0, 0, 0);
  let end = new Date(endDate);
  end.setHours(0, 0, 0, 0);
  let diff =
    Math.ceil(Math.abs(end.getTime() - start.getTime()) / 1000 / 3600 / 24) + 1;
  let theDate =
    endDate.getTime() - startDate.getTime() < 0
      ? new Date(endDate)
      : new Date(startDate);
  let excelDate: number = date2Excel(theDate);
  let mainFun = (i) => {
    let newDay = moment(theDate, "DD-MM-YYYY").add(i, "day").toDate();

    const curDayName = days[newDay.getDay()];
    if (calendar.working_days_of_the_week[curDayName] !== null) {
      const exception = exceptions ? exceptions.get(excelDate) : null;
      const periodArray = exception ? exception.periods :
          calendar.working_days_of_the_week[curDayName] ?
              calendar.working_days_of_the_week[curDayName] : [];
      const periods = periodArray.sort((a, b) => a.periodRank - b.periodRank);
      periods.forEach((period) => {
        const periodStart = new Date(
          new Date(
            (theDate.toISOString().replace(/T.*/, " ") + period.start).replace(
              /-/g,
              "/"
            )
          )
        );
        let periodFinish = new Date(
          new Date(
            (theDate.toISOString().replace(/T.*/, " ") + period.finish).replace(
              /-/g,
              "/"
            )
          )
        );
        if (!period.start && !period.finish) {
          return;
        }
        let diff = (periodFinish.getTime() - periodStart.getTime()) / 1000;
        allTime += diff;
      });
    }
    excelDate++;
  };
  for (let i = 0; i < diff; i++) {
    mainFun(i);
  }

  if (endDate.getTime() - startDate.getTime() < 0) {
    return -allTime;
  }
  return allTime;
};

export const timeFromEnd = (
  startDate: Date,
  endDate: Date,
  calendar: CalendarModel
) => {
  const exceptions = calendar.exceptionsArr
  const curDayName = days[endDate.getDay()];
  let allTime: number = 0;

  let insideFirstRank = false;
  let mainFun = (period) => {
    if (!period.start || !period.finish) return;

    if (endDate.getTime() < startDate.getTime()) {
      let dayDate = ("0" + endDate.getDate()).slice(-2);
      let strDate = endDate.toISOString().replace(/T.*/, "");
      const periodStart = new Date(
        new Date(
          strDate.slice(0, strDate.length - 2) + dayDate + " " + period.start
        )
      );
      let periodFinish = new Date(
        new Date(
          strDate.slice(0, strDate.length - 2) + dayDate + " " + period.finish
        )
      );

      if (
        endDate.getTime() >= periodStart.getTime() &&
        endDate.getTime() <= periodFinish.getTime()
      ) {
        let diff = endDate.getTime() - periodStart.getTime();
        allTime += diff;
      }

      if (endDate.getTime() > periodFinish.getTime()) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
      }
    } else {
      let dayDate = ("0" + endDate.getDate()).slice(-2);
      let strDate = endDate.toISOString().replace(/T.*/, "");
      const periodStart = new Date(
        new Date(
          strDate.slice(0, strDate.length - 2) + dayDate + " " + period.start
        )
      );
      let periodFinish = new Date(
        new Date(
          strDate.slice(0, strDate.length - 2) + dayDate + " " + period.finish
        )
      );
      if (
        endDate.getTime() < periodStart.getTime() &&
        period.periodRank === 0
      ) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
        insideFirstRank = true;
      }

      if (
        endDate.getTime() >= periodStart.getTime() &&
        endDate.getTime() <= periodFinish.getTime()
      ) {
        let diff = periodFinish.getTime() - endDate.getTime();
        allTime += diff;

        if (period.periodRank === 0) {
          insideFirstRank = true;
        }
      }

      if (
        (period.periodRank > 0 && insideFirstRank) ||
        (period.periodRank > 0 && endDate.getTime() < periodStart.getTime())
      ) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
      }
    }
  };
  for (const key in calendar.working_days_of_the_week) {
    if (key === curDayName && calendar.working_days_of_the_week[key] !== null) {
      let excelDate = date2Excel(endDate);
      const exception = exceptions ? exceptions.get(excelDate) : null;
      const periodArray = exception ? exception.periods :
            calendar.working_days_of_the_week[days[endDate.getDay()]] ?
                calendar.working_days_of_the_week[days[endDate.getDay()]] : [];
      const periods = periodArray.sort((a, b) => a.periodRank - b.periodRank);

      periods.forEach((period) => {
        mainFun(period);
      });
    }
  }

  return Math.floor(allTime / 1000);
};

export const timeFromStart = (
  startDate: Date,
  endDate: Date,
  calendar: CalendarModel
) => {
  const exceptions = calendar.exceptionsArr
  const curDayName = days[startDate.getDay()];
  let allTime: number = 0;
  let insideFirstRank = false;

  let mainFun = (period) => {
    if (!period.start || !period.finish) return;
    let dayDate = ("0" + startDate.getDate()).slice(-2);
    let strDate = startDate.toISOString().replace(/T.*/, "");
    const periodStart = new Date(
      new Date(
        strDate.slice(0, strDate.length - 2) + dayDate + " " + period.start
      )
    );
    let periodFinish = new Date(
      new Date(
        strDate.slice(0, strDate.length - 2) + dayDate + " " + period.finish
      )
    );
    if (!period.start && !period.finish) {
      return allTime;
    }
    if (endDate.getTime() < startDate.getTime()) {
      if (
        startDate.getTime() < periodStart.getTime() &&
        period.periodRank === 0
      ) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
        insideFirstRank = true;
      }

      if (
        startDate.getTime() >= periodStart.getTime() &&
        startDate.getTime() <= periodFinish.getTime()
      ) {
        let diff = periodFinish.getTime() - startDate.getTime();
        allTime += diff;

        if (period.periodRank === 0) {
          insideFirstRank = true;
        }
      }

      if (period.periodRank > 0 && insideFirstRank) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
      }
    } else {
      if (
        startDate.getTime() >= periodStart.getTime() &&
        startDate.getTime() <= periodFinish.getTime()
      ) {
        let diff = startDate.getTime() - periodStart.getTime();
        allTime += diff;
      }

      if (startDate.getTime() > periodFinish.getTime()) {
        let diff = periodFinish.getTime() - periodStart.getTime();
        allTime += diff;
      }
    }
  };
  for (const key in calendar.working_days_of_the_week) {
    if (key === curDayName && calendar.working_days_of_the_week[key] !== null) {
      let excelDate = date2Excel(startDate);
      const exception = exceptions ? exceptions.get(excelDate) : null;
      const periodArray = exception ? exception.periods :
            calendar.working_days_of_the_week[days[startDate.getDay()]] ?
                calendar.working_days_of_the_week[days[startDate.getDay()]] : [];
      const periods = periodArray.sort((a, b) => a.periodRank - b.periodRank);

      periods.forEach((period) => {
        mainFun(period);
      });
    }
  }

  return Math.floor(allTime / 1000);
};

export const isNonWorkingPeriod = (date: Date, calendar: CalendarModel) => {
  const exceptions = calendar.exceptionsArr
  const curDayName = days[date.getDay()];
  let result = true;
  let mainFun = (period) => {
    if (!period.start || !period.finish) return true;
    let dayDate = ("0" + date.getDate()).slice(-2);
    let strDate = date.toISOString().replace(/T.*/, "");
    const periodStart = new Date(
      new Date(
        strDate.slice(0, strDate.length - 2) + dayDate + " " + period.start
      )
    );
    let periodFinish = new Date(
      new Date(
        strDate.slice(0, strDate.length - 2) + dayDate + " " + period.finish
      )
    );
    // if (!period.start && !period.finish) { return }
    if (
      date.getTime() >= periodStart.getTime() &&
      date.getTime() <= periodFinish.getTime()
    ) {
      result = false;
    }
  };
  for (const key in calendar.working_days_of_the_week) {
    if (key === curDayName && calendar.working_days_of_the_week[key] !== null) {
      let excelDate = date2Excel(date);
      const exception = exceptions ? exceptions.get(excelDate) : null;
      const periodArray = exception ? exception.periods :
          calendar.working_days_of_the_week[days[date.getDay()]] ?
              calendar.working_days_of_the_week[days[date.getDay()]] : [];
      const periods = periodArray.sort((a, b) => a.periodRank - b.periodRank);
      periods.forEach((period) => {
        mainFun(period);
      });
    }
  }

  return result;
};

export const returnDayStartAndDayEnd = (
  date: Date,
  calendar: CalendarModel
) => {
  const dateWithWorkDaysAdd = moment(date)
    .add(
      assignDuration(0, date, calendar as CalendarModel, DIR.FORWARD),
      "hours"
    )
    .toDate();
  const curDayName = days[dateWithWorkDaysAdd.getDay()];
  let currentDay: any = calendar.working_days_of_the_week[curDayName] || [];
  const dayPeriods = currentDay.sort((a, b) => a.periodRank - b.periodRank);

  return {
    start: dayPeriods[0].start,
    end: dayPeriods[dayPeriods.length - 1].finish,
  };
};
