import {Icon, Label} from "semantic-ui-react";
import React, {useCallback} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {useDispatch} from "react-redux";
import {SearchActions} from "../../../../../store/actions/search.actions";
import styles from '../SearchBar.module.scss';

export const ChipsList = () => {
    const searchParam = useTypedSelector(state => state.search.parameters);
    const memberList = useTypedSelector(state => state.team.memberList);
    const user = useTypedSelector(state => state.authorization.user);
    const dispatch = useDispatch();

    const removeChip = useCallback((text: string) => {
        if (memberList.some(el => el.userEmail === text)) {
            const el = memberList.find(el => el.userEmail === text);
            dispatch(SearchActions.removeIdFromTaskforecFilter([el!.userId]));
            return;
        }
        dispatch(SearchActions.removeChip(text));
    }, [dispatch, memberList]);


    const Chip = useCallback(chip => (
        <Label className={styles.ActiveFilterChip}>
            {chip.text}
            <Icon name='delete' onClick={() => removeChip(chip.text)}/>
        </Label>
    ), [removeChip]);

    if (!user) {
        return null;
    }

    return (
        <div className="search-bar-chips-wrap">
            {
                searchParam.chipList.map((chip) =>
                    <Chip key={chip.text} {...chip}/>
                )
            }
            {
                memberList
                    .filter(el =>
                        searchParam.taskForces.some(taskForce => taskForce === el.userId && taskForce !== user.userId)
                    )
                    .map(el =>
                        <Chip type='filter' text={el.userEmail} key={el.userId}/>
                    )
            }
        </div>
    );
};
