import FirebaseUsage from "../firebase.usage";
import { TeamFunctions } from "../../models/firebase-functions.model";
import { MemberOrganizationEnum } from "../../models/member-organization.enum";
import { GateKeeperEum } from "../../models/gate-keeper.enum";

export default class FirebaseTeamFunctions {
  public static async makeEvaluatorToggle(args: { memberId: string }) {
    return await FirebaseUsage.functions(TeamFunctions.MAKE_EVALUATOR_TOGGLE, {
      timeout: 10000000,
    })({
      ...args,
    });
  }

  public static async removeMember(args: {
    memberId: string;
    projectId: string;
    type: string;
    nominatedMembersList?: { userEmail: string; id: string }[];
    lastSuperActor?: boolean;
  }) {
    return await FirebaseUsage.functions(TeamFunctions.REMOVE_MEMBER, {
      timeout: 10000000,
    })({
      ...args,
    });
  }

  public static async addNewMember(args: {
    userEmail: string;
    projectId: string;
    grade: MemberOrganizationEnum;
    clientId: string;
  }) {
    return await FirebaseUsage.functions(TeamFunctions.ADD_MEMBER, {
      timeout: 10000000,
    })({
      ...args,
    });
  }

  public static async addNewGroup(args: {
    groupName: string;
    projectId: string;
  }) {
    return await FirebaseUsage.functions(TeamFunctions.ADD_GROUP, {
      timeout: 10000000,
    })({
      ...args,
    });
  }

  public static async changeMemberGrade(args: {
    grade: MemberOrganizationEnum;
    memberId: string;
  }) {
    return await FirebaseUsage.functions(TeamFunctions.CHANGE_GRADE, {
      timeout: 10000000,
    })({
      ...args,
    });
  }

  public static async changeMemberGateKeeper(args: {
    gateKeeper: GateKeeperEum;
    memberId: string;
  }) {
    return await FirebaseUsage.functions(TeamFunctions.CHANGE_GATEKEEPER, {
      timeout: 10000000,
    })({
      ...args,
    });
  }
}
