import React, { useCallback } from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';

interface Props {
    deleteProject: () => void;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    projectsIds: string[];
    setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteProjectModal: React.FC<Props> = ({ deleteProject, modalOpen, setModalOpen, projectsIds, setError }) => {

    const triggerProjectDeletionHandler = useCallback(() => {
        if (projectsIds.length) {
            setError(false);
            return setModalOpen(true);
        }
        setError(true);
    }, [projectsIds.length, setError, setModalOpen])    

    return (
        <Modal 
            trigger={<Button onClick={triggerProjectDeletionHandler} color='green'>Delete Projects</Button>}
            basic
            size='small'
            onClose={() => setModalOpen(false)}
            open={modalOpen}
        >
            <Header icon='eraser' content='Delete Projects' />
            <Modal.Content>
                <p>
                    Would you like to delete projects?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)} basic color='red' inverted>
                    <Icon name='remove' /> No
                </Button>
                <Button onClick={deleteProject} color='green' inverted>
                    <Icon name='checkmark' /> Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
}