import EventLog from "../../models/responses/event-log-model";
import { createAction, ActionsUnion } from "@martin_hotell/rex-tils";

export enum ActionNames {
    SET_EVENT_LOG_LIST = '[Event Log] Set Event Log List',
}


export const Actions = {
    setEventLogList: (eventLogList: EventLog[]) => createAction(ActionNames.SET_EVENT_LOG_LIST, {eventLogList}),
};

export type Actions = ActionsUnion<typeof Actions>;