import { Maybe } from "@martin_hotell/rex-tils";
import TaskModel from "../models/responses/task.model";
import styles from "../pages/ProjectPage/pages/FlowPage/components/Task/TaskContainer.module.scss";

export const getMilestoneColor = (task: Maybe<TaskModel>): string => {
    if(task) {
        switch (task.predStatus) {
            case 1: {
                return styles.MilestoneGreen;
            }
            case 2: {
                return styles.MilestoneYellow;
            }
            case 3: {
                return styles.MilestoneRed;
            }
            default: {
                return styles.MilestoneBlue;
            }
        }
    }
    return '';
};