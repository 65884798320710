import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";
import {
  useActiveProjectSelector,
  useProjectLoadingStageSelector,
  useUploadingNewProject,
} from "../../../../../../store/selectors/project.selectors";
import { Maybe } from "@martin_hotell/rex-tils";
import { FileApi } from "../../../../../../api/file.api";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import { UploadProjectDataTable } from "./components/UploadProjectDataTable";
// import { CalendarModel } from "../../../../../../models/responses/calendar.model";
import { Omit } from "@martin_hotell/rex-tils";
// import ProjectTransactions from "../../../../../../store/thunk/transactions/project.transactions";
// import { useUserSelector } from "../../../../../../store/selectors/authorization.selectors";
import * as ProjectActions from "../../../../../../store/actions/project.actions";
import { useDispatch } from "react-redux";
import ProjectTransactions from "../../../../../../store/thunk/transactions/project.transactions";
import { useUserSelector } from "../../../../../../store/selectors/authorization.selectors";
import uuid from "uuid";

export interface ProjectsData {
  id: string;
  name: string;
  projectDataUrl: any;
  exist: boolean;
  errorMessage?: string;
  projectData: any;
  clientId: string;
}

interface InjectProps {
  open: boolean;
  onClose: () => void;
  title: string;
  uploadHandler: (
    projects: Omit<ProjectsData, "exist" | "errorMessage">[],
    callback: () => void
  ) => void;
  multiFile: boolean;
  errorMessage?: string;
  isUpdate?: boolean;
  //handleInput: () => void;
  setErrorMessage?: (message: string) => void;
  setUploadStrategyModalOpen: (value: boolean) => void;
}

type PropsType = InjectProps;

const LoaderSpinner: React.FC<{ text: string }> = ({ text }) => (
  <div className="loader-wrap">
    <Dimmer active inverted>
      <Loader inverted>{text}</Loader>
    </Dimmer>
  </div>
);

export const UploadProjectData: React.FC<PropsType> = (props) => {
  //const [dragInZone, setDragInZone] = useState(false);
  const loading = useUploadingNewProject();
  const [localStartLoading, setLocalStartLoading] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [fileTransformationLoading, setFileTransformationLoading] =
    useState(false);
  const [projects, setProjects] = useState<ProjectsData[]>([]);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [renameOccured, setRenameOccured] = useState(false);
  const user = useUserSelector();
  const [isProjectUploadCanceling, setIsProjectUploadCanceling] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const projectLoadingStage = useProjectLoadingStageSelector();
  // const [isProjectUploaded, setIsProjectUploaded] = useState<boolean>(false);
  const isMountedRef = useRef(false);
  const inputHandler = useRef<HTMLInputElement | null>(null);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const activeProject = useActiveProjectSelector();

  useEffect(() => {
    if (props.open && !localStartLoading) {
      inputHandler.current?.click();
      var body = document.body;
      if (!body.classList.contains("completeModal")) {
        body.classList.add("hiddenModal");
      }
    }
  }, [props, localStartLoading]);

  useEffect(() => {
    isMountedRef.current = true;
    if (loading && localStartLoading) {
      setLocalStartLoading(false);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [loading, localStartLoading]);

  useEffect(() => {
    isMountedRef.current = true;
    if (props.errorMessage) {
      setLocalStartLoading(false);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [props.errorMessage]);

  useEffect(() => {
    isMountedRef.current = true;
    setDisabled(!projects.length);
    if (!projects.length && props.setErrorMessage) {
      props.setErrorMessage("");
      setLocalErrorMessage("");
    }
    if (projects.some((project) => project.exist)) {
      setLocalErrorMessage(
        `This project(s) "${projects
          .filter((el) => el.exist)
          .map((el) => el.name)
          .join(",")}" is already in use, please rename!`
      );
    } else {
      setLocalErrorMessage("");
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [projects, props]);

  useEffect(() => {
    isMountedRef.current = true;
    if (projectLoadingStage.projectLoaded) {
      setIsProjectUploadCanceling(false);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [projectLoadingStage]);

  const uploadProject = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      localStorage.removeItem("projectId");
      if (isMountedRef.current) {
        dispatch(
          ProjectActions.Actions.setProjectLoadingStage({
            ...projectLoadingStage,
            projectLoaded: false,
            projectStopped: false,
          })
        );
      }
      setLocalStartLoading(true);
      setOpenUploadFile(false);
      setTimeout(() => {
        props.uploadHandler(projects, async () => {
          if (isMountedRef.current) {
            setProjects([]);
            props.setUploadStrategyModalOpen(false);
          }
        });
      }, 100);
    },
    [props, projects, dispatch, projectLoadingStage]
  );

  const checkOnProjectExits = useCallback(
    async (projectList: { name: string }[]) => {
      return FirebaseUsage.functions("checkIfProjectExist")(projectList).then(
        (data) => data.data
      );
    },
    []
  );

  const onDropHandler = useCallback(
    async (files: Maybe<FileList>) => {
      //setDragInZone(false);
      var body = document.body;
      body.classList.remove("hiddenModal");
      body.classList.add("completeModal");

      if (!files) {
        return;
      }
      console.log(files);
      console.log(props);

      let error = false;

      const handlingFilesFormat = (files: FileList, index = 0) => {
        const file = files.item(index);
        if (file && file.name.slice(file.name.length - 3) !== "xer") {
          error = true;
        }
        if (files.item(index + 1)) {
          handlingFilesFormat(files, index + 1);
        }
      };

      handlingFilesFormat(files);

      if (error) {
        return;
      }

      setFileTransformationLoading(true);
      let jsonData = await FileApi.transformXerToJson(
        props.multiFile ? files : files.item(0),
        props.isUpdate,
        props.isUpdate
          ? activeProject
            ? activeProject.projectId
            : null
          : null,
        user
      );

      const newProjectList: any[] = [];
      jsonData.forEach((data, i) => {
        newProjectList.push(
          data.errorMessage
            ? {
                errorMessage: data.errorMessage,
              }
            : {
                name: data[i][0],
                id: data[i][1],
              }
        );
      });

      const existingProjects: any = await checkOnProjectExits(
        newProjectList.map((e) => {
          return { name: e.name };
        })
      );
      setProjects(
        newProjectList.map((project) => ({
          ...project,
          exist: existingProjects.some((el) => el === project.name),
        }))
      );
      setFileTransformationLoading(false);

      const projectsWithEist = newProjectList.map((project) => ({
        ...project,
        exist: existingProjects.some((el) => el === project.name),
      }));

      if (projectsWithEist.some((project) => project.exist)) {
        //setDragInZone(true);
        return;
      }

      if (!confirmTerms) {
        return;
      }

      // Upload
      localStorage.removeItem("projectId");
      if (isMountedRef.current) {
        dispatch(
          ProjectActions.Actions.setProjectLoadingStage({
            ...projectLoadingStage,
            projectLoaded: false,
            projectStopped: false,
          })
        );
      }
      setLocalStartLoading(true);
      setTimeout(() => {
        props.uploadHandler(newProjectList, async () => {
          setLocalStartLoading(false);
          props.onClose();
          setLocalStartLoading(false);
          setProjects(
            newProjectList.map((project) => ({
              ...project,
              exist: existingProjects.some((el) => el === project.name),
            }))
          );
        });
      }, 100);
    },
    [props, checkOnProjectExits, dispatch, projectLoadingStage, confirmTerms]
  );

  const closeModal = useCallback(() => {
    props.onClose();
    setProjects([]);
    setConfirmTerms(false);
    setLocalStartLoading(false);
    var body = document.body;
    body.classList.add("hiddenModal");
    body.classList.remove("dimmable");
    body.classList.remove("dimmed");
  }, [props]);

  const cancelUpload = useCallback(async () => {
    if (localStartLoading) {
      setIsProjectUploadCanceling(true);
      if (isMountedRef.current) {
        dispatch(
          ProjectActions.Actions.setProjectLoadingStage({
            ...projectLoadingStage,
            projectStopped: true,
            projectNames: projects.map((el) => el.name),
          })
        );
      }
    } else {
      setProjects([]);
    }
    if (user && localStartLoading) {
      await ProjectTransactions.cancelProjectUploading(
        projects.map((el) => el.name),
        user
      );
      setIsProjectUploadCanceling(false);
    }
    // if (loading || localStartLoading) {
    //   setIsProjectUploadCanceling(true);
    // }
    closeModal();
  }, [
    closeModal,
    dispatch,
    projectLoadingStage,
    localStartLoading,
    projects,
    user,
  ]);

  const onChangeProjectNameHandler = useCallback(
    async (data: { [key: number]: string }) => {
      console.log("🚀 ~ data:", data);
      const newProjectList = projects.map((el, index) => ({
        ...el,
        name: data[index] ? data[index] : el.name,
      }));
      console.log("🚀 ~ newProjectList ~ projects:", projects);
      console.log("🚀 ~ newProjectList ~ newProjectList:", newProjectList);

      let existingProjects: any;
      if (isMountedRef.current) {
        existingProjects = await checkOnProjectExits(newProjectList);
      }

      setProjects(
        newProjectList.map((project) => ({
          ...project,
          exist: existingProjects.some((el) => el === project.name),
        }))
      );
      setRenameOccured(true);
    },
    [projects, checkOnProjectExits]
  );
  const onSetClientIdHandler = useCallback(
    async (data: any) => {
      const newProjectList = projects.map((el, index) => ({
        ...el,
        clientId: data,
      }));

      let existingProjects: any;
      if (isMountedRef.current) {
        existingProjects = await checkOnProjectExits(newProjectList);
      }

      setProjects(
        projects.map((project) => ({
          ...project,
          clientId: data,
        }))
      );
    },
    [projects, checkOnProjectExits]
  );
  return (
    <>
      {isProjectUploadCanceling && (
        <Dimmer active>
          <Loader indeterminate>Canceling project upload...</Loader>
        </Dimmer>
      )}

      {!localErrorMessage && openUploadFile && (
        <div style={{ opacity: "0" }}>
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile"
            accept=".xer"
            ref={inputHandler}
            disabled={fileTransformationLoading}
            onChange={(evt) => onDropHandler(evt.target.files)}
          />
        </div>
      )}

      {
        <Modal
          open={props.open}
          closeIcon
          onClose={!loading ? closeModal : undefined}
          // onUnmount={cancelUpload}
          style={{ animationDuration: "1s" }}
        >
          <Modal.Header>
            {localErrorMessage ? localErrorMessage : props.title}
          </Modal.Header>
          <Modal.Content image>
            <div className="flex-1">
              {props.errorMessage && fileTransformationLoading && (
                <Message negative>
                  <Message.Header>{props.errorMessage}</Message.Header>
                </Message>
              )}
              {projects
                .filter((project) => project.errorMessage)
                .map((project) => (
                  <Message key={project.name} negative>
                    <Message.Header>{project.name}:</Message.Header>
                    <p>{project.errorMessage}</p>
                  </Message>
                ))}
              {/* {localErrorMessage && (
              <Message negative>
                <Message.Header>{localErrorMessage}</Message.Header>
              </Message>
            )} */}

              <div className="relative flex-1">
                {localStartLoading ? (
                  <LoaderSpinner text="Creating pull system - this may take a few minutes" />
                ) : null}
                {fileTransformationLoading ? (
                  <LoaderSpinner text="Preparing to upload..." />
                ) : null}
                {loading ? <LoaderSpinner text="Uploading..." /> : null}
                <Form className="flex-1">
                  <Form.Field>
                    {!props.isUpdate &&
                    (localErrorMessage ||
                      renameOccured ||
                      projects.length > 0) ? (
                      <UploadProjectDataTable
                        projects={projects}
                        onChangeProjectName={onChangeProjectNameHandler}
                        onSetClientId={onSetClientIdHandler}
                      />
                    ) : (
                      <div
                        style={{
                          height: 50,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        Ready To Upload
                      </div>
                    )}
                  </Form.Field>
                </Form>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="terms"
              style={{
                pointerEvents: localStartLoading ? "none" : "unset",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              <input
                type="checkbox"
                id="terms"
                checked={confirmTerms}
                onChange={() => setConfirmTerms(!confirmTerms)}
                style={{ cursor: "pointer", marginRight: "10px" }}
              />
              <label htmlFor="terms" style={{ cursor: "pointer" }}>
                I confirm that I have read and accept the &nbsp;
                <a
                  href="https://www.flowledger.io/customer-terms-of-service"
                  style={{ cursor: "pointer" }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Customer Terms of Service
                </a>
              </label>
            </div>
            <div className="buttons">
              <Button
                negative
                disabled={loading}
                onClick={cancelUpload}
                style={{ marginRight: "15px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={uploadProject}
                positive
                disabled={
                  disabled ||
                  loading ||
                  projects.some((project) => project.exist) ||
                  localStartLoading ||
                  fileTransformationLoading ||
                  !confirmTerms
                }
                icon="checkmark"
                labelPosition="right"
                content="Upload"
              />
            </div>
          </Modal.Actions>
        </Modal>
      }
    </>
  );
};
