import XMLWriter from 'xml-writer'
import TaskModel from "../../../models/responses/task.model";
import {Timestamp} from "firebase/firestore";
import {v4 as uuidv4} from 'uuid';
import { saveAs } from "file-saver";
import { convertIndexToSeconds, convertDateToIndex } from "../../cpm-functions/cpm-app/functions/handleEvent";
import {RelationshipsModel} from "../../../models/responses/relationships.model";
import FirebaseUsage from "../../../firebase/firebase.usage";
import {TaskListSectionModel} from "../../../models/task-list-section.model";
import TaskStatusModel from "../../../models/responses/task-status.model";
import ProjectModel from "../../../models/responses/project.model";

export default function createP6Xml(tasks: any | TaskModel[] = [],
                                    calendarsMap,
                                    relationships: any[],
                                    wbs: any[],
                                    cpmMap: any,
                                    calendars: any,
                                    activeProject: any
) {
  const xw = new XMLWriter('  ')
  xw.startDocument('1.0', 'UTF-8')
  xw.startElement('APIBusinessObjects')
      .writeAttribute('xmlns', "http://xmlns.oracle.com/Primavera/P6Professional/V16.2/API/BusinessObjects")
      .writeAttribute('xmlns:xsi', "http://www.w3.org/2001/XMLSchema-instance")
      .writeAttribute('xsi:schemaLocation', "http://xmlns.oracle.com/Primavera/P6Professional/V16.2/API/BusinessObjects http://xmlns.oracle.com/Primavera/P6Professional/V16.2/API/p6apibo.xsd")

  function dateConversion(timestamp: Timestamp) {
    return timestamp.toDate().toISOString().split('.')[0]
  }

  const handleConstraints = {
    'CS_MSO': 'Must Start On',
    'CS_MEO': 'Must Finish On',
    'CS_ALAP': 'As Late As Possible',
    'CS_MEOA': 'Finish On or After',
    'CS_MSOB': 'Start On or Before',
    'CS_MEOB': 'Finish On or Before',
    'CS_MANDFINISH': 'Mandatory Finish',
    'CS_MANDSTART': 'Mandatory Start',
  }

  const handleTypes = {
    TT_Task: 'Task Dependent',
    TT_RSRC: 'Resource Dependent',
    TT_WBS: 'WBS Summary',
    TT_LOE: 'Level of Effort',
    TT_Mile: 'Start Milestone',
    TT_FinMile: 'Finish Milestone',
    TT_TASK: 'Task Dependent',
    TT_Rsrc: 'Resource Dependent',
    TT_FINMILE: 'Finish Milestone',
    TT_MILE: 'Start Milestone',
  }

  const handleLinkTypes = {
    FF: "Finish to Finish",
    FS: "Finish to Start",
    SF: "Start to Finish",
    SS: "Start to Start"
  }

  const daysArray = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  function formatTime(time) {
    const timeArray = time.split(':')
    if (timeArray[1] === '00') {
      return `${parseInt(timeArray[0]) - 1}:59:00`
    } else {
      return `${timeArray[0]}:${parseInt(timeArray[1]) - 1}:00`
    }
  }

  // Do calendars
  for (const calendar of calendars) {
    let hours = 0
    daysArray.forEach((day: any) => {
        if (calendar.working_days_of_the_week[day.toLowerCase()]) {
            for (const period of calendar.working_days_of_the_week[day.toLowerCase()]) {
              const finishHour = parseInt(period.finish.split(':')[0]) + ((parseInt(period.finish.split(':')[1]) + 1)/60)
              const startHour = parseInt(period.start.split(':')[0]) + (parseInt(period.start.split(':')[1])/60)
              hours += (finishHour - startHour)
            }
        }
    })

    xw.startElement('Calendar')
        .writeRaw(`
    <BaseCalendarObjectId xsi:nil="true" />
    <HoursPerDay>${calendar.working_hours_per_day}</HoursPerDay>
    <HoursPerMonth>${(hours * 4.3).toFixed(0)}</HoursPerMonth>
    <HoursPerWeek>${hours.toFixed(0)}</HoursPerWeek>
    <HoursPerYear>${(hours * 50).toFixed(0)}</HoursPerYear>
    <IsDefault>0</IsDefault>
    <IsPersonal>0</IsPersonal>
    <Name>${calendar.calendar_name}</Name>
    <ObjectId>${calendar.calendar_id}</ObjectId>
    <ProjectObjectId xsi:nil="true" />
    <Type>Global</Type>
    <StandardWorkWeek>${daysArray.map((day: any) => `
      <StandardWorkHours>
        <DayOfWeek>${day}</DayOfWeek>${calendar.working_days_of_the_week[day.toLowerCase()] ? calendar.working_days_of_the_week[day.toLowerCase()].map((times: any) => `
        <WorkTime>
          <Start>${times.start}:00</Start>
          <Finish>${formatTime(times.finish)}</Finish>
        </WorkTime>`) : '\n        <WorkTime xsi:nil="true" />'}
      </StandardWorkHours>`)}
    </StandardWorkWeek>${calendar.workday_exceptions ? calendar.workday_exceptions.map((exception: any, index) => {
      if (index === 0) {
        return `
    <HolidayOrExceptions>
      <HolidayOrException>
        <Date>${dateConversion(FirebaseUsage.timestamp(new Date((exception.date - 25569) * 86400000)))}</Date>
        ${exception.periods[0].start ? exception.periods.map((period: any) => `
        <WorkTime>
          <Start>${period.start}:00</Start>
          <Finish>${formatTime(period.finish)}</Finish>
        </WorkTime>`) : '<WorkTime xsi:nil="true" />'}
      </HolidayOrException>`} else if (index === calendar.workday_exceptions.length - 1) {
        return `
      <HolidayOrException>
        <Date>${dateConversion(FirebaseUsage.timestamp(new Date((exception.date - 25569) * 86400000)))}</Date>
        ${exception.periods[0].start ? exception.periods.map((period: any) => `
        <WorkTime>
          <Start>${period.start}:00</Start>
          <Finish>${formatTime(period.finish)}</Finish>
        </WorkTime>`) : '<WorkTime xsi:nil="true" />'}
      </HolidayOrException>
    </HolidayOrExceptions>`} else {
        return `
      <HolidayOrException>
        <Date>${dateConversion(FirebaseUsage.timestamp(new Date((exception.date - 25569) * 86400000)))}</Date>
        ${exception.periods[0].start ? exception.periods.map((period: any) => `
        <WorkTime>
          <Start>${period.start}:00</Start>
          <Finish>${formatTime(period.finish)}</Finish>
        </WorkTime>`) : '<WorkTime xsi:nil="true" />'}
      </HolidayOrException>`}}
        ) : ''}
  `).endElement()
}

  // 2 MAIN CHANGES - PLANNED START DATE OF PROJECT REQUIRED AND WBS OBJECT ID MAY BE REQUIRED (IT'S THE PLANNED START!!!)
  xw.startElement('Project')
      .writeRaw(`
    <ActivityDefaultActivityType>Task Dependent</ActivityDefaultActivityType>
    <ActivityDefaultCalendarObjectId>${calendars[0].calendar_id}</ActivityDefaultCalendarObjectId>
    <ActivityDefaultCostAccountObjectId xsi:nil="true" />
    <ActivityDefaultDurationType>Fixed Duration and Units</ActivityDefaultDurationType>
    <ActivityDefaultPercentCompleteType>Duration</ActivityDefaultPercentCompleteType>
    <ActivityDefaultPricePerUnit>0</ActivityDefaultPricePerUnit>
    <ActivityIdBasedOnSelectedActivity>1</ActivityIdBasedOnSelectedActivity>
    <ActivityIdIncrement>10</ActivityIdIncrement>
    <ActivityIdPrefix>A</ActivityIdPrefix>
    <ActivityIdSuffix>1000</ActivityIdSuffix>
    <ActivityPercentCompleteBasedOnActivitySteps>0</ActivityPercentCompleteBasedOnActivitySteps>
    <AddActualToRemaining>0</AddActualToRemaining>
    <AddedBy>admin</AddedBy>
    <AllowNegativeActualUnitsFlag>0</AllowNegativeActualUnitsFlag>
    <AnnualDiscountRate xsi:nil="true" />
    <AnticipatedFinishDate xsi:nil="true" />
    <AnticipatedStartDate xsi:nil="true" />
    <AssignmentDefaultDrivingFlag>1</AssignmentDefaultDrivingFlag>
    <AssignmentDefaultRateType>Price / Unit</AssignmentDefaultRateType>
    <CheckOutStatus>0</CheckOutStatus>
    <CostQuantityRecalculateFlag>0</CostQuantityRecalculateFlag>
    <CriticalActivityFloatLimit>0</CriticalActivityFloatLimit>
    <CriticalActivityPathType>Critical Float</CriticalActivityPathType>
    <CurrentBaselineProjectObjectId xsi:nil="true" />
    <DataDate>${dateConversion(FirebaseUsage.timestamp(new Date((Math.floor((new Date().getTime() / 1000) / 86400) * 86400) * 1000)))}</DataDate>
    <DateAdded>${dateConversion(FirebaseUsage.timestamp(new Date((Math.floor((new Date().getTime() / 1000) / 86400) * 86400) * 1000)))}</DateAdded>
    <DefaultPriceTimeUnits>Hour</DefaultPriceTimeUnits>
    <DiscountApplicationPeriod xsi:nil="true" />
    <EarnedValueComputeType>Activity Percent Complete</EarnedValueComputeType>
    <EarnedValueETCComputeType>ETC = Remaining Cost for Activity</EarnedValueETCComputeType>
    <EarnedValueETCUserValue>0.88</EarnedValueETCUserValue>
    <EarnedValueUserPercent>0.06</EarnedValueUserPercent>
    <EnableSummarization>1</EnableSummarization>
    <FiscalYearStartMonth>1</FiscalYearStartMonth>
    <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
    <Id>FL-1230</Id>
    <IndependentETCLaborUnits>0</IndependentETCLaborUnits>
    <IndependentETCTotalCost>0</IndependentETCTotalCost>
    <LastFinancialPeriodObjectId xsi:nil="true" />
    <LevelingPriority>10</LevelingPriority>
    <LinkActualToActualThisPeriod>1</LinkActualToActualThisPeriod>
    <LinkPercentCompleteWithActual>1</LinkPercentCompleteWithActual>
    <LinkPlannedAndAtCompletionFlag>1</LinkPlannedAndAtCompletionFlag>
    <MustFinishByDate xsi:nil="true" />
    <Name>${activeProject.name}</Name>
    <ObjectId>1334</ObjectId>
    <OriginalBudget>0</OriginalBudget>
    <ParentEPSObjectId xsi:nil="true" />
    <PlannedStartDate>${dateConversion(FirebaseUsage.timestamp(new Date(activeProject.earliestDate)))}</PlannedStartDate>
    <PrimaryResourcesCanMarkActivitiesAsCompleted>1</PrimaryResourcesCanMarkActivitiesAsCompleted>
    <ProjectForecastStartDate xsi:nil="true" />
    <ResetPlannedToRemainingFlag>0</ResetPlannedToRemainingFlag>
    <ResourceCanBeAssignedToSameActivityMoreThanOnce>1</ResourceCanBeAssignedToSameActivityMoreThanOnce>
    <ResourcesCanAssignThemselvesToActivities>1</ResourcesCanAssignThemselvesToActivities>
    <ScheduledFinishDate>${dateConversion(FirebaseUsage.timestamp(new Date(activeProject.latestDate)))}</ScheduledFinishDate>
    <Status>Active</Status>
    <StrategicPriority>500</StrategicPriority>
    <SummarizeToWBSLevel>2</SummarizeToWBSLevel>
    <SummaryLevel>Assignment Level</SummaryLevel>
    <UseProjectBaselineForEarnedValue>1</UseProjectBaselineForEarnedValue>
    <WBSCodeSeparator>.</WBSCodeSeparator>
    <WBSObjectId>60527</WBSObjectId>
    <WebSiteRootDirectory xsi:nil="true" />
    <WebSiteURL xsi:nil="true" />`)


  // Generate WBS IDs
  let wbsIds = new Map()
  let wbsId = 40000
  for (const header of wbs) {
    wbsIds.set(header.wbs_id, wbsId)
    wbsId++
  }

  let sequenceNumber = 0
  for (const header of wbs) {
    if (header.parent_wbs_id) {
    xw.startElement('WBS')
        .writeRaw(`
      <AnticipatedFinishDate xsi:nil="true" />
      <AnticipatedStartDate xsi:nil="true" />
      <Code>${header.wbs_short_name}</Code>
      <EarnedValueComputeType>Activity Percent Complete</EarnedValueComputeType>
      <EarnedValueETCComputeType>ETC = Remaining Cost for Activity</EarnedValueETCComputeType>
      <EarnedValueETCUserValue>0.88</EarnedValueETCUserValue>
      <EarnedValueUserPercent>0.06</EarnedValueUserPercent>
      <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
      <IndependentETCLaborUnits>0</IndependentETCLaborUnits>
      <IndependentETCTotalCost>0</IndependentETCTotalCost>
      <Name>${header.wbs_name}</Name>
      <ObjectId>${wbsIds.get(header.wbs_id)}</ObjectId>
      <OriginalBudget>0</OriginalBudget>
      ${wbsIds.get(header.parent_wbs_id) !== 40000 ? `<ParentObjectId>${wbsIds.get(header.parent_wbs_id)}</ParentObjectId>` : `<ParentObjectId xsi:nil="true" />`}
      <ProjectObjectId>1334</ProjectObjectId>
      <SequenceNumber>${sequenceNumber * 100}</SequenceNumber>
      <Status>Active</Status>
      <WBSCategoryObjectId xsi:nil="true" />
    `)
        .endElement()
      sequenceNumber++
    }
  }

  // Do Tasks
  let idNumber = 200000
  let i = 0
  let taskIds = new Map()
  for (let task of tasks) {
    task.task_name = task.task_name.replace(/&/g, '&amp;')
    idNumber++
    const cpmTask = cpmMap.get(task.task_id)
    if (task.taskListType === TaskListSectionModel.CONFIRMED_COMPLETE) {
      xw.startElement('Activity')
          .writeRaw(`
      <ActualDuration>${((cpmTask.ef - cpmTask.ef) / 2).toFixed(0)}</ActualDuration>
      <ActualFinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.act_end_date.seconds + 1800) * 1000)))}</ActualFinishDate>
      <ActualLaborCost>0</ActualLaborCost>
      <ActualLaborUnits>0</ActualLaborUnits>
      <ActualNonLaborCost>0</ActualNonLaborCost>
      <ActualNonLaborUnits>0</ActualNonLaborUnits>
      <ActualStartDate>${dateConversion(task.act_start_date)}</ActualStartDate>
      <ActualThisPeriodLaborCost>0</ActualThisPeriodLaborCost>
      <ActualThisPeriodLaborUnits>0</ActualThisPeriodLaborUnits>
      <ActualThisPeriodNonLaborCost>0</ActualThisPeriodNonLaborCost>
      <ActualThisPeriodNonLaborUnits>0</ActualThisPeriodNonLaborUnits>
      <AtCompletionDuration>${((cpmTask.ef - cpmTask.ef) / 2).toFixed(0)}</AtCompletionDuration>
      <AtCompletionExpenseCost>0</AtCompletionExpenseCost>
      <AtCompletionLaborCost>0</AtCompletionLaborCost>
      <AtCompletionLaborUnits>0</AtCompletionLaborUnits>
      <AtCompletionNonLaborCost>0</AtCompletionNonLaborCost>
      <AtCompletionNonLaborUnits>0</AtCompletionNonLaborUnits>
      <AutoComputeActuals>0</AutoComputeActuals>
      <CalendarObjectId>${task.calendar_id}</CalendarObjectId>
      <DurationPercentComplete>100</DurationPercentComplete>
      <DurationType>Fixed Duration and Units</DurationType>
      <EstimatedWeight>1</EstimatedWeight>
      <ExpectedFinishDate xsi:nil="true" />
      <ExternalEarlyStartDate xsi:nil="true" />
      <ExternalLateFinishDate xsi:nil="true" />
      <Feedback />
      <FinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.act_end_date.seconds + 1800) * 1000)))}</FinishDate>
      <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
      <Id>${task.task_code}</Id>
      <IsNewFeedback>0</IsNewFeedback>
      <LevelingPriority>Normal</LevelingPriority>
      <Name>${task.task_name}</Name>
      <NonLaborUnitsPercentComplete>0</NonLaborUnitsPercentComplete>
      <NotesToResources />
      <ObjectId>${idNumber}</ObjectId>
      <PercentComplete>100</PercentComplete>
      <PercentCompleteType>Duration</PercentCompleteType>
      <PhysicalPercentComplete>100</PhysicalPercentComplete>
      <PlannedDuration>${task.targetDuration}</PlannedDuration>
      <PlannedFinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.act_end_date.seconds + 1800) * 1000)))}</PlannedFinishDate>
      <PlannedLaborCost>0</PlannedLaborCost>
      <PlannedLaborUnits>0</PlannedLaborUnits>
      <PlannedNonLaborCost>0</PlannedNonLaborCost>
      <PlannedNonLaborUnits>0</PlannedNonLaborUnits>
      <PlannedStartDate>${dateConversion(task.act_start_date)}</PlannedStartDate>
      <PrimaryConstraintDate xsi:nil="true" />
      <PrimaryConstraintType xsi:nil="true" />
      <PrimaryResourceObjectId xsi:nil="true" />
      <ProjectObjectId>1334</ProjectObjectId>
      <RemainingDuration>0</RemainingDuration>
      <RemainingEarlyFinishDate xsi:nil="true" />
      <RemainingEarlyStartDate xsi:nil="true" />
      <RemainingLaborCost>0</RemainingLaborCost>
      <RemainingLaborUnits>0</RemainingLaborUnits>
      <RemainingLateFinishDate xsi:nil="true" />
      <RemainingLateStartDate xsi:nil="true" />
      <RemainingNonLaborCost>0</RemainingNonLaborCost>
      <RemainingNonLaborUnits>0</RemainingNonLaborUnits>
      <ResumeDate xsi:nil="true" />
      <SecondaryConstraintDate xsi:nil="true" />
      <SecondaryConstraintType xsi:nil="true" />
      <StartDate>${dateConversion(task.act_start_date)}</StartDate>
      <Status>Completed</Status>
      <SuspendDate xsi:nil="true" />
      <Type>Task Dependent</Type>
      <UnitsPercentComplete>100</UnitsPercentComplete>
      ${wbsIds.get(task.wbs_id) === 40000 ? `<WBSObjectId xsi:nil="true" />` :`<WBSObjectId>${wbsIds.get(task.wbs_id)}</WBSObjectId>`}
    `).endElement()
    } else if (task.taskListType === TaskListSectionModel.DECLARED_COMPLETE) {
      xw.startElement('Activity')
          .writeRaw(`
      <ActualDuration>${((cpmTask.ef - cpmTask.ef) / 2).toFixed(0)}</ActualDuration>
      <ActualFinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.declaredCompleteTimestamp.seconds + 1800) * 1000)))}</ActualFinishDate>
      <ActualLaborCost>0</ActualLaborCost>
      <ActualLaborUnits>0</ActualLaborUnits>
      <ActualNonLaborCost>0</ActualNonLaborCost>
      <ActualNonLaborUnits>0</ActualNonLaborUnits>
      <ActualStartDate>${dateConversion(task.act_start_date)}</ActualStartDate>
      <ActualThisPeriodLaborCost>0</ActualThisPeriodLaborCost>
      <ActualThisPeriodLaborUnits>0</ActualThisPeriodLaborUnits>
      <ActualThisPeriodNonLaborCost>0</ActualThisPeriodNonLaborCost>
      <ActualThisPeriodNonLaborUnits>0</ActualThisPeriodNonLaborUnits>
      <AtCompletionDuration>${((cpmTask.ef - cpmTask.ef) / 2).toFixed(0)}</AtCompletionDuration>
      <AtCompletionExpenseCost>0</AtCompletionExpenseCost>
      <AtCompletionLaborCost>0</AtCompletionLaborCost>
      <AtCompletionLaborUnits>0</AtCompletionLaborUnits>
      <AtCompletionNonLaborCost>0</AtCompletionNonLaborCost>
      <AtCompletionNonLaborUnits>0</AtCompletionNonLaborUnits>
      <AutoComputeActuals>0</AutoComputeActuals>
      <CalendarObjectId>${task.calendar_id}</CalendarObjectId>
      <DurationPercentComplete>100</DurationPercentComplete>
      <DurationType>Fixed Duration and Units</DurationType>
      <EstimatedWeight>1</EstimatedWeight>
      <ExpectedFinishDate xsi:nil="true" />
      <ExternalEarlyStartDate xsi:nil="true" />
      <ExternalLateFinishDate xsi:nil="true" />
      <Feedback />
      <FinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.declaredCompleteTimestamp.seconds + 1800) * 1000)))}</FinishDate>
      <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
      <Id>${task.task_code}</Id>
      <IsNewFeedback>0</IsNewFeedback>
      <LevelingPriority>Normal</LevelingPriority>
      <Name>${task.task_name}</Name>
      <NonLaborUnitsPercentComplete>0</NonLaborUnitsPercentComplete>
      <NotesToResources />
      <ObjectId>${idNumber}</ObjectId>
      <PercentComplete>100</PercentComplete>
      <PercentCompleteType>Duration</PercentCompleteType>
      <PhysicalPercentComplete>100</PhysicalPercentComplete>
      <PlannedDuration>${task.targetDuration}</PlannedDuration>
      <PlannedFinishDate>${dateConversion(FirebaseUsage.timestamp(new Date((task.declaredCompleteTimestamp.seconds + 1800) * 1000)))}</PlannedFinishDate>
      <PlannedLaborCost>0</PlannedLaborCost>
      <PlannedLaborUnits>0</PlannedLaborUnits>
      <PlannedNonLaborCost>0</PlannedNonLaborCost>
      <PlannedNonLaborUnits>0</PlannedNonLaborUnits>
      <PlannedStartDate>${dateConversion(task.act_start_date)}</PlannedStartDate>
      <PrimaryConstraintDate xsi:nil="true" />
      <PrimaryConstraintType xsi:nil="true" />
      <PrimaryResourceObjectId xsi:nil="true" />
      <ProjectObjectId>1334</ProjectObjectId>
      <RemainingDuration>0</RemainingDuration>
      <RemainingEarlyFinishDate xsi:nil="true" />
      <RemainingEarlyStartDate xsi:nil="true" />
      <RemainingLaborCost>0</RemainingLaborCost>
      <RemainingLaborUnits>0</RemainingLaborUnits>
      <RemainingLateFinishDate xsi:nil="true" />
      <RemainingLateStartDate xsi:nil="true" />
      <RemainingNonLaborCost>0</RemainingNonLaborCost>
      <RemainingNonLaborUnits>0</RemainingNonLaborUnits>
      <ResumeDate xsi:nil="true" />
      <SecondaryConstraintDate xsi:nil="true" />
      <SecondaryConstraintType xsi:nil="true" />
      <StartDate>${dateConversion(task.act_start_date)}</StartDate>
      <Status>Completed</Status>
      <SuspendDate xsi:nil="true" />
      <Type>Task Dependent</Type>
      <UnitsPercentComplete>100</UnitsPercentComplete>
      ${wbsIds.get(task.wbs_id) === 40000 ? `<WBSObjectId xsi:nil="true" />` :`<WBSObjectId>${wbsIds.get(task.wbs_id)}</WBSObjectId>`}
    `).endElement()
    } else if (task.act_start_date) {
      let actualDuration = (convertDateToIndex(Math.floor((new Date().getTime() / 1000) / 86400) * 86400, cpmTask.cal_id, calendarsMap) - cpmTask.es) / 2
      let remainingDuration = cpmTask.duration / 2
      let physicalPercentComplete = task.progress ? task.progress.sort((a, b) => b.date.seconds - a.date.seconds)[0].progress : 0
      let earlyStart: string = dateConversion(FirebaseUsage.timestamp(new Date(convertIndexToSeconds(cpmTask.ad, cpmTask.cal_id, calendarsMap) * 1000)))
      let earlyFinish: string = dateConversion(FirebaseUsage.timestamp(new Date((convertIndexToSeconds(cpmTask.ef, cpmTask.cal_id, calendarsMap) + 1800) * 1000)))
      let lateStart: string = dateConversion(FirebaseUsage.timestamp(new Date(convertIndexToSeconds(cpmTask.ls, cpmTask.cal_id, calendarsMap) * 1000)))
      let lateFinish: string = dateConversion(FirebaseUsage.timestamp(new Date((convertIndexToSeconds(cpmTask.lf, cpmTask.cal_id, calendarsMap) + 1800) * 1000)))
      xw.startElement('Activity')
          .writeRaw(`
      <ActualDuration>${actualDuration.toFixed(0)}</ActualDuration>
      <ActualFinishDate xsi:nil="true" />
      <ActualLaborCost>0</ActualLaborCost>
      <ActualLaborUnits>0</ActualLaborUnits>
      <ActualNonLaborCost>0</ActualNonLaborCost>
      <ActualNonLaborUnits>0</ActualNonLaborUnits>
      <ActualStartDate>${dateConversion(task.act_start_date)}</ActualStartDate>
      <ActualThisPeriodLaborCost>0</ActualThisPeriodLaborCost>
      <ActualThisPeriodLaborUnits>0</ActualThisPeriodLaborUnits>
      <ActualThisPeriodNonLaborCost>0</ActualThisPeriodNonLaborCost>
      <ActualThisPeriodNonLaborUnits>0</ActualThisPeriodNonLaborUnits>
      <AtCompletionDuration>${((cpmTask.ef - cpmTask.ef) / 2).toFixed(0)}</AtCompletionDuration>
      <AtCompletionExpenseCost>0</AtCompletionExpenseCost>
      <AtCompletionLaborCost>0</AtCompletionLaborCost>
      <AtCompletionLaborUnits>0</AtCompletionLaborUnits>
      <AtCompletionNonLaborCost>0</AtCompletionNonLaborCost>
      <AtCompletionNonLaborUnits>0</AtCompletionNonLaborUnits>
      <AutoComputeActuals>0</AutoComputeActuals>
      <CalendarObjectId>${task.calendar_id}</CalendarObjectId>
      <DurationPercentComplete>${((1 - (remainingDuration / Math.max((cpmTask.ef - cpmTask.es) / 2, 1))) * 100).toFixed(0)}</DurationPercentComplete>
      <DurationType>Fixed Duration and Units</DurationType>
      <EstimatedWeight>1</EstimatedWeight>
      <ExpectedFinishDate xsi:nil="true" />
      <ExternalEarlyStartDate xsi:nil="true" />
      <ExternalLateFinishDate xsi:nil="true" />
      <Feedback />
      <FinishDate>${earlyFinish}</FinishDate>
      <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
      <Id>${task.task_code}</Id>
      <IsNewFeedback>0</IsNewFeedback>
      <LevelingPriority>Normal</LevelingPriority>
      <Name>${task.task_name}</Name>
      <NonLaborUnitsPercentComplete>${((1 - (remainingDuration / Math.max((cpmTask.ef - cpmTask.es) / 2, 1))) * 100).toFixed(0)}</NonLaborUnitsPercentComplete>
      <NotesToResources />
      <ObjectId>${idNumber}</ObjectId>
      <PercentComplete>${((1 - (remainingDuration / Math.max((cpmTask.ef - cpmTask.es) / 2, 1))) * 100).toFixed(0)}</PercentComplete>
      <PercentCompleteType>Duration</PercentCompleteType>
      <PhysicalPercentComplete>${physicalPercentComplete}</PhysicalPercentComplete>
      <PlannedDuration>${(task.targetDuration / 2).toFixed(0)}</PlannedDuration>
      <PlannedFinishDate>${dateConversion(task.expiryDate)}</PlannedFinishDate>
      <PlannedLaborCost>0</PlannedLaborCost>
      <PlannedLaborUnits>0</PlannedLaborUnits>
      <PlannedNonLaborCost>0</PlannedNonLaborCost>
      <PlannedNonLaborUnits>0</PlannedNonLaborUnits>
      <PlannedStartDate>${dateConversion(task.act_start_date)}</PlannedStartDate>
      ${task.constraint_date ? `<PrimaryConstraintDate>${dateConversion(task.constraint_date)}</PrimaryConstraintDate>` : `<PrimaryConstraintDate xsi:nil="true" />`}
      ${task.constraint_type !== '' ? `<PrimaryConstraintType>${handleConstraints[task.constraint_type]}</PrimaryConstraintType>` : `<PrimaryConstraintType xsi:nil="true" />`}
      <PrimaryResourceObjectId xsi:nil="true" />
      <ProjectObjectId>1334</ProjectObjectId>
      <RemainingDuration>${remainingDuration.toFixed(0)}</RemainingDuration>
      <RemainingEarlyFinishDate>${earlyFinish}</RemainingEarlyFinishDate>
      <RemainingEarlyStartDate>${earlyStart}</RemainingEarlyStartDate>
      <RemainingLaborCost>0</RemainingLaborCost>
      <RemainingLaborUnits>0</RemainingLaborUnits>
      <RemainingLateFinishDate>${lateFinish}</RemainingLateFinishDate>
      <RemainingLateStartDate>${lateStart}</RemainingLateStartDate>
      <RemainingNonLaborCost>0</RemainingNonLaborCost>
      <RemainingNonLaborUnits>0</RemainingNonLaborUnits>
      <ResumeDate xsi:nil="true" />
      <SecondaryConstraintDate xsi:nil="true" />
      <SecondaryConstraintType xsi:nil="true" />
      <StartDate>${dateConversion(task.act_start_date)}</StartDate>
      <Status>In Progress</Status>
      <SuspendDate xsi:nil="true" />
      <Type>Task Dependent</Type>
      <UnitsPercentComplete>${((1 - (remainingDuration / Math.max((cpmTask.ef - cpmTask.es) / 2, 1))) * 100).toFixed(0)}</UnitsPercentComplete>
      ${wbsIds.get(task.wbs_id) === 40000 ? `<WBSObjectId xsi:nil="true" />` :`<WBSObjectId>${wbsIds.get(task.wbs_id)}</WBSObjectId>`}
    `).endElement()
    } else {
      let start: string = dateConversion(FirebaseUsage.timestamp(new Date(convertIndexToSeconds(cpmTask.es, cpmTask.cal_id, calendarsMap) * 1000)))
      let finish: string = dateConversion(FirebaseUsage.timestamp(new Date((convertIndexToSeconds(cpmTask.ef, cpmTask.cal_id, calendarsMap) + 1800) * 1000)))
      let lateStart: string = dateConversion(FirebaseUsage.timestamp(new Date(convertIndexToSeconds(cpmTask.ls, cpmTask.cal_id, calendarsMap) * 1000)))
      let lateFinish: string = dateConversion(FirebaseUsage.timestamp(new Date((convertIndexToSeconds(cpmTask.lf, cpmTask.cal_id, calendarsMap) + 1800) * 1000)))
      xw.startElement('Activity')
          .writeRaw(`
      <ActualDuration>0</ActualDuration>
      <ActualFinishDate xsi:nil="true" />
      <ActualLaborCost>0</ActualLaborCost>
      <ActualLaborUnits>0</ActualLaborUnits>
      <ActualNonLaborCost>0</ActualNonLaborCost>
      <ActualNonLaborUnits>0</ActualNonLaborUnits>
      <ActualStartDate xsi:nil="true" />
      <ActualThisPeriodLaborCost>0</ActualThisPeriodLaborCost>
      <ActualThisPeriodLaborUnits>0</ActualThisPeriodLaborUnits>
      <ActualThisPeriodNonLaborCost>0</ActualThisPeriodNonLaborCost>
      <ActualThisPeriodNonLaborUnits>0</ActualThisPeriodNonLaborUnits>
      <AtCompletionDuration>${task.targetDuration / 2}</AtCompletionDuration>
      <AtCompletionExpenseCost>0</AtCompletionExpenseCost>
      <AtCompletionLaborCost>0</AtCompletionLaborCost>
      <AtCompletionLaborUnits>0</AtCompletionLaborUnits>
      <AtCompletionNonLaborCost>0</AtCompletionNonLaborCost>
      <AtCompletionNonLaborUnits>0</AtCompletionNonLaborUnits>
      <AutoComputeActuals>0</AutoComputeActuals>
      <CalendarObjectId>${task.calendar_id}</CalendarObjectId>
      <DurationPercentComplete>0</DurationPercentComplete>
      <DurationType>Fixed Duration and Units</DurationType>
      <EstimatedWeight>1</EstimatedWeight>
      <ExpectedFinishDate xsi:nil="true" />
      <ExternalEarlyStartDate xsi:nil="true" />
      <ExternalLateFinishDate xsi:nil="true" />
      <Feedback />
      <FinishDate>${finish}</FinishDate>
      <GUID>{${uuidv4().toString().toUpperCase()}}</GUID>
      <Id>${task.task_code}</Id>
      <IsNewFeedback>0</IsNewFeedback>
      <LevelingPriority>Normal</LevelingPriority>
      <Name>${task.task_name}</Name>
      <NonLaborUnitsPercentComplete>0</NonLaborUnitsPercentComplete>
      <NotesToResources />
      <ObjectId>${idNumber}</ObjectId>
      <PercentComplete>0</PercentComplete>
      <PercentCompleteType>Duration</PercentCompleteType>
      <PhysicalPercentComplete>0</PhysicalPercentComplete>
      <PlannedDuration>${task.targetDuration / 2}</PlannedDuration>
      <PlannedFinishDate>${finish}</PlannedFinishDate>
      <PlannedLaborCost>0</PlannedLaborCost>
      <PlannedLaborUnits>0</PlannedLaborUnits>
      <PlannedNonLaborCost>0</PlannedNonLaborCost>
      <PlannedNonLaborUnits>0</PlannedNonLaborUnits>
      <PlannedStartDate>${start}</PlannedStartDate>
      ${task.constraint_date ? `<PrimaryConstraintDate>${dateConversion(task.constraint_date)}</PrimaryConstraintDate>` : `<PrimaryConstraintDate xsi:nil="true" />`}
      ${task.constraint_type !== '' ? `<PrimaryConstraintType>${handleConstraints[task.constraint_type]}</PrimaryConstraintType>` : `<PrimaryConstraintType xsi:nil="true" />`}
      <PrimaryResourceObjectId xsi:nil="true" />
      <ProjectObjectId>1334</ProjectObjectId>
      <RemainingDuration>${(task.remainingDuration / 2).toFixed(0)}</RemainingDuration>
      <RemainingEarlyFinishDate>${finish}</RemainingEarlyFinishDate>
      <RemainingEarlyStartDate>${start}</RemainingEarlyStartDate>
      <RemainingLaborCost>0</RemainingLaborCost>
      <RemainingLaborUnits>0</RemainingLaborUnits>
      <RemainingLateFinishDate>${lateFinish}</RemainingLateFinishDate>
      <RemainingLateStartDate>${lateStart}</RemainingLateStartDate>
      <RemainingNonLaborCost>0</RemainingNonLaborCost>
      <RemainingNonLaborUnits>0</RemainingNonLaborUnits>
      <ResumeDate xsi:nil="true" />
      <SecondaryConstraintDate xsi:nil="true" />
      <SecondaryConstraintType xsi:nil="true" />
      <StartDate>${start}</StartDate>
      <Status>Not Started</Status>
      <SuspendDate xsi:nil="true" />
      <Type>Task Dependent</Type>
      <UnitsPercentComplete>0</UnitsPercentComplete>
      ${wbsIds.get(task.wbs_id) === 40000 ? `<WBSObjectId xsi:nil="true" />` :`<WBSObjectId>${wbsIds.get(task.wbs_id)}</WBSObjectId>`}
    `).endElement()
    }
    taskIds.set(task.task_id, idNumber + i)
  }

  // Do relationships
  let linkId = 300000
  for (const relation of relationships) {
    linkId++
    xw.startElement('Relationship')
        .writeRaw(`
      <Lag>${relation.lag / 2}</Lag>
      <ObjectId>${linkId}</ObjectId>
      <PredecessorActivityObjectId>${taskIds.get(relation.pred_task_id)}</PredecessorActivityObjectId>
      <PredecessorProjectObjectId>1334</PredecessorProjectObjectId>
      <SuccessorActivityObjectId>${taskIds.get(relation.task_id)}</SuccessorActivityObjectId>
      <SuccessorProjectObjectId>1334</SuccessorProjectObjectId>
      <Type>${handleLinkTypes[relation.pred_type]}</Type>
    `).endElement()
  }

  // xw.endElement()
  xw.endDocument()

  // save to  text file

  const ampersandIncorrect = new RegExp('&amp(?!;)', 'g')
  const ampersandRegEx = new RegExp('&(?!amp;)', 'g')
  let outString = xw.toString()
      .replace(/>,/g, '>')
      .replace(ampersandIncorrect, '&amp;')
      .replace(ampersandRegEx, '&amp;')

  saveAs(new Blob([outString], {type: "text/plain;charset=utf-8"}), `${activeProject.name}-${(new Date().getTime() / 1000).toFixed(0)}.xml`)
  // console.log(xw.toString().replace(/>,/g, '>'))
  return xw.toString()
}
