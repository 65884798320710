import {getXERContent} from "./getXERContentMap";
import loopDetection from "./loopDetection";
import uploadToFirestore from "./uploadToFirestore";


export default async function parseXERContent (projContent, userId) {
    const timeStart = new Date().getTime()
    try {
        let data: any = {}
        data.projects = await getXERContent(projContent, 'project', 'proj', true, 'projects')
        data.links = await getXERContent(projContent, 'taskpred', 'task_pred', true, 'relationships')
        data.wbsMap = await getXERContent(projContent, 'projwbs', 'wbs', true, 'wbs')
        data.taskMap = await getXERContent(projContent, 'task', 'task', true, 'tasks')
        data.calendars = await getXERContent(projContent, 'calendar', 'clndr', true, 'calendar')
        // data.activityCodeTypes = await getXERContent(projContent, 'actvtype', 'actv_code_type', true)
        // data.activityCodes = await getXERContent(projContent, 'actvcode', 'actv_code', true)
        // data.activityCodeAssignments = await getXERContent(projContent, 'taskactv', 'task', true)
        data.udfType = await getXERContent(projContent, 'udftype', 'udf_type', true, 'udfType')
        data.udfValue = await getXERContent(projContent, 'udfvalue', 'fk', false, 'udfValue')

        console.log(data)
        const loopDetect = loopDetection(data.taskMap, data.links)
        let uploadResult: any[] | null = null
        if (loopDetect) {
            return ["error", "Loop detected in project"]
        } else {
            console.log("No loop detected")
            try {
                uploadResult = await uploadToFirestore(data, userId)
            } catch (err: any) {
                console.log(err)
                return ["error", err.toString()]
            }

        }

        console.log('XER file parsed successfully in ' + (new Date().getTime() - timeStart) + ' ms')
        return [uploadResult, data]
        // return ['test', data]
    }
    catch (err: any) {
        console.log(err)
        return ["error", err.toString()]
    }
}
