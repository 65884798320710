import {Dispatch, MiddlewareAPI} from "redux";
import {RootStateModel} from "../../reducers";
import * as authorizationActions from '../../actions/authorization.actions'
import {subsManager} from "../subs-manager/subs-manager";


export function authorizationMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
    return (next: Dispatch) => (action: authorizationActions.Actions) => {
        const returnValue = next(action);

        switch (action.type) {
            case authorizationActions.ActionNames.AUTH: {
                const user = api.getState().authorization.user;
                if (user) {
                    subsManager.subscribeProjectList(user.userId);
                    subsManager.subscribeProjectPermission(user.userId);
                }
                break;
            }
            case authorizationActions.ActionNames.NOT_AUTH: {
                // subsManager.removeAllSubscriptions();
                break;
            }
            case authorizationActions.ActionNames.LOG_OUT: {
                subsManager.removeAllSubscriptions()
                break;
            }
            default: {
                break;
            }
        }

        return returnValue
    }
}
