import * as authorizationActions from "../actions/authorization.actions";
import AuthorizationApi from "../../api/authorization.api";
import {ThunkResult} from "../store.types";
import SignInBodyModel from "../../models/reqest-body-models/sign-in-body.model";
import FirebaseUsage from "../../firebase/firebase.usage";
import {COLLECTIONS} from "../../firebase/constants";

export default class AuthorizationThunk {
    static sendSignInLinkToEmail(data: SignInBodyModel): ThunkResult<Promise<void>> {
        return async (dispatch) => {
            try {
                dispatch(authorizationActions.Actions.sendSignInLinkToEmail());
                await AuthorizationApi.sendSignInLinkToEmail(data);
                dispatch(authorizationActions.Actions.sendSignInLinkToEmailSuccess());
            } catch (e) {
                dispatch(authorizationActions.Actions.sendSignInLinkToEmailFail());
            }
        }
    }

    static signInWithEmailLink(): ThunkResult<Promise<void>> {
        return async (dispatch) => {
            try {
                await AuthorizationApi.signInWithEmailLink();
                localStorage.removeItem('email-for-sign-in');
            } catch (e) {
                dispatch(authorizationActions.Actions.notAuth());
            }
        }
    }

    static logOut(): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            const user = getState().authorization.user;
            if (user) {
                await FirebaseUsage.updateDoc(COLLECTIONS.USERS, user.userId, {
                            settings: {
                                ...user.settings,
                                token: null
                            }
                        }
                    );
                dispatch(authorizationActions.Actions.logOut());
                await AuthorizationApi.logOut();
                localStorage.clear();
            }
        }
    }
}
