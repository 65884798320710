import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Dropdown,
  FormField,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
// import { TaskType } from "../../../../../../../models/task-type";
import FirebaseUsage from "../../../../../../../firebase/firebase.usage";
import FirebaseProjectFunctions from "../../../../../../../firebase/firebase-functions/project-service";

interface InjectedProps {
  projects: Array<{
    name: string;
    projectDataUrl: any;
    exist: boolean;
    errorMessage?: string;
    projectData: any;
  }>;

  onChangeProjectName(data: { [key: number]: string }): void;
  onSetClientId(data: any): void;
}

export const UploadProjectDataTable: React.FC<InjectedProps> = ({
  projects,
  onChangeProjectName,
  onSetClientId,
}) => {
  const [editProject, setEditProject] = useState({});
  const [clients, setClients]: any = useState([]);
  const onChangeProjectNameHandler = useCallback(
    (key: number) => {
      console.log(editProject);
      onChangeProjectName(editProject);
      const newEditProject = { ...editProject };
      delete newEditProject[key];
      setEditProject(newEditProject);
    },
    [editProject, onChangeProjectName]
  );

  useEffect(() => {
    const fetchClients = async () => {
      const { data } = await FirebaseProjectFunctions.getClients();
      if ((data as any[]).length > 0) {
        setClients(
          (data as any[]).map((client: any) => ({
            key: client.clientId,
            text: client.domain,
            value: client.clientId,
          }))
        );
      }
    };

    fetchClients();
  }, []);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {/*<Table.HeaderCell/>*/}
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Client</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {projects.map((project, key) => (
          <Table.Row
            key={project.name}
            error={project.exist || Boolean(project.errorMessage)}
          >
            {/*<Table.Cell>*/}
            {/*    <Button onClick={() => clearDataInput(key)} icon*/}
            {/*            color="red"*/}
            {/*            size="small">*/}
            {/*        <Icon name='times'/>*/}
            {/*    </Button>*/}
            {/*</Table.Cell>*/}
            <Table.Cell>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <Icon name="folder" style={{ marginTop: "-7px" }} />
                <div className="mr-10 d-inline-block word-break-all">
                  {editProject[key] !== undefined ? (
                    <Input
                      value={editProject[key]}
                      onChange={(evt) =>
                        setEditProject({ [key]: evt.target.value })
                      }
                    />
                  ) : (
                    project.name
                  )}
                </div>
                <Button
                  size="mini"
                  icon={editProject[key] !== undefined ? "check" : "edit"}
                  onClick={() =>
                    editProject[key] !== undefined
                      ? onChangeProjectNameHandler(key)
                      : setEditProject({ [key]: project.name })
                  }
                />
              </div>
            </Table.Cell>
            <Table.Cell>
              <FormField>
                {/* <label>Task Type</label> */}
                <Dropdown
                  clearable
                  fluid
                  search
                  selection
                  options={clients}
                  placeholder="Select Client"
                  onChange={(e, data) => {
                    e.preventDefault();
                    onSetClientId(data.value);
                  }}
                />
              </FormField>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
