import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {RelationshipsModel} from "../../models/responses/relationships.model";

export enum ActionNames {
    SET_RELATIONSHIPS_LIST = '[Relationships] Set Relationships List',
}


export const Actions = {
    setRelationshipsList: (relationshipsList: RelationshipsModel[]) => createAction(ActionNames.SET_RELATIONSHIPS_LIST, {relationshipsList}),
};

export type Actions = ActionsUnion<typeof Actions>;
