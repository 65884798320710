import styled from "styled-components";

export interface AdditionalContentUIProp {
    isOpen: boolean;
}


export const HeaderWrapUI = styled.div`
display: flex;
justify-content: space-between;
padding: 15px 30px;
background-color: rgb(51, 51, 51);
position: relative;
z-index: 10;
    min-height: 70px;
`;

export const HeaderActionsButtonsWrapUI = styled.div`
display: flex;
`;

export interface HeaderActionButtonUIProps {
    active: boolean
}

export const HeaderActionButtonUI = styled.button<HeaderActionButtonUIProps>`
    padding: 0.45em !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    border-radius: .28571429rem;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    border: none;
    cursor: pointer;
    margin-left: 10px;
    transition: opacity .07s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        opacity: 0.5;
        cursor: default;
         &:hover {
        opacity: 0.5;
      }
    }
    &:hover {
        opacity: 0.9;
    }
    &:focus {
    outline: none;
    }
`;
