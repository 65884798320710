import {projectMiddleware} from "./middlewares/project.middleware";
import {authorizationMiddleware} from "./middlewares/authorization.middleware";
import {taskMiddleware} from "./middlewares/task.middleware";
import {searchMiddleware} from "./middlewares/search.middleware";


export const combineMiddleware = [
    authorizationMiddleware,
    projectMiddleware,
    taskMiddleware,
    searchMiddleware,
];

