
export default function generateWBSId(wbsMap, wbsId) {
    let wbsItem = wbsMap.get(wbsId)
    if (!wbsItem) {
        return {wbsId: "", level: 0}
    }
    let level = 0
    let wbsIdName = wbsItem.data.wbs_short_name
    wbsItem = wbsMap.get(wbsItem.data.parent_wbs_id)
    if (!wbsItem) {
        return {wbsId: '', level: level}
    }
    let wbsPath = wbsItem.data.wbs_short_name + '.' + wbsIdName
    level++

    while (true) {
        let parentId = wbsItem.data.parent_wbs_id
        wbsItem = wbsMap.get(parentId)
        if (!wbsItem) {
            return {wbsId: wbsPath, level: level}
        }
        wbsPath = wbsItem.data.wbs_short_name + '.' + wbsPath
        level++
    }
}