import React, { useCallback, useMemo, useState } from "react";
import { Accordion, Button, Header, Label, Modal } from "semantic-ui-react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useProjectMemberListSelector,
  useTaskforceListSelector,
} from "../../../../../../store/selectors/team.selectors";
import { useDispatch } from "react-redux";
import { TeamThunk } from "../../../../../../store/thunk/team.thunk";
import ProjectMembersModel from "../../../../../../models/responses/project-members.model";
import { MemberGradeModal } from "../MemberGradeModal";
import {
  useUserGradeSelector,
  useUserSelector,
} from "../../../../../../store/selectors/authorization.selectors";
import { MemberOrganizationEnum } from "../../../../../../models/member-organization.enum";
import { useActiveProjectSelector } from "../../../../../../store/selectors/project.selectors";
import { useActiveTaskSelector } from "../../../../../../store/selectors/task/task.selectors";
import Validations from "../../../../../../utils/validations";
import { TaskThunk } from "../../../../../../store/thunk/task.thunk";
import { AddNewMemberFormValue } from "../HeaderMember";
import styles from "./TaskForce.module.scss";
import classNames from "classnames";
import { SearchActions } from "../../../../../../store/actions/search.actions";
import {
  faAngleDown,
  faAngleUp,
  faTasks,
  faUserTimes,
  faCheck,
  faBalanceScaleLeft,
  faEnvelope,
} from "@fortawesome/pro-light-svg-icons";
import {
  faUsersMedical,
  faKeySkeleton,
  faGlasses,
  faAt,
  faUser,
  faUserGear,
  faUserGroup,
} from "@fortawesome/pro-solid-svg-icons";
import { GateKeeperEum } from "../../../../../../models/gate-keeper.enum";

interface InjectedProps {
  searchValue: string;
  addNewTaskforceMember: (data: AddNewMemberFormValue) => void;
}

type PropsType = InjectedProps;

export const TaskForce: React.FC<PropsType> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clickedMember, setClickedMember] = useState<ProjectMembersModel>();
  const taskForceList = useTaskforceListSelector();
  const projectMembersList = useProjectMemberListSelector();
  const user = useUserSelector();
  const activeProject = useActiveProjectSelector();
  const [grateMemberModal, setGradeMemberModal] = useState<boolean>(false);
  const userGrade = useUserGradeSelector();
  const activeTask = useActiveTaskSelector();
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState<
    "taskforce" | "projectMembers" | ""
  >("");
  const combinedLists = useMemo(
    () => [taskForceList, projectMembersList],
    [taskForceList, projectMembersList]
  );

  const [selectedMember, setSelectedMember] = useState<string>("");
  const [activeSecondaryTitle, setActiveSecondaryTitle] = useState<{
    index: number;
    isActive: boolean | undefined;
  }>({
    index: -1,
    isActive: false,
  });
  const [activeRole, setActiveRole] = useState<{
    index: number;
    isActive: boolean | undefined;
  }>({
    index: -1,
    isActive: false,
  });

  const projectMember = // @ts-ignore
    projectMembersList.find((member) => member.userId === selectedMember);

  const mailToRedirect = useCallback(
    (member: ProjectMembersModel) => {
      if (
        activeTask &&
        user &&
        activeTask.taskForce.some(
          (el) => el === user.userId
        ) /*&& props.type === 'taskforce'*/
      ) {
        window.location.href = `mailto: ${member.userEmail}?subject=${
          activeTask!.wbs
        }&body=${activeTask!.task_name}, ${activeTask!.task_code}`;
      } else {
        window.location.href = `mailto: ${member.userEmail}?subject=${
          activeProject!.name
        }`;
      }
    },
    [activeTask, user, activeProject]
  );

  const getMemberIconName = useCallback(
    (grade: MemberOrganizationEnum): IconDefinition => {
      switch (grade) {
        case MemberOrganizationEnum.OBSERVER: {
          return faGlasses;
        }
        case MemberOrganizationEnum.ACTOR: {
          return faUserGroup;
        }
        case MemberOrganizationEnum.LEAD_ACTOR: {
          return faUser;
        }
        case MemberOrganizationEnum.SUPER_ACTOR: {
          return faUserGear;
        }
        case MemberOrganizationEnum.GROUPS: {
          return faAt;
        }
        default: {
          return faUser;
        }
      }
    },
    []
  );

  const setModalOpened = useCallback(
    (type: "taskforce" | "projectMembers" | "") => {
      setModalType(type);
      setGradeMemberModal(true);
    },
    []
  );

  const accordionSecondaryTitleClickHandler = useCallback(
    (titleIndex: number, userId: string) => {
      setSelectedMember(userId);
      if (activeSecondaryTitle.index !== titleIndex) {
        setActiveSecondaryTitle({
          index: titleIndex,
          isActive: true,
        });
      } else {
        setActiveSecondaryTitle({
          ...activeSecondaryTitle,
          isActive: !activeSecondaryTitle.isActive,
        });
      }
    },
    [activeSecondaryTitle]
  );

  const roleAccordionClickHandler = useCallback(
    (titleIndex: number) => {
      if (activeRole.index !== titleIndex) {
        setActiveRole({
          index: titleIndex,
          isActive: true,
        });
      } else {
        setActiveRole({
          ...activeRole,
          isActive: !activeRole.isActive,
        });
      }
    },
    [activeRole]
  );

  const changeMemberGrade = useCallback(
    async (grade: MemberOrganizationEnum) => {
      if (!activeProject || !projectMember) {
        return null;
      }
      // @ts-ignore
      dispatch(TeamThunk.changeMemberGrade(grade, projectMember, activeProject.projectId));
    },
    [activeProject, dispatch, projectMember]
  );

  if (!activeProject) {
    return null;
  }

  return (
    <>
      {combinedLists.map((list, listIndex) => {
        return (
          listIndex === 0 && (
            <div className="accordion-wrapper" key={listIndex}>
              <div className="main-title">
                <span>
                  {listIndex === 0 &&
                    `Taskforce ${
                      activeTask ? `(${activeTask.task_code})` : ""
                    }`}
                </span>
              </div>

              {!list.length ? (
                <div className="no-tasks">
                  {listIndex === 0 && (
                    <>
                      <FontAwesomeIcon
                        icon={faUsersMedical}
                        className={classNames(styles.NoTasksIcon)}
                      />
                      <p>Create a taskforce</p>
                    </>
                  )}
                </div>
              ) : (
                <Accordion
                  styled
                  panels={list
                    // @ts-ignore
                    .filter((member) =>
                      props.searchValue
                        ? member.userEmail.includes(props.searchValue)
                        : true
                    )
                    .sort(function (a, b) {
                      const order = [
                        MemberOrganizationEnum.SUPER_ACTOR,
                        MemberOrganizationEnum.LEAD_ACTOR,
                        MemberOrganizationEnum.ACTOR,
                        MemberOrganizationEnum.OBSERVER,
                        MemberOrganizationEnum.GROUPS,
                      ];

                      return order.indexOf(a.grade) - order.indexOf(b.grade);
                    })
                    .map((member, index) => {
                      return {
                        key: `${member.userId}-${index}`,
                        title: {
                          content: (
                            <div
                              className={classNames(styles.MemberItemWrap)}
                              onClick={() =>
                                accordionSecondaryTitleClickHandler(
                                  index,
                                  member.userId
                                )
                              }
                            >
                              {(member.gateKeeper ===
                                GateKeeperEum.LockChecklist ||
                                member.gateKeeper ===
                                  GateKeeperEum.TaskSignOff ||
                                member.gateKeeper === GateKeeperEum.Both) && (
                                <FontAwesomeIcon
                                  className={styles.MemberKeyIcon}
                                  icon={faKeySkeleton}
                                />
                              )}
                              <FontAwesomeIcon
                                className="task-force"
                                icon={getMemberIconName(member.grade)}
                              />
                              <span className={styles.MemberEmailLabel}>
                                {member.userEmail}
                              </span>
                              {member.is_pending && (
                                <Label className={styles.MemberPendingLabel}>
                                  P
                                </Label>
                              )}
                              <FontAwesomeIcon
                                icon={
                                  activeSecondaryTitle.index === index &&
                                  activeSecondaryTitle.isActive
                                    ? faAngleUp
                                    : faAngleDown
                                }
                              />
                            </div>
                          ),
                        },
                        content: {
                          content: (
                            <>
                              <div
                                className="content-option"
                                onClick={() => {
                                  dispatch(
                                    SearchActions.setTaskforceFilter([
                                      member.userId,
                                    ])
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTasks} />
                                <span>View Tasks</span>
                              </div>

                              {userGrade[MemberOrganizationEnum.SUPER_ACTOR] ? (
                                <div
                                  className="content-option"
                                  onClick={() =>
                                    dispatch(
                                      // @ts-ignore
                                      TeamThunk.makeEvaluatorToggle(
                                        member.memberId
                                      )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faBalanceScaleLeft} />
                                  <span>
                                    {member.gateKeeper
                                      ? "Remove evaluator priviledge"
                                      : "Make evaluator priviledge"}
                                  </span>
                                </div>
                              ) : null}
                              {member.grade !== MemberOrganizationEnum.GROUPS &&
                              (userGrade[MemberOrganizationEnum.SUPER_ACTOR] ||
                                userGrade[
                                  MemberOrganizationEnum.LEAD_ACTOR
                                ]) ? (
                                //   &&
                                // Validations.sameEmailDomain(
                                //   member.userEmail,
                                //   user ? user.userEmail : "")
                                <>
                                  <Accordion styled className="sub-accordion">
                                    <Accordion.Title>
                                      <div
                                        onClick={() =>
                                          roleAccordionClickHandler(index)
                                        }
                                        className="sub-title"
                                      >
                                        <span className={styles.roleLabel}>
                                          Role
                                        </span>
                                        <FontAwesomeIcon
                                          icon={
                                            activeRole.index === index &&
                                            activeRole.isActive
                                              ? faAngleUp
                                              : faAngleDown
                                          }
                                        />
                                      </div>
                                    </Accordion.Title>
                                    <Accordion.Content
                                      active={
                                        activeRole.index === index &&
                                        activeRole.isActive
                                      }
                                    >
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.SUPER_ACTOR
                                          )
                                        }
                                      >
                                        <span>Administrator</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.SUPER_ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.LEAD_ACTOR
                                          )
                                        }
                                      >
                                        <span>Lead</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.LEAD_ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.ACTOR
                                          )
                                        }
                                      >
                                        <span>Team member</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.OBSERVER
                                          )
                                        }
                                      >
                                        <span>Observer</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.OBSERVER && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.GROUPS
                                          )
                                        }
                                      >
                                        <span>Groups</span>
                                        {/* {member.grade ===
                                          MemberOrganizationEnum.GROUPS && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )} */}
                                      </div>
                                    </Accordion.Content>
                                  </Accordion>
                                </>
                              ) : null}
                              {member.grade !==
                                MemberOrganizationEnum.GROUPS && (
                                <div
                                  className="content-option"
                                  onClick={() => mailToRedirect(member)}
                                >
                                  <FontAwesomeIcon icon={faEnvelope} />
                                  <span>Send an email</span>
                                </div>
                              )}
                              {userGrade[MemberOrganizationEnum.SUPER_ACTOR] ||
                              (userGrade[MemberOrganizationEnum.LEAD_ACTOR] &&
                                Validations.sameEmailDomain(
                                  member.userEmail,
                                  user ? user.userEmail : ""
                                )) ? (
                                <div
                                  className="content-option"
                                  onClick={() =>
                                    dispatch(
                                      // @ts-ignore
                                      TaskThunk.removeTaskForce(
                                        activeTask!.task_id,
                                        member.userEmail,
                                        activeTask!.taskListType,
                                        member.grade ===
                                          MemberOrganizationEnum.GROUPS
                                          ? "group"
                                          : "user",
                                        member.grade ===
                                          MemberOrganizationEnum.GROUPS
                                          ? member.groupId
                                          : ''
                                      )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faUserTimes} />
                                  <span>
                                    Remove from taskforce
                                    {/* {index === 0
                                      ? "Remove from taskforce"
                                      : "Remove from pull system"} */}
                                  </span>
                                </div>
                              ) : null}
                            </>
                          ),
                        },
                      };
                    })}
                />
              )}
            </div>
          )
        );
      })}

      <MemberGradeModal
        open={grateMemberModal}
        member={
          modalType && modalType === "taskforce"
            ? taskForceList.find((member) => member.userId === selectedMember)
            : // @ts-ignore
              projectMembersList.find(
                (member) => member.userId === selectedMember
              )
        }
        onClose={() => setGradeMemberModal(false)}
      />

      <Modal dimmer="blurring" className="modal-event" open={openModal}>
        <Header
          textAlign="center"
          content={
            <h3>
              You are the only Administrator on this project, assign a
              replacement or the project will be closed
            </h3>
          }
        />
        <Modal.Actions>
          <Button negative onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() => {
              if (clickedMember) {
                dispatch(
                  // @ts-ignore
                  TeamThunk.removeMember(
                    clickedMember?.memberId,
                    activeProject.projectId,
                    clickedMember.grade === MemberOrganizationEnum.GROUPS
                      ? "group"
                      : "user"
                  )
                );
                setOpenModal(false);
              }
            }}
          >
            Close Anyway
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
