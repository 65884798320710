import React, {useMemo, useCallback} from 'react';
import {Modal, Button, Icon, Checkbox} from 'semantic-ui-react';
import {useProjectMemberListSelector} from '../../../../../../store/selectors/team.selectors';
import {useUserAsMemberMapSelector} from '../../../../../../store/selectors/authorization.selectors';

interface InjectedProps {
    opened: boolean,
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    leaveProject: () => void,
    setParentModalOpened: React.Dispatch<React.SetStateAction<boolean>>,
    nominatedMembersList: { userEmail: string, id: string }[],
    setNominatedMembersList(args: { userEmail: string, id: string }[]): void;
    projectId: string;
}

export const NavBarNominationModal: React.FC<InjectedProps> = props => {
    const {opened, setOpened, leaveProject, setParentModalOpened, nominatedMembersList, setNominatedMembersList} = props;
    const projectMembers = useProjectMemberListSelector();
    const userAsMemberMap = useUserAsMemberMapSelector();
    const userAsMember = useMemo(() => userAsMemberMap[props.projectId], [userAsMemberMap, props.projectId]);
    const leaveHandler = useCallback(() => {
        setOpened(false);
        setParentModalOpened(false);
        leaveProject();
    }, [leaveProject, setOpened, setParentModalOpened]);

    const setNewNominatedMember = useCallback((checked: boolean | undefined, obj: { userEmail: string, id: string }) => {
        const newNominatedMembersList: { userEmail: string, id: string }[] = [...nominatedMembersList];
        if (checked) {
            newNominatedMembersList.push(obj);
            setNominatedMembersList(newNominatedMembersList);
        } else {
            const newArr = newNominatedMembersList.filter(member => member.userEmail !== obj.userEmail);
            setNominatedMembersList(newArr);
        }
    }, [nominatedMembersList, setNominatedMembersList])

    return (
        <Modal
            className='modal-nomination'
            size="mini"
            open={opened}
            onClose={() => setOpened(false)}
            closeIcon
            style={{ animationDuration: "1s" }}
        >
            <Modal.Header className="modal-nomination-header">
                Nominate Super Actors
            </Modal.Header>
            <Modal.Content className="nomination-members">
                {userAsMember ? projectMembers
                    // @ts-ignore
                        .filter(member => member.memberId !== userAsMember.memberId)
                        .map(member =>
                            <Checkbox
                                key={member.memberId}
                                id={member.memberId}
                                name={member.userEmail}
                                onChange={(_, data) => setNewNominatedMember(data.checked, {
                                    userEmail: data.name || '',
                                    id: data.id!.toString() || ''
                                })}
                                className='nomination-member' label={member.userEmail}
                            />)
                    : 'You are not a member of a project'
                }
            </Modal.Content>
            <Modal.Actions>
                <Button inverted color='green' onClick={leaveHandler}>
                    <Icon name='arrow left'/>Leave
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
