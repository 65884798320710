import {ReportItem} from "../../store.types";
import {SearchParametersModel} from "../../reducers/search";
import { FirebaseReportFunctions } from "../../../firebase/firebase-functions/report-service";

export default abstract class ReportTransactions {

    public static async addReportItem(projectId: string, name: string, params: SearchParametersModel) {
        return FirebaseReportFunctions.addReportItem({
            projectId,
            name,
            params
        })
    }


    public static async removeReportItem(reportId: string) {
        return FirebaseReportFunctions.removeReportItem({
            reportId
        })
    }


    public static async editReportItem(report: ReportItem) {
        return FirebaseReportFunctions.editReportItem({
            report
        })
    }
}
