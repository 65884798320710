enum RouteNameConstants {
    LOGIN = 'login',
    PROJECT_ID = ':project_id',
    FLOW = 'flow',
    DELETE_PROJECT = 'delete-project',
    CHOOSE_PROJECT = 'choose-project',
    LANDING_PAGE = 'landing-page',
    REPORTS = 'reports',
    REPORTID = ':reportId',
}

export default RouteNameConstants;
