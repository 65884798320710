import { ChatOpenAI } from '@langchain/openai';
import { PromptTemplate } from '@langchain/core/prompts';
import { HttpResponseOutputParser } from 'langchain/output_parsers';

// import { JSONLoader } from "langchain/document_loaders/fs/json";
import { RunnablePassthrough, RunnableSequence } from '@langchain/core/runnables'
import { formatDocumentsAsString } from 'langchain/util/document';
import { CharacterTextSplitter } from 'langchain/text_splitter';
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import type { Document } from "@langchain/core/documents";
import {OpenAIEmbeddings} from "@langchain/openai";
import { SelfQueryRetriever } from "langchain/retrievers/self_query";
import { PineconeTranslator } from "@langchain/pinecone";
import { attributeInfo } from "../models/chat/chat-attributes";
import {Pinecone} from "@pinecone-database/pinecone";
import { PineconeStore } from "@langchain/pinecone";
import store from "../store/store";
import FirebaseUsage from "../firebase/firebase.usage";



// const loader = new JSONLoader(
//     "src/data/states.json",
//     ["/state", "/code", "/nickname", "/website", "/admission_date", "/admission_number", "/capital_city", "/capital_url", "/population", "/population_rank", "/constitution_url", "/twitter_url"],
// );

export const dynamic = 'force-dynamic'

/**
 * Basic memory formatter that stringifies and passes
 * message history directly into the model.
 */
const formatMessage = (message) => {
    return `${message.role}: ${message.content}`;
};

const TEMPLATE =
    `Answer the user's questions based only on the following context. If the answer is not in the context, reply politely that you do not have that information available. 
    The information contained in the context relates to a live construction project. There are a series of collections that contain information about the project. 
    tasks is a list of all the tasks to be completed. project contains information about the project. All dates are in timestamp format. Convert these to human-readable dates (dd-mmm-yyyy).
    ==============================
    Context: {context}
    ==============================
    Current conversation: {chat_history}
    
    user: {question}
    assistant:`;

const TEMPLATE2 =
    `This application is for an AI chatbot. Langchain is being used to return context to the OpenAI chat assistant.
    You need to reformat the user's question so it can be used as a query to the Langchain and Pinecone models.
    The response to this llm query will be used as the input for the langchain selfQueryRetriever.
    The context provided for this task is a JSON string representing the metadata used in the Pinecone database.
    ==============================
    Context: {context}
    ==============================
    Current conversation: {chat_history}
    
    user: {question}
    assistant:`;


export async function sendMessage (messages: any) {
    try {
        const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
        const projectTasks =
            [...store.getState().task.tasks.confirmedComplete,
            ...store.getState().task.tasks.declaredComplete,
            ...store.getState().task.tasks.inProgress,
            ...store.getState().task.tasks.pending
            ];
        const project = store.getState().project.activeProject
        const projectData = {
            tasks: projectTasks,
            project: project
        }

        const currentMessageContent = messages[messages.length - 1].content;

        // const docs = await loader.load();

        // load a JSON object
        const textSplitter = new CharacterTextSplitter();

        const docs = await textSplitter.createDocuments([JSON.stringify(projectData)]);

        const prompt = PromptTemplate.fromTemplate(TEMPLATE);

        const model = new ChatOpenAI({
            apiKey: process.env.OPENAI_API_KEY,
            model: 'gpt-3.5-turbo',
            temperature: 0,
            streaming: true,
            verbose: true,
        });

        /**
         * Chat models stream message chunks rather than bytes, so this
         * output parser handles serialization and encoding.
         */
        const parser = new HttpResponseOutputParser();

        const chain = RunnableSequence.from([
            {
                question: (input) => input.question,
                chat_history: (input) => input.chat_history,
                context: () => formatDocumentsAsString(docs),
            },
            prompt,
            model,
            parser,
        ]);

        console.log(currentMessageContent)

        // Convert the response into a friendly text-stream
        const stream = await chain.invoke({
            chat_history: formattedPreviousMessages.join('\n'),
            question: currentMessageContent,
        });

        // read the text stream and return an object
        const blob = new Blob([stream], { type: 'text/plain' });
        const reader = new FileReader();
        reader.readAsText(blob);
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    const response = reader.result;
                    console.log(response)
                    resolve(response);
                }
            };
            reader.onerror = reject;
        });


    } catch (e: any) {
        return Response.json({ error: e.message }, { status: e.status ?? 500 });
    }
}

export async function sendChatMessageWithData(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const currentMessageContent = messages[messages.length - 1].content;

    const prompt = ChatPromptTemplate.fromTemplate(`
Answer the question based only on the context provided.

Context: {context}

Question: {question}`);

    const formatDocs = (docs: Document[]) => {
        return docs.map((doc) => JSON.stringify(doc)).join("\n\n");
    };

    const llm = new ChatOpenAI({
        apiKey: process.env.OPENAI_API_KEY,
        model: "gpt-4o",
        temperature: 0
    });

    const projectId = project!.projectId;

    const pinecone = new Pinecone({ apiKey: process.env.PINECONE_API_KEY! });
    const pineconeIndex = pinecone.Index("flowchat-qa-index").namespace(projectId);
    const embeddings = new OpenAIEmbeddings({
        apiKey: process.env.OPENAI_API_KEY,
    });
    const vectorStore = await PineconeStore.fromExistingIndex(embeddings, {
        pineconeIndex: pineconeIndex,
        namespace: projectId
    })

    const selfQueryRetriever = SelfQueryRetriever.fromLLM({
        llm: llm,
        vectorStore: vectorStore,
        documentContents: "Task records from a construction project management system.",
        attributeInfo: attributeInfo,
        structuredQueryTranslator: new PineconeTranslator(),
    });

    const ragChain = RunnableSequence.from([
        {
            context: selfQueryRetriever.pipe(formatDocs),
            question: new RunnablePassthrough(),
        },
        prompt,
        llm,
        new StringOutputParser(),
    ]);

    // Convert the response into a friendly text-stream
    // const stream = await ragChain.invoke({
    //     chat_history: formattedPreviousMessages.join('\n'),
    //     question: currentMessageContent,
    // });

    // const stream = await ragChain.invoke({
    //     chat_history: formattedPreviousMessages.join('\n'),
    //     question: currentMessageContent,
    // });

    const stream = await ragChain.invoke(currentMessageContent);

    console.log("text returned", stream)

    return stream;

    // // read the text stream and return an object
    // const blob = new Blob([stream], { type: 'text/plain' });
    // const reader = new FileReader();
    // reader.readAsText(blob);
    // return new Promise((resolve, reject) => {
    //     reader.onload = () => {
    //         if (typeof reader.result === "string") {
    //             const response = reader.result;
    //             console.log(response)
    //             resolve(response);
    //         }
    //     };
    //     reader.onerror = reject;
    // });
}

export async function sendMessageWithPinecone(messages: any) {
    const formattedPreviousMessages = messages.slice(0, -1).map(formatMessage);
    const project = store.getState().project.activeProject
    const currentMessageContent = messages[messages.length - 1].content;
    const response = FirebaseUsage.functions(`get-llm-query-v2/?projectId=${project!.projectId}&message=${currentMessageContent}`)
    ({projectId: project!.projectId, message: currentMessageContent}).then((response: any) => {
        console.log(response)
        return response
    })
        .catch((error: any) => console.log("error", error))
    return response
}
