import React, { useEffect, useState } from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskContainer} from "./Task/TaskContainer";
import TaskModel from "../../../../../models/responses/task.model";
import { Maybe } from "@martin_hotell/rex-tils";
import { useActiveTaskSelector } from "../../../../../store/selectors/task/task.selectors";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import TaskCheckListWrapper from "./TaskChecklistWrapper/TaskCheckListWrapper";
import * as taskActions from "../../../../../store/actions/task.actions";
import {useDispatch} from "react-redux";

export const TaskSuccessors: React.FC = () => {
    const successorsTaskList = useTypedSelector(state => state.task.successors.list);
    const activeTask = useActiveTaskSelector();
    const [task, setTask] = useState<Maybe<TaskModel<string>>>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setTask(activeTask);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTask])

    if (!task || !activeTask) {
        return null;
    }

    return (
        <div className="task-predecessors-container">
            <div className='task-predecessor-main'>Predecessor</div>
            <div>
                <TaskContainer task={task} hidePassBtn
                   goToPred={() => {
                       dispatch(taskActions.Actions.setActiveTask(task));
                       dispatch(
                           taskActions.Actions.openRelatedTaskList(
                               task,
                               "predecessors"
                           )
                       );
                   }}
                   goToSuc={() => {
                       dispatch(taskActions.Actions.setActiveTask(task));
                       dispatch(
                           taskActions.Actions.openRelatedTaskList(
                               task,
                               "successors"
                           )
                       );
                   }}
                />
                {
                    task &&
                    task.task_id === activeTask.task_id &&
                    task.status !== TaskStatusModel.COMPLETE &&
                    <TaskCheckListWrapper/>
                }
            </div>
            <div className='task-predecessor-main'>
                <span>Successors</span>
                <span>Relationships</span>
            </div>
            <div className="task-predecessors-list">
                <div className="lazy-load-container">
                    <div className="task-list-container">
                        {
                            successorsTaskList.map(task =>
                                <React.Fragment key={task.task_id}>
                                    <TaskContainer type="succ" showPredType task={task} hidePassBtn
                                       goToPred={() => {
                                           dispatch(taskActions.Actions.setActiveTask(task));
                                           dispatch(
                                               taskActions.Actions.openRelatedTaskList(
                                                   task,
                                                   "predecessors"
                                               )
                                           );
                                       }}
                                       goToSuc={() => {
                                           dispatch(taskActions.Actions.setActiveTask(task));
                                           dispatch(
                                               taskActions.Actions.openRelatedTaskList(
                                                   task,
                                                   "successors"
                                               )
                                           );
                                       }}
                                    />
                                    {
                                        activeTask &&
                                        task.task_id === activeTask.task_id &&
                                        activeTask.status !== TaskStatusModel.COMPLETE &&
                                        <TaskCheckListWrapper/>
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
