import prodConfig from './firebase.config.prod';
import stageConfig from './firebase.config.stage';

let firebaseConfig = process.env.REACT_APP_ENVIRONMENT === 'prod' ? prodConfig : stageConfig;

firebaseConfig.authDomain = process.env.REACT_APP_ENVIRONMENT === "prod" ?
    window.location.hostname === 'localhost' ?
        firebaseConfig.authDomain :
        window.location.hostname :
    firebaseConfig.authDomain;

export default firebaseConfig;
