enum TaskStatusModel {
    NOT_STARTED = 'not started',
    IN_PROGRESS = 'in progress',
    DECLARED_COMPLETE = 'declared complete',
    COMPLETE = 'completed',
    SUSPENDED = 'suspended',
    BLOCK = 'block',
    RETIRED = 'retired',
}

export default TaskStatusModel;
