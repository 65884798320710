// import updatePredStatus from "./UpdatePredStatus";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";

export default async function cpmSchedule (
    noPredTasks: any[],
    noSuccTasks: any[],
    cpmMap: Map<any, any>,
    calendarsMap: Map<any, any>,
    projectId: string,
    db,
    latestDate: number
) {
    let batch: any[] = []
    let batchCount = 0
    let tasks: any[] = []

    const timeStart = new Date().getTime()
    let newNoSuccTasks: any[] = []
    let scope = "specific"
    const masterCalendarDict = async (seconds, calendarId) => {
        return parseInt(calendarsMap.get(`mcd:${seconds}:${calendarId}`))
    }

    const specificCalendarDict = async (index, calendarId) => {
        return parseInt(calendarsMap.get(`scd:${calendarId}:${index}`))
    }

    const masterWorkPatternDict = async (index, calendarId) => {
        return parseInt(calendarsMap.get(`mwp:${index}:${calendarId}`))
    }
    let globalLateFinish = latestDate

    async function convertIndexToSeconds(index, calendarId) {
        const date = await specificCalendarDict(index, calendarId)
        if (isNaN(date)) {
            return await convertOutOfRangeIndexToDate(index, calendarId)
        } else {
            return date
        }
    }

    async function generateTask(taskId) {
        return cpmMap.get(taskId)
    }

    async function generateLink(linkId) {
        return cpmMap.get(linkId)
    }


    async function convertOutOfRangeIndexToDate(index, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        // const weeklyIndices = specificWorkPatternDict[calendarId].weeklyIndices
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((index - maxIndex) / halfHours)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = await masterWorkPatternDict(maxWeekIndex, calendarId)
        const relativeMaxDayIndex = parseInt(calendarsMap.get(`swp:maxDayIndex:${calendarId}`))
        const relativeMaxHalfHourIndex = parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${relativeMaxWeekIndex}`))
        const remainingHalfHours = index - maxIndex - (weeks * halfHours)
        const newIndex = remainingHalfHours + relativeMaxWeekIndex > halfHours ? (remainingHalfHours + relativeMaxWeekIndex) - halfHours : remainingHalfHours + relativeMaxWeekIndex
        const newWeekDayIndex = parseInt(calendarsMap.get(`swp:wi:d:${calendarId}:${newIndex}`))
        const additionalDays = relativeMaxWeekIndex + remainingHalfHours > halfHours ? 7 - relativeMaxDayIndex + newWeekDayIndex : newWeekDayIndex - relativeMaxDayIndex

        return maxDate + (weeks * 604800) + (additionalDays * 86400) + ((parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${newIndex}`)) - relativeMaxHalfHourIndex) * 1800)
    }

    async function convertOutOfRangeDateToIndex(date, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((date - maxDate) / 604800)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = await masterWorkPatternDict(maxWeekIndex, calendarId)
        const remainingHalfHours = Math.floor((date - maxDate) / 1800) - (weeks * 336)
        const newMasterIndex = remainingHalfHours + maxWeekIndex > 336 ? (remainingHalfHours + maxWeekIndex) - 336 : remainingHalfHours + maxWeekIndex
        const newCalendarSpecificIndex = await masterWorkPatternDict(newMasterIndex, calendarId)

        return  remainingHalfHours + maxWeekIndex > 336 ? maxIndex + (weeks * halfHours) + (halfHours - relativeMaxWeekIndex) + (newCalendarSpecificIndex) :
            maxIndex + (weeks * halfHours) + (newCalendarSpecificIndex - relativeMaxWeekIndex)

    }

    async function getOutOfRangeLateralIndex (index, calendarId, lateralCalendarId, addOn) {
        if (calendarId === lateralCalendarId) {
            return index + addOn
        }
        const date = await convertOutOfRangeIndexToDate(index, calendarId)
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${lateralCalendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${lateralCalendarId}`))
        const weekCount = Math.floor((date - maxDate - 1800 - (3 * 86400)) / 604800)
        const weekHalfHourCount = parseInt(calendarsMap.get(`swp:wi:count:${lateralCalendarId}`))
        const weekIndexDatePlusWeeks = (((maxDate + (weekCount * 604800)) - 1800 - (3 * 86400)) -
            Math.floor(((maxDate + (weekCount * 604800)) - 1800 - (3 * 86400)) / 604800) * 604800) / 1800
        const correspondingCalendarIndexDatePlusWeeks = await masterWorkPatternDict(weekIndexDatePlusWeeks, lateralCalendarId)
        const weekIndexDate = ((date - 1800 - (3 * 86400)) - Math.floor((date - 1800 - (3 * 86400)) / 604800) * 604800) / 1800
        const correspondingCalendarIndexDate = await masterWorkPatternDict(weekIndexDate, lateralCalendarId)
        const indicesToAdd = correspondingCalendarIndexDatePlusWeeks < correspondingCalendarIndexDate ?
            correspondingCalendarIndexDate - correspondingCalendarIndexDatePlusWeeks :
            weekHalfHourCount - correspondingCalendarIndexDatePlusWeeks + correspondingCalendarIndexDate

        return maxIndex + (weekCount * weekHalfHourCount) + indicesToAdd + addOn
    }

    const handleTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    const handlePredTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': 1,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 1,
        'TT_FINMILE': 0,
    }

    const handleTypesBp = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': -1,
        'TT_Task': -1,
        'TT_Rsrc': -1,
        'TT_WBS': 0,
        'TT_TASK': -1,
        'TT_RSRC': -1,
        'TT_MILE': -1,
        'TT_FINMILE': 0,
    }

    const handleTaskStatus = {
        'not started': 1,
        'in progress': 1,
        'block': 1,
        'suspended': 1,
        'completed': 0,
        'declared complete': 0,
    }

    const handleTypesFinish = {
        'TT_LOE': 1,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 1,
        'TT_Rsrc': 1,
        'TT_WBS': 0,
        'TT_TASK': 1,
        'TT_RSRC': 1,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    async function getLateralIndex(index, calendarId, lateralCalendarId, addOn) {
        if (calendarId === lateralCalendarId) {
            return index + addOn
        } else {
            const lateralIndex = await masterCalendarDict(await specificCalendarDict(index, calendarId), lateralCalendarId)
            if (!isNaN(lateralIndex)) {
                if (await specificCalendarDict(index, calendarId) === await specificCalendarDict(lateralIndex, lateralCalendarId)) {
                    return lateralIndex + addOn
                } else {
                    return lateralIndex
                }
            } else {
                return getOutOfRangeLateralIndex(index, calendarId, lateralCalendarId, addOn)
            }
        }
    }

    function checkRemainingDuration(task) {
        if (task.duration === 0) {
            return 0
        } else {
            return 1
        }
    }

    async function commitBatch(batch) {
        let batchObject = FirebaseUsage.batch()
        await batch.forEach((task) => {
            if (task.lf) {
                batchObject.update(FirebaseUsage.getDocumentRef(COLLECTIONS.TASKS, task.task_code), {
                    early_start_date: task.es,
                    early_end_date: task.ef,
                    late_start_date: task.ls,
                    late_end_date: task.lf,
                    float: task.float
                })
            } else {
                batchObject.update(FirebaseUsage.getDocumentRef(COLLECTIONS.TASKS, task.task_code), {
                    early_start_date: task.es,
                    early_end_date: task.ef,
                    float: task.float
                })
            }
        })
        batchObject.commit().catch((error) => console.log(error))
    }

    // Forward Pass
    async function calculateEsEf(link) {
        let predIndex
        let cd
        const predTask = await generateTask(link.pred_task_id)
        const succTask = await generateTask(link.task_id)

        const statusValue = Math.max(handleTaskStatus[succTask.status_code], handleTaskStatus[predTask.status_code])
        const lag = parseFloat(link.lag_hr_cnt)
        const handleNegativeLag = lag < 0 ? lag : 0

        if (link.pred_type === 'FS') {
            predIndex = Math.max(Math.round(predTask.ef + (lag * statusValue)), predTask.ad + predTask.duration - checkRemainingDuration(predTask) + handleNegativeLag)
            cd = 1 - Math.max(handleTypes[succTask.task_type], handlePredTypes[predTask.task_type], 1 - checkRemainingDuration(predTask))
        } else if (link.pred_type === 'FF') {
            predIndex = Math.max(Math.round(predTask.ef + (lag * statusValue) - succTask.duration), predTask.ad + predTask.duration - checkRemainingDuration(predTask) - succTask.duration + handleNegativeLag)
            cd = 1 - Math.max(handleTypes[succTask.task_type], handlePredTypes[predTask.task_type], 1 - checkRemainingDuration(predTask))
        } else if (link.pred_type === 'SS') {
            predIndex = Math.max(Math.round(predTask.es + (lag * statusValue)), predTask.ad + handleNegativeLag)
            cd = 0
        } else {
            predIndex = Math.max(Math.round(predTask.es + (lag * statusValue) - succTask.duration), predTask.ad - succTask.duration + handleNegativeLag)
            cd = 0
        }

        predIndex = await getLateralIndex(predIndex, predTask.cal_id, succTask.cal_id, cd)
        cpmMap.set(link.link_id, {...link, ad: predIndex})

        if (predIndex > succTask.ad && predTask.task_type !== 'TT_LOE') {
            succTask.ad = predIndex
        }

        succTask.p_cnt -= 1

        let newAd = 0
        const predList = cpmMap.get(`${succTask.task_code}:preds`)

        let predecessors: any[] = []
        for (const predecessor of predList) {
            predecessors.push(cpmMap.get(predecessor).ad)
        }
        if (predecessors.length > 0) {
            newAd = Math.max(...predecessors)
        } else {
            newAd = succTask.ad
        }

        // console.log(succTask.task_code, succTask.es, succTask.ef, newAd, succTask, predList, predecessors)

        if (newAd !== succTask.es) {
            if (succTask.status_code === 'not started') {
                if (succTask.alap === '') {
                    succTask.es = newAd
                    succTask.ad = newAd
                }
                else {
                    succTask.es = Math.max(newAd, succTask.es)
                    succTask.ad = Math.max(newAd, succTask.ad)
                }
            }
            if (succTask.alap === '') {
                if (succTask.duration === 0) {
                    succTask.ef = succTask.status_code !== 'completed' ? newAd : succTask.ef
                } else {
                    succTask.ef = succTask.status_code !== 'completed' ? newAd + succTask.duration - 1 : succTask.ef
                }
            } else {
                if (succTask.duration === 0) {
                    succTask.ef = succTask.status_code !== 'completed' ? succTask.es : succTask.ef
                } else {
                    succTask.ef = succTask.status_code !== 'completed' ? succTask.es + succTask.duration - 1 : succTask.ef
                }
            }
            if (succTask.ef === '') {
                if (succTask.duration === 0) {
                    succTask.ef = newAd
                } else {
                    succTask.ef = newAd + succTask.duration - 1
                }
            }

            cpmMap.set(succTask.task_code, {...succTask, p_cnt: succTask.p_sv})

            if (succTask.s_cnt === 0) {
                newNoSuccTasks.push(succTask.task_code)
            }

            const successors = cpmMap.get(`${succTask.task_code}:succs`)
            for (const successor of successors) {
                await calculateEsEf(await generateLink(successor))
            }

            const es = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(succTask.es, succTask.cal_id) * 1000))
            const ef = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(succTask.ef, succTask.cal_id) + (1800 * handleTypesFinish[succTask.task_type])) * 1000))

            batch.push({
                task_code: succTask.task_code,
                es: es,
                ef: ef,
                float: succTask.lf - succTask.ef
            })

            batchCount += 1
            if (batchCount === 500) {
                commitBatch(batch).catch((error) => console.log(error))
                batch = []
                batchCount = 0
            }
        }
    }

    for (const task of noPredTasks) {
        const successors = cpmMap.get(`${task}:succs`)
        for (const successor of successors) {
            const succLink = await generateLink(successor)
            tasks.push(succLink.task_id)
            await calculateEsEf(succLink)
        }
    }

    console.log("time taken for forward pass", new Date().getTime() - timeStart, "ms")

    async function calculateAlap(link) {
        let succIndex
        let cd
        const predTask= await generateTask(link.pred_task_id)
        const succTask= await generateTask(link.task_id)

        const statusValue = Math.max(handleTaskStatus[succTask.status_code], handleTaskStatus[predTask.status_code])

        if (link.pred_type === 'FS') {
            succIndex = Math.round(succTask.es - (parseFloat(link.lag_hr_cnt) * statusValue))
            cd = Math.max(handleTypesBp[predTask.task_type], handleTypesBp[succTask.task_type], 0 - checkRemainingDuration(succTask))
        }
        else if (link.pred_type === 'SS') {
            succIndex = Math.round(succTask.es - (parseFloat(link.lag_hr_cnt) * statusValue) + predTask.duration)
            cd = Math.max(handleTypesBp[predTask.task_type], handleTypesBp[succTask.task_type], 0 - checkRemainingDuration(succTask))
        }
        else if (link.pred_type === 'FF') {
            succIndex = Math.round(succTask.ef - (parseFloat(link.lag_hr_cnt) * statusValue))
            cd = 0
        }
        else {
            succIndex = Math.round(succTask.ef - (parseFloat(link.lag_hr_cnt) * statusValue) + predTask.duration)
            cd = 0
        }

        return await getLateralIndex(succIndex, succTask.cal_id, predTask.cal_id, cd)
    }

    // Backward Pass
    async function calculateLsLf(link, scope) {
        let succIndex
        let cd
        const predTask= await generateTask(link.pred_task_id)
        const succTask= await generateTask(link.task_id)
        if (predTask.s_cnt === predTask.s_sv && scope === 'all') {
            predTask.lf = ''
            predTask.ls = ''
            predTask.af = ''
            predTask.alap = ''
        }

        let localLateFinish = await masterCalendarDict(globalLateFinish, predTask.cal_id)
        if (isNaN(localLateFinish)) {
            localLateFinish = await convertOutOfRangeDateToIndex(globalLateFinish, predTask.cal_id)
        }
        const statusValue = Math.max(handleTaskStatus[succTask.status_code], handleTaskStatus[predTask.status_code])

        if (link.pred_type === 'FS') {
            succIndex = Math.round(succTask.ls - (parseFloat(link.lag_hr_cnt) * statusValue))
            cd = Math.max(handleTypesBp[predTask.task_type], handleTypesBp[succTask.task_type], 0 - checkRemainingDuration(succTask))
        }
        else if (link.pred_type === 'SS') {
            succIndex = Math.round(succTask.ls - (parseFloat(link.lag_hr_cnt) * statusValue) + predTask.duration)
            cd = Math.max(handleTypesBp[predTask.task_type], handleTypesBp[succTask.task_type], 0 - checkRemainingDuration(succTask))
        }
        else if (link.pred_type === 'FF') {
            succIndex = Math.round(succTask.lf - (parseFloat(link.lag_hr_cnt) * statusValue))
            cd = 0
        }
        else {
            succIndex = Math.round(succTask.lf - (parseFloat(link.lag_hr_cnt) * statusValue) + predTask.duration)
            cd = 0
        }

        succIndex = await getLateralIndex(succIndex, succTask.cal_id, predTask.cal_id, cd)
        cpmMap.set(link.link_id, {...link, af: succIndex})

        if (predTask.af === '') {
            predTask.af = localLateFinish
        }

        if (succIndex < parseInt(predTask.af) && succTask.task_type !== 'TT_LOE') {
            predTask.af = succIndex
        }

        if (predTask.cstr_type === 'CS_ALAP') {
            const alapFinish = await calculateAlap(link)
            if (predTask.alap === '') {
                predTask.alap = localLateFinish
            }
            if (alapFinish < predTask.alap) {
                predTask.alap = alapFinish
            }
        }

        predTask.s_cnt -= 1

        if (scope === 'all') {
            if (predTask.s_cnt <= 0) {
                // let newAf = 0
                // const succList = cpmMap.get(`${predTask.task_code}:succs`)
                // let successors: any[] = []
                // for (const successor of succList) {
                //     successors.push(cpmMap.get(successor).af)
                // }
                // if (successors.length > 0) {
                //     newAf = Math.max(...successors)
                // } else {
                //     newAf = succTask.af
                // }
                // predTask.af = newAf

                if (predTask.af < predTask.ef) {
                    predTask.lf = predTask.ef
                    predTask.ls = predTask.lf - predTask.duration + checkRemainingDuration(predTask)
                } else {
                    predTask.lf = predTask.af
                    predTask.ls = predTask.lf - predTask.duration + checkRemainingDuration(predTask)
                }
                if (predTask.alap !== '' && predTask.status_code === 'not started') {
                    predTask.ef = Math.min(predTask.lf, predTask.alap)
                    predTask.es = predTask.ef - predTask.duration + checkRemainingDuration(predTask)
                }

                cpmMap.set(predTask.task_code, {...predTask, s_cnt: predTask.s_sv})

                const predecessors = cpmMap.get(`${predTask.task_code}:preds`)
                for (const predecessor of predecessors) {
                    await calculateLsLf(await generateLink(predecessor), 'all')
                }

                const es = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(predTask.es, predTask.cal_id) * 1000))
                const ef = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(predTask.ef, predTask.cal_id) + (1800 * handleTypesFinish[predTask.task_type])) * 1000))
                const lf = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(predTask.lf, predTask.cal_id) + (1800 * handleTypesFinish[predTask.task_type])) * 1000))
                const ls = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(predTask.ls, predTask.cal_id) * 1000))

                batch.push({task_code: predTask.task_code, es: es, ef: ef, ls: ls, lf: lf, float: predTask.lf - predTask.ef})
                batchCount += 1
                if (batchCount === 500) {
                    commitBatch(batch).catch((error) => console.log(error))
                    batch = []
                    batchCount = 0
                }
            } else {
                // else decrease s_cnt of predTask
                cpmMap.set(predTask.task_code, predTask)
            }
        } else {
            let newAf = 0
            const succList = cpmMap.get(`${predTask.task_code}:succs`)
            const successors: any[] = []
            for (const successor of succList) {
                successors.push(parseInt(cpmMap.get(successor).af))
            }
            newAf = Math.min(...successors)

            if (newAf !== predTask.lf) {
                predTask.af = newAf
                predTask.lf = newAf
                predTask.ls = predTask.lf - predTask.duration + checkRemainingDuration(predTask)
                if (predTask.alap !== '') {
                    predTask.ef = Math.min(predTask.lf, predTask.alap)
                    predTask.es = predTask.ef - predTask.duration + checkRemainingDuration(predTask)
                }

                cpmMap.set(predTask.task_code, {...predTask, s_cnt: predTask.s_sv})

                const predecessors = cpmMap.get(`${predTask.task_code}:preds`)
                for (const predecessor of predecessors) {
                    await calculateLsLf(await generateLink(predecessor), 'specific')
                }

                const es = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(predTask.es, predTask.cal_id) * 1000))
                const ef = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(predTask.ef, predTask.cal_id) + (1800 * handleTypesFinish[predTask.task_type])) * 1000))
                const lf = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(predTask.lf, predTask.cal_id) + (1800 * handleTypesFinish[predTask.task_type])) * 1000))
                const ls = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(predTask.ls, predTask.cal_id) * 1000))

                batch.push({task_code: predTask.task_code, es: es, ef: ef, ls: ls, lf: lf, float: predTask.lf - predTask.ef})
                batchCount += 1
                if (batchCount === 500) {
                    commitBatch(batch).catch((error) => console.log(error))
                    batch = []
                    batchCount = 0
                }
            }
        }
    }

    // determine globalLateFinish
    let globalLf = 0
    for (const taskId of noSuccTasks) {
        const thisLf = parseInt(cpmMap.get(taskId).ef)
        const calId = cpmMap.get(taskId).cal_id
        let thisLfDate = await specificCalendarDict(thisLf, calId)
        if (isNaN(thisLfDate)) {
            thisLfDate = await convertOutOfRangeIndexToDate(thisLf, calId)
        }
        if (thisLfDate > globalLf) {
            // globalLateFinish = thisLfDate
            globalLf = thisLfDate
        }
    }

    const previousGlobalLf = cpmMap.get(`globalLf:${projectId}`)

    let finalNoSuccTasks
    if (previousGlobalLf) {
        if (globalLf !== parseInt(previousGlobalLf)) {
            console.log("globalLateFinish changed", new Date(globalLf * 1000), new Date(parseInt(previousGlobalLf) * 1000))
            finalNoSuccTasks = noSuccTasks
            scope = "all"
            cpmMap.set(`globalLf:${projectId}`, globalLf)
        } else {
            console.log("globalLateFinish did not change", globalLf, parseInt(previousGlobalLf))
            finalNoSuccTasks = newNoSuccTasks
        }
    } else {
        finalNoSuccTasks = noSuccTasks
        cpmMap.set(`globalLf:${projectId}`, globalLf)
    }

    // Go through list of tasks with no successors
    for (const taskId of finalNoSuccTasks) {
        const predecessors = cpmMap.get(`${taskId}:preds`)
        const task = await generateTask(taskId)
        let localLateFinishSucc = await masterCalendarDict(globalLf, task.cal_id)
        if (isNaN(localLateFinishSucc)) {
            localLateFinishSucc = await convertOutOfRangeDateToIndex(globalLf, task.cal_id)
        }

        if (task.lf === '') {
            if (task.af === '') {
                task.af = localLateFinishSucc
            }
            if (task.af < task.ef) {
                task.lf = task.ef
                task.ls = task.lf - task.duration + checkRemainingDuration(task)
            } else {
                task.lf = task.af
                task.ls = task.lf - task.duration + checkRemainingDuration(task)
            }
        } else if (task.lf !== localLateFinishSucc) {
            if (task.ec !== 'n') {
                if(localLateFinishSucc < task.ec) {
                    task.af = localLateFinishSucc
                    if (task.af < task.ef) {
                        task.lf = task.ef
                        task.ls = task.lf - task.duration + checkRemainingDuration(task)
                    } else {
                        task.lf = task.af
                        task.ls = task.lf - task.duration + checkRemainingDuration(task)
                    }
                } else {
                    task.af = task.ec
                    if (task.af < task.ef) {
                        task.lf = task.ef
                        task.ls = task.lf - task.duration + checkRemainingDuration(task)
                    } else {
                        task.lf = task.af
                        task.ls = task.lf - task.duration + checkRemainingDuration(task)
                    }
                }
            } else {
                task.af = localLateFinishSucc
                if (task.af < task.ef) {
                    task.lf = task.ef
                    task.ls = task.lf - task.duration + checkRemainingDuration(task)
                } else {
                    task.lf = task.af
                    task.ls = task.lf - task.duration + checkRemainingDuration(task)
                }
            }
        }
        cpmMap.set(task.task_code, task)
        for (const predecessor of predecessors) {
            await calculateLsLf(await generateLink(predecessor), scope)
        }

        const es = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(task.es, task.cal_id) * 1000))
        const ef = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(task.ef, task.cal_id) + (1800 * handleTypesFinish[task.task_type])) * 1000))
        const lf = FirebaseUsage.timestamp(new Date((await convertIndexToSeconds(task.lf, task.cal_id) + (1800 * handleTypesFinish[task.task_type])) * 1000))
        const ls = FirebaseUsage.timestamp(new Date(await convertIndexToSeconds(task.ls, task.cal_id) * 1000))

        batch.push({task_code: task.task_code, es: es, ef: ef, ls: ls, lf: lf, float: task.lf - task.ef})
        batchCount += 1
        if (batchCount === 500) {
            commitBatch(batch).catch((error) => console.log(error))
            batch = []
            batchCount = 0
        }

        FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, projectId, {
            lastUpdate: FirebaseUsage.timestamp(new Date())
        }).catch(err => console.log(err))
    }
    if (batchCount > 0) {
        commitBatch(batch).catch((error) => console.log(error))
    }

    cpmMap.set(`predStatus:${projectId}`, tasks)
    // updatePredStatus(tasks, cpmMap, db, calendarsMap).catch(err => console.log(err))
    console.log("CPM took " + (new Date().getTime() - timeStart) + "ms")
    return cpmMap
}
