import { Timestamp } from "firebase/firestore";
import store from "../store/store";
import FirebaseUsage from "../firebase/firebase.usage";
import { COLLECTIONS } from "../firebase/constants";
import { MessageType } from "../models/responses/message.model";
import TaskModel from "../models/responses/task.model";
import {WbsModel} from "../models/responses/wbs.model";
import batchUpdate from "./firestoreBatch.utils";
import {CpmTaskModel} from "../models/responses/cpm-task.model";
import ProjectMembersModel from "../models/responses/project-members.model";
import {MemberOrganizationEnum} from "../models/member-organization.enum";


export async function leaveTaskForce(
  taskId: string,
  userId: string,
  whoRemoved: string,
  eventTimestamp: Timestamp
) {
  const members = store.getState().team.memberList;
  const filteredMembers = members.filter((member) => member.userId === userId);
  if (filteredMembers.length === 0) {
    return;
  }
  const taskData = await FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then(
    (doc) => doc.data()
  );
  if (!taskData) {
    return;
  }
  const taskForce = taskData.taskForce.filter(
    (memberId: string) => memberId !== userId
  );
  const users = taskForce.length > 0 ?
      taskForce.map(user => ({userId: user, seen: user === userId})) : []
  const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
  await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
    ledgerId: ledgerEntry.id,
    projectId: taskData.projectId,
    timestamp: eventTimestamp,
    logTimestamp: eventTimestamp,
    taskId: taskId,
    type: MessageType.TFL,
    userId: whoRemoved,
    userEmail: filteredMembers[0].userEmail,
  });

  await FirebaseUsage.updateDoc(COLLECTIONS.TASKS, taskId, {
    taskForce: taskForce,
    users: users,
  });
}

export async function joinTaskForce(
  taskId: string,
  userId: string,
  whoAdded: string,
  eventTimestamp: Timestamp
) {
  const members = store.getState().team.memberList;
  const filteredMembers = members.filter((member) => member.userId === userId);

  if (filteredMembers.length === 0) {
    return;
  }
  const taskData = await FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then(
    (doc) => doc.data()
  );
  if (!taskData) {
    return;
  }
  const taskForce = [...taskData.taskForce, userId];
  const users = taskForce.length > 0 ?
      taskForce.map(user => ({userId: user, seen: user === userId})) : []

  const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
  await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
    ledgerId: ledgerEntry.id,
    projectId: taskData.projectId,
    timestamp: eventTimestamp,
    logTimestamp: eventTimestamp,
    taskId: taskId,
    type: MessageType.TFJ,
    userId: whoAdded,
    userEmail: filteredMembers[0].userEmail,
  });

  await FirebaseUsage.updateDoc(COLLECTIONS.TASKS, taskId, {
    taskForce: taskForce,
    users: users,
  });
}

export async function writeLedgerEntry(
  taskId: string,
  userId: string,
  eventTimestamp: Timestamp
) {
  const members = store.getState().team.memberList;
  const filteredMembers = members.filter((member) => member.userId === userId);
  if (filteredMembers.length === 0) {
    return;
  }
  const taskData = await FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then(
    (doc) => doc.data()
  );
  if (!taskData) {
    return;
  }
  const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
  console.log(MessageType.TBS);
  await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
    ledgerId: ledgerEntry.id,
    projectId: taskData.projectId,
    timestamp: eventTimestamp,
    logTimestamp: eventTimestamp,
    taskId: taskId,
    type: MessageType.TBS,
    userId: userId,
    userEmail: filteredMembers[0].userEmail,
  });
}

export async function userHasSeen(userId: string, activeTask: TaskModel) {
    const taskForce = activeTask.taskForce;
    const existingUsers = activeTask.users;
    const users = taskForce.length > 0 ?
        taskForce.map(user => ({userId: user, seen: user === userId ? true : existingUsers ?
              existingUsers.find(u => u.userId === user)?.seen || false : false})) : []
    return await FirebaseUsage.updateDoc(COLLECTIONS.TASKS, activeTask.task_id, {
        users: users,
    });
}

export async function resetUsersHaveSeen(userId: string, activeTask: TaskModel) {
  const taskForce = activeTask.taskForce;
  const users = taskForce.length > 0 ?
      taskForce.map(user => ({userId: user, seen: user === userId})) : []
  return await FirebaseUsage.updateDoc(COLLECTIONS.TASKS, activeTask.task_id, {
    users: users,
  });
}

export async function addGroupToProject(groupName: string, projectId: string) {
  const globalGroup = await FirebaseUsage.getQuery(COLLECTIONS.GLOBAL_GROUPS,
      ["groupName", "==", groupName])
      .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data()));

    if (!globalGroup || globalGroup.length === 0) {
      const newGroup = FirebaseUsage.getBlankDoc(COLLECTIONS.GLOBAL_GROUPS);
        await FirebaseUsage.setDocumentWithDoc(newGroup, {
            groupName: groupName,
            groupId: newGroup.id,
        });
        const newMember = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS);
        await FirebaseUsage.setDocumentWithDoc(newMember, {
            userId: newGroup.id,
            userEmail: groupName,
            projectId: projectId,
            memberId: newMember.id,
            groupId: newGroup.id,
            grade: MemberOrganizationEnum.GROUPS,
            is_admin: false,
            is_ledger: false,
            is_pending: false,
            tasks: 0,
        });
        return;
    }
    const group = globalGroup[0];
    const newMember = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS);
    await FirebaseUsage.setDocumentWithDoc(newMember, {
        userId: group.groupId,
        userEmail: group.groupName,
        projectId: projectId,
        memberId: newMember.id,
        groupId: group.groupId,
        grade: MemberOrganizationEnum.GROUPS,
        is_admin: false,
        is_ledger: false,
        is_pending: false,
        tasks: 0,
    });
    return;
}

export async function wbsJoin (wbsId: string,
                               wbs: WbsModel[],
                               userId: string,
                               whoAdded: string,
                               allTasks: TaskModel[],
                               cpmMap: Map<string,
                               CpmTaskModel>,
                               userEmail: string) {
  let allTasksMap = new Map<string, TaskModel>();
  allTasks.forEach(task => allTasksMap.set(task.task_id, task));
  let batch: any[] = [];

  const wbsJoinRecursion = (wbsId: string) => {
    const wbsItem = wbs.find(w => w.wbs_id === wbsId);
    if (!wbsItem) {
      return;
    }
    if (wbsItem.tasks && wbsItem.tasks.length > 0) {
      const taskData: (TaskModel | undefined) [] = wbsItem.tasks.map((taskId: string) => allTasksMap.get(taskId));
      taskData.forEach(task => {
        if (!task) {
          return;
        }
        let taskForce = task.taskForce;
        if (taskForce.includes(userId)) {
          return;
        }
        taskForce.push(userId);
        const users = taskForce.length > 0 ?
            taskForce.map(user => ({userId: user, seen: user === userId})) : []
        batch.push({
            type: 'update',
            ref: cpmMap.get(task.task_id)?.doc_ref,
            data: {
                taskForce: taskForce,
                users: users,
            }
            });
        const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
        batch.push({
          type: 'set',
          ref: ledgerEntry,
          data: {
            ledgerId: ledgerEntry.id,
            projectId: wbsItem.projectId,
            timestamp: FirebaseUsage.timestamp(),
            logTimestamp: FirebaseUsage.timestamp(),
            taskId: task.task_id,
            type: MessageType.TFJ,
            userId: whoAdded,
            userEmail: userEmail
          }
        });
        })

      }
    wbsItem.children.forEach(child => wbsJoinRecursion(child));
  }
    wbsJoinRecursion(wbsId);
    await batchUpdate(batch);
    return
}

export async function wbsLeave (wbsId: string,
                                wbs: WbsModel[],
                                userId: string,
                                whoRemoved: string,
                                allTasks: TaskModel[],
                                cpmMap: Map<string,
                                CpmTaskModel>,
                                userEmail: string) {
  let allTasksMap = new Map<string, TaskModel>();
  allTasks.forEach(task => allTasksMap.set(task.task_id, task));
  let batch: any[] = [];

  const wbsLeaveRecursion = (wbsId: string) => {
    const wbsItem = wbs.find(w => w.wbs_id === wbsId);
    if (!wbsItem) {
      return;
    }
    if (wbsItem.tasks && wbsItem.tasks.length > 0) {
      const taskData: (TaskModel | undefined) [] = wbsItem.tasks.map((taskId: string) => allTasksMap.get(taskId));
      taskData.forEach(task => {
        if (!task) {
          return;
        }
        let taskForce = task.taskForce;
        if (!taskForce.includes(userId)) {
          return;
        }
        taskForce = taskForce.filter(member => member !== userId);
        const users = taskForce.length > 0 ?
            taskForce.map(user => ({userId: user, seen: user === userId})) : []
        batch.push({
            type: 'update',
            ref: cpmMap.get(task.task_id)?.doc_ref,
            data: {
                taskForce: taskForce,
                users: users,
            }
            });
        const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY);
        batch.push({
          type: 'set',
          ref: ledgerEntry,
          data: {
            ledgerId: ledgerEntry.id,
            projectId: wbsItem.projectId,
            timestamp: FirebaseUsage.timestamp(),
            logTimestamp: FirebaseUsage.timestamp(),
            taskId: task.task_id,
            type: MessageType.TFL,
            userId: whoRemoved,
            userEmail: userEmail
          }
        });
        })

      }
    wbsItem.children.forEach(child => wbsLeaveRecursion(child));
  }
    wbsLeaveRecursion(wbsId);
    await batchUpdate(batch);
    return
}