import * as projectActions from '../../actions/project.actions';
import ProjectModel from "../../../models/responses/project.model";
import { Maybe } from "@martin_hotell/rex-tils";
import GeoModel from "../../../models/responses/geo.model";

export interface DetectedUploadingProjectsSteps {
    totalWrites: number;
    uploadedWrites: number;
}

export interface ProjectLoadingStage {
    projectStopped: boolean;
    projectLoaded: boolean;
    projectNames: string[];
}

export class StateModel {
    calculationsInProcess: boolean = false;
    cpmComplete: boolean = false;
    monteCarloIndex: number = 0;
    view: string = 'task'
    projectList: ProjectModel[] = [];
    archiveProjectList: ProjectModel[] = [];
    projectListLoaded = false;
    activeProjectLoaded = false;
    activeProject: Maybe<ProjectModel> = null;
    activeProjectQuality: any = {};
    activeProjectCalendars: Map<string, any> = new Map();
    cpmMap: Map<string, any> = new Map();
    chatInitialised = false;
    chatLog: any[] = [];
    trackedMilestones: any[] = [];
    uploadingNewProject = false;
    geoData: any[] = [];
    activeGeo: { geo: null | GeoModel, fileName: null | string } | null = null;
    detectedSteps: DetectedUploadingProjectsSteps = {
        totalWrites: 0,
        uploadedWrites: 0,
    };
    uploadingError: string | null = null;
    projectLoadingStage: ProjectLoadingStage = {
        projectLoaded: false,
        projectStopped: false,
        projectNames: []
    };
    vectorStore: any = null;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: projectActions.Actions) {
    switch (action.type) {
        case projectActions.ActionNames.SET_PROJECT_LIST: {
            // console.log('🏠 state in SET_PROJECT_LIST', state)
            // console.log('🏠 action in SET_PROJECT_LIST', action)
            return {
                ...state,
                projectList: action.payload.projectList,
                projectListLoaded: true
            }
        }
        case projectActions.ActionNames.SET_ARCHIVE_PROJECT_LIST: {
            // console.log('🏠 state in SET_PROJECT_LIST', state)
            // console.log('🏠 action in SET_PROJECT_LIST', action)
            return {
                ...state,
                archiveProjectList: action.payload.archiveProjectList,
                projectListLoaded: true
            }
        }
        case projectActions.ActionNames.UPLOADING_NEW_PROJECT_FAIL: {
            // console.log('state UPLOADING_NEW_PROJECT_FAIL', state);
            // console.log('action UPLOADING_NEW_PROJECT_FAIL', action);
            return {
                ...state,
                uploadingError: action.payload.error,
                uploadingNewProject: false,
            }
        }
        case projectActions.ActionNames.SET_ACTIVE_PROJECT: {
            // console.log('state SET_ACTIVE_PROJECT', state);
            // console.log('action SET_ACTIVE_PROJECT', action);
            return {
                ...state,
                activeProject: action.payload.project,
                activeProjectLoaded: true
            }
        }
        case projectActions.ActionNames.UPLOADING_NEW_PROJECT_STARTED: {
            // console.log('state UPLOADING_NEW_PROJECT_STARTED', state);
            // console.log('action UPLOADING_NEW_PROJECT_STARTED', action);
            return {
                ...state,
                uploadingNewProject: true,
                detectedSteps: {
                    totalWrites: action.payload.totalWrites,
                    uploadedWrites: 0
                }
            }
        }
        case projectActions.ActionNames.UPLOADING_NEW_PROJECT_DETECT_STEP: {
            // console.log('state UPLOADING_NEW_PROJECT_DETECT_STEP', state);
            // console.log('action UPLOADING_NEW_PROJECT_DETECT_STEP', action);
            return {
                ...state,
                detectedSteps: {
                    ...state.detectedSteps,
                    uploadedWrites: action.payload.uploadedWrites
                }
            }
        }
        case projectActions.ActionNames.UPLOADING_NEW_PROJECT_FINISHED: {
             // console.log('state UPLOADING_NEW_PROJECT_FINISHED', state);
             // console.log('action UPLOADING_NEW_PROJECT_FINISHED', action);
            return {
                ...state,
                detectedSteps: {
                    totalWrites: 0,
                    uploadedWrites: 0,
                },
                uploadingNewProject: false
            }
        }
        case projectActions.ActionNames.SET_PROJECT_LOADING_STAGE: {
            // console.log('state SET_PROJECT_LOADING_STAGE', state);
            // console.log('action SET_PROJECT_LOADING_STAGE', action);
            return {
                ...state,
                projectLoadingStage: action.payload.projectLoadingStage
            }
        }
        case projectActions.ActionNames.SET_CALCULATIONS_IN_PROCESS: {
            // console.log('state SET_CALCULATIONS_IN_PROCESS', state);
            // console.log('action SET_CALCULATIONS_IN_PROCESS', action);
            return {
                ...state,
                calculationsInProcess: action.payload.value
            }
        }
        case projectActions.ActionNames.SET_ACTIVE_PROJECT_CALENDARS: {
            console.log('state SET_ACTIVE_PROJECT_CALENDARS');
            return {
                ...state,
                activeProjectCalendars: action.payload.calendars
            }
        }
        case projectActions.ActionNames.SET_CPM_MAP: {
            console.log('action SET_CPM_MAP');
            return {
                ...state,
                cpmMap: action.payload.cpmMap
            }
        }
        case projectActions.ActionNames.SET_CPM_COMPLETE: {
            return {
                ...state,
                cpmComplete: action.payload.cpmComplete
            }
        }
        case projectActions.ActionNames.SET_TRACKED_MILESTONES: {
            console.log('action SET_TRACKED_MILESTONES', action.payload.trackedMilestones);
            return {
                ...state,
                trackedMilestones: action.payload.trackedMilestones
            }
        }
        case projectActions.ActionNames.SET_MONTE_CARLO_INDEX: {
            return {
                ...state,
                monteCarloIndex: action.payload.monteCarloIndex
            }
        }
        case projectActions.ActionNames.SET_VIEW: {
            return {
                ...state,
                view: action.payload.view
            }
        }
        case projectActions.ActionNames.SET_ACTIVE_PROJECT_QUALITY: {
            return {
                ...state,
                activeProjectQuality: action.payload.quality
            }
        }
        case projectActions.ActionNames.SET_GEO_DATA: {
            return {
                ...state,
                geoData: action.payload.geoData
            }
        }
        case projectActions.ActionNames.SET_ACTIVE_GEO: {
            return {
                ...state,
                activeGeo: action.payload.geo
            }
        }
        case projectActions.ActionNames.SET_ACTIVE_PROJECT_REASONS: {
            console.log('action SET_ACTIVE_PROJECT_REASONS', action.payload.reasons);
            return {
                ...state,
                ...state.activeProject,
                issueCategories: action.payload.reasons
            }
        }
        case projectActions.ActionNames.SET_CHAT_LOG: {
            return {
                ...state,
                chatLog: action.payload.chatLog
            }
        }
        case projectActions.ActionNames.SET_CHAT_INITIALISED: {
            return {
                ...state,
                chatInitialised: action.payload.value
            }
        }
        case projectActions.ActionNames.SET_VECTOR_STORE: {
            return {
                ...state,
                vectorStore: action.payload.vectorStore
            }
        }
        default:
            return { ...state };
    }
}
