import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTaskforceListSelector } from '../../../../../../store/selectors/team.selectors';
import { useUserSelector } from '../../../../../../store/selectors/authorization.selectors';
import {
    useActiveTaskSelector
} from '../../../../../../store/selectors/task/task.selectors';
import { TaskThunk } from '../../../../../../store/thunk/task.thunk';
import { CheckList } from '../../../../../../models/checklist-model';
import { useDispatch } from 'react-redux';
import EventTextModel from '../../../../../../models/event-text-model.enum';
import TaskStatusModel from '../../../../../../models/responses/task-status.model';
import TaskChecklist from './components/TaskChecklist';
import TaskAddChecklist from './components/TaskAddChecklist';
import uuid from "uuid";
import { MessageType } from '../../../../../../models/responses/message.model';
import { useActiveProjectSelector } from '../../../../../../store/selectors/project.selectors';
import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import { faLock, faLockOpen } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TaskListSectionModel} from "../../../../../../models/task-list-section.model";
const TaskCheckListWrapper: React.FC = () => {

    const activeProject = useActiveProjectSelector();
    const [inputValue, setInputValue] = useState<string>('');
    const [isCheckListOpened, setIsCheckListOpened] = useState<boolean>(false);
    const [isLockOpened, setIsLockOpened] = useState<boolean>(false);
    const taskforceMembers = useTaskforceListSelector();
    const user = useUserSelector();
    const activeTask = useActiveTaskSelector();
    const dispatch = useDispatch();
    const inputRef = useRef<any>(null);
    const [opened, setOpened] = useState<boolean>(false);
    const [isActive, setIsActive] = useState(activeTask ? activeTask.taskListType === TaskListSectionModel.QUEUED ? true : false : false);
    // @ts-ignore
    const [eventText, setEventText] = useState<EventTextModel>(EventTextModel.EVENT_DESCRIPTION);
    const [activeChecklistItem, setActiveChecklistItem] = useState<CheckList | null>(null);

    useEffect(() => {
        if (isCheckListOpened && inputRef.current) {
          inputRef.current.focus();
        }
    }, [isCheckListOpened]);

    // useEffect(() => {
    //     if (activeTask) {
    //         const allChecked: boolean = Array.isArray(activeTask.checklist) ? activeTask.checklist.every(el => el.isChecked) : false;
    //         const someChecked: boolean = Array.isArray(activeTask.checklist) ? activeTask.checklist.some(el => el.isChecked) : false;

    //         if (activeTask.checklist && activeTask.checklist.length > 0 && allChecked && activeTask.status !== TaskStatusModel.DECLARED_COMPLETE) {
    //             setEventText(EventTextModel.COMPLETE_TASK);
    //             setOpened(true);
    //         }

    //         if (activeTask.checklist && activeTask.checklist.length > 0 && !allChecked && someChecked && activeTask.status !== TaskStatusModel.IN_PROGRESS &&
    //             activeTask.status !== TaskStatusModel.DECLARED_COMPLETE && activeTask.status !== TaskStatusModel.COMPLETE) {
        //             setEventText(EventTextModel.START_TASK);
        //             setOpened(true);
        //         }
        //     }
    // }, [activeTask]);

    // useEffect(() => {
    //     if (activeTask) {
    //       const allChecked: boolean = Array.isArray(activeTask.checklist) ? activeTask.checklist.every(el => el.isChecked) : false;
    //       if (activeTask.checklist && activeTask.checklist.length > 0 && allChecked && activeTask.status !== TaskStatusModel.DECLARED_COMPLETE) {
    //         // @ts-ignore
    //         dispatch(TaskThunk.completeChecklistNotification(activeTask.task_id));
    //       }
    //     }
    // }, [activeTask]);

    // useEffect(() => {
    //     if (activeTask) {
    //       const allChecked: boolean = Array.isArray(activeTask.checklist) ? activeTask.checklist.every(el => el.isChecked) : false;
    //       if (activeTask.checklist && activeTask.checklist.length > 0 && allChecked && activeTask.status !== TaskStatusModel.DECLARED_COMPLETE) {
    //         addEventLog();
    //       }
    //     }
    // }, [activeTask]);

    const updateCheckListLock = useCallback(() => {
      if (activeTask) {
        setEventText(EventTextModel.ADD_EVENT_LOG);
        // @ts-ignore
        dispatch(TaskThunk.addEventLog(activeTask.task_id, MessageType.CHK, activeTask.taskListType));
        // @ts-ignore
        dispatch(TaskThunk.completeChecklistNotification(activeTask.task_id));
            // @ts-ignore
        dispatch(TaskThunk.updateCheckListLock(activeTask.task_id, true, activeTask.taskListType));
      }
    },[activeTask, dispatch]);

    const addCheckListHandler = useCallback(() => {
      if (activeTask && inputValue) {
        const checklist: CheckList[] = [
          ...Array.isArray(activeTask.checklist) ? activeTask.checklist : [],
          {
              name: inputValue,
              isChecked: false,
              id: uuid.v4(),
              // userEmail: user!.userEmail,
          }
        ]
        // @ts-ignore
        dispatch(TaskThunk.updateChecklist(checklist, activeTask.task_id, activeTask.taskListType));
      }
      setInputValue('');
    }, [inputValue, activeTask, dispatch]);

    const addImportedCheckListHandler = useCallback(() => {
        if (activeTask) {
          // @ts-ignore
          dispatch(TaskThunk.updateChecklist(activeTask.importedChecklist, activeTask.task_id, activeTask.taskListType));
        }
    }, [activeTask, dispatch]);

    const lockChecklist = () => {
      updateCheckListLock();
      setIsLockOpened(false);
    }

    const checklistItemChangeHandler = useCallback((item: CheckList) => {
      if (activeTask) {
          item = {
            ...item,
            userEmail: user!.userEmail,
            checkedDate: new Date(),
          };
          const checklist = [...Array.isArray(activeTask.checklist) ? activeTask.checklist : []]
          .map(el => el.id === item.id ? item : el);
          // @ts-ignore
          dispatch(TaskThunk.updateChecklist(checklist, activeTask.task_id, activeTask.taskListType));
        }
        setActiveChecklistItem(item);
    }, [activeTask, dispatch]);

    const removeCheckListHandler = useCallback((itemId: string) => {
        if (activeTask) {
            const checklist = [...Array.isArray(activeTask.checklist) ? activeTask.checklist : []]
                .filter(el => el.id !== itemId);
            // @ts-ignore
            dispatch(TaskThunk.updateChecklist(checklist, activeTask.task_id, activeTask.taskListType));
        }
    }, [activeTask, dispatch]);

    const isUserTaskforceMember = taskforceMembers.some(
      (member) => member.userEmail === user!.userEmail
    );

    return activeTask &&
      <div
        className={
          "checklist-wrapper " +
          (activeTask.importedChecklist &&
          activeTask.importedChecklist.length > 0 &&
          activeTask.checklist.length === 0
            ? "flex"
            : "")
        }
      >
        {activeTask.checklist.length ? (
          <>
            <div
              className="collapse-title"
              onClick={() => setIsActive(!isActive)}
            >
              {
                <FontAwesomeIcon
                  icon={!isActive ? faAngleUp : faAngleDown}
                  className="chevron-icon"
                />
              }
              <p className="calendar-title" style={{ marginBottom: "unset" }}>
                Checklist
              </p>
              <FontAwesomeIcon
                icon={activeTask.checkListLock ? faLock : faLockOpen}
                className="lock-icon"
              />
            </div>
            {isActive && (
              <TaskChecklist
                taskChecklist={activeTask.checklist}
                onChangeHandler={checklistItemChangeHandler}
                removeHandler={removeCheckListHandler}
                eventText={eventText}
                opened={opened}
                setOpened={setOpened}
                activeItem={activeChecklistItem}
                setActiveItem={setActiveChecklistItem}
                disabled={!isUserTaskforceMember}
              />
            )}
          </>
        ) : null}
        {activeTask.status !== TaskStatusModel.DECLARED_COMPLETE &&
          activeTask.status !== TaskStatusModel.IN_PROGRESS &&
          !activeTask.checkListLock && isUserTaskforceMember && (
            <TaskAddChecklist
              isChecklistOpened={isCheckListOpened}
              setIsChecklistOpened={setIsCheckListOpened}
              isLockOpened={isLockOpened}
              setIsLockOpened={setIsLockOpened}
              taskChecklist={activeTask.checklist}
              addChecklistHandler={addCheckListHandler}
              addImportedChecklistHandler={addImportedCheckListHandler}
              lockChecklist={lockChecklist}
              inputValue={inputValue}
              setInputValue={setInputValue}
              inputRef={inputRef}
              activeTask={activeTask}
              disabled={!isUserTaskforceMember}
            />
          )}
      </div>
    // ) : null;
}

export default TaskCheckListWrapper;
