import React, {useEffect } from "react";
import {
    useActiveProjectSelector,
    useCpmCompleteSelector,
    useProjectViewSelector
} from "../../../../store/selectors/project.selectors";
import {useTaskListLoadCountSelector} from "../../../../store/selectors/task/task.selectors";
import {Loader} from "semantic-ui-react";
import {TaskList} from "./components/TaskList";
import FirebaseProjectFunctions from "../../../../firebase/firebase-functions/project-service";
import { useDispatch } from 'react-redux';
import * as projectActions from "../../../../store/actions/project.actions";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import {useProjectMemberListSelector} from "../../../../store/selectors/team.selectors";
import {COLLECTIONS} from "../../../../firebase/constants";
import QualityPage from "../QualityPage/QualityPage";
import GanttChart from "../../components/GanttChart/GanttChart";


const FlowPage: React.FC = () => {
    const activeProject = useActiveProjectSelector();
    const [oldActiveProject, setOldActiveProject]: any = React.useState(null);
    const cpmFinished = useCpmCompleteSelector()
    const dispatch = useDispatch();
    const taskListLoadCount = useTaskListLoadCountSelector();
    const user = useUserSelector()
    const projectMembersList = useProjectMemberListSelector();
    const projectView = useProjectViewSelector();

    useEffect(() => {
        if (taskListLoadCount >= 5 && activeProject && activeProject.projectId !== oldActiveProject) {
            let cpmRefresh = false;
            const beginningOfToday = new Date().setHours(0, 0, 0, 0) / 1000;
            if (activeProject.lastUpdate && activeProject.lastUpdate.seconds < beginningOfToday) {
                cpmRefresh = true;
            }
            console.log("running CPM on load")
            FirebaseProjectFunctions.fullCPMCalc(activeProject.projectId, "false", dispatch, cpmRefresh)
                .then(() => {
                    console.log("cpm run on load - successful")
                    dispatch(projectActions.Actions.setCpmComplete(true))
                    if (user && projectMembersList) {
                        const member = projectMembersList.find((member) => member.userId === user.userId)
                        const memberId = member!.memberId
                        FirebaseUsage.updateDoc(COLLECTIONS.PROJECT_MEMBERS, memberId, {
                            lastViewed: FirebaseUsage.timestamp(),
                            totalViews: member!.totalViews ? member!.totalViews + 1 : 1
                        }).catch((err) => console.log(err))
                    }
                })
                .catch((err) => console.log("cpm run on load unsuccessful - ", err))
            setOldActiveProject(activeProject.projectId);
        }
    }, [taskListLoadCount]);

    return cpmFinished ? (
        <div className="flow-page-container">
            {projectView === "task" ?
            <TaskList/> : projectView === "quality" ? <QualityPage/> : projectView === "gantt" ? <GanttChart/> : <TaskList/>
            }
        </div>
    ) : <Loader active content={"Project data loading..."} size={"large"}/>
};

export default FlowPage;
