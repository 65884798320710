import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import FirebaseUsage from './firebase/firebase.usage';
import * as performance from 'firebase/performance';
import * as messaging from 'firebase/messaging';
import 'firebase/messaging';
import 'firebase/performance';
import firebaseConfig from './config/firebase.config';
import '../node_modules/react-toastify/dist/ReactToastify.min.css'

performance.initializePerformance(FirebaseUsage.app);
const isSupported = messaging.isSupported().then((isSupported) => {
// @ts-ignore
if (isSupported) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().catch((err) => console.log(err));
  }

  // FirebaseUsage.messaging().usePublicVapidKey(firebaseConfig.messagingProjectsPublicKey);

  FirebaseUsage.getTokenFromMessaging({
      vapidKey: firebaseConfig.vapidKey,
    })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log('registration token available ' + currentToken);
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        // ...
      }
    })
    .catch((err) => {
      console.log('--An error occurred while retrieving token. ', err);
      // ...
    });

  Notification.requestPermission()
    .then((permission) => {
      if (permission !== 'granted') {
      }
    })
    .catch((err) => {
      console.log('requestPermission ERROR', err);
    });
}});

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
  // const db = FirebaseUsage.database();
  // db._settings({
  //   host: 'localhost:8080',
  //   ssl: false,
  // });
  FirebaseUsage.useFunctionsEmulator();
  FirebaseUsage.useDatabaseEmulator()
  console.log("Using emulators")
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
