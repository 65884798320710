export enum TaskFunctions {
  EVALUATE_COMPLETE = "evaluateCompletionTask",
  EVALUATE_COMPLETE_TASKS = "evaluateCompletionTasks",
  DECLARE_COMPLETE = "declareCompleteTask",
  RUN_TASK = "runActiveTask",
  BLOCK_TASK = "setBlockTask",
  SUSPEND_TASK = "suspendActiveTask",
  CHANGE_REMAINING_DURATION_OF_ACTIVE_TASK = "changeRemainingDurationOfActiveTask",
  SEND_POST = "sendPost",
  UPDATE_USER_TASK_SEEN = "updateUserTaskSeen",
  JOIN_TASKFORCE = "joinTaskForce",
  ADD_TASKFORCE = "addTaskForce",
  REMOVE_TASKFORCE = "removeTaskForce",
  LEAVE_TASKFORCE = "leaveTaskForce",
  ADD_NEW_TASKLIST = "addNewTaskList",
  ADD_COMPLETED_TASK_COUNT = "addCompletedTaskCount",
  SET_CHECKLIST = "setChecklist",
  REMOVE_CHECKLIST = "removeChecklist",
  UPDATE_CHECKLIST = "updateChecklist",
  CPM_CALCULATION = "cpmCalculation",
  CALC_FORECAST = "calcForecast",
  REFRESH_TASKS = "runRefresh",
  COMPLETE_CHECKLIST_NOTIFICATION = "completeChecklistNotification",
  TASK_BEHIND_SCHEDULE_NOTIFICATION = "taskBehindScheduleNotification",
  ASSIGNED_TO_TASKFORCE_NOTIFICATION = "assignedToTaskforceNotification",
  ADD_EVENT_LOG = "addEventLog",
  UPDATE_CHECKLIST_LOCK = "updateCheckListLock",
}

export enum TeamFunctions {
  MAKE_EVALUATOR_TOGGLE = "makeEvaluatorToggle",
  REMOVE_MEMBER = "removeMember",
  ADD_MEMBER = "addNewMember2",
  ADD_GROUP = "addNewGroup",
  CHANGE_GRADE = "changeMemberGrade",
  CHANGE_GATEKEEPER = "changeMemberGateKeeper",
  CHECK_USER_EXISTS_BY_EMAIL = "checkUserExistsByEmail",
}

export enum ReportFunctions {
  ADD_REPORT_ITEM = "addReportItem",
  REMOVE_REPORT_ITEM = "removeReportItem",
  EDIT_REPORT_ITEM = "editReportItem",
}

export enum ProjectFunctions {
  UPLOAD_PROJECT = "uploadNewProject",
  CURVES_CALCULATION = "curvesCalculation",
  PROJECT_DELETION = "projectDeletion",
  TRANSFORM_XER_TO_JSON = "transformXerToJson",
  TRANSFORM_XER_TO_JSON_FROM_URL = "transformXerToJsonFromURL",
  GET_GOOGLE_CLOUD_SIGNED_URL = "getGoogleCloudSignedURL",
  CALCULATE_RACING_LINE = "calculateRacingLine",
  UPLOAD_V_LIST = "uploadVList",
  SEND_MAILS = "sendMails",
  CANCEL_UPLOADING = "cancelProjectUploading",
  CANCEL_UPDATE = "cancelUploadStrategy",
  UPLOADPROJECT = "uploadProject",
  UPDATE_PROJECT_FLOAT = "updateProjectFloat",
  UPDATE_TARGET_FRONT_FACTOR = "updateTargetFrontFactor",
  SHARE_REPORT_ITEM_TO_ANOTHER_PROJECT = "shareReportItemToAnotherProject",
  SET_CALENDARS_TO_RAM = "setCalendarsToRAM",
  SET_CPM_MAP_TO_RAM = "setCPMMapToRAM",
  FULL_CPM_CALC = "fullCPMCalc",
  TASK_CPM = "taskCPM",
  XER_TO_JSON = "xerToJson",
  CONFIRM_UPLOAD = "confirmUpload",
  UPDATE_PRED_STATUSES = "updatePredStatuses",
  ASSIGN_BASELINE_TO_PROJECT = "getBaseline",
  MAKE_BASELINE_CURRENT = "makeBaselineCurrent",
  GET_CLIENTS = "getClients",
  BACKUP_ALL_PROJECTS = "backupAllProjects",
}
