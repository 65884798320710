import React, { useEffect } from "react";
import store from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppRouting from "./AppRouting";
import styled, { ThemeProvider } from "styled-components";
import theme from "./constants/theme";
import { ToastContainer } from "react-toastify";
import FirebaseUsage from "./firebase/firebase.usage";

const AppWrap = styled.div`
  background-color: ${(props) => props.theme.background.main};
  flex: 1;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
`;

const App = () => {
  useEffect(() => {
    FirebaseUsage.onMessage((payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: `/${payload.data.type}.png`,
      };

      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications.");
      } else if (Notification.permission === "granted") {
        const notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        const url = `${window.location.origin}/reports/${payload.data.reportId}`;

        notification.onclick = function (event) {
          event.preventDefault();
          window.open(url, "_blank");
          notification.close();
        };
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <AppWrap>
          <AppRouting/>
        </AppWrap>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
