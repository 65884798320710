import EXIF from "exif-js";
import ExifReader from "exifreader";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

async function imageProcessing (file: any,
                                type: string,
                                index: number,
                                attachmentCount: number,
                                setImageAttachments: any,
                                setAttachmentLoading: any,
                                alternativeFile?: any,
) {
    let attachment: any = {}
    const reader = new FileReader()
    reader.onloadend = () => {
        const base64 = reader.result
        const image = new Image()
        image.src = base64 as string
        image.onload = () => {
            // @ts-ignore
            EXIF.getData(image, function () {
                attachment.geoData = EXIF.getAllTags(image)
            })
        }
    }

    if (type === "heic") {
        const geoData = await ExifReader.load(alternativeFile)
        attachment.geoData = {
            // @ts-ignore
            GPSLatitude: geoData.GPSLatitude?.value.map((el: any[]) => el[0] / el[1]),
            // @ts-ignore
            GPSLongitude: geoData.GPSLongitude?.value.map((el: any[]) => el[0] / el[1]),
            GPSAltitude: geoData.GPSAltitude?.description,
            GPSImgDirection: geoData.GPSImgDirection?.description,
            DateTime: geoData.DateTime?.description,
        }
    } else {
        reader.readAsDataURL(file)
    }

    const binaryReader = new FileReader()
    binaryReader.onloadend = () => {
        attachment.arrayBuffer = binaryReader.result
    }
    binaryReader.readAsArrayBuffer(file)

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 400,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(file, options)

    const compressedReader = new FileReader()
    compressedReader.onloadend = () => {
        attachment.src = compressedReader.result
        attachment.filename = file.name
        attachment.modified = file.lastModified
        attachment.type = file.type

        setImageAttachments(attachment)
        if (index === attachmentCount) setAttachmentLoading(false)
    }
    compressedReader.readAsDataURL(compressedFile)
    return attachment
}

export async function handleImage (event: any, setImageAttachments: any, setAttachmentLoading: any) {
    event.preventDefault()
    const files = event.target.files
    let attachments: any[] = []
    let index = 0
    for (let file of files) {
        index++
        if (file.type === "image/heic") {
            // convert heic to jpg
            const heicReader = new FileReader()
            heicReader.onloadend = async () => {
                const arrayBuffer = heicReader.result
                const heicBlob = new Blob([new Uint8Array(arrayBuffer as ArrayBuffer)], {type: "image/heic"})
                await heic2any({
                    blob: heicBlob,
                    toType: "image/jpeg",
                    quality: file.size > 1048576 ? 1000000 / file.size : 1
                }).then((jpgBlob) => {
                    // @ts-ignore
                    const newFile = new File([jpgBlob], file.name.replace(/\.heic|.HEIC/, ".jpg"), {type: "image/jpeg"})
                    imageProcessing(newFile, "heic", index, files.length, setImageAttachments, setAttachmentLoading, file).then((attachment) => attachments.push(attachment))
                })
            }
            heicReader.readAsArrayBuffer(file)
        } else {
            await imageProcessing(file, "normal", index, files.length, setImageAttachments, setAttachmentLoading,null).then((attachment) => attachments.push(attachment))
        }
    }
    return attachments
}