import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {UserDocModel} from "../../models/responses/user.model";
import ProjectMembersModel from "../../models/responses/project-members.model";

export enum ActionNames {
    SEND_SIGN_IN_LINK_TO_EMAIL = '[Authorization] Send Sign In Link To Email',
    SEND_SIGN_IN_LINK_TO_EMAIL_SUCCESS = '[Authorization] Send Sign In Link To Email Success',
    SEND_SIGN_IN_LINK_TO_EMAIL_FAIL = '[Authorization] Send Sign In Link To Email Fail',

    LOG_OUT = '[Authorization] Log Out',

    AUTH = '[Authorization] Auth',
    SET_MEMBER_PERMISSIONS = '[Authorization] Set Member Permissions',
    SET_USER_SIDEBAR_OPEN = '[Authorization] Set User Sidebar Open',
    NOT_AUTH = '[Authorization] Not Auth',

    SIGN_IN_WITH_EMAIL_LINK = '[Authorization] Sign In With Email Link',
}


export const Actions = {
    auth: (user: UserDocModel) => createAction(ActionNames.AUTH, {user}),
    notAuth: () => createAction(ActionNames.NOT_AUTH),
    setMemberPermissions: (members: ProjectMembersModel[]) => createAction(ActionNames.SET_MEMBER_PERMISSIONS, {members}),
    setUserSidebarOpen: (isOpen: boolean) =>
        createAction(ActionNames.SET_USER_SIDEBAR_OPEN, { isOpen }),
    sendSignInLinkToEmail: () => createAction(ActionNames.SEND_SIGN_IN_LINK_TO_EMAIL),
    sendSignInLinkToEmailSuccess: () => createAction(ActionNames.SEND_SIGN_IN_LINK_TO_EMAIL_SUCCESS),
    sendSignInLinkToEmailFail: () => createAction(ActionNames.SEND_SIGN_IN_LINK_TO_EMAIL_FAIL),
    logOut: () => createAction(ActionNames.LOG_OUT),
    signInWithEmailLink: () => createAction(ActionNames.SIGN_IN_WITH_EMAIL_LINK),
};


export type Actions = ActionsUnion<typeof Actions>;
