
export default function loopDetection (tasks, relationships) {

    console.log("starting loop detection")
    // Build a graph from the relationships
    let graph = new Map()
    relationships.forEach(rel => {
        if (tasks.get(rel.data.pred_task_id).data.task_type !== "TT_LOE" && tasks.get(rel.data.task_id).data.task_type !== "TT_LOE") {
            let predTask = tasks.get(rel.data.pred_task_id).data
            let thisTask = tasks.get(rel.data.task_id).data
            if (!graph.has(rel.data.pred_task_id)) {
                graph.set(rel.data.pred_task_id, {id: rel.data.pred_task_id, status: predTask ? predTask.status_code : null, pCnt: 0, succs: []})
            }
            if (!graph.has(rel.data.task_id)) {
                graph.set(rel.data.task_id, {id: rel.data.task_id, status: thisTask ? thisTask.status_code : null, pCnt: 0, succs: []})
            }
            let predItem = graph.get(rel.data.pred_task_id)
            let thisItem = graph.get(rel.data.task_id)

            if (predItem.incompletePreds) {
                if (thisItem.status !== "TK_Complete") {
                    predItem.incompletePreds.forEach((pred, i) => {
                        let incompletePred = graph.get(pred)
                        incompletePred.succs.push(rel.data.task_id)
                        thisItem.pCnt += 1
                    })
                } else {
                    thisItem.incompletePreds = thisItem.incompletePreds ?
                        [...thisItem.incompletePreds, ...predItem.incompletePreds] :
                        [...predItem.incompletePreds]
                }
            }

            if (thisItem.status === "TK_Complete" && predItem.status !== "TK_Complete") {
                thisItem.incompletePreds = thisItem.incompletePreds ?
                    [...thisItem.incompletePreds, rel.data.pred_task_id] : [rel.data.pred_task_id]
            } else {
                predItem = predItem['succs'] ? {
                        ...predItem,
                        succs: [...predItem.succs, rel.data.task_id]} :
                    {...predItem, succs: [rel.data.task_id]}
                thisItem.pCnt += 1
            }
            // ss = redundant successors
            predItem = predItem['ss'] ? {
                    ...predItem,
                    ss: [...predItem.ss, rel.data.task_id]} :
                {...predItem, ss: [rel.data.task_id]}

            graph.set(rel.data.pred_task_id, predItem)
            graph.set(rel.data.task_id, thisItem)
        }
    })

    function forwardPass (id) {
        let graphItem = graph.get(id)
        graphItem.pCnt -= 1
        graph.set(id, graphItem)
        if (graphItem.pCnt === 0) {
            if (graphItem.succs) {
                graphItem.succs.forEach(successor => {
                    forwardPass(successor)
                })
            }
            graph.delete(id)
        }
    }

    graph.forEach((value, key) => {
        if (value.pCnt === 0) {
            value.succs.forEach(succ => {
                forwardPass(succ)
            })
            graph.delete(key)
        }
    })

    let tasksOut: any[] = []
    let loopsOut = new Map()
    function followLoop(key) {
        loopsOut.set(key, graph.get(key))
        let graphItem = graph.get(key)
        if (graphItem.ss) {
            graphItem.ss.forEach(successor => {
                if (!loopsOut.has(successor)) {
                    followLoop(successor)
                }
            })
        }
    }

    graph = new Map(Array.from(graph.entries()).sort((a, b) => new Date(tasks.get(a[0]).target_start_date).getTime() - new Date(tasks.get(b[0]).target_start_date).getTime()))

    graph.forEach((value, key) => {
        followLoop(key)
    })

    loopsOut.forEach((value, key) => {
        console.log(tasks.get(key), key, value)
        tasksOut.push(tasks.get(key))
    })

    return tasksOut.length !== 0 ? tasksOut : false

}