import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import {RelationshipsModel} from "../../models/responses/relationships.model";

export class RelationshipsSubscriptions {
    static relationshipsList(projectId: string, callback: DefaultSubscribeCallback<RelationshipsModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.RELATIONSHIPS, ['project_id', '==', projectId])
            .then(data => {
                callback(data.docs.map(d => d.data() as RelationshipsModel))
            });
    }
}
