import { MemberOrganizationEnum } from "../../../models/member-organization.enum";
import FirebaseTeamFunctions from "../../../firebase/firebase-functions/team-service";
import { GateKeeperEum } from "../../../models/gate-keeper.enum";

export default abstract class TeamTransactions {
  static addNewTaskforceMember(
    userEmail: string,
    projectId: string,
    organization: MemberOrganizationEnum
  ) {
    throw new Error("Method not implemented.");
  }

  public static async makeEvaluatorToggle(memberId: string) {
    return FirebaseTeamFunctions.makeEvaluatorToggle({
      memberId,
    });
  }

  public static async removeMember(
    memberId: string,
    projectId: string,
    type: string,
    nominatedMembersList?: { userEmail: string; id: string }[],
    lastSuperActor?: boolean,
  ) {
    return FirebaseTeamFunctions.removeMember({
      memberId,
      projectId,
      type,
      nominatedMembersList,
      lastSuperActor,
    });
  }

  public static async addNewMember(
    userEmail: string,
    projectId: string,
    grade: MemberOrganizationEnum,
    clientId: string
  ) {
    return FirebaseTeamFunctions.addNewMember({
      userEmail,
      projectId,
      grade, clientId,
    });
  }

  public static async addNewGroup(groupName: string, projectId: string) {
    return FirebaseTeamFunctions.addNewGroup({
      groupName,
      projectId,
    });
  }

  public static async changeMemberGrade(
    grade: MemberOrganizationEnum,
    memberId: string
  ) {
    return FirebaseTeamFunctions.changeMemberGrade({
      grade,
      memberId,
    });
  }

  public static async changeMemberGateKeeper(
    gateKeeper: GateKeeperEum,
    memberId: string
  ) {
    return FirebaseTeamFunctions.changeMemberGateKeeper({
      gateKeeper,
      memberId,
    });
  }
}
