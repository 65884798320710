import FirebaseUsage from "../firebase/firebase.usage";
import ProjectModel from "../models/responses/project.model";
import shortestPath from "./funnel-calculation";

export default async function generateCurves(taskList: any, projectId: string) {
    let {earliestDate, latestDate} = await FirebaseUsage.getDoc('projects', projectId).then(doc => doc.data() as ProjectModel)

    if (!earliestDate) {
        taskList.sort((a, b) => {
            if (a.act_end_date && b.act_end_date) {
                return a.act_end_date.toDate().getTime() - b.act_end_date.toDate().getTime()
            } else if (a.early_end_date && b.early_end_date) {
                return a.early_end_date.toDate().getTime() - b.early_end_date.toDate().getTime()
            } else {
                return 0
            }
        })
        earliestDate = taskList[0].act_end_date ? taskList[0].act_end_date.toDate().getTime() : taskList[0].early_end_date.toDate().getTime()
    }
    if (!latestDate) {
        taskList.sort((a, b) => {
            if (a.late_end_date && b.late_end_date) {
                return b.late_end_date.toDate().getTime() - a.late_end_date.toDate().getTime()
            } else {
                return 0
            }
        })
        latestDate = taskList[0].act_end_date ? taskList[0].act_end_date.toDate().getTime() : taskList[0].early_end_date.toDate().getTime()
    }

    let early: Map<number, number> = new Map()
    let late: Map<number, number> = new Map()
    let expected: Map<number, number> = new Map()

    let earlyTotal = 0
    let lateTotal = 0
    let expectedTotal = 0

    let earlyCumulative = new Map()
    let lateCumulative = new Map()
    let expectedCumulative = new Map()

    for (const task of taskList) {
        if (task.act_end_date) {
            const endDate = Math.ceil((task.act_end_date.toDate().getTime() / 1000) / 86400) * 86400
            const earlyTotal = early.get(endDate)
            const lateTotal = late.get(endDate)
            early.set(endDate, earlyTotal ? earlyTotal + 1 : 1)
            late.set(endDate, lateTotal ? lateTotal + 1 : 1)
        } else {
            const earlyEndDate = task.early_end_date ? Math.ceil((task.early_end_date.toDate().getTime() / 1000) / 86400) * 86400 : null
            const lateEndDate = task.early_end_date ? Math.ceil((task.late_end_date.toDate().getTime() / 1000) / 86400) * 86400 : null
            if (earlyEndDate) {
                const earlyTotal = early.get(earlyEndDate)
                early.set(earlyEndDate, earlyTotal ? earlyTotal + 1 : 1)
            }
            if (lateEndDate) {
                const lateTotal = late.get(lateEndDate)
                late.set(lateEndDate, lateTotal ? lateTotal + 1 : 1)
            }
        }
    }

    for (let i = (Math.floor((earliestDate! / 1000) / 86400) * 86400 ); i <= Math.ceil((latestDate! / 1000) / 86400) * 86400; i += 86400) {
        const earlyCount = early.get(i)
        const lateCount = late.get(i)
        earlyTotal += earlyCount ? earlyCount : 0
        lateTotal += lateCount ? lateCount : 0
        earlyCumulative.set(i, earlyTotal)
        lateCumulative.set(i, lateTotal)
    }

    const expectedCumulativeArray = shortestPath(
        earlyCumulative,
        lateCumulative,
        [Math.ceil((latestDate! / 1000) / 86400) * 86400, earlyTotal])

    let expectedCumulativeMap = new Map()
    expectedCumulativeArray.forEach((point, i) => {
        if (i === expectedCumulativeArray.length - 1) {
            return
        }
        for (let x = point[0]; x < expectedCumulativeArray[i + 1][0]; x += 86400) {
            expectedCumulativeMap.set(x, point[1] + ((expectedCumulativeArray[i + 1][1] - point[1]) / (expectedCumulativeArray[i + 1][0] - point[0])) * (x - point[0]))
        }
    })

    return {
        early: Array.from(earlyCumulative.entries()).map(el => ({date: {seconds: el[0]}, cumTasks: el[1]})),
        late: Array.from(lateCumulative.entries()).map(el => ({date: {seconds: el[0]}, cumTasks: el[1]})),
        expected: Array.from(expectedCumulativeMap.entries()).map(el => ({date: {seconds: el[0]}, cumTasks: el[1]})),
    }

}