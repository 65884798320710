import moment from "moment-timezone";
import { Timestamp } from "firebase/firestore";

export const getDaysFrom = (date: Date, fromDate?: Date) => {
    var dateStart = fromDate ? fromDate : new Date(1900, 0, 1);
    var dateEnd = date;
      
    // To calculate the time difference of two dates
    var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
      
    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    return Difference_In_Days;
};
export const date2Excel = (date: Date) => {

    var returnDateTime = 25569.0 + ((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return Math.floor(+returnDateTime.toString().substr(0,20));
};
export const monthArrayFromDuration = (start: Date, end: Date) => {
    const months: Array<string | number> = [];
    const dateStart = moment(start);
    const dateEnd = moment(end);
    while (dateEnd.diff(dateStart, 'months') >= 0) {
        months.push(dateStart.format('YYYY MMM'));
        dateStart.add(1, 'months');
    }
    return months
};

export const excel2date = (excel: number) => {
    var utc_days  = Math.floor(excel - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = excel - Math.floor(excel) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    // return gregorianDaysToDate(dateToGregorianDays(new Date(1900, 0, 1)) + excel - 2)
};

export const daysArrayFromDuration = (start: Date, end: Date, getString?: boolean) => {
    const dates: Array<Date | string> = [];
    const dateStart = moment(start);
    const dateEnd = moment(end);
    while (dateEnd.diff(dateStart, 'days') >= 0) {
        let d;
        if (getString) {
            d = dateStart.format('DD MMM')
        } else {
            d = dateStart.toDate();
        }
        // d.setHours(0, 0, 0);
        dates.push(d);
        dateStart.add(1, 'day');
    }
    return dates
};

export const datesArrayFromDuration = (start: Date, end: Date) => {
    const dates: Array<Date> = [];
    let monthStartNum = start.getMonth();
    let monthEndNum = end.getMonth();
    let yearStartNum = start.getFullYear();
    let yearEndNum = end.getFullYear();
    const dateStart = moment(start);
    // const dateEnd = moment(end);
    let monthsDiff;
    if (yearEndNum > yearStartNum) {
        monthsDiff = ((yearEndNum - yearStartNum) * 12) - monthStartNum + monthEndNum;
    } else {
        monthsDiff = monthEndNum - monthStartNum;
    }


    let increment = 0;
    while (monthsDiff >= increment) {
        const d = dateStart.toDate();
        d.setDate(1);
        d.setHours(0, 0, 0);
        dates.push(d);
        dateStart.add(1, 'months');
        increment++;
    }
    return dates
};

export const getIOSDate = (seconds: number , transformUKtime = false) =>{
    if(transformUKtime){
        let date = new Date(seconds * 1000);
        const clientOldTimeZoneOffset = 0//getting diff in server machine
        const clientNewTimeZoneOffset = getTimeZoneOffsetFromUTC("Europe/London",date);
        date = transformTimeZoneDates(date,clientNewTimeZoneOffset,clientOldTimeZoneOffset);
       return Timestamp.fromDate(
            date
        ).toDate().toISOString();
        
    }
   return Timestamp.fromDate(
        new Date(seconds * 1000)
    ).toDate().toISOString();
    
}


export const ukTimezonify = (date) =>{
    date = date instanceof Date ? date :  new Date(date.seconds * 1000);
    const clientOldTimeZoneOffset = date.getTimezoneOffset()//getting diff in server machine
    const clientNewTimeZoneOffset = getTimeZoneOffsetFromUTC("Europe/London",date);
    date = transformTimeZoneDates(date,clientNewTimeZoneOffset,clientOldTimeZoneOffset);
   return date
    

}
export const min = (arr: any[]) => {
    const l_arr = arr.filter(el => typeof el === "number");
    return Math.min.apply(Math, l_arr)
};
export const max = (arr: any[]) => {
    const l_arr = arr.filter(el => typeof el === "number");
    return l_arr.length ? Math.max.apply(Math, l_arr) : NaN
};

export const average = (arr: any[]) => {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
        total += arr[i];
    }
    return total / arr.length;
};


export const getDateByMonthNumber = (index: number) => {
    let d = new Date();
    d.setMonth(index);
    d.setDate(1);
    d.setHours(0, 0, 0);
    return d;
};


export const equalDates = (x: Date | null, y: Date) => x && x.getTime() === y.getTime();


export const nullifyTimeFromDate = (date: Date) => {
    const l_d = new Date(date);
    l_d.setHours(0, 0, 0, 0);
    return l_d;
}

export  const transformTimeZoneDates = (
    date,
    clientNewTimeZoneOffset,
    clientOldTimeZoneOffset
  ) => {
    const minsDiff = Math.abs( clientNewTimeZoneOffset) - Math.abs( clientOldTimeZoneOffset);
    if (minsDiff <= 0) {
      date.setMinutes(date.getMinutes() - Math.abs(minsDiff));
    } else {
      date.setMinutes(date.getMinutes() + Math.abs(minsDiff));
    }
    return date;
  }

  export  const getTimeZoneOffsetFromUTC = (timeZone, date) => {
    date = date || moment();
    var timeZoneName = moment(date).tz(timeZone).format('Z');
    var matchData = timeZoneName.match(/([+-])(\d+)(?::(\d+))?/);
    if (!matchData) throw "cannot parse timezone name: " + timeZoneName;
  
    var sign = matchData[1];
    var hour = matchData[2];
    var minute = matchData[3];
  
    var result = parseInt(hour) * 60;
    if (sign === "+") result *= -1;
    if (minute) result += parseInt(minute);
    // @ts-ignore
    return result;
  }
