import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { ProjectThunk } from "../../../../store/thunk/project.thunk";
import classNames from "classnames";
import {
  useArchiveProjectListSelector,
  useProjectListSelector,
} from "../../../../store/selectors/project.selectors";
import { useUserSelector } from "../../../../store/selectors/authorization.selectors";
import { List } from "semantic-ui-react";
import { UploadProjectDataModal } from "./components/UploadProjectDataModal/UploadProjectDataModal";
import { faMinusOctagon, faPageBreak } from "@fortawesome/pro-light-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RouteNameConstants from "../../../../constants/route-name.constants";
import NotificationAccordion from "./components/NotificationAccordion/NotificationAccordion";
import { NavigationProjectList } from "./components/NavigationProjectList";
import { useActiveProjectSelector } from "../../../../store/selectors/project.selectors";
// import { useTypedSelector } from '../../../../store/selectors/selectors.utils';
// import { TaskType } from "../../../../models/task-type";

const NavigationBarNotificationItem = () => {
  const user = useUserSelector();
  return user ? (
    <div className="navigation-bar-notifications-item">
      <NotificationAccordion />
    </div>
  ) : null;
};
  const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod' 
const NavigationBarContainer = ({ isLarge, setLargeMode }) => {
  const user = useUserSelector();
  const [openUpdateStrategyModal, setOpenUpdateStrategyModal] = useState(false);
  const projects = useProjectListSelector();
  const [largeProjectNameWidth, setLargeProjectNameWidth] = useState(0);
  useEffect(() => {
    setLargeProjectNameWidth(
      projects.reduce((acc, project) => {
        if (project.name.length > acc) {
          return project.name.length;
        }
        return acc;
      }, 0)
    );
  }, []);
  const largeProjectNameTransform = {
    transform: `translateX(${-largeProjectNameWidth * 10}px)`,
    width: largeProjectNameWidth * 10 + "px",
  };

  const archiveProjects = useArchiveProjectListSelector();
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const activeProject = useActiveProjectSelector();

  return (
    <React.Fragment>
      {isLarge && (
        <div
          className="navigation-bar-overlay"
          onClick={() => setLargeMode(false)}
        />
      )}
      <div
        className={classNames("navigation-bar-container", {
          close: !isLarge,
          open: isLarge,
        })}
        style={
          isLarge
            ? { width: largeProjectNameWidth * 10 + "px", minWidth: "300px" }
            : largeProjectNameTransform
        }
      >
        <div
          className="navigation-bar-content"
          style={
            isLarge
              ? { width: largeProjectNameWidth * 10 + "px", minWidth: "300px" }
              : {}
          }
        >
          <div className="navigation-bar-Logo">
            <div className="header-menu-content">
              {/*Burger Button*/}
              <div className="header-menu-button-wrap first">
                {activeProject &&
                Object.keys(activeProject.suspended).length > 0 ? (
                  <div
                    className={classNames("notifications-label", {
                      hide: isLarge,
                    })}
                  >
                    {Object.keys(activeProject.suspended).length}
                  </div>
                ) : null}
                <div
                  className="header-menu-button"
                  onClick={() => setLargeMode(!isLarge)}
                >
                  <div
                    className={classNames("menu-burger", {
                      "times-mode": isLarge,
                    })}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/images/flowledger_logo_v1.png"
              alt="logo of flowledger"
            />
          </div>
          <NavigationBarNotificationItem />
          <List>
            <List.Item>
              <List.Header className="navigation-option">
                <div
                  className={classNames("title", {
                    active:
                      location.pathname ===
                      `/${RouteNameConstants.CHOOSE_PROJECT}`,
                  })}
                  onClick={() => {
                    // @ts-ignore
                    dispatch(ProjectThunk.setActiveProjectId(null));
                    history(`/${RouteNameConstants.CHOOSE_PROJECT}`);
                  }}
                >
                  {/* <List.Icon className='icon-projects' name='settings' /> */}
                  <p>Pull Systems</p>
                </div>
                <NavigationProjectList
                  projects={projects}
                  setOpenUpdateStrategyModal={setOpenUpdateStrategyModal}
                />
              </List.Header>
            </List.Item>

            {archiveProjects.length > 0 ? (
              <List.Item>
                <List.Header className="navigation-option">
                  <div className="title">
                    <p>Archive</p>
                  </div>
                  <NavigationProjectList
                    projects={archiveProjects}
                    setOpenUpdateStrategyModal={setOpenUpdateStrategyModal}
                  />
                </List.Header>
              </List.Item>
            ) : null}

            <List.Item>
             {/* /!* Delete Project Button *!/ */}
             { (isDevEnv || user?.isDeleteProjects)  && (<Link to={`/${RouteNameConstants.DELETE_PROJECT}`}>
                 <div className="navigation-bar-button-wrap">
                   <div className="navigation-bar-button">
                     <div className="navigation-bar-button-content">
                       <FontAwesomeIcon icon={faMinusOctagon} />
                       <span className="navigation-title">Delete Project</span>
                     </div>
                   </div>
                 </div>
               </Link>)

             }
           
             
            </List.Item>
            <List.Item>
                            {/* <Link to={`/${RouteNameConstants.LANDING_PAGE}`}>
                                <div className='navigation-bar-button-wrap'>
                                    <div className="navigation-bar-button">
                                        <div className="navigation-bar-button-content">
                                            <FontAwesomeIcon icon={faPageBreak} />
                                            <span className="navigation-title">Landing Page</span>
                                        </div>
                                    </div>
                                </div>
                            </Link> */}
                        </List.Item>
          </List>
        </div>
      </div>
      <UploadProjectDataModal
        title="Update strategy"
        onClose={() => setOpenUpdateStrategyModal(false)}
        open={openUpdateStrategyModal}
        uploadStrategy
        setUploadStrategyModalOpen={setOpenUpdateStrategyModal}
      />
    </React.Fragment>
  );
};

export default NavigationBarContainer;
