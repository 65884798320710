import TaskModel from "../models/responses/task.model";
import {SearchParametersModel} from "../store/reducers/search";
import TaskStatusModel from "../models/responses/task-status.model";

const filterTaskList = (taskList, searchParams: SearchParametersModel) => {
    const outlist = taskList.filter((task) => {
        if (!searchParams.chipList.length && !searchParams.searchText && !searchParams.taskForces.length && !searchParams.startDate && !searchParams.finishDate && !searchParams.alert) {
            return true;
        }
        let filtering = true;

        if (searchParams.alert) {
            if (task.status === TaskStatusModel.SUSPENDED ||
                task.status === TaskStatusModel.BLOCK ||
                (task.float <= 1) ||
                (task.expiryDate &&
                task.expiryDate.seconds < new Date().getTime() / 1000 &&
                    task.status !== TaskStatusModel.COMPLETE && task.status !== TaskStatusModel.DECLARED_COMPLETE
                )) {
                filtering = true;
            } else {
                filtering = false;
            }
        }

        if (searchParams.chipList.length) {
            filtering = searchParams.chipList.every(
                (chip) =>
                    task.wbs.toLowerCase().includes(chip.text.toLowerCase()) ||
                    task.task_code.toLowerCase().includes(chip.text.toLowerCase()) ||
                    task.wbsPath.toLowerCase().includes(chip.text.toLowerCase()) ||
                    task.task_name.toLowerCase().includes(chip.text.toLowerCase())
            );
        }

        if (searchParams.startDate || searchParams.finishDate) {
            const taskStartDate = task.act_start_date ? task.act_start_date.seconds * 1000 : task.early_start_date.seconds * 1000;
            const taskFinishDate = task.act_end_date ? task.act_end_date.seconds * 1000 :
                task.declaredCompleteTimestamp ? task.declaredCompleteTimestamp.seconds * 1000
                : task.early_end_date.seconds * 1000;
            if (searchParams.startDate) {
                if (searchParams.finishDate) {
                    if (taskStartDate > searchParams.finishDate || taskFinishDate < searchParams.startDate) {
                        filtering = false;
                    }
                } else {
                    if (taskFinishDate < searchParams.startDate) {
                        filtering = false;
                    }
                }
            } else {
                if (taskStartDate > searchParams.finishDate!) {
                    filtering = false;
                }
            }
        }

        if (filtering && searchParams.searchText) {
            filtering =
                task.wbs
                    .toLowerCase()
                    .includes(searchParams.searchText.toLowerCase()) ||
                task.task_code
                    .toLowerCase()
                    .includes(searchParams.searchText.toLowerCase()) ||
                task.wbsPath
                    .toLowerCase()
                    .includes(searchParams.searchText.toLowerCase()) ||
                task.task_name
                    .toLowerCase()
                    .includes(searchParams.searchText.toLowerCase());
        }
        if (filtering && searchParams.taskForces.length) {
            if (task.taskForce.length === 0) {
                return false
            }
            filtering = searchParams.taskForces.some((el) =>
                task.taskForce.includes(el)
            );
        }

        return filtering;
    });
    return outlist;
};

export default filterTaskList;