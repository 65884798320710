import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import ProjectMembersModel from "../../models/responses/project-members.model";
import {ReportItem} from "../../store/store.types";
import {PendingMembershipsModel} from "../../models/pending-memberships.model";

export class MemberSubscriptions {
    static projectPermission(userId: string | null, callback: DefaultSubscribeCallback<ProjectMembersModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.PROJECT_MEMBERS, ['userId', '==', userId])
            .then(data => {
                const members: ProjectMembersModel[] = data.docs.map(el => el.data() as ProjectMembersModel);
                callback(members);
            });
    }

    static memberList(projectId: string, callback: DefaultSubscribeCallback<ProjectMembersModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.PROJECT_MEMBERS, ['projectId', '==', projectId])
            .then(data => {
                callback(data.docs.map(el => el.data()) as ProjectMembersModel[]);
            });
    }

    static pendingMembers(projectId: string, callback: DefaultSubscribeCallback<PendingMembershipsModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.PENDING_MEMBERSHIPS, ['projectId', '==', projectId])
            .then(data => {
                callback(data.docs.map(el => el.data() as PendingMembershipsModel));
            });
    }

    static reportList(projectId: string, callback: DefaultSubscribeCallback<ReportItem[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.REPORTS, ['projectId', '==', projectId])
            .then(data => {
                callback(
                    data.docs.map(el => ({
                        // @ts-ignore
                        uuid: el.id, ...el.data()
                    })) as ReportItem[]
                );
            });
    }
}
