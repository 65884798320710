import {
    Button, Dimmer, Dropdown,
    FormCheckbox, Loader,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {BaselineModel} from "../../../../../../models/responses/baseline.model";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../../../firebase/constants";
import FirebaseProjectFunctions from "../../../../../../firebase/firebase-functions/project-service";


export default function ManageBaselineModal({
    activeProject,
    manageBaselines,
    setManageBaselines
}) {

    const [baselines, setBaselines] = useState<BaselineModel[]>([])
    const [status, setStatus] = useState<string>('')
    const [roles, setRoles] = useState<any[]>([
        {role: 'Project', baselineId: null, text: 'Project', value: 'Project', key: 'Project', disabled: false},
        {role: 'Primary', baselineId: null, text: 'Primary', value: 'Primary', key: 'Primary', disabled: false},
        {role: 'Secondary', baselineId: null, text: 'Secondary', value: 'Secondary', key: 'Secondary', disabled: false},
    ])

    useEffect(() => {
        if (status === '') {
            FirebaseUsage.getQuery(COLLECTIONS.BASELINES, ['relatesToProject', '==', activeProject.projectId]).then((data) => {
                setBaselines(
                    data.docs.map((doc) => doc.data() as BaselineModel).filter(baseline => !baseline.deleted)
                )
                setRoles(prevState => {
                    let output = [...prevState]
                    data.docs.forEach(doc => {
                        const baseline = doc.data() as BaselineModel
                        if (baseline.role) {
                            output = output.map(role => {
                                if (role.role === baseline.role) {
                                    role.baselineId = baseline.baselineId
                                    role.disabled = true
                                }
                                return role
                            })
                        }
                    })
                    return output
                })
            })
        }
    }, [status, activeProject, manageBaselines]);

    const deleteBaseline = (baseline: BaselineModel) => {
        FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject.projectId, {
            baselines: activeProject.baselines.filter(bl => bl.baselineId !== baseline.baselineId)
        }).catch(e => console.error(e))
        return FirebaseUsage.updateDoc(COLLECTIONS.BASELINES, baseline.baselineId, {deleted: true, role: null})
    }

    const assignCurrent = async (baseline: BaselineModel) => {
        if (!baseline.isCurrent) {
            return FirebaseProjectFunctions.makeBaselineCurrent(baseline.baselineId)
        }
    }

    return (
        <Modal closeIcon onClose={() => {
            setManageBaselines(false)
            setStatus('')
        }} open={manageBaselines}>
            <Modal.Header>Manage project baselines</Modal.Header>
            <Modal.Content className="milestone-modal-content">
                <div className="milestone-modal-table-wrapper"
                     style={{maxHeight: "600px", overflow: "auto", minHeight: "400px"}}
                >
                    {status === 'loadingCurrent' ?
                        <Dimmer active inverted>
                            <Loader inverted>Adding current</Loader>
                        </Dimmer> : status === 'loadingDelete' ?
                            <Dimmer active inverted>
                                <Loader inverted>Deleting</Loader>
                            </Dimmer> : null}
                    <Table className="milestone-modal-table">
                        <TableHeader className="milestone-modal-table-header">
                            <TableRow>
                                <TableHeaderCell width={2}>Current</TableHeaderCell>
                                <TableHeaderCell>Baseline Name</TableHeaderCell>
                                <TableHeaderCell textAlign="center" width={4}>Matched Activities</TableHeaderCell>
                                <TableHeaderCell textAlign="center" width={2}>Role</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {baselines.map((baseline: BaselineModel) => (
                                <TableRow key={baseline.baselineId}>
                                    <TableCell>
                                        <span style={{display: "flex", flexDirection: "row"}}>
                                          <FormCheckbox
                                              checked={baseline.isCurrent}
                                              onClick={() => {
                                                  setStatus('loadingCurrent')
                                                  assignCurrent(baseline).then(() => setStatus(''))
                                              }
                                          }
                                          />
                                            &nbsp;
                                            <FontAwesomeIcon
                                                icon={faTrashCan} size={ "lg" }
                                                className={'delete-icon'}
                                                onClick={() => {
                                                    setStatus('loadingDelete')
                                                    deleteBaseline(baseline).then(() => setStatus(''))
                                                }}
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {baseline.baselineShortName}&nbsp;-&nbsp;{baseline.baselineName}
                                    </TableCell>
                                    <TableCell textAlign="center" >
                                        {`${baseline.matchingActivities} / ${activeProject.totalTaskCount}`}
                                    </TableCell>
                                    <TableCell textAlign={"center"}>
                                        <Dropdown
                                            className="baseline-role-dropdown"
                                            clearable
                                            options={roles}
                                            selection
                                            onChange={(e, data) => {
                                                setRoles(prevState => {
                                                    return prevState.map(role => {
                                                        if (role.role === data.value) {
                                                            role.baselineId = baseline.baselineId
                                                            role.disabled = true
                                                        }
                                                        return role
                                                    })
                                                })
                                                FirebaseUsage.updateDoc(COLLECTIONS.BASELINES, baseline.baselineId, {role: data.value})
                                                    .catch(e => console.error(e))
                                            }}
                                            value={baseline.role ? baseline.role : undefined}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button active
                        onClick={() => {
                            setManageBaselines(false)
                            setStatus('')
                        }
                }
                >Close</Button>
            </Modal.Actions>
        </Modal>
    )
}