import React from "react";
import {Popup} from "semantic-ui-react";

export default function MetricsBox(props: any) {
    const {title, total, percentage, green, red, type, delta, description, setHoverMetric, hoverMetric} = props

    const colours = {
        green: 'rgba(15, 157, 88, 0.80)',
        red: 'rgba(217,0,27,0.80)',
        amber: 'rgba(244,180,0,0.80)'
    }

    const hoverColours = {
        green: '#0f9d58',
        red: '#d9001b',
        amber: '#f4b400'
    }

    const fontColours = {
        green: '#ffffff',
        red: '#ffffff',
        amber: '#000000'
    }

    const colourPicker = () => {
        if (type === 'task' || type === 'link') {
            if (percentage <= green) {
                return 'green'
            } else if (percentage > red) {
                return 'red'
            } else {
                return 'amber'
            }
        } else if (type === 'metric') {
            if (percentage <= green) {
                return 'green'
            } else if (percentage > red) {
                return 'red'
            } else {
                return 'amber'
            }
        } else if (type === 'index') {
            if (percentage >= green) {
                return 'green'
            } else if (percentage < red) {
                return 'red'
            } else {
                return 'amber'
            }
        } else {
            if (Math.abs(percentage) <= green) {
                return 'green'
            } else if (Math.abs(percentage) > red) {
                return 'red'
            } else {
                return 'amber'
            }
        }
    }

    const colour = colourPicker()
    const backgroundColour = colours[colour]
    const fontColour = fontColours[colour]

    const formatDelta = (delta: number) => {
        if (delta > 0) {
            return `+${delta}`
        } else {
            return delta
        }
    }

    return (
        <Popup content={description} trigger={
            <div className={'metrics-box-container'} style={
                hoverMetric === title ? {backgroundColor: hoverColours[colour], color: fontColours[colour]} :
                {backgroundColor: backgroundColour, color: fontColour}}
                onMouseEnter={() => setHoverMetric(title)}
                onMouseLeave={() => setHoverMetric('')}
            >
                <div className={'metrics-description'}>
                    <p className="metrics-description-text"
                       style={hoverMetric === title ? {fontSize: "11px", fontWeight: "bold", paddingLeft: "5px", paddingRight: "5px"} :
                        {fontSize: "11px", fontWeight: "bold", paddingLeft: "5px", paddingRight: "5px"}}>{title}</p>
                </div>
                <p style={{fontWeight: "bold"}}>{type === 'delta' ? formatDelta(delta) : total}</p>
                {type === 'metric' || type === 'index' ? null :
                <p>{`(${((percentage) * 100).toFixed(2)}%)`}</p>}
            </div>}
        />
    )

}