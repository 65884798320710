import React, { useState, useCallback } from 'react';
import { Header, Button } from 'semantic-ui-react';
import { useUserSelector } from '../../../../store/selectors/authorization.selectors';
import { UploadProjectDataModal } from '../../components/NavigationBarContainer/components/UploadProjectDataModal/UploadProjectDataModal';
import styles from './ChooseProjectPage.module.scss';

const ChooseProjectPage = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useUserSelector();
  const handleOpen = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.preventDefault();
      setOpen(true);
    },
    []
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={styles.ChooseProject}>
      <Header as="h3">
        Hi there, please select a pull system from the sub-menu or...
      </Header>
      <Button disabled={currentUser?.enableSA ? !currentUser?.enableSA : true} onClick={handleOpen} className={styles.BtnCreateProject}>
            CREATE NEW
      </Button>
        <UploadProjectDataModal
        title="Create new pull system"
        onClose={handleClose}
        open={open}
        setUploadStrategyModalOpen={setOpen}
      />
    </div>
  );
};

export default ChooseProjectPage;
