import React, {useMemo} from 'react';
import {TaskListSection} from "../FlowPage/components/TaskListSection/TaskListSection";
import {useActiveProjectQualitySelector} from "../../../../store/selectors/project.selectors";
import MetricsBox from "./components/MetricsBox";
import {Popup} from "semantic-ui-react";
export default function QualityPage() {

    const qualityData = useActiveProjectQualitySelector()
    const [hoverMetric, setHoverMetric] = React.useState<string>("")

    // DCMA 14 Point Assessment

    // Merge Hotspot

    // Logic Density

    //
    const totalIncompleteTasks = qualityData.totalTasks - qualityData.totalCompletedTasks
    const totalIncompleteLinks = qualityData.totalLinks - qualityData.totalCompletedLinks

    let metrics = {
        [`${qualityData.totalInProgressTasks >= qualityData.targetFronts ?  "Running Hot" : "Running Cold"}`]: {axis: 'execution', green: 0.1, red: 0.3, type: 'delta', score: qualityData.inefficiency.toFixed(2), weight: 50, delta: qualityData.totalInProgressTasks - qualityData.targetFronts,
            description: `${qualityData.totalInProgressTasks} tasks are in progress, ${qualityData.targetFronts} is the optimal level to deliver the project as efficiently as possible.`},
        "Missing Predecessors": {records: qualityData.missingPredecessors, green: 0.01, red: 0.05, type: 'task', score: qualityData.missingPredecessors.length / totalIncompleteTasks, weight: 20,
            description: "A task that has no predecessor. This can cause a task to start before it should, or not start at all.",},
        "Missing Successors": {records: qualityData.missingSuccessors, green: 0.01, red: 0.05, type: 'task', score: qualityData.missingSuccessors.length / totalIncompleteTasks, weight: 100,
            description: "A task that has no successor. This can cause a task to finish before it should, or not finish at all."},
        "Leads": {records: qualityData.leads, green: 0.01, red: 0.05, type: 'link', score: qualityData.leads.length / totalIncompleteLinks, weight: 60,
            description: "A lead is a relationship where the successor task starts before the predecessor task finishes. This is bad practice as it cannot be risk-modelled accurately and does not show the true relationship between tasks."},
        "Lags": {records: qualityData.lags, green: 0.01, red: 0.05, type: 'link', score: qualityData.lags.length / totalIncompleteLinks, weight: 20,
            description: "A lag is a relationship where there is an artificial buffer between the predecessor and the successor task. This is bad practice as it cannot be risk-modelled accurately and does not show the true relationship between tasks."},
        "Invalid Dates": {records: qualityData.invalidDates, green: 0.00, red: 0.00, type: 'task', score: qualityData.invalidDates.length / totalIncompleteTasks, weight: 10,
            description: "A task that has forecast dates before today or actual dates after today. This is indicative of inaccurate progress reporting or forecasting."},
        "Hard Constraints": {records: qualityData.hardConstraints, green: 0.01, red: 0.05, type: 'task', score: qualityData.hardConstraints.length / totalIncompleteTasks, weight: 80,
            description: "A task that has a hard constraint, preventing it from updating correctly with the rest of the schedule. Also has potentially unintended effects on the critical path."},
        "Start to Finish Relationships": {records: qualityData.SF, green: 0.00, red: 0.00, type: 'link', score: qualityData.SF.length / totalIncompleteLinks, weight: 70
            , description: "A relationship where the successor task cannot finish until the predecessor task starts. This is bad practice as it cannot be risk-modelled accurately and breaks the deterministic cause-effect relationship type that the Critical Path Method depends upon."},
        "Finish to Finish Relationships": {records: qualityData.FF, green: 0.05, red: 0.10, type: 'link', score: qualityData.FF.length / totalIncompleteLinks, weight: 10,
            description: "A relationship where the successor task cannot finish until the predecessor task finishes. This is bad practice as it cannot be risk-modelled accurately and breaks the deterministic cause-effect relationship type that the Critical Path Method depends upon."},
        "Start to Start Relationships": {records: qualityData.SS, green: 0.05, red: 0.10, type: 'link', score: qualityData.SS.length / totalIncompleteLinks, weight: 10,
            description: "A relationship where the successor task cannot start until the predecessor task starts. This is bad practice as it cannot be risk-modelled accurately and breaks the deterministic cause-effect relationship type that the Critical Path Method depends upon."},
        "High Float": {records: qualityData.highFloat, green: 0.05, red: 0.10, type: 'task', score: qualityData.highFloat.length / totalIncompleteTasks, weight: 50
            , description: "A task that has a float greater than 44 days. While this is not necessarily indicative of a problem, it is worth investigating as tasks could be missing the correct successors."},
        "High Duration": {records: qualityData.highDuration, green: 0.10, red: 0.20, type: 'task', score: qualityData.highDuration.length / totalIncompleteTasks, weight: 50
            , description: "A task that has a duration greater than 44 days. Tasks with longer durations are indicative of a less mature construction schedule. Granularity is important for accurate forecasting/clash detection."},
        "Missed Activities": {records: qualityData.missedActivities, green: 0.05, red: 0.10, type: 'task', score: qualityData.missedActivities.length / totalIncompleteTasks, weight: 80
            , description: "An incomplete task that has a baseline completion date before today. If missed tasks accumulate, they will result in delays to key dates as the volume of incomplete work becomes unmanageable within the remaining time."},
        "High Logic Density": {records: qualityData.mergeHotspot, green: 0.05, red: 0.10, type: 'task', score: qualityData.mergeHotspot.length / totalIncompleteTasks, weight: 50,
            description: "A task that has more than 2 predecessors. This is indicative of a merge hotspot, where many tasks are converging on a single task. This can cause bottlenecks and delays to the project."},
        "Average Logic Density": {green: 1, red: 2, type: 'metric', score: qualityData.logicDensity, weight: 40,
            description: "The average number of predecessor links per task. The ideal number of incoming links to a task is 1. A highly co-dependent network can make it difficult to model and mitigate delays."},
        "Out Of Sequence Activities": {records: qualityData.outOfSequence, green: 0.05, red: 0.10, type: 'task', score: qualityData.outOfSequence.length / qualityData.totalTasks, weight: 30,
            description: "A task that has started or finished before its predecessor conditions have been met. This is indicative of inaccurate forecasting or progress reporting."},
        "Baseline Execution Index": {green: 0.95, red: 0.80, type: 'index', score: qualityData.baselineExecutionIndex ? qualityData.baselineExecutionIndex : 1, weight: 40,
            description: "The ratio of completed tasks to tasks that should have been completed by today. A score of 1 indicates that all tasks have been completed on time."},
    }

    const metricsScore = () => {
        let score = 0
        let maxScore = 0
        const executionPhaseIndex = 1 - (Math.abs((qualityData.totalCompletedTasks / qualityData.totalTasks) - 0.5) / 0.5)
        console.log(executionPhaseIndex)
        let output: any[] = []
        for (let key in metrics) {
            const effectiveWeight = metrics[key].axis ? metrics[key].axis === 'execution' ? metrics[key].weight * executionPhaseIndex : metrics[key].weight : metrics[key].weight
            maxScore += effectiveWeight
            if (metrics[key].type === 'index') {
                if (metrics[key].score > metrics[key].red) {
                    score += ((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight
                    output.push({key, score: effectiveWeight - (((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight)})
                } else {
                    score -= ((metrics[key].score - metrics[key].red) / metrics[key].red) * effectiveWeight
                    output.push({key, score: effectiveWeight + (((metrics[key].score - metrics[key].red) / metrics[key].red) * effectiveWeight)})
                }
            } else if (metrics[key].score <= 0) {
                score += effectiveWeight
                output.push({key, score: 0})
            } else if (metrics[key].red > 0) {
                if (metrics[key].score <= metrics[key].red) {
                    score += (1 - (metrics[key].score / metrics[key].red)) * effectiveWeight
                    output.push({key, score: effectiveWeight - ((1 - (metrics[key].score / metrics[key].red)) * effectiveWeight)})
                } else {
                    score -= ((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight
                    output.push({key, score: effectiveWeight + (((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight)})
                }
            } else {
                score -= ((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight
                output.push({key, score: effectiveWeight + (((metrics[key].score - metrics[key].red) / (1 - metrics[key].red)) * effectiveWeight)})
            }
        }
        output = output.sort((a, b) => b.score - a.score)

        return {score: Math.max(score / maxScore, 0), output, maxScore}
    }

    const overall = useMemo(() => {
        return metricsScore()
    }, [])

    const overallScore = overall.score
    const scoresSorted = overall.output
    const maxScore = overall.maxScore

    const metricsGauge = scoresSorted.map((score, index) => {
         return (score.score === 0 ? null :
             <Popup content={`${score.key}: ${((score.score / maxScore) * 100).toFixed(2)}`} trigger={
             <div className={'metrics-gauge-component-red'}
                  onMouseEnter={() => setHoverMetric(score.key)}
                  onMouseLeave={() => setHoverMetric('')}
                  style={hoverMetric === score.key ? {backgroundColor: "#d9001b", minWidth: `${(score.score / maxScore) * 100}%`, maxWidth: `${(score.score / maxScore) * 100}%`} :
                      {minWidth: `${(score.score / maxScore) * 100}%`, maxWidth: `${(score.score / maxScore) * 100}%`}
             }></div>}/>
         )
        }
    )

    const metricsBoxes = () => {
        let metricsDom: any = []
        for (let key in metrics) {
            metricsDom.push(
                <MetricsBox
                    key={key}
                    title={key}
                    total={metrics[key].records ? metrics[key].records.length : metrics[key].score}
                    percentage={metrics[key].score}
                    green={metrics[key].green}
                    red={metrics[key].red}
                    type={metrics[key].type}
                    delta={metrics[key].delta ? metrics[key].delta : null}
                    description={metrics[key].description}
                    setHoverMetric={setHoverMetric}
                    hoverMetric={hoverMetric}
                />
            )
        }
        return metricsDom
    }

    const metricsBoxOut = metricsBoxes()

    const metricsOutput = () => {
        let metricsDom: any = []
        for (let key in metrics) {
            if (!metrics[key].records) continue
            if (metrics[key].records.length === 0) continue
            metricsDom.push(
                <TaskListSection
                    key={key}
                    title={key}
                    headline={key}
                    text={key}
                    taskList={metrics[key].records}
                    isLoading={false}
                    moreTasks={metrics[key].records.length > 10}
                    view={"future"}
                    setView={() => {console.log("doing this view set")}}
                    total={qualityData.totalTasks}
                    quality={true}
                    type={metrics[key].type}
                    open={false}
                    setOpen={() => {console.log("doing this open set")}}
                />
                // <p>{key}</p>
            )
        }
        return metricsDom
    }

    const metricsOut = metricsOutput()

    return (
        <div className="lazy-load-container">
            <div className={'metrics-top-line'}>
                <div className={'metrics-overall-div'}>
                    <p style={{fontWeight: "bold", marginLeft: "7px", marginRight: "7px"}}>FlowLedger Score</p>
                    <p className={'metrics-score'}
                       style={overallScore > 0.8 ? {
                            backgroundColor: "#0f9d58",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "18px"} : overallScore > 0.6 ? {
                            backgroundColor: "#f4b400",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "18px"} : {
                            backgroundColor: "#d9001b",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "18px"
                           }}
                    >{(overallScore * 100).toFixed(2)}</p>
                </div>
                <div className={"metrics-cluster"}>
                    {metricsBoxOut}
                </div>
            </div>
            <Popup content={`Overall FlowLedger score: ${(overallScore * 100).toFixed(2)}`}
                trigger={
            <div className={'metrics-gauge'}>
                {metricsGauge}
            </div>}>
            </Popup>
            {metricsOut}
        </div>
    );
}