import FirebaseUsage from "../firebase.usage";
import { COLLECTIONS } from "../constants";
import { DefaultSubscribeCallback } from "../../store/middleware/middlewares";
import TaskModel from "../../models/responses/task.model";
import store from "../../store/store";
import TaskStatusModel from "../../models/responses/task-status.model";
import { TaskListSectionModel } from "../../models/task-list-section.model";
import { query, orderBy } from "firebase/firestore";
import moment from "moment";
import { CalendarModel } from "../../models/responses/calendar.model";
import {CpmTaskModel} from "../../models/responses/cpm-task.model";
import {convertDateToIndex} from "../../utils/cpm-functions/cpm-app/functions/handleEvent";
import * as projectActions from "../../store/actions/project.actions";


const updateCPMMap = (cpmMap: any, taskList: TaskModel[], projectCalendars: any) => {
  // let outCPMMap: Map<string, any> | null = cpmMap.size > 0 ? new Map(cpmMap) : null
  if (cpmMap.size === 0) return
  taskList.forEach((task: TaskModel) => {
    const taskData: CpmTaskModel = cpmMap.get(task.task_id)
    if (taskData) {
      const cpmTask: CpmTaskModel = {
        ...taskData,
        es: task.early_start_date ? convertDateToIndex(task.early_start_date.seconds, taskData.cal_id, projectCalendars) : taskData.es,
        ef: task.early_end_date ? convertDateToIndex(task.early_end_date.seconds, taskData.cal_id, projectCalendars) : taskData.ef,
        ls: task.late_start_date ? convertDateToIndex(task.late_start_date.seconds, taskData.cal_id, projectCalendars) : taskData.ls,
        lf: task.late_end_date ? convertDateToIndex(task.late_end_date.seconds, taskData.cal_id, projectCalendars) : taskData.lf,
        status_code: task.status,
        predStatus: task.predStatus,
      }
      cpmMap.set(task.task_id, cpmTask)
    }
  })
  // store.dispatch(projectActions.Actions.setCpmMap(outCPMMap))
}

export const generateTaskFromSnapshot = (data: any) => {
  const changes = data.docChanges();
  let changeList : any[] = []
  changes.forEach((change: any) => {
      changeList.push(change.doc.data())
  })
  updateCPMMap(store.getState().project.cpmMap, changeList, store.getState().project.activeProjectCalendars)

  return data.docs.map((doc: any) => {
    const d = doc.data();
    return {
      ...d,
      status: d.status.toLowerCase(),
      docRef: doc.ref
    } as TaskModel;
  });
};

export interface TaskListSubscriptionParameters {
  project_id: string;
  taskListType?: TaskListSectionModel;
  statuses?: Array<TaskStatusModel | string>;
  taskForces: Array<string>;
  page: number;
  flow?: boolean;
  alert: boolean;
  searchValue?: string;
}

export interface RelatedTasksSubscriptionParameters {
  project_id: string;
  taskId: string;
}

export class TaskSubscriptions {
  static getTaskListRef(
    params: Pick<
      TaskListSubscriptionParameters,
      "project_id" | "page" | "taskListType" | "statuses"
    >
  ) {
    if (params.statuses && params.statuses!.length > 0) {
      params.statuses = params.statuses!.filter(
        (stat) => stat !== TaskStatusModel.RETIRED
      );
    } else {
      params.statuses = Object.values(TaskStatusModel).filter(
        (stat) => stat !== TaskStatusModel.RETIRED
      );
    }
    if (params.taskListType) {
      if (params.statuses && params.statuses.length > 0) {
        return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
          ["projectId", "==", params.project_id],
          ["taskListType", "==", params.taskListType],
          ["status", "in", params.statuses],
        ]);
      }
      return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
        ["projectId", "==", params.project_id],
        ["taskListType", "==", params.taskListType],
      ]);
    } else {
      return FirebaseUsage.getCompoundQueryAsQuery(COLLECTIONS.TASKS, [
        ["projectId", "==", params.project_id],
        ["status", "in", params.statuses],
      ]);
    }
  }

  static async taskList(
    params: TaskListSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    let qRef = await TaskSubscriptions.getTaskListRef(params);
    if (params.taskListType === TaskListSectionModel.CONFIRMED_COMPLETE) {
      qRef = query(qRef, orderBy("act_end_date", "desc"));
    } else if (params.taskListType === TaskListSectionModel.DECLARED_COMPLETE) {
      qRef = query(qRef, orderBy("declaredCompleteTimestamp", "desc"));
    } else if (params.taskListType === TaskListSectionModel.WORK_IN_PROCESS) {
      qRef = query(qRef, orderBy("late_start_date"));
    } else if (params.taskListType === TaskListSectionModel.QUEUED) {
      qRef = query(qRef, orderBy("forecastDate"));
    } else if (params.taskListType === TaskListSectionModel.PENDING) {
        qRef = query(qRef, orderBy("taskCreatedTimestamp"));
    }

    return await FirebaseUsage.queryListenerRef(
      qRef,
      (data: any) => callback(generateTaskFromSnapshot(data))
    );
  }

  static successorsList(
    params: RelatedTasksSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    const relationshipsList = store.getState().relationships.relationshipsList;
    const taskIdsQuery = relationshipsList
      .filter((relation) => relation.pred_task_id === params.taskId)
      .map((relation) => relation.task_id);

    if (!Boolean(taskIdsQuery.length)) {
      return () => callback([]);
    }

    return FirebaseUsage.getCompoundQuery(COLLECTIONS.TASKS, [
      ["projectId", "==", params.project_id],
      ["task_id", "in", taskIdsQuery],
    ]).then((data) => callback(generateTaskFromSnapshot(data)));
  }

  static predecessorsList(
    params: RelatedTasksSubscriptionParameters,
    callback: DefaultSubscribeCallback<TaskModel[]>
  ) {
    const relationshipsList = store.getState().relationships.relationshipsList;
    const taskIdsQuery = relationshipsList
      .filter((relation) => relation.task_id === params.taskId)
      .map((relation) => relation.pred_task_id);

    if (!Boolean(taskIdsQuery.length)) {
      return () => callback([]);
    }
    return FirebaseUsage.getCompoundQuery(COLLECTIONS.TASKS, [
      ["projectId", "==", params.project_id],
      ["task_id", "in", taskIdsQuery],
    ]).then((data) => callback(generateTaskFromSnapshot(data)));
  }
}
