import TaskModel from "../models/responses/task.model";
import FirebaseUsage from "../firebase/firebase.usage";
import {COLLECTIONS} from "../firebase/constants";
import store from "../store/store";
import TrackedMilestoneModel from "../models/responses/tracked-milestone.model";
import {Actions} from "../store/actions/project.actions";

export async function addNewTrackedMilestone(
    taskIds: any [],
    visibleToAll: boolean,
    projectId: string,
    dispatch
) {
    const user = store.getState().authorization.user;
    const existingTrackedMilestones = store.getState().project.trackedMilestones
    let newTrackedMilestones: TrackedMilestoneModel[] = []
    for (const taskId of taskIds) {
        const task = await FirebaseUsage.getDoc(COLLECTIONS.TASKS, taskId).then(doc => doc.data()) as TaskModel;
        console.log(task)
        const trackedMilestoneDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.TRACKED_MILESTONES);
        const trackedMilestoneData = {
            milestoneId: trackedMilestoneDoc.id,
            projectId: projectId,
            taskId: task.task_id,
            taskCode: task.task_code,
            userId: user!.userId!,
            visibleToAll: visibleToAll,
            constraintType: "CS_MANADD",
            constraintDate: task.constraint_date,
            baselineDate: task.early_end_date,
            milestoneName: task.task_name,
            wbsName: task.wbs,
            hidden: false,
        }
        newTrackedMilestones.push(trackedMilestoneData)
        await FirebaseUsage.setDocumentWithDoc(trackedMilestoneDoc, trackedMilestoneData).catch(err => console.error(err));
    }
    const setTackedMilestone = async () => dispatch(Actions.setTrackedMilestones(newTrackedMilestones.concat(existingTrackedMilestones)))
    setTackedMilestone().catch(err => console.log(err))
}


