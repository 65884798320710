import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import {WbsModel} from "../../models/responses/wbs.model";

export class WBSSubscriptions {
    static wbsList(project_id: string, callback: DefaultSubscribeCallback<WbsModel[]>) {
        return FirebaseUsage.getQuery(COLLECTIONS.WORK_BREAKDOWN_STRUCTURE, ['projectId', '==', project_id])
            .then(data => {
                callback(data.docs.map(d => ({
                    ...d.data(), expanded: d.data().level === 0
                } as WbsModel)));
            });
    }
}
