import React, { useCallback } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export const NavBarLeaveProjectModal = (props: {opened: boolean, setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    leaveProject: () => void}) => {
    const {opened, setOpened, leaveProject} = props;

    const leaveProjectHandler = useCallback(() => {
        setOpened(false);
        leaveProject();
    }, [leaveProject, setOpened])

    return (
        <Modal 
            className='modal-leave-project'
            size="mini"
            basic
            open={opened}
            onClose={() => setOpened(false)}
        >
            <Modal.Header className="modal-leave-project-header">Leave Project?</Modal.Header>
            <Modal.Actions>
                <Button inverted color='red' content="No" onClick={() => setOpened(false)} />
                <Button inverted color='green' content="Yes" onClick={leaveProjectHandler} />
            </Modal.Actions>
        </Modal>
    );
}