import React from "react";
import {Modal, Button, Input, Dropdown, Form, FormField, FormGroup} from "semantic-ui-react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {CalendarModel} from "../../../../../models/responses/calendar.model";
import {
    useInProgressTaskListSelector, usePendingTaskListSelector,
    useQueuedTaskListSelector
} from "../../../../../store/selectors/task/task.selectors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addNewTask from "../../../../../utils/addNewTask";
import generateWBSPath from "../../../../../utils/cpm-functions/set-cpm-map/functions/generateWbsPath";
import {useUserSelector} from "../../../../../store/selectors/authorization.selectors";

export default function NewTaskModal(props: any) {
    const [predecessors, setPredecessors]: any[] = React.useState([])
    const [successors, setSuccessors]: any[] = React.useState([])
    const [wbsHeading, setWbsHeading]: any[] = React.useState([])
    const calendars = useTypedSelector((state) => state.project.activeProject!.calendars)
    const inProgressTasks = useInProgressTaskListSelector()
    const queuedTasks = useQueuedTaskListSelector()
    const pendingTasks = usePendingTaskListSelector()
    const wbsList = useTypedSelector((state) => state.wbs.wbsList)
    const [constraint, setConstraint]: any = React.useState(null)
    const [constraintDate, setConstraintDate]: any = React.useState(null)
    const [calendarId, setCalendarId]: any = React.useState(null)
    const [calendar, setCalendar]: any = React.useState(null)
    const [taskType, setTaskType]: any = React.useState(null)
    const [taskName, setTaskName]: any = React.useState(null)
    const [taskCode, setTaskCode]: any = React.useState(null)
    const [taskDuration, setTaskDuration]: any = React.useState(null)
    const [missing, setMissing]: any = React.useState([])
    const [isValid, setIsValid]: any = React.useState(true)
    const user = useUserSelector()
    const calendarDict = useTypedSelector((state) => state.project.activeProject!.calendarDict)


    const constraintsArray = [
        {key: "CS_ALAP", text: "As Late as Possible", value: "CS_ALAP"},
        {key: "CS_MEOB", text: "Finish On or Before", value: "CS_MEOB"},
        {key: "CS_MEOA", text: "Finish On or After", value: "CS_MEOA"},
        {key: "CS_MSOB", text: "Start On or Before", value: "CS_MSOB"},
        {key: "CS_MSOA", text: "Start On or After", value: "CS_MSOA"},
        {key: "CS_MSO", text: "Must Start On", value: "CS_MSO"},
        {key: "CS_MEO", text: "Must Finish On", value: "CS_MEO"},
    ]

    const taskTypes = [
        {key: "TT_Task", text: "Normal Task", value: "TT_Task"},
        {key: "TT_FinMile", text: "Finish Milestone", value: "TT_FinMile"},
        {key: "TT_Mile", text: "Start Milestone", value: "TT_Mile"},
    ]

    const {
        setNewTaskModalOpen,
        newTaskModalOpen,
        projectId
    } = props.props

    return (
        <Modal open={newTaskModalOpen}>
            <Modal.Header>Create a new task</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormField
                        error={missing.includes("Task Name") && !taskName}
                    >
                        <label>Task Name</label>
                        <Input
                            style={{width: "100%"}}
                            placeholder="Type Task Name"
                            error={missing.includes("Task Name") && !taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                        />
                    </FormField>
                    <FormGroup>
                        <FormField style={{minWidth: "50%"}} error={missing.includes("Task Code") && !taskCode}>
                            <label>Task ID</label>
                            <Input placeholder="Type Task ID"
                                   error={missing.includes("Task Code") && !taskCode}
                                   onChange={(e) => setTaskCode(e.target.value)}
                            />
                        </FormField>
                        <FormField style={{minWidth: "50%"}} error={missing.includes("Task Type") && !taskType}
                        >
                            <label>Task Type</label>
                            <Dropdown
                                clearable
                                fluid
                                error={missing.includes("Task Type") && !taskType}
                                search
                                selection
                                options={taskTypes}
                                placeholder="Select Task Type"
                                onChange={(e, data) => {
                                    e.preventDefault()
                                    setTaskType(data.value)
                                }}
                            />
                        </FormField>
                    </FormGroup>
                    <FormField error={missing.includes("Working Calendar") && !calendarId}>
                        <label>Working Calendar</label>
                        <Dropdown
                            clearable
                            fluid
                            search
                            error={missing.includes("Working Calendar") && !calendarId}
                            selection
                            options={calendars
                                .map((el: CalendarModel) => ({
                                    key: el.calendar_id,
                                    text: el.calendar_name,
                                    value: el.calendar_id
                                }))}
                            placeholder="Select Working Calendar"
                            onChange={(e, data) => {
                                e.preventDefault()
                                setCalendarId(data.value)
                                setCalendar(calendars.find((el) => el.calendar_id === data.value))
                            }}
                        />
                    </FormField>
                    <FormField error={missing.includes("Task Duration") && !taskDuration}>
                        <label>Task Duration (working days)</label>
                        <Input type={"number"}
                               error={missing.includes("Task Duration") && !taskDuration}
                               placeholder="Task Duration"
                               onChange={(e) => setTaskDuration(e.target.value)}
                        />
                    </FormField>
                    <FormField error={missing.includes("WBS Heading") && !wbsHeading}>
                        <label>WBS Heading</label>
                        <Dropdown
                            clearable
                            fluid
                            search
                            selection
                            error={missing.includes("WBS Heading") && !wbsHeading}
                            options={wbsList
                                .map((el) => {
                                    if (el.wbs_code)
                                    return {
                                        key: el.wbs_id, text: `${el.wbs_code.replace(/\./g, " . ")}  -  ${el.wbs_name}`, value: el.wbs_id
                                    }
                                    return {
                                        key: el.wbs_id, text: el.wbs_name, value: el.wbs_id
                                    }
                                })}
                            placeholder="WBS Heading"
                            onChange={(e, data) => {
                                e.preventDefault()
                                setWbsHeading(data.value)
                            }}
                        />
                    </FormField>
                    <FormField>
                        <label>Predecessors</label>
                        <Dropdown
                            clearable
                            fluid
                            multiple
                            search
                            selection
                            onLabelClick={(e, data) => {
                                e.preventDefault()
                                console.log(data)
                            }}
                            options={inProgressTasks
                                .concat(queuedTasks).concat(pendingTasks)
                                .map((el) => ({key: el.task_id, text: `${el.task_code} - ${el.task_name}`, value: el.task_id}))
                                .filter((el) => !successors.includes(el.value))
                            }
                            placeholder="Select predecessors"
                            onChange={(e, data) => {
                                e.preventDefault()
                                setPredecessors(data.value)
                            }}
                        />
                    </FormField>
                    <FormField>
                        <label>Successors</label>
                        <Dropdown
                            clearable
                            fluid
                            multiple
                            search
                            selection
                            options={inProgressTasks
                                .concat(queuedTasks).concat(pendingTasks)
                                .map((el) => ({
                                    key: el.task_id,
                                    text: `${el.task_code} - ${el.task_name}`,
                                    value: el.task_id
                                }) as any)
                                .filter((el) => !predecessors.includes(el.value))
                            }
                            placeholder="Select successors"
                            onChange={(e, data) => {
                                e.preventDefault()
                                setSuccessors(data.value)
                            }}
                        />
                    </FormField>
                    <span style={{display: "flex", flexDirection: "row", width: "100%"}}>
                        <FormField style={{width: "70%"}}>
                            <label>Constraint (optional)</label>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={constraintsArray}
                                placeholder="Optional constraint"
                                onChange={(e, data) => {
                                    e.preventDefault()
                                    setConstraint(data.value)
                                    setConstraintDate(data.value && data.value !== "CS_ALAP" ? new Date() : null)
                                }}
                            />
                        </FormField>
                        <FormField style={{width: "30%", display: "flex", flexDirection: "column"}}>
                            <label style={{display: "flex", flexDirection: "column"}}>
                                Constraint date
                            </label>
                            <DatePicker selected={constraintDate}
                                        disabled={!constraint || constraint === "CS_ALAP"}
                                        className="react-datepicker"
                                        dateFormat="dd-MMM-yyyy"
                                        onChange={(date: Date) => setConstraintDate(date)}
                            />
                        </FormField>
                    </span>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                    <Button onClick={() => setNewTaskModalOpen(false)}>Cancel</Button>
                    <Button positive onClick={() => {
                        let missed: any[] = []
                        if (!taskName) {
                            missed.push("Task Name")
                            setIsValid(false)
                        }
                        if (!taskCode) {
                            missed.push("Task Code")
                            setIsValid(false)
                        }
                        if (!taskType) {
                            missed.push("Task Type")
                            setIsValid(false)
                        }
                        if (!calendarId) {
                            missed.push("Working Calendar")
                            setIsValid(false)
                        }
                        if (!taskDuration) {
                            missed.push("Task Duration")
                            setIsValid(false)
                        }
                        if (!wbsHeading) {
                            missed.push("WBS Heading")
                            setIsValid(false)
                        }
                        if (missed.length > 0) {
                            setMissing(missed)
                            return
                        }
                        const wbs = wbsList.find((el) => el.wbs_id === wbsHeading)
                        const parentWbs = wbsList.find((el) => el.wbs_id === wbs!.parent_wbs_id)

                        let wbsMap = new Map()
                        wbsList.forEach((el) => wbsMap.set(el.wbs_id, el))
                        let wbsPath = generateWBSPath(wbsMap, wbs!.wbs_id)

                        addNewTask({
                            task_name: taskName,
                            task_code: taskCode,
                            task_type: taskType,
                            calendar_id: calendarId,
                            constraintDate: constraintDate,
                            constraint: constraint,
                            wbs_id: wbsHeading,
                            wbs_name: wbs!.wbs_name,
                            wbsPath: wbsPath.wbsPath,
                            parent_wbs_name: parentWbs ? {
                                wbs_id: parentWbs.wbs_id,
                                wbs_name: parentWbs.wbs_name,
                                parent_wbs_id: parentWbs.parent_wbs_id
                                } : null,
                            taskDuration: taskDuration * calendar.working_hours_per_day * 2,
                            predecessors: predecessors,
                            successors: successors,
                            targetDuration: taskDuration,
                            wbs,
                        }, projectId, user, calendars, calendarDict, inProgressTasks.concat(queuedTasks)).then(() => setNewTaskModalOpen(false))
                    }}>Create</Button>
                </Modal.Actions>
        </Modal>
    )
}