import React, {useState} from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';
import {NavBarNominationModal} from './NavBarNominationModal';

interface InjectedProps {
    opened: boolean,
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    leaveProject: () => void,
    nominatedMembersList: { userEmail: string, id: string }[],
    setNominatedMembersList(args: { userEmail: string, id: string }[]): void;
    projectId: string
}

export const NavBarCloseProjectModal: React.FC<InjectedProps> = props => {
    const {opened, setOpened, leaveProject, nominatedMembersList, setNominatedMembersList} = props;
    const [isNominated, setIsNominated] = useState<boolean>(false);

    return (
        <>
            <Modal
                className='modal-close-project'
                size="mini"
                basic
                open={opened}
                closeIcon
                onClose={() => setOpened(false)}
                style={{ animationDuration: "1s" }}
            >
                <Modal.Header className="modal-close-project-header">
                    You are the only Super—Actor on this project, assign a replacement or the project will be closed!
                </Modal.Header>
                <Modal.Actions>
                    <Button inverted color='red' onClick={leaveProject}>
                        <Icon name="close"/>Close anyway
                    </Button>
                    <Button inverted color='green' onClick={() => setIsNominated(true)}>
                        <Icon name='legal'/>Nominate
                    </Button>
                </Modal.Actions>
            </Modal>
            <NavBarNominationModal
                projectId={props.projectId}
                opened={isNominated}
                setOpened={setIsNominated}
                leaveProject={leaveProject}
                setParentModalOpened={setOpened}
                nominatedMembersList={nominatedMembersList}
                setNominatedMembersList={setNominatedMembersList}
            />
        </>
    );
}
