import React, {useEffect, useMemo} from 'react';
import { BarStack } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { scaleLinear as d3ScaleLinear } from 'd3-scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal } from '@visx/legend';
import { localPoint } from '@visx/event';
import moment from 'moment';

type TooltipData = {
    bar: any;
    key: string;
    index: number;
    height: number;
    width: number;
    x: number;
    y: number;
    color: string;
};

export type BarStackProps = {
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    events?: boolean;
    userId: any;
    timeData: any;
    keys: string[];
    labels: any
};

const purple1 = '#6c5efb';
const purple2 = '#c998ff';
export const purple3 = '#172554';
export const background = "#eff6ff";
const defaultMargin = { top: 40, right: 0, bottom: 0, left: 0 };
const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
};

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

// accessors
const getDate = (d) => moment(new Date(d.date * 1000)).format('DD-MM-YY');

let tooltipTimeout: number;

export default function Example({
                                    width,
                                    height,
                                    margin = defaultMargin,
                                    userId,
                                    timeData,
                                    keys,
                                    labels,
                                }: BarStackProps) {
    const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<TooltipData>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // TooltipInPortal is rendered in a separate child of <body /> and positioned
        // with page coordinates which should be updated on scroll. consider using
        // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
        scroll: true,
    });

    const data = useMemo(() => {
        let data: any[] = []
        let totals: any[] = []
        timeData.forEach((value, key) => {
            const obj = value[userId]
            data.push({
                date: key,
                tasksJoined: obj.tasksJoined,
                tasksLeft: obj.tasksLeft,
                tasksStarted: obj.tasksStarted,
                tasksCompleted: obj.tasksCompleted,
                tasksSuspended: obj.tasksSuspended,
                tasksEvaluated: obj.tasksEvaluated,
                comments: obj.comments,
                other: obj.other
            })
            totals.push(obj.tasksJoined + obj.tasksLeft + obj.tasksStarted + obj.tasksCompleted + obj.tasksSuspended + obj.tasksEvaluated + obj.comments + obj.other)
        })
        return {data, totals}
    }, [userId])

    const maxOfTotals = Math.max(...data.totals)

    // scales
    const dateScale = scaleBand<string>({
        domain: data.data.map(getDate),
        padding: 0.2,
    });
    const yScale = scaleLinear<number>({
        domain: [0, maxOfTotals],
    });

    const colorScale = scaleOrdinal({
        domain: keys,
        range: ["#93c5fd", "#60a5fa", "#3b82f6", "#2563eb", "#1d4ed8", "#1e40af", "#1e3a8a", "#172554"],
    });

    if (width < 10) return null;
    // bounds
    const xMax = width;
    const yMax = height - margin.top - 100;

    dateScale.rangeRound([0, xMax]);
    yScale.range([yMax, 0]);

    const yAxisTicks = yScale.ticks()
        .filter(tick => Number.isInteger(tick));

    return width < 10 ? null : (
        <div style={{ position: 'relative' }}>
            <svg ref={containerRef} width={width} height={height}>
                <rect x={0} y={0} width={width} height={height} fill={background} rx={5} />
                <Grid
                    top={margin.top}
                    left={margin.left}
                    xScale={dateScale}
                    yScale={yScale}
                    width={xMax}
                    height={yMax}
                    stroke="black"
                    strokeOpacity={0.1}
                    xOffset={dateScale.bandwidth() / 2}
                />
                <Group top={margin.top}>
                    <BarStack
                        data={data.data}
                        keys={keys}
                        x={getDate}
                        xScale={dateScale}
                        yScale={yScale}
                        color={colorScale}
                    >
                        {(barStacks) =>
                            barStacks.map((barStack) =>
                                barStack.bars.map((bar) => (
                                    <rect
                                        key={`bar-stack-${barStack.index}-${bar.index}`}
                                        x={bar.x}
                                        y={bar.y}
                                        height={bar.height}
                                        width={bar.width}
                                        fill={bar.color}
                                        onMouseLeave={() => {
                                            tooltipTimeout = window.setTimeout(() => {
                                                hideTooltip();
                                            }, 300);
                                        }}
                                        onMouseMove={(event) => {
                                            if (tooltipTimeout) {
                                                clearTimeout(tooltipTimeout);
                                            }
                                            // TooltipInPortal expects coordinates to be relative to containerRef
                                            // localPoint returns coordinates relative to the nearest SVG, which
                                            // is what containerRef is set to in this example.
                                            const eventSvgCoords = localPoint(event);
                                            const left = bar.x + bar.width / 2;
                                            console.log(eventSvgCoords)
                                            showTooltip({
                                                tooltipData: bar,
                                                tooltipTop: eventSvgCoords?.y,
                                                tooltipLeft: left,
                                            });
                                        }}
                                    />
                                )),
                            )
                        }
                    </BarStack>
                </Group>
                <AxisRight scale={yScale}
                    tickFormat={(value) => {
                        // @ts-ignore
                        if (value % 1 === 0) {
                            return String(value)
                        } else {
                            return ""
                        }}}
                    stroke="transparent"
                    tickStroke="transparent"
                    orientation={'right'}
                    top={20}
                    hideZero={true}
                    tickLabelProps={{
                        fill: "#373945",
                        fontSize: 11,
                        textAnchor: 'end',
                    }}
                />
                {/*<AxisLeft*/}
                {/*    scale={yScale}*/}
                {/*    tickFormat={(value) => {*/}
                {/*        // @ts-ignore*/}
                {/*        if (value % 1 === 0) {*/}
                {/*            return String(value)*/}
                {/*        } else {*/}
                {/*            return ""*/}
                {/*        }*/}

                {/*    }}*/}
                {/*    orientation={'left'}*/}
                {/*/>*/}
                <AxisBottom
                    top={yMax + margin.top}
                    scale={dateScale}
                    // tickFormat={formatDate}
                    stroke={purple3}
                    tickStroke={purple3}
                    tickLabelProps={{
                        fill: purple3,
                        fontSize: 11,
                        textAnchor: 'middle',
                    }}
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: height - 50,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                }}
            >
                <LegendOrdinal labelFormat={(item) => labels[item]}
                               scale={colorScale}
                               direction="row"
                               labelMargin="0 15px 0 0"
                />
            </div>

            {tooltipData && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                    <div style={{ color: colorScale(tooltipData.key) }}>
                        <strong>{tooltipData.key}</strong>
                    </div>
                    <div>{tooltipData.bar.data[tooltipData.key]}℉</div>
                    <div>
                        <small>{getDate(tooltipData.bar.data)}</small>
                    </div>
                </TooltipInPortal>
            )}
        </div>
    );
}