import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import MessageModel from "../../models/responses/message.model";

export enum ActionNames {
    SET_LEDGER_LIST = '[Ledger] Set Ledger List',
}


export const Actions = {
    setLedgerList: (ledgerList: MessageModel[]) => createAction(ActionNames.SET_LEDGER_LIST, {ledgerList}),
};

export type Actions = ActionsUnion<typeof Actions>;
