import {ActionType, createAction} from "typesafe-actions";
import {Chip, ReportItem} from "../store.types";
import {SearchParametersModel} from "../reducers/search";
import {Omit} from "@martin_hotell/rex-tils";


export const SearchActions = {
    setSearchValue: createAction('[Search] Set Search Value')<string>(),
    setTaskforceFilter: createAction('[Search] Set TaskForce Filter')<(string | null)[]>(),
    removeIdFromTaskforecFilter: createAction('[Search] Remove Id From TaskForce Filter')<(string | null)[]>(),
    toggleAlert: createAction('[Search] Toogle Alert')(),
    setReportList: createAction('[Search] Set Report List')<ReportItem[]>(),
    addChip: createAction('[Search] Add Chip')<Chip[]>(),
    removeChip: createAction('[Search] Remove Chip')<string>(),
    removeAllChip: createAction('[Search] Remove All Chip')(),
    setReport: createAction('[Search] Set Report')<Omit<SearchParametersModel, 'searchText'>>(),
    setMapSearchText: createAction('[Search] Set Map Search Text')<string>(),
    setStartDate: createAction('[Search] Set Start Date')<number | null>(),
    setFinishDate: createAction('[Search] Set Finish Date')<number | null>(),
    resetAllFilters: createAction('[Search] Reset All Filters')(),

    setSearchBarLoading: createAction('[Search] Set Search Bar Loading')<boolean>(),
};

export type Actions = ActionType<typeof SearchActions>;
