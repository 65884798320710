import {MessageType} from "../models/responses/message.model";
import LedgerEntry from "../models/responses/ledger-entry.model";
import FirebaseUsage from "../firebase/firebase.usage";
import {COLLECTIONS} from "../firebase/constants";
import ImageModel from "../models/responses/image.model";
import ProjectFileModel from "../models/responses/project-file.model";
import firebaseConfig from "../config/firebase.config";
import {TaskType} from "../models/task-type";

export const transformEventType = (eventType: MessageType) => {
  switch (eventType) {
    case MessageType.STD: {
      return "Task Started";
    }
    case MessageType.RES: {
      return "Task Resumed";
    }
    case MessageType.DIR: {
      return "Task Put On Hold Due To Directive";
    }
    case MessageType.SUS: {
      return "Task Suspended";
    }
    case MessageType.BLC: {
      return "Task Blocked";
    }
    case MessageType.UNB: {
      return "Task Unblocked";
    }
    case MessageType.ISS: {
        return "Task Issue Logged";
    }
    case MessageType.INC: {
      return "Task Put On Hold Due To Incident";
    }
    case MessageType.INF: {
      return "Task Put On Hold Due To Information";
    }
    case MessageType.DEC: {
      return "Task Declared Complete";
    }
    case MessageType.COM: {
      return "Task Evaluated Complete";
    }
    case MessageType.REJ: {
      return "Task Evaluated Incomplete";
    }
    case MessageType.TFJ: {
      return "Taskforce Joined";
    }
    case MessageType.TFL: {
      return "Taskforce Left";
    }
    case MessageType.ATF: {
      return "Added To Taskforce";
    }
    case MessageType.RTF: {
      return "Removed From Taskforce";
    }
    case MessageType.WTG: {
      return "Task Waiting To Start";
    }
    case MessageType.CHK: {
      return "Checklist complete - task is ready to start";
    }
    case MessageType.TBS: {
      return "This task is behind schedule, status report required";
    }
    case MessageType.IMG: {
      return "Image(s) added to task";
    }
    case MessageType.FIL: {
      return "File(s) added to task";
    }
    case MessageType.FOR: {
        return "Task finish date reforecast";
    }
    case MessageType.MSG: {
        return "Message added to task";
    }
    default: {
      return "";
    }
  }
};


export const transformTaskType = (taskType: string) => {
    switch (taskType) {
        case TaskType.TT_TASK: {
          return "Task Dependent";
        }
      case TaskType.TT_FIN_MILE: {
        return "Finish Milestone";
        }
      case TaskType.TT_RSRC: {
        return "Resource Dependent";
      }
      case TaskType.TT_MILE: {
          return "Start Milestone";
      }
    case TaskType.TT_WBS: {
        return "WBS Summary";
    }
    case TaskType.TT_LOE: {
        return "Level Of Effort";
    }
    default: {
        return "";
    }}
}


export const transformEventDetails = async (ledgerEntry: LedgerEntry, attachmentId?: string) => {
    switch (ledgerEntry.type) {
        case MessageType.IMG: {
          const image = await FirebaseUsage.getDoc(COLLECTIONS.IMAGES, attachmentId!).then((doc) => doc.data() as ImageModel);
          const storageArray = image.storagePath.split("/");
        return {v: `${ledgerEntry.text}: ${image?.fileName}`, l: {Target:
                `${firebaseConfig.fileApiUrl}/?project_storage_id=${storageArray[0]}&type=project_images&file_id=${storageArray[2]}&document_id=${image.imageId}`}};
        }
        case MessageType.FIL: {
            const file = await FirebaseUsage.getDoc(COLLECTIONS.PROJECT_FILES, attachmentId!).then((doc) => doc.data() as ProjectFileModel);
            const storageArray = file.storagePath.split("/");
        return {v: `${ledgerEntry.text}: ${file?.fileName}`, l: {Target:
                `${firebaseConfig.fileApiUrl}/?project_storage_id=${storageArray[0]}&type=project_files&file_id=${storageArray[2]}&document_id=${file.fileId}`}};
        }
        case MessageType.MSG: {
            return ledgerEntry.text;
        }
        case MessageType.SUS: {
          return `${ledgerEntry.suspendReason} - ${ledgerEntry.text}`;
        }
        default: {
        return "";
        }
    }
}

export const transformPredStatus = (predStatus: number) => {
  switch (predStatus) {
    case 1: {
      // Task ready to start
      return "Green";
    }
    case 2: {
      // Task nearly ready to start
      return "Amber";
    }
    case 3: {
      // Task not ready to start
      return "Red";
    }
    case 4: {
      // Task complete
      return null;
    }
    default: {
      return "";
    }
  }
};
