import FirebaseUsage from '../firebase/firebase.usage';
import LocalStorageHelper from '../utils/local-storage.helper';
import SignInBodyModel from "../models/reqest-body-models/sign-in-body.model";
import {UserCredential} from "@firebase/auth-types";

export default class AuthorizationApi {
    public static async sendSignInLinkToEmail(body: SignInBodyModel): Promise<void> {
        return FirebaseUsage.sendSignInLinkToEmail(body.email, {
            url: window.location.origin,
            handleCodeInApp: true,
        }).then(function () {
            LocalStorageHelper.setEmailForSignIn(body.email);
        }).catch(function (error) {
            console.log(error);
        });
    }

    public static async signInWithEmailLink() {
        const isSignIn = FirebaseUsage.isSignInWithEmailLink(window.location.href);
        const email = LocalStorageHelper.emailForSignIn();
        if(!email || !isSignIn) {
            throw new Error('not Auth');
        }
        return isSignIn
            ? await FirebaseUsage.signInWithEmailLink(email || '', window.location.href)
                .catch(() => {
                    return null;
                })
            : null
    }

    public static async logOut() {
        return await FirebaseUsage.signOutOfAuth();
    }
}
