import MessageModel, { MessageType } from "../models/responses/message.model";
import FirebaseUsage from "../firebase/firebase.usage";
import { COLLECTIONS } from "../firebase/constants";
import LedgerEntry from "../models/responses/ledger-entry.model";
import TaskModel, { ProcessedFrom } from "../models/responses/task.model";
import TaskStatusModel from "../models/responses/task-status.model";
import { TaskListSectionModel } from "../models/task-list-section.model";
import { TaskThunk } from "../store/thunk/task.thunk";
import store from "../store/store";

async function refreshTasks(task: TaskModel, projectId: string, dispatch: any) {
  await TaskThunk.refreshTasks(task, projectId, dispatch);
}

async function removeMsg(ledgerId: string, activeProject) {
  await FirebaseUsage.deleteDocument(COLLECTIONS.LEDGER_ENTRY, ledgerId).then(
    () => {
      console.log("Message deleted from ledger");
    }
  );
  await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG, [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]]).then((snap) => {
    snap.docs.map((el) =>
      FirebaseUsage.deleteDocument(COLLECTIONS.EVENT_LOG, el.id)
    );
  });
}

async function undoStart(ledgerId: string, activeProject, dispatch: any) {
  const ledgerEntry = await FirebaseUsage.getDoc(
    COLLECTIONS.LEDGER_ENTRY,
    ledgerId
  ).then((doc) => doc.data() as LedgerEntry);
  let task = await FirebaseUsage.getDoc(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId
  ).then((doc) => doc.data() as TaskModel);

  const subsequentLedgerEntries =
    task.status === TaskStatusModel.COMPLETE ||
    task.status === TaskStatusModel.DECLARED_COMPLETE
      ? await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
            [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
            .then((snap) =>
          snap.docs.map((el) =>
            // @ts-ignore
            el.data().type === MessageType.DEC
              ? (el.data() as LedgerEntry)
              : // @ts-ignore
              el.data().type === MessageType.COM
              ? (el.data() as LedgerEntry)
              : null
          )
        )
      : [];

  task = {
    ...task,
    status: TaskStatusModel.NOT_STARTED,
    act_start_date: null,
    act_end_date: null,
    expiryDate: null,
    enteredWorkInProcessTime: null,
    declaredCompleteTimestamp: null,
    processedFrom: null,
    taskListType: TaskListSectionModel.WORK_IN_PROCESS,
    evaluated: false,
    early_end_date: FirebaseUsage.timestamp(),
    early_start_date: FirebaseUsage.timestamp(),
    late_end_date: FirebaseUsage.timestamp(),
    late_start_date: FirebaseUsage.timestamp(),
  };

  // Finish up
  const ledgerDeleteList: any[] = [ledgerEntry, ...subsequentLedgerEntries];

  ledgerDeleteList.map(async (el) => {
    if (el) {
      await FirebaseUsage.deleteDocument(
        COLLECTIONS.LEDGER_ENTRY,
        ledgerId
      ).then(() => {
        console.log("Message deleted from ledger");
      });
      await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
          [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
          .then((snap) => {
        snap.docs.map((el) =>
          FirebaseUsage.deleteDocument(COLLECTIONS.EVENT_LOG, el.id)
        );
      });
    }
  });

  return await refreshTasks(task, ledgerEntry.projectId, dispatch);
}

async function undoConfirmedComplete(ledgerId: string, activeProject) {
  const ledgerEntry = await FirebaseUsage.getDoc(
    COLLECTIONS.LEDGER_ENTRY,
    ledgerId
  ).then((doc) => doc.data() as LedgerEntry);
  let task = await FirebaseUsage.getDoc(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId
  ).then((doc) => doc.data() as TaskModel);

  task = {
    ...task,
    status: TaskStatusModel.DECLARED_COMPLETE,
    declaredCompleteTimestamp: task.act_end_date,
    act_end_date: null,
    processedFrom: ProcessedFrom.IN_PROCESS,
    taskListType: TaskListSectionModel.DECLARED_COMPLETE,
    evaluated: false,
  };

  await FirebaseUsage.deleteDocument(COLLECTIONS.LEDGER_ENTRY, ledgerId).catch(
    (err) => console.log(err)
  );
  await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
      [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
      .then((snap) => {
    snap.docs.map((el) =>
      FirebaseUsage.deleteDocument(COLLECTIONS.EVENT_LOG, el.id)
    );
  });

  return await FirebaseUsage.setDocument(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId,
    task
  ).catch((err) => console.log(err));
}

async function undoDeclaredComplete(ledgerId: string, activeProject, dispatch: any) {
  const ledgerEntry = await FirebaseUsage.getDoc(
    COLLECTIONS.LEDGER_ENTRY,
    ledgerId
  ).then((doc) => doc.data() as LedgerEntry);
  let task = await FirebaseUsage.getDoc(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId
  ).then((doc) => doc.data() as TaskModel);

  const subsequentLedgerEntries =
    task.status === TaskStatusModel.COMPLETE
      ? await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
            [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
            .then((snap) =>
          snap.docs.map((el) =>
            // @ts-ignore
            el.data().type === MessageType.DEC
              ? (el.data() as LedgerEntry)
              : // @ts-ignore
              el.data().type === MessageType.COM
              ? (el.data() as LedgerEntry)
              : null
          )
        )
      : [];

  task = {
    ...task,
    status: TaskStatusModel.IN_PROGRESS,
    act_end_date: null,
    declaredCompleteTimestamp: null,
    processedFrom: ProcessedFrom.QUEUED,
    taskListType: TaskListSectionModel.WORK_IN_PROCESS,
    evaluated: false,
    early_end_date: FirebaseUsage.timestamp(),
    late_end_date: FirebaseUsage.timestamp(),
  };

  const ledgerDeleteList: any[] = [ledgerEntry, ...subsequentLedgerEntries];
  // Finish up
  ledgerDeleteList.concat(ledgerEntry).map(async (el) => {
    if (el) {
      await FirebaseUsage.deleteDocument(
        COLLECTIONS.LEDGER_ENTRY,
        ledgerId
      ).then(() => {
        console.log("Message deleted from ledger");
      });
      await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
          [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
          .then((snap) => {
        snap.docs.map((el) =>
          FirebaseUsage.deleteDocument(COLLECTIONS.EVENT_LOG, el.id)
        );
      });
    }
  });

  return await refreshTasks(task, ledgerEntry.projectId, dispatch);
}

async function undoSuspension(ledgerId: string, activeProject) {
  const ledgerEntry = await FirebaseUsage.getDoc(
    COLLECTIONS.LEDGER_ENTRY,
    ledgerId
  ).then((doc) => doc.data() as LedgerEntry);
  let task = await FirebaseUsage.getDoc(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId
  ).then((doc) => doc.data() as TaskModel);

  task = {
    ...task,
    status: TaskStatusModel.IN_PROGRESS,
    act_end_date: null,
    declaredCompleteTimestamp: null,
    processedFrom: ProcessedFrom.QUEUED,
    taskListType: TaskListSectionModel.WORK_IN_PROCESS,
    evaluated: false,
    early_end_date: FirebaseUsage.timestamp(),
    late_end_date: FirebaseUsage.timestamp(),
    suspended: false,
    suspendReason: null,
  };

  await FirebaseUsage.deleteDocument(COLLECTIONS.LEDGER_ENTRY, ledgerId).then(
    () => {
      console.log("Entry deleted from ledger");
    }
  );
  await FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG,
      [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", ledgerId]])
      .then((snap) => {
    snap.docs.map((el) =>
      FirebaseUsage.deleteDocument(COLLECTIONS.EVENT_LOG, el.id)
    );
  });

  return await FirebaseUsage.setDocument(
    COLLECTIONS.TASKS,
    ledgerEntry.taskId,
    task
  ).catch((err) => console.log(err));
}

function undoAttachments(ledgerEntry: MessageModel, type: string, activeProject) {
    if (ledgerEntry.attachments) {
        ledgerEntry.attachments.forEach((attachment) => {
          FirebaseUsage.getDoc(`project_${type}s`, attachment).then((doc) => {
            if (!doc.data()) return
            const storagePath = doc.data()!.storagePath
            FirebaseUsage.deleteFileFromStorage(storagePath).then(() => {
              console.log(`${doc.data()!.fileName} deleted from storage`)
                FirebaseUsage.deleteDocument(`project_${type}s`, attachment).then(() => {
                    console.log(`${doc.data()!.fileName} deleted from ${type}`)
              })
              if (type === "image") {
                FirebaseUsage.getCompoundQuery(COLLECTIONS.GEO_DATA, [['projectId', '==', activeProject!.projectId], ["imageId", "==", doc.data()!.imageId]]).then((snap) => {
                  snap.docs.forEach((el) => {
                    FirebaseUsage.deleteDocument(COLLECTIONS.GEO_DATA, el.id).then(() => {
                      console.log("Geo data deleted")
                    })
                  })
                })
              }
            })
          })
      })
    }
    FirebaseUsage.deleteDocument(COLLECTIONS.LEDGER_ENTRY, ledgerEntry.ledgerId).then(() => {
        console.log("Message deleted from ledger")
    })
}


export default function undoAction(message: MessageModel, dispatch: any) {
  const ledgerId = message.ledgerId;
  const activeProject = store.getState().project.activeProject;

  switch (message.type) {
    case MessageType.MSG:
      // Undo a message
      removeMsg(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.STD:
      // Undo a task started
      undoStart(ledgerId, activeProject, dispatch).catch((err) => console.log(err));
      break;
    case MessageType.DIR:
      // Undo a task suspended – directive
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.FIL:
      undoAttachments(message, "file", activeProject)
      break;
    case MessageType.IMG:
      undoAttachments(message, "image", activeProject)
      break;
    case MessageType.BLC:
      // Undo a task blocked
      break;
    case MessageType.UNB:
      // Undo a task blocked
      break;
    case MessageType.INC:
      // Undo a task suspended – incident
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.INF:
      // Undo a task suspended – information
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.OTH:
      // Undo a task suspended – other
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.RES:
      // Undo a task suspended – resource
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.DEC:
      // Undo a task declared complete
      undoDeclaredComplete(ledgerId, activeProject, dispatch).catch((err) => console.log(err));
      break;
    case MessageType.REJ:
      // Undo a task declaration rejected
      break;
    case MessageType.COM:
      // Undo a task complete
      undoConfirmedComplete(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.TFJ:
      // Undo a taskforce Join
      break;
    case MessageType.TFL:
      // Undo a taskforce Left
      break;
    case MessageType.SUS:
      // Undo a task suspended
      undoSuspension(ledgerId, activeProject).catch((err) => console.log(err));
      break;
    case MessageType.EVL:
      // Undo a task evaluated
      break;
    case MessageType.ATF:
      // Undo a added to taskforce
      break;
    case MessageType.RTF:
      // Undo a removed from taskforce
      break;
    case MessageType.WTG:
      // Undo a waiting to start
      break;
    case MessageType.CHK:
      // Undo a checklist complete
      break;
    case MessageType.TBS:
      // Undo a task behind schedule
      break;
    default:
      break;
  }
}
