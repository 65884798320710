import React from "react";
import styles from "../../FlowPage/components/Task/TaskContainer.module.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromLine, faArrowRightFromBracket, faArrowLeftFromBracket, faArrowLeftFromLine } from "@fortawesome/pro-light-svg-icons";

import {RelationshipsModel} from "../../../../../models/responses/relationships.model";
import {TaskPredType} from "../../../../../models/task-pred-type.enum";
import {useCpmMapSelector} from "../../../../../store/selectors/project.selectors";

interface InjectedProps {
    link: RelationshipsModel | any;
    index: number;
}

export const LinkContainer: React.FC<InjectedProps> = React.memo(
    ({...props }) => {
        const { link, index } = props;
        const cpmMap = useCpmMapSelector()
        const task = cpmMap.get(link.task_id)
        const predTask = cpmMap.get(link.pred_task_id)

        const linkConverter = () => {
            switch (link.pred_type) {
                case TaskPredType.FS:
                    return "Finish to Start";
                case TaskPredType.SS:
                    return "Start to Start";
                case TaskPredType.FF:
                    return "Finish to Finish";
                case TaskPredType.SF:
                    return "Start to Finish";
            }
        }

        return (
            <div className={styles.Container} style={{cursor: "auto"}}>
                <p className={styles.index}>{index}</p>
                <div className={styles.IconWrap} style={{marginLeft: "80px"}}>
                        <>
                            <FontAwesomeIcon icon={link.pred_type === TaskPredType.FS ?
                                faArrowRightFromLine :
                                link.pred_type === TaskPredType.SS ?
                                    faArrowRightFromBracket :
                                    link.pred_type === TaskPredType.FF ?
                                        faArrowLeftFromBracket : faArrowLeftFromLine
                            }/>
                        </>
                    <div className={styles.ProgressBarWrap}>
                        <div className={styles.ProgressBar}>
                            <div
                                style={{height: "100%"}}
                                className={styles.LineBlue}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.Description}>
                    <div className="d-flex">
                        <h4>{task.task_name}</h4>
                    </div>
                    <h5>
                        {`Predecessor: ${predTask.task_name}`}
                    </h5>
                    <div className={styles.Description}>
                        <h4>{linkConverter()}{` ${link.lagDays < 0 ? ' - ': ' + '}${link.lagDays} days`}</h4>
                    </div>
                </div>

            </div>
        );
    }
);
