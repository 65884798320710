import FirebaseUsage from "../../../firebase/firebase.usage";

export async function getXERContent (projContent, category, idName, unique, collectionRef) {
    // GET CONTENT FIELDS
    let re2 = projContent ?
        new RegExp(`(?<=%T\\t${category.toUpperCase()}(\\r\\n|\\n))([\\s\\S]*?)(?=\r\n%R|%T)`, 'g') :
        null
    const contentMatch = projContent ? projContent.match(re2) : null
    const contentFields = projContent && contentMatch ? contentMatch[0]
        .replace(/%F\t/, "").split("\t") : null

    console.log(category)
    // Get Content
    let re3 = projContent  && contentMatch ?
        new RegExp(`(?<=%T\\t${category.toUpperCase()}\r\n%F\\t(\\w+[\\t\\r\\n]){${contentFields.length}})([\\s\\S]*?)(?=\r\n%T|%E)`, 'g') :
        null
    const catContent = projContent && contentMatch ? projContent.match(re3)[0].split(/\n?%R\t/)
            .filter(item => item !== "")
            .map(item => item.replace('\r', '').split("\t"))
        : null

    let createUpload = new Map()

    if (projContent && contentMatch) {
        catContent.forEach((item, x) => {
            let outObj = {}
            item.forEach((field, i) => {
                // get last 4 digits of field name
                if (contentFields[i].slice(-4) === 'date') {
                    outObj = {...outObj, [contentFields[i]]: field !== '' ?
                            FirebaseUsage.timestamp(new Date(field)) :
                            null
                    }
                } else if (contentFields[i].slice(-3) === 'cnt') {
                    outObj = {...outObj, [contentFields[i]]: field !== '' ?
                            parseFloat(field) :
                            null
                    }
                } else {
                    outObj = {...outObj, [contentFields[i]]: field}
                }
            })

            if (unique) {
                createUpload.set(outObj[`${idName}_id`], {
                    data: outObj,
                    docRef: FirebaseUsage.getBlankDoc(collectionRef)
                })
            } else {
                createUpload.set(x, {
                    data: outObj,
                    docRef: FirebaseUsage.getBlankDoc(collectionRef)
                })
            }
        })
    }
    return createUpload
}