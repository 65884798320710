import TaskStatusModel from "../../../../models/responses/task-status.model";
import shortestPathCalc from "./funnelCalc";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import updatePredStatus from "../../cpm-app/functions/UpdatePredStatus";


export default async function racingLineCalculation(
    earliestDate: number,
    latestDate: number,
    cpmMap: Map<string, any>,
    calendarsMap: Map<string, any>,
    taskList: any[],
    calendars: string[],
    projectId: string,
    specific: boolean,
) {
    const timeStart = new Date().getTime()
    function convertIndexToSeconds(index, calendarId) {
        const date = specificCalendarDict(index, calendarId)
        if (isNaN(date)) {
            return convertOutOfRangeIndexToDate(index, calendarId)
        } else {
            return date
        }
    }

    function convertOutOfRangeIndexToDate(index, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((index - maxIndex) / halfHours)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId)
        const relativeMaxDayIndex = parseInt(calendarsMap.get(`swp:maxDayIndex:${calendarId}`))
        const relativeMaxHalfHourIndex = parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${relativeMaxWeekIndex}`))
        const remainingHalfHours = index - maxIndex - (weeks * halfHours)
        const newIndex = remainingHalfHours + relativeMaxWeekIndex > halfHours ? (remainingHalfHours + relativeMaxWeekIndex) - halfHours : remainingHalfHours + relativeMaxWeekIndex
        const newWeekDayIndex = parseInt(calendarsMap.get(`swp:wi:d:${calendarId}:${newIndex}`))
        const additionalDays = relativeMaxWeekIndex + remainingHalfHours > halfHours ? 7 - relativeMaxDayIndex + newWeekDayIndex : newWeekDayIndex - relativeMaxDayIndex

        return maxDate + (weeks * 604800) + (additionalDays * 86400) + ((parseInt(calendarsMap.get(`swp:wi:h:${calendarId}:${newIndex}`)) - relativeMaxHalfHourIndex) * 1800)
    }

    const specificCalendarDict = (index, calendarId) => {
        return parseInt(calendarsMap.get(`scd:${calendarId}:${index}`))
    }

    const masterCalendarDict = (seconds, calendarId) => {
        return parseInt(calendarsMap.get(`mcd:${seconds}:${calendarId}`))
    }

    const masterWorkPatternDict = (index, calendarId) => {
        return parseInt(calendarsMap.get(`mwp:${index}:${calendarId}`))
    }

    function convertDateToIndex(date, calendarId) {
        let index = masterCalendarDict(date, calendarId)
        if (isNaN(index)) {
            return convertOutOfRangeDateToIndex(date, calendarId)
        }
        return index
    }

    function convertOutOfRangeDateToIndex(date, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((date - maxDate) / 604800)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId)
        const remainingHalfHours = Math.floor((date - maxDate) / 1800) - (weeks * 336)
        const newMasterIndex = remainingHalfHours + maxWeekIndex > 336 ? (remainingHalfHours + maxWeekIndex) - 336 : remainingHalfHours + maxWeekIndex
        const newCalendarSpecificIndex = masterWorkPatternDict(newMasterIndex, calendarId)

        return remainingHalfHours + maxWeekIndex > 336 ? maxIndex + (weeks * halfHours) + (halfHours - relativeMaxWeekIndex) + (newCalendarSpecificIndex) :
            maxIndex + (weeks * halfHours) + (newCalendarSpecificIndex - relativeMaxWeekIndex)
    }

    function roundUpToDaysEnd(date) {
        return Math.ceil(date / 86400) * 86400
    }

    const early: Map<number, {} | undefined> = new Map()
    const late: Map<number, {} | undefined> = new Map()

    const cumulativeMap = new Map()

    let hasWorkInProgress = {}
    for (let calendar of calendars) {
        hasWorkInProgress[calendar] = false
    }

    taskList.forEach(task => {
        const taskData = cpmMap.get(task)
        if (taskData.status_code === TaskStatusModel.IN_PROGRESS ||
            taskData.status_code === TaskStatusModel.SUSPENDED) {
            hasWorkInProgress[taskData.cal_id] = true
            const earlyEndDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.ef, taskData.cal_id))
            const earlyStartDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.ad, taskData.cal_id))
            const lateEndDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.lf, taskData.cal_id))
            const lateStartDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.ls, taskData.cal_id))
            const earlyTotalEnd: {} | undefined = early.get(earlyEndDate)
            const earlyTotalStart: {} | undefined = early.get(earlyStartDate)
            const lateTotalEnd: {} | undefined = late.get(lateEndDate)
            const lateTotalStart: {} | undefined = late.get(lateStartDate)
            const durationTotal = Math.max(taskData.ef - taskData.ad, 0)

            early.set(earlyEndDate, earlyTotalEnd ? earlyTotalEnd[taskData.cal_id] ?
                {...earlyTotalEnd, [taskData.cal_id]: earlyTotalEnd[taskData.cal_id] + durationTotal} :
                {...earlyTotalEnd, [taskData.cal_id]: durationTotal} :
                {[taskData.cal_id]: durationTotal})
            late.set(lateEndDate, lateTotalEnd ? lateTotalEnd[taskData.cal_id] ?
                {...lateTotalEnd, [taskData.cal_id]: lateTotalEnd[taskData.cal_id] + durationTotal} :
                {...lateTotalEnd, [taskData.cal_id]: durationTotal} :
                {[taskData.cal_id]: durationTotal})

            early.set(earlyStartDate, earlyTotalStart ? earlyTotalStart[taskData.cal_id] ?
                {...earlyTotalStart, [taskData.cal_id]: earlyTotalStart[taskData.cal_id] + durationTotal} :
                {...earlyTotalStart, [taskData.cal_id]: durationTotal} :
                {[taskData.cal_id]: durationTotal})
            late.set(lateStartDate, lateTotalStart ? lateTotalStart[taskData.cal_id] ?
                {...lateTotalStart, [taskData.cal_id]: lateTotalStart[taskData.cal_id] + durationTotal} :
                {...lateTotalStart, [taskData.cal_id]: durationTotal} :
                {[taskData.cal_id]: durationTotal})
        } else
            if (taskData.status_code === TaskStatusModel.NOT_STARTED) {
            const taskType = taskData.task_type.toLowerCase()
            if (taskType === "tt_task" || taskType === "tt_rsrc") {
            const earlyEndDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.ef, taskData.cal_id))
            const earlyStartDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.es, taskData.cal_id))
            const lateEndDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.lf, taskData.cal_id))
            const lateStartDate = roundUpToDaysEnd(convertIndexToSeconds(taskData.ls, taskData.cal_id))
            const earlyTotalEnd: {} | undefined = early.get(earlyEndDate)
            const lateTotalEnd: {} | undefined = late.get(lateEndDate)
            const earlyTotalStart: {} | undefined = early.get(earlyStartDate)
            const lateTotalStart: {} | undefined = late.get(lateStartDate)

            early.set(earlyEndDate, earlyTotalEnd ? earlyTotalEnd[taskData.cal_id] ?
                {...earlyTotalEnd, [taskData.cal_id]: earlyTotalEnd[taskData.cal_id] + taskData.duration} :
                {...earlyTotalEnd, [taskData.cal_id]: taskData.duration} :
                {[taskData.cal_id]: taskData.duration})
            late.set(lateEndDate, lateTotalEnd ? lateTotalEnd[taskData.cal_id] ?
                {...lateTotalEnd, [taskData.cal_id]: lateTotalEnd[taskData.cal_id] + taskData.duration} :
                {...lateTotalEnd, [taskData.cal_id]: taskData.duration} :
                {[taskData.cal_id]: taskData.duration})

            early.set(earlyStartDate, earlyTotalStart ? earlyTotalStart[taskData.cal_id] ?
                {...earlyTotalStart, [taskData.cal_id]: earlyTotalStart[taskData.cal_id] + taskData.duration} :
                {...earlyTotalStart, [taskData.cal_id]: taskData.duration} :
                {[taskData.cal_id]: taskData.duration})
            late.set(lateStartDate, lateTotalStart ? lateTotalStart[taskData.cal_id] ?
                {...lateTotalStart, [taskData.cal_id]: lateTotalStart[taskData.cal_id] + taskData.duration} :
                {...lateTotalStart, [taskData.cal_id]: taskData.duration} :
                {[taskData.cal_id]: taskData.duration})
            }
        }
    })

    let earlyTotal = {}
    let lateTotal = {}
    for (let cal of calendars) {
        earlyTotal[cal] = 0
        lateTotal[cal] = 0
    }

    const latestDateToDaysEnd = roundUpToDaysEnd(latestDate)

    for (let i = earliestDate; i <= latestDateToDaysEnd; i += 86400) {
        for (let cal of calendars) {
            const earlyCount = early.get(i)
            const lateCount = late.get(i)
            if (earlyCount && earlyCount[cal]) {
                earlyTotal[cal] += earlyCount[cal] / 2
            }
            if (lateCount && lateCount[cal]) {
                lateTotal[cal] += lateCount[cal] / 2
            }
            cumulativeMap.set(i, {early: {...earlyTotal}, late: {...lateTotal}})
        }
    }

    const dataDate = Math.floor((new Date().getTime() / 1000) / 86400) * 86400

    const expectedCumulativeObject = shortestPathCalc(cumulativeMap,
        [dataDate, cumulativeMap.get(dataDate).early],
        [latestDateToDaysEnd, cumulativeMap.get(latestDateToDaysEnd).early],
        calendars, hasWorkInProgress)


    let targetFronts = {}
    let totalFronts = 0
    for (let cal of calendars) {
        if (expectedCumulativeObject[cal]) {
            let x = convertDateToIndex(expectedCumulativeObject[cal][1][0], cal) - convertDateToIndex(expectedCumulativeObject[cal][0][0], cal)
            let y = 0
            if (expectedCumulativeObject[cal][1][0] - expectedCumulativeObject[cal][0][0] <= 86400 && expectedCumulativeObject[cal].length > 2) {
                y = expectedCumulativeObject[cal][2][1]
                x = convertDateToIndex(expectedCumulativeObject[cal][2][0], cal) - convertDateToIndex(expectedCumulativeObject[cal][0][0], cal)
            } else {
                y = expectedCumulativeObject[cal][1][1] - expectedCumulativeObject[cal][0][1]
            }
            targetFronts[cal] =  Math.ceil(y / Math.max(x, 1))
            totalFronts += targetFronts[cal]
        }
    }

    // Problems with updating only a few pred statuses as indexing does not work.
    // updatePredStatus(specific ? cpmMap.get(`predStatus:${projectId}`) : taskList, cpmMap, calendarsMap, targetFronts).catch(err => console.log(err))
    // Reverting to updating all predStatuses and indices at once
    updatePredStatus(taskList, cpmMap, calendarsMap, targetFronts, projectId, dataDate).catch(err => console.log(err))

    FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, projectId, {
        targetFronts,
        totalFronts
    })
        .catch(err => console.log(err))

    return totalFronts
}