import { ActionsUnion, createAction } from "@martin_hotell/rex-tils";
import ProjectMembersModel from "../../models/responses/project-members.model";
import {PendingMembershipsModel} from "../../models/pending-memberships.model";

export enum ActionNames {
  SET_PROJECTS_MEMBER_LIST = "[Team] Set Member List",
  SET_PENDING_MEMBER_LIST = "[Team] Set Pending Member List",
  LOADING_TEAM_SIDEBAR = "[Team] Loading Team Sidebar",

  LOADING_MEMBER_GRADE = "[Team] Loading Member Grade",
  LOAD_MEMBER_GRADE = "[Team] Load Member Grade",

  LOADING_MEMBER_GATEKEEPER = "[Team] Loading Member GateKeeper",
  LOAD_MEMBER_GATEKEEPER = "[Team] Load Member GateKeeper",
}

export const Actions = {
  setMemberList: (memberList: ProjectMembersModel[]) =>
    createAction(ActionNames.SET_PROJECTS_MEMBER_LIST, { memberList }),
  setPendingMemberList: (pendingMemberList: PendingMembershipsModel[]) =>
    createAction(ActionNames.SET_PENDING_MEMBER_LIST, { memberList: pendingMemberList }),
  loadingTeamSidebar: () => createAction(ActionNames.LOADING_TEAM_SIDEBAR),
  loadingMemberGrade: () => createAction(ActionNames.LOADING_MEMBER_GRADE),
  loadMemberGrade: () => createAction(ActionNames.LOAD_MEMBER_GRADE),
  loadingMemberGateKeeper: () =>
    createAction(ActionNames.LOADING_MEMBER_GATEKEEPER),
  loadMemberGateKeeper: () => createAction(ActionNames.LOAD_MEMBER_GATEKEEPER),
};

export type Actions = ActionsUnion<typeof Actions>;
