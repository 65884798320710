import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import EventLog from "../../models/responses/event-log-model";

export class EventLogSubscriptions {
    static eventLogList(taskId: string, projectId: any, callback: DefaultSubscribeCallback<EventLog[]>) {
        return FirebaseUsage.getCompoundQuery(COLLECTIONS.EVENT_LOG, [['projectId', '==', projectId], ['taskId', '==', taskId]])
            .then(data => {
                callback(data.docs.map(d => d.data() as EventLog))
            });
    }
}
