import React, {useEffect, useState} from 'react';
import {COLLECTIONS} from "../../../../../firebase/constants";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import {Loader} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCirclePlus, faFileExcel, faFilePdf, faFileWord, faImage} from "@fortawesome/free-solid-svg-icons";
import ProjectFileModel from "../../../../../models/responses/project-file.model";
import {useActiveProjectSelector} from "../../../../../store/selectors/project.selectors";

export default function FileAttachmentPreview (props) {
    const activeProject = useActiveProjectSelector()
    const getFileIcon = (type: string) => {
        switch (type) {
            case "image/jpeg":
            case "image/png":
                return faImage;
            case "application/pdf":
                return faFilePdf;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
                return faFileExcel
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword":
                return faFileWord
            default:
                return faFileCirclePlus;
        }
    }

    const [fileList, setFileList] = useState<ProjectFileModel[]>([]);

    useEffect(() => {
        FirebaseUsage.getCompoundQuery(COLLECTIONS.PROJECT_FILES, [['projectId', '==', activeProject!.projectId],["ledgerId", "==", props.ledgerId]]).then((snapshot) =>
            setFileList(snapshot.docs.map((doc) => doc.data() as ProjectFileModel)));
    }, [props.ledgerId]);

    return (
        <div style={{maxHeight: "300px", overflow: "auto"}}>
            {fileList.length > 0 ?
                fileList.length === 1 ? (
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                            marginLeft: "20px"
                        }}
                             className={"ledger-file"}
                             onClick={() => FirebaseUsage.downloadFileFromStorage(fileList[0])}
                        >
                            <FontAwesomeIcon icon={getFileIcon(fileList[0].type)} size={"2x"} style={{marginTop: "7px"}}  />
                            <p style={{marginLeft: "5px", marginTop: "5px"}}>{fileList[0].fileName}</p>
                        </div>
                    ) :
                    fileList.map((file: ProjectFileModel, key: number) => (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    cursor: "pointer"
                                }} className={"ledger-file"}
                                     onClick={() => FirebaseUsage.downloadFileFromStorage(file)}
                                     key={`${props.ledgerId}-${key}`}
                                >
                                    <FontAwesomeIcon icon={getFileIcon(file.type)} size={"2x"}
                                                     key={`${props.ledgerId}-${key}`} style={{marginTop: "7px"}}/>
                                    <p style={{marginLeft: "5px", marginTop: "5px"}}>{fileList[0].fileName}</p>
                                </div>
                            ))
                     : (<Loader active inline size={"small"} />)}
        </div>
    )
}