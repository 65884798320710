import * as taskActions from "../../actions/task.actions";
import TaskModel from "../../../models/responses/task.model";
import { Maybe } from "@martin_hotell/rex-tils";
import { TaskListSectionModel } from "../../../models/task-list-section.model";
import { TaskListLoading } from "../../../models/task-list-loading.model";
import { CalendarModel } from "../../../models/responses/calendar.model";

class RelatedTasksViewModel {
  open: boolean = false;
  list: TaskModel[] = [];
  loading: boolean = false;
}

export class StateModel {
  taskListLoadCount: number = 0;
  tasks: {
    inProgress: TaskModel[];
    confirmedComplete: TaskModel[];
    declaredComplete: TaskModel[];
    queued: TaskModel[];
    pending: TaskModel[];
  } = {
    inProgress: [],
    confirmedComplete: [],
    declaredComplete: [],
    queued: [],
    pending: [],
  };

  page: number = 1;
  activeTask: Maybe<TaskModel> = null;
  lightSelectedTask: Maybe<TaskModel> = null;
  taskListLoading: TaskListLoading = {
    ConfirmedCompleteIsLoading: false,
    DeclaredCompleteIsLoading: false,
    WorkInProgressIsLoading: false,
    QueuedIsLoading: false,
    PendingIsLoading: false,
  };
  moreTasks = {
    confirmedComplete: true,
    declaredComplete: true,
    queued: true,
    inProgress: true,
    pending: true,
  };
  toolbarLoadingMap: { [key: string]: boolean } = {};
  predecessors: RelatedTasksViewModel = new RelatedTasksViewModel();
  successors: RelatedTasksViewModel = new RelatedTasksViewModel();
  calendar: CalendarModel | null = null;
  refreshTaskList: boolean = false;
}

export const initialState = new StateModel();

let oldLength: number = 0;

export function reducer(state = initialState, action: taskActions.Actions) {
  switch (action.type) {
    case taskActions.ActionNames.SET_IN_PROGRESS_TASK_LIST: {
      const oldActiveTask = state.activeTask;
      const activeTask = oldActiveTask
        ? action.payload.taskList.find(
            (el) => el.task_id === oldActiveTask.task_id
          )
        : null;
      return {
        ...state,
        activeTask: activeTask ? activeTask : oldActiveTask,
        taskListLoadCount: state.taskListLoadCount + 1,
        tasks: {
          ...state.tasks,
          inProgress: action.payload.taskList,
        },
        taskListLoading: {
          ...state.taskListLoading,
          WorkInProgressIsLoading: false,
        },
        moreTasks: {
          ...state.moreTasks,
          inProgress:
            JSON.stringify(action.payload.taskList) !==
            JSON.stringify(state.tasks.inProgress),
        },
      };
    }
    case taskActions.ActionNames.SET_CONFIRMED_COMPLETE_TASK_LIST: {
      const oldActiveTask = state.activeTask;
      const activeTask = oldActiveTask
        ? action.payload.taskList.find(
            (el) => el.task_id === oldActiveTask.task_id
          )
        : null;
      return {
        ...state,
        activeTask: activeTask ? activeTask : oldActiveTask,
        taskListLoadCount: state.taskListLoadCount + 1,
        tasks: {
          ...state.tasks,
          confirmedComplete: action.payload.taskList,
        },
        taskListLoading: {
          ...state.taskListLoading,
          ConfirmedCompleteIsLoading: false,
        },
        moreTasks: {
          ...state.moreTasks,
          confirmedComplete:
            JSON.stringify(action.payload.taskList) !==
            JSON.stringify(state.tasks.confirmedComplete),
        },
      };
    }
    case taskActions.ActionNames.SET_CALENDAR: {
      return {
        ...state,
        calendar: action.payload.calendar,
      };
    }
    case taskActions.ActionNames.SET_DECLARED_COMPLETE_TASK_LIST: {
      const oldActiveTask = state.activeTask;
      const activeTask = oldActiveTask
        ? action.payload.taskList.find(
            (el) => el.task_id === oldActiveTask.task_id
          )
        : null;
      return {
        ...state,
        activeTask: activeTask ? activeTask : oldActiveTask,
        taskListLoadCount: state.taskListLoadCount + 1,
        tasks: {
          ...state.tasks,
          declaredComplete: action.payload.taskList,
        },
        taskListLoading: {
          ...state.taskListLoading,
          DeclaredCompleteIsLoading: false,
        },
        moreTasks: {
          ...state.moreTasks,
          declaredComplete:
            JSON.stringify(action.payload.taskList) !==
            JSON.stringify(state.tasks.declaredComplete),
        },
      };
    }
    case taskActions.ActionNames.SET_QUEUED_TASK_LIST: {
      const oldActiveTask = state.activeTask;
      const activeTask = oldActiveTask
        ? action.payload.taskList.find(
            (el) => el.task_id === oldActiveTask.task_id
          )
        : null;
      return {
        ...state,
        activeTask: activeTask ? activeTask : oldActiveTask,
        taskListLoadCount: state.taskListLoadCount + 1,
        tasks: {
          ...state.tasks,
          queued: action.payload.taskList,
        },
        taskListLoading: {
          ...state.taskListLoading,
          QueuedIsLoading: false,
        },
        moreTasks: {
          ...state.moreTasks,
          // queued: JSON.stringify(action.payload.taskList) !== JSON.stringify(state.tasks.queued)
          queued:
            JSON.stringify(action.payload.taskList) !==
              JSON.stringify(state.tasks.queued) &&
            action.payload.taskList.length > oldLength,
        },
      };
    }
    case taskActions.ActionNames.SET_PENDING_TASK_LIST: {
      const oldActiveTask = state.activeTask;
      const activeTask = oldActiveTask
          ? action.payload.taskList.find(
              (el) => el.task_id === oldActiveTask.task_id
          )
          : null;
      return {
        ...state,
        activeTask: activeTask ? activeTask : oldActiveTask,
        taskListLoadCount: state.taskListLoadCount + 1,
        tasks: {
          ...state.tasks,
          pending: action.payload.taskList,
        },
        taskListLoading: {
          ...state.taskListLoading,
          PendingIsLoading: false,
        },
        moreTasks: {
          ...state.moreTasks,
          // queued: JSON.stringify(action.payload.taskList) !== JSON.stringify(state.tasks.queued)
          pending:
              JSON.stringify(action.payload.taskList) !==
              JSON.stringify(state.tasks.pending) &&
              action.payload.taskList.length > oldLength,
        },
      };
    }
    case taskActions.ActionNames.SET_TASK_LIST_COUNT: {
        return {
            ...state,
            taskListLoadCount: action.payload.taskListCount,
        };
    }
    case taskActions.ActionNames.LOAD_MORE_TASK_LIST: {
      oldLength = state.tasks.queued.length;
      return {
        ...state,
        page: action.payload.page,
        taskListLoading: {
          ...state.taskListLoading,
          ConfirmedCompleteIsLoading:
            action.payload.taskListType ===
            TaskListSectionModel.CONFIRMED_COMPLETE,
          DeclaredCompleteIsLoading:
            action.payload.taskListType ===
            TaskListSectionModel.DECLARED_COMPLETE,
          WorkInProgressIsLoading:
            action.payload.taskListType ===
            TaskListSectionModel.WORK_IN_PROCESS,
          QueuedIsLoading:
            action.payload.taskListType === TaskListSectionModel.QUEUED,
          PendingIsLoading:
            action.payload.taskListType === TaskListSectionModel.PENDING,
        },
      };
    }
    case taskActions.ActionNames.SET_ACTIVE_TASK: {
      console.log("☢️ state in SET_ACTIVE_TASK", state);
      console.log("☢️ action in SET_ACTIVE_TASK", action);
      return {
        ...state,
        activeTask: action.payload.task,
      };
    }
    case taskActions.ActionNames.LIGHT_SELECTED_TASK: {
      return {
        ...state,
        lightSelectedTask: action.payload.task,
      };
    }
    case taskActions.ActionNames.TASK_TOOLBAR_LOADING: {
      return {
        ...state,
        toolbarLoadingMap: {
          ...state.toolbarLoadingMap,
          [action.payload.taskId]: true,
        },
      };
    }
    case taskActions.ActionNames.TASK_TOOLBAR_SUCCESS:
    case taskActions.ActionNames.TASK_TOOLBAR_FAIL: {
      return {
        ...state,
        toolbarLoadingMap: {
          ...state.toolbarLoadingMap,
          [action.payload.taskId]: false,
        },
      };
    }
    case taskActions.ActionNames.SET_REFRESH_TASK_LIST:
    case taskActions.ActionNames.UNSET_REFRESH_TASK_LIST: {
      return {
        ...state,
        refreshTaskList: action.payload.refreshTaskList,
      };
    }
    case taskActions.ActionNames.OPEN_RELATED_TASK_LIST: {
      return {
        ...state,
        // activeTask: state.activeTask ? state.activseTask : action.payload.mainTask,
        [action.payload.type]: {
          open: true,
          list: [],
          loading: true,
        },
      };
    }
    case taskActions.ActionNames.SET_RELATED_TASK_LIST: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: action.payload.list,
          loading: false,
        },
      };
    }
    case taskActions.ActionNames.CLOSE_RELATED_TASK_LIST: {
      console.log(" state in CLOSE_RELATED_TASK_LIST", state);
      console.log(" action in CLOSE_RELATED_TASK_LIST", action);
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: [],
          open: false,
        },
      };
    }

    case taskActions.ActionNames.SET_ACTIVE_TASK_CHECKLIST: {
      console.log("state in SET_ACTIVE_TASK_CHECKLIST", state);
      console.log("action in SET_ACTIVE_TASK_CHECKLIST", action);
      return {
        ...state,
        activeCheckList: action.payload.activeChecklist,
      };
    }

    case taskActions.ActionNames.SET_DEBUG_CPM_TASKLIST: {
      console.log("state in SET_DEBUG_CPM_TASKLIST", state);
      console.log("action in SET_DEBUG_CPM_TASKLIST", action);
      return {
        ...state,
        debugCpmTasklist: action.payload.tasklist,
      };
    }

    case taskActions.ActionNames.SORT_LIST: {
      console.log("👍 state in SORT_LIST", state);
      console.log("👍 action in SORT_LIST", action);
      return {
        ...state,
        tasks: action.payload.taskList,
      };
    }

    default:
      return state;
  }
}
