import React, { useState, useCallback, useEffect, useRef } from 'react';
import styles from './LandingPage.module.scss';
import {NavLink, useNavigate} from 'react-router-dom';
import RouteNameConstants from '../../constants/route-name.constants';
import classNames from 'classnames';
import { Form, Button, Image, Message, Loader, Dimmer } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { ProjectThunk } from '../../store/thunk/project.thunk';
import { Maybe } from '@martin_hotell/rex-tils';
import { FileApi } from '../../api/file.api';
import { ProjectsData } from '../ProjectPage/components/NavigationBarContainer/components/UploadProjectDataModal/UploadProjectData';
import FirebaseUsage from '../../firebase/firebase.usage';
import * as projectActions from "../../store/actions/project.actions";
import { UploadProjectDataTable } from '../ProjectPage/components/NavigationBarContainer/components/UploadProjectDataModal/components/UploadProjectDataTable';
import ProjectTransactions from "../../store/thunk/transactions/project.transactions";
import { useUserSelector } from '../../store/selectors/authorization.selectors';

const LandingPage: React.FC = () => {

    const dispatch = useDispatch();
    const user = useUserSelector();
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [receiveUpdates, setReceiveUpdates] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileTransformationLoading, setFileTransformationLoading] = useState(false);
    const [projects, setProjects] = useState<ProjectsData[]>([]);
    const [isProjectCreating, setIsProjectCreating] = useState<boolean>(false);
    const [isProjectCreated, setIsProjectCreated] = useState<boolean>(false);
    const [emailForStressTest, setEmailForStressTest] = useState<string>('');
    const [localErrorMessage, setLocalErrorMessage] = useState<string>('');
    const [isBrochureDownloading, setIsBrochureDownloading] = useState<boolean>(false);
    const [downloadMessage, setDownloadMessage] = useState<string>('');
    const [isProjectAdded, setIsProjectAdded] = useState<boolean>(false)
    const downloadLinkRef = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isProjectCreated) {
            setIsProjectCreating(false);
            setIsProjectCreated(false);
            setIsProjectAdded(true);
        }
    }, [isProjectCreated])

    useEffect(() => {
        if (!projects.length) {
            setLocalErrorMessage('');
        }
        if (projects.some(project => project.exist)) {
            setLocalErrorMessage(`Project(s) "${projects.filter(el => el.exist).map(el => el.name).join(',')}" already exist! Please rename before continuing`)
        } else {
            setLocalErrorMessage('');
        }
    }, [projects]);

    const downloadHandler = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setDownloadMessage('');
        if (email && firstName && lastName && receiveUpdates) {
            setIsBrochureDownloading(true);
            await ProjectTransactions.sendMails(email, firstName, lastName, (jobTitle || phoneNumber || company) ? {
                phoneNumber: phoneNumber || '',
                jobTitle: jobTitle || '',
                company: company || ''
            } : undefined)
            setShowWarning(false);
            setIsBrochureDownloading(false);
            setDownloadMessage('We sent you an email. You can check it now.');
        } else {
            setShowWarning(true);
        }
    }, [company, email, firstName, lastName, jobTitle, phoneNumber, receiveUpdates])

    const checkOnProjectExits = useCallback(async (projectList: { name: string }[]) => {
        return FirebaseUsage.functions('checkIfProjectExist')(projectList).then(data => data.data) as any;
    }, []);

    const onAddFile = useCallback(async (files: Maybe<FileList>) => {
        if (!files) {
            return;
        }

        let error = false;

        const handlingFilesFormat = (files: FileList, index = 0) => {
            const file = files.item(index);
            if (file && file.name.slice(file.name.length - 3) !== 'xer') {
                error = true;
            }
            if (files.item(index + 1)) {
                handlingFilesFormat(files, index + 1)
            }
        };

        handlingFilesFormat(files);

        if (error) {
            return;
        }

        setFileTransformationLoading(true);
        const jsonData = await FileApi.transformXerToJson(files.item(0), false, null, user);
        const newProjectList: any[] = [];
        jsonData.forEach((data, i) => {
            newProjectList.push(data.errorMessage ? {
                errorMessage: data.errorMessage} : {
                name: data[i][0],
                id: data[i][1],
            })
        });
        const existingProjects: any[] = await checkOnProjectExits(newProjectList.filter(el => el.data).map(e => {return {name:e.name}}));
        console.log(newProjectList.filter(el => el.data));

        setProjects(newProjectList.map(project => ({
            ...project,
            exist: existingProjects.some(el => el === project.name)
        })));
        setFileTransformationLoading(false);
    }, [checkOnProjectExits]);

    const createNewProject = useCallback(async (projects: any[], callback: () => void, email?: string) => {
        const startTime = new Date().getTime();
        setIsProjectCreating(true);
        try {
            // @ts-ignore
            dispatch(ProjectThunk.uploadProject2(projects, callback,
                (projectId) => {
                    navigate(`/${projectId}/${RouteNameConstants.FLOW}`)
                }
            ))
        } catch (e:any) {
            console.log(e);
            dispatch(projectActions.Actions.uploadingNewProjectFinished());
            dispatch(projectActions.Actions.uploadingNewProjectFail(e.message ? e.message : 'Something went wrong with transforming XER to JSON'));
        }
        setProjects([]);
        console.log(`createNewProject Took ${(new Date().getTime() - startTime) / 1000} s`);
        setIsProjectCreated(true);
    }, [dispatch]);

    const uploadProject = useCallback((event: React.MouseEvent<HTMLButtonElement>, email: string) => {
        event.stopPropagation();
        event.preventDefault();
        setTimeout(() => {
            createNewProject(projects, () => {
                setProjects([]);
            }, email).catch(e => console.log(e));
        }, 100);
    }, [projects, createNewProject]);

    const submitHandler = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsLoading(true);
        setDownloadMessage('')
        e.preventDefault();
        if (email && firstName && lastName && jobTitle && phoneNumber && company) {
            setIsBrochureDownloading(true);
            await ProjectTransactions.sendMails(email, firstName, lastName, (jobTitle || phoneNumber || company) ? {
                phoneNumber: phoneNumber || '',
                jobTitle: jobTitle || '',
                company: company || ''
            } : undefined)
            setShowWarning(false);
            setIsBrochureDownloading(false);
            setDownloadMessage('We sent you an email. You can check it now.')
        } else {
            setShowWarning(true);
        }
        setIsLoading(false);
    }, [company, email, firstName, lastName, jobTitle, phoneNumber])

    const onChangeProjectNameHandler = useCallback(async (data: { [key: number]: string }) => {
        const newProjectList = projects.map((el, index) => ({
            ...el,
            name: data[index] ? data[index] : el.name
        }));
        console.log(newProjectList);
        const existingProjects: string[] = await checkOnProjectExits(newProjectList.map(e => {return {name:e.name}}));

        setProjects(newProjectList.map(project => ({
            ...project,
            exist: existingProjects.some(el => el === project.name)
        })));
    }, [projects, checkOnProjectExits]);

    return (
        <div
            className={styles.LandingPage}
        >
            {
                isBrochureDownloading &&
                <Dimmer active>
                    <Loader indeterminate>Sending Emails...</Loader>
                </Dimmer>
            }
            <header className={styles.Header}>
                <NavLink
                    className={styles.Logo}
                    to={`/${RouteNameConstants.FLOW}`}
                >
                    Flowledger
                </NavLink>
                <nav className={styles.Navigation}>
                    <a className={styles.NavLink} href="#dynamic">Project Planning</a>
                    <a className={styles.NavLink} href="#brochure">Get Brochure</a>
                    <a className={styles.NavLink} href="#demo">Watch Demo</a>
                    <a className={styles.NavLink} href="#performance">Performance</a>
                    <a className={styles.NavLink} href="#call">Contact Us</a>
                    <a className={styles.NavLink} href="#stress-test">Stress Test</a>
                </nav>
                <NavLink
                    className={styles.Login}
                    to={`/${RouteNameConstants.LOGIN}`}
                >
                    <FontAwesomeIcon icon={faSignIn} size='lg' />
                </NavLink>
            </header>
            <div
                className={styles.Hero}
            >
                <Image className={styles.LogoImage} src='images/logo.png' />
                <Image className={styles.Banner} src='images/banner.jpg' centered />
            </div>
            <main className={styles.Main}>
                <section id='dynamic' className={classNames(styles.Section)}>
                    <h3 className={styles.SectionHeader}>Move to Dynamic Project Planning</h3>
                    <Image className={styles.ImageFlow} src='images/screen-fl.png' centered />
                    <div>
                        <h5 className={styles.SectionSubTitle}>
                            FlowLedger’s online project planning platform is specially designed for complex project planning in construction and infrastructure.
                        </h5>
                        <ul className={styles.List}>
                            <li className={styles.ListItem}>Empowering project planners to transform project productivity and delivery to a standard unseen before.</li>
                            <li className={styles.ListItem}>Real-time Dynamic Project Planning, powered by AI, Lean Six-Sigma and DMAIC engines.</li>
                            <li className={styles.ListItem}>Exploit hidden efficiency opportunities to optimise delivery.</li>
                        </ul>
                    </div>
                </section>
                <section id='brochure' className={classNames(styles.Section)}>
                    <h3 className={styles.SectionHeader}>Download the brochure</h3>
                    {
                        showWarning &&
                        <Message negative>
                            <Message.Header>Fill the required fields</Message.Header>
                        </Message>
                    }
                    {
                        downloadMessage &&
                        <Message positive>
                            <Message.Header>{downloadMessage}</Message.Header>
                        </Message>
                    }
                    {
                        !isLoading
                            ? (
                                <Form className={styles.Form}>
                                    <Form.Input value={email} onChange={(_, data) => setEmail(data.value)} className={styles.Input} label='Your email' type='email' required />
                                    <Form.Input value={phoneNumber} onChange={(_, data) => setPhoneNumber(data.value)} className={styles.Input} label='Your mobile phone number' type='text' />
                                    <Form.Input value={firstName} onChange={(_, data) => setFirstName(data.value)} className={styles.Input} label='Your first name' type='text' required />
                                    <Form.Input value={lastName} onChange={(_, data) => setLastName(data.value)} className={styles.Input} label='Your last name' type='text' required />
                                    <Form.Input value={company} onChange={(_, data) => setCompany(data.value)} className={styles.Input} label='Your company' type='text' />
                                    <Form.Input value={jobTitle} onChange={(_, data) => setJobTitle(data.value)} className={styles.Input} label='Your job title' type='text' />
                                    <Form.Checkbox onChange={(_, data) => setReceiveUpdates(Boolean(data.checked))} className={styles.Checkbox} label='I agree to receive product updates' required />
                                    <Button onClick={downloadHandler} className={styles.SubmitButton} primary type='submit'>Download</Button>
                                    <a ref={downloadLinkRef} id="download-link" href="images/mypdf.pdf" download hidden>Download</a>
                                </Form>
                            )
                            : <Loader className={styles.Loader} active inline />
                    }
                </section>
                <section id='demo' className={classNames(styles.Section)}>
                    <h3 className={styles.SectionHeader}>Watch demo</h3>
                    <div style={{width: '100%', height: '500px'}}></div>
                    {/*<iframe
                        width="560"
                        height="315"
                        src="link here"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />*/}
                </section>
                <section id='performance' className={styles.Section}>
                    <h3 className={styles.SectionHeader}>Add value to performance where it matters most</h3>
                    <ul className={styles.List}>
                        <li className={styles.ListItem}>
                            <div className={styles.ListTitle}>Workflow Control</div>
                            <div>Pull System sorts and prioritises tasks</div>
                        </li>
                        <li className={styles.ListItem}>
                            <div className={styles.ListTitle}>Float Control</div>
                            <div>Recommendation Engine constantly responds to status changes and feeds back into the pull system</div>
                        </li>
                        <li className={styles.ListItem}>
                            <div className={styles.ListTitle}>Single-Source Ledger</div>
                            <div>Real-time performance data for accountability, transparency and reporting</div>
                        </li>
                    </ul>
                </section>
                <section id='call' className={styles.Section}>
                    <h3 className={styles.SectionHeader}>Request a discovery call</h3>
                    {
                        downloadMessage &&
                        <Message positive>
                            <Message.Header>{downloadMessage}</Message.Header>
                        </Message>
                    }
                    {
                        showWarning &&
                        <Message negative>
                            <Message.Header>Fill the required fields</Message.Header>
                        </Message>
                    }
                    <Form className={styles.Form}>
                        <Form.Input value={email} onChange={(_, data) => setEmail(data.value)} className={styles.Input} label='Your email' type='email' required />
                        <Form.Input value={phoneNumber} onChange={(_, data) => setPhoneNumber(data.value)} className={styles.Input} label='Your mobile phone number' type='text' required />
                        <Form.Input value={firstName} onChange={(_, data) => setFirstName(data.value)} className={styles.Input} label='Your first name' type='text' required />
                        <Form.Input value={lastName} onChange={(_, data) => setLastName(data.value)} className={styles.Input} label='Your last name' type='text' required />
                        <Form.Input value={company} onChange={(_, data) => setCompany(data.value)} className={styles.Input} label='Your company' type='text' required />
                        <Form.Input value={jobTitle} onChange={(_, data) => setJobTitle(data.value)} className={styles.Input} label='Your job title' type='text' required />
                        <Button onClick={submitHandler} className={styles.SubmitButton} primary type='submit'>Submit</Button>
                    </Form>
                </section>
                <section id='stress-test' className={styles.Section}>
                    <h3 className={styles.SectionHeader}>Stress Test</h3>
                    <p style={{textAlign: 'center'}}>
                        Is your project overheating or keeping its cool?
                        Please provide your email and latest schedule to get an instant response
                    </p>
                    {   localErrorMessage &&
                         <Message negative>
                            <Message.Header>{localErrorMessage}</Message.Header>
                        </Message>
                    }
                    {   isProjectAdded &&
                         <Message positive>
                            <Message.Header>Project was added successfully</Message.Header>
                        </Message>
                    }
                    <Form className={classNames(styles.Form, styles.FormFlex)}>
                        <Form.Input value={emailForStressTest} onChange={(_, data) => setEmailForStressTest(data.value)} className={styles.Input} label='Your email' type='email' required />
                        <div
                            className={projects.length ? styles.FormAddWrapperFilled : styles.FormAddWrapper}
                        >
                            {projects.length
                                ? <UploadProjectDataTable
                                    projects={projects}
                                    onChangeProjectName={onChangeProjectNameHandler}
                                    onSetClientId={()=>{}}
                                />
                                : <div style={{paddingRight: '10px', width: '50%'}}>
                                    <div className={styles.UploadingInputWrapper}>
                                        <input
                                            style={{padding: 0, height: '38px', cursor: 'pointer'}}
                                            type="file" name="file" id="file" className="inputfile"
                                            accept=".xer"
                                            disabled={fileTransformationLoading}
                                            onChange={evt => {
                                                evt.preventDefault();
                                                evt.stopPropagation();
                                                onAddFile(evt.target.files);
                                            }}
                                        />
                                        <p
                                            className={styles.PlaceholderInput}
                                        >
                                            Add XER file
                                        </p>
                                    </div>
                                </div>
                            }
                            <div
                                className={styles.UploadingButton}
                            >
                                <Button
                                    onClick={(event) => uploadProject(event, emailForStressTest)}
                                    disabled={projects.some(project => project.exist) || fileTransformationLoading || !emailForStressTest || !projects.length || !user}
                                    content='Upload'
                                />
                            </div>
                        </div>
                        <p className={styles.Point}>*Currently only support Primavera P6</p>
                    </Form>
                </section>
            </main>
            <footer
                className={styles.Footer}
            >
                <div className={styles.Contacts}>
                    <div className={styles.ContactsInfo}>
                        Contacts:
                    </div>
                    <div className={styles.Partner}>
                        Partnerships Director: helen.turner@flowledger.app / +44 (0) 7873 165291
                    </div>
                    <div className={styles.Founder}>
                        Founder: rhys.tanner@flowledger.app
                    </div>
                </div>
                <div className={styles.Registered}>
                    Registered in England number, address
                </div>
            </footer>
            {
                isProjectCreating &&
                <Dimmer active>
                    <Loader indeterminate>Creating a project...</Loader>
                </Dimmer>
            }
            {
                fileTransformationLoading &&
                <Dimmer active>
                    <Loader indeterminate >Transforming file</Loader>
                </Dimmer>
            }
        </div>
    );
}

export default LandingPage;