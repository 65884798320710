export default {
  apiKey: "AIzaSyB0dlskkSNauWEH21U_Vligh8SMa0g4b4o",
  authDomain: "flowledgerprod.firebaseapp.com",
  projectId: "flowledgerprod",
  fileApiUrl: "https://europe-west3-flowledgerprod.cloudfunctions.net/get-file-api",
  storageBucket: "flowledgerprod.appspot.com",
  messagingSenderId: "854414998693",
  appId: "1:854414998693:web:22c9d36e8effb18b43614c",
  xerUploadBucketName: "fl-xerfiles-bucket-prod",
  measurementId: "G-8SNT9RR6TN",
  vapidKey:
    'BLRZ4mYS-NbzMKCg7CuZLIK7TbknzfcPftiAJ6HUkWmGRjz03Gq3kuAGn69Oa7XVt0ejObuBPTwY6IM53ECOKTY',
};
