import * as React from 'react';
import {Input} from 'semantic-ui-react';


const ReduxInput: React.FunctionComponent<any> = ({
                                        input,
                                        label,
                                        meta: {touched, error},
                                        ...custom
                                    }) => {
    return (
        <div>
            <Input
                style={{width: '100%'}}
                label={label}
                error={touched && !!error}
                {...input}
                {...custom}
            />
        </div>
    );
};


export default ReduxInput;
