import React, {useEffect, useState} from 'react';
import {COLLECTIONS} from "../../../../../firebase/constants";
import FirebaseUsage from "../../../../../firebase/firebase.usage";
import ImageModel from "../../../../../models/responses/image.model";
import {Dimmer, Loader, Modal} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faMapLocationDot} from "@fortawesome/pro-light-svg-icons";
import {useDispatch} from "react-redux";
import {useActiveProjectSelector, useGeoDataSelector} from "../../../../../store/selectors/project.selectors";
import GeoModel from "../../../../../models/responses/geo.model";
import {Actions} from "../../../../../store/actions/project.actions";

export default function LedgerImagePreview (props) {
    const [imageList, setImageList] = useState<any>([]);
    const [modalImage, setModalImage] = useState<any>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    const geoData = useGeoDataSelector()
    const activeProject = useActiveProjectSelector()


    useEffect(() => {
        FirebaseUsage.getCompoundQuery(COLLECTIONS.IMAGES, [['projectId', '==', activeProject!.projectId], ["ledgerId", "==", props.ledgerId]]).then((snapshot) =>
            setImageList(snapshot.docs.map((doc) => doc.data())));
    }, [props.ledgerId]);

    function downloadImageForModal(image: ImageModel) {
        FirebaseUsage.downloadImageAsBlob(image).then((url) => setModalImage(url))
    }


    return (
        <div>
            {imageList.length > 0 ?
                imageList.length === 1 ? (
                    <div>
                        <img style={{marginTop: "7px"}} src={imageList[0].src} alt={imageList[0].fileName}
                             className={"ledger-image"} onClick={() => {
                            setShowModal(true)
                            downloadImageForModal(imageList[0])
                        }}/>
                        <span className='ledger-item-actions'>
                            <FontAwesomeIcon icon={faCloudArrowDown} className="ledger-item-action"
                                             onClick={() => FirebaseUsage.downloadImageFromStorage(imageList[0])}/>
                            <FontAwesomeIcon icon={faMapLocationDot}
                                             className={imageList[0].hasGeoData ?
                                                 "ledger-item-action" :
                                                 "ledger-item-action-inactive"}
                                             onClick={() => {
                                                 if (!imageList[0].hasGeoData) return;
                                                 const geoPoint = geoData.find((geo: GeoModel) => geo.imageId === imageList[0].imageId)
                                                 if (geoPoint) {
                                                     dispatch(Actions.setActiveGeo({geo: geoPoint, fileName: imageList[0].fileName}))
                                                     dispatch(Actions.setView('task-map'))
                                                 }
                                             }}/>
                        </span>
                    </div>
                        ) :
                        (
                        <div style={{marginTop: "7px"}} className="ledger-image-container">
                            {imageList.map((image: ImageModel, key: number) => (
                                <div className="ledger-image-multiple" key={`ledgerImage=${key}`}>
                                    <img key={`${props.ledgerId}-${key}`} src={image.src} alt={image.fileName}
                                         className={"ledger-image-small"} onClick={() => {
                                                setShowModal(true)
                                                downloadImageForModal(image)
                                         }}/>
                                </div>
                            ))
                            }
                        </div>
                        ) : (<Loader active inline size={"small"}/>)}
            {modalImage ?
            <Modal open={showModal} onClose={() => {
                setShowModal(false)
                setModalImage(null)
            }} closeIcon
                   style={{width: "auto", height: "auto"}}
            >
                <Modal.Content>
                    <div className="modal-image-container">
                        <img src={modalImage} alt={modalImage?.fileName}
                             className={"ledger-image-large"}
                        />
                    </div>
                </Modal.Content>
                            <Modal.Actions>
                    <FontAwesomeIcon icon={faCloudArrowDown} className="ledger-item-action" style={{fontSize: "22px"}}
                                     onClick={() => FirebaseUsage.downloadImageFromStorage(modalImage)}/>
                    <FontAwesomeIcon icon={faMapLocationDot}  style={{fontSize: "22px"}}
                                     className={modalImage?.hasGeoData ?
                                         "ledger-item-action" :
                                         "ledger-item-action-inactive"}
                                     onClick={() => {
                                         if (!modalImage?.hasGeoData) return;
                                         const geoPoint = geoData.find((geo: GeoModel) => geo.imageId === modalImage.imageId)
                                         if (geoPoint) {
                                             dispatch(Actions.setActiveGeo({geo: geoPoint, fileName: modalImage.fileName}))
                                             setModalImage(null)
                                             dispatch(Actions.setView('task-map'))
                                         }
                                     }}/>
                </Modal.Actions>
            </Modal> : showModal &&
                <Dimmer inline="true" active>
                    <Loader inline size={"large"} inverted/>
                </Dimmer>}
        </div>
    )
}