import {applyMiddleware, createStore} from 'redux';
import * as fromRoot from './reducers/index';
import {composeEnhancers} from './utils';
import thunk from 'redux-thunk';
import {combineMiddleware} from "./middleware";

export function configureStore(initialState?: fromRoot.RootStateModel) {

    const devsMiddleware = [thunk];

    const enhancer = composeEnhancers(
        applyMiddleware(
            ...devsMiddleware,
            ...combineMiddleware,
        )
    );

    return createStore(fromRoot.reducers, initialState, enhancer);
}

const store = configureStore();

export default store;
