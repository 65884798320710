import FirebaseUsage from "../firebase.usage";
import {COLLECTIONS} from "../constants";
import {DefaultSubscribeCallback} from "../../store/middleware/middlewares";
import {CalendarModel} from "../../models/responses/calendar.model";

export class CalendarSubscriptions {
    static calendar(projectId: string, calendarId: string, callback: DefaultSubscribeCallback<CalendarModel | null>) {
        return FirebaseUsage.getCompoundQuery(COLLECTIONS.CALENDAR, [['project_id', '==', projectId], ['calendar_id', '==', calendarId]])
            .then(data => {
                if (data.docs[0]) {
                    callback(data.docs[0].data() as CalendarModel)
                } else {
                    callback(null)
                }
            });
    }
}
