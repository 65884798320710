import * as wbsActions from '../../actions/wbs.actions';
import {WbsModel} from "../../../models/responses/wbs.model";

export class StateModel {
    wbsList: WbsModel[] = [];
    wbsListLoading = true;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: wbsActions.Actions) {
    switch (action.type) {
        case wbsActions.ActionNames.SET_WBS_LIST: {
            return {
                ...state,
                wbsList: action.payload.wbsList,
                wbsListLoading: false
            }
        }
        default:
            return state;
    }
}
