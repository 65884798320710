export const attributeInfo = [
    {name: "record_type",
        description: "The type of project record",
        type: "string <'project' | 'task' | 'project member'>"},
    {name: "task_code",
        description: "The user generated unique identifier of the task",
        type: "string"},
    {name: "task_name",
        description: "The task description. A string describing what the task is",
        type: "string"},
    {name: "task_id",
        description: "The firestore database unique identifier of the task",
        type: "string"},
    {name: "wbs",
        description: "The work breakdown structure description of the task's parent breakdown structure object",
        type: "string"},
    {name: "wbs_path",
        description: "The full path of the task's parent breakdown structure object",
        type: "string"},
    {name: "completion_status",
        description: "The completion status of the task",
        type: "string <'not started' | 'in progress' | 'completed' | 'suspended' | 'declared complete'>"},
    {name: "task_type",
        description: "The type of the task. Most will be either 'task' or 'start/finish milestone'",
        type: "string"},
    {name: "project_id",
        description: "The firestore database unique identifier of the project",
        type: "string"},
    {name : "task_readiness",
        description: "The readiness of the task",
        type: "string"},
    {name: "flow_status",
        description: "The position of the task within the process flow",
        type: "string"},
    {name: "deadline",
        description: "The deadline date of the task. If the early_finish_date or actual_finish_date is after the deadline, the task is overdue",
        type: "timestamp"},
    {name: "early_start_date",
        description: "The earliest start date of the task",
        type: "timestamp"},
    {name: "early_finish_date",
        description: "The earliest finish date of the task",
        type: "timestamp"},
    {name: "late_start_date",
        description: "The latest possible start date of the task without delaying the project",
        type: "timestamp"},
    {name: "late_finish_date",
        description: "The latest possible start date of the task without delaying the project",
        type: "timestamp"},
    {name: "baseline_early_start_date",
        description: "The early start date of the baseline task",
        type: "timestamp"},
    {name: "baseline_early_finish_date",
        description: "The early finish date of the baseline task",
        type: "timestamp"},
    {name: "baseline_late_start_date",
        description: "The late start date of the baseline task",
        type: "timestamp"},
    {name: "baseline_late_finish_date",
        description: "The late finish date of the baseline task",
        type: "timestamp | null"},
    {name: "act_start_date",
        description: "The actual start date of the task. Null if the task has not started",
        type: "timestamp | null"},
    {name: "act_end_date",
        description: "The actual end date of the task. Null if the task is not complete",
        type: "timestamp"},
    {name: "predecessor_relationships",
        description: "The relationships of the task with its predecessors",
        type: "an array of objects"},
    {name: "successor_relationships",
        description: "The relationships of the task with its successors",
        type: "an array of objects"},
    {name: "remaining_duration_days",
        description: "The remaining duration of the task in days",
        type: "float"},
    {name: "original_duration_days",
        description: "The original duration of the task in days",
        type: "float"},
    {name: "baseline_duration_days",
        description: "The baseline duration of the task in days",
        type: "float"},
    {name: "baseline_remaining_duration_days",
        description: "The remaining duration of the baseline task in days",
        type: "float"},
    {name: "task_suspended",
        description: "The suspension status of the task",
        type: "string <'yes' | 'no'>"},
    {name: "task_blocked",
        description: "The blocked status of the task",
        type: "string <'yes' | 'no'>"},
    {name: "task_critical",
        description: "The critical status of the task",
        type: "string <'yes' | 'no'>"},
    {name: "total_float_days",
        description: "The total float of the task in days if less than or equal to 0 then the task is critical",
        type: "float"},
    {name: "priority_order",
        description: "The priority order of the task. Only applicable to incomplete tasks",
        type: "integer"},
    {name: "task_prerequisite_checklist",
        description: "The checklist of the task prerequisites",
        type: "an array of objects"},
    {name: "is_recommended_to_be_worked_on",
        description: "A yes-no string denoting whether the recommendation engine suggests this task should be being worked on.",
        type: "string <'yes' | 'no'>"},
    {name: "task_force_members",
        description: "The members of the task force using their display names or email addresses",
        type: "an array of strings"},
    {name: "event_log",
        description: "A list of all of the events that have occurred on this task created by user inputs",
        type: "an array of objects"},
    {name: "user_id",
        description: "The firestore database unique identifier of the user",
        type: "string"},
    {name: "grade" ,
        description: "The membership grade of the user",
        type: "string"},
    {name: "user_email",
        description: "The email of the user",
        type: "string"},
    {name: "blocked_task_list",
        description: "An object with the details of the tasks that are blocked",
        type: "object"},
    {name: "suspended_task_list",
        description: "An object with the details of the tasks that are suspended",
        type: "object"},
    {name: "total_task_count",
        description: "The total number of tasks in the project",
        type: "integer"},
    {name: "issue_categories",
        description: "The list of reasons why tasks don't go as planned",
        type: "an array of objects"},
    {name: "project_name",
        description: "The name of the project",
        type: "string"},
    {name: "project_member_count",
        description: "The number of members in the project",
        type: "integer"},
]