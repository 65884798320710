// import { HNSWLib } from "@langchain/community/vectorstores/hnswlib";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import {OpenAIEmbeddings} from "@langchain/openai";
import { Document } from "@langchain/core/documents";

import store from "../store/store";
import {CpmTaskModel} from "../models/responses/cpm-task.model";
import ProjectMembersModel from "../models/responses/project-members.model";
import FirebaseUsage from "../firebase/firebase.usage";
import {COLLECTIONS} from "../firebase/constants";
import LedgerEntry from "../models/responses/ledger-entry.model";

export async function createVectorStore (projectId: string) {
    console.log("Creating vector store")
    // const llm = new ChatOpenAI({
    //     apiKey: process.env.OPENAI_API_KEY,
    //     model: "gpt-4o",
    //     temperature: 0
    // });
    const projectCalendars = store.getState().project.activeProjectCalendars
    const embeddings = new OpenAIEmbeddings();
    const cpmMap = store.getState().project.cpmMap
    const projectMembers = store.getState().authorization.membersMap
    const project = store.getState().project.activeProject
    // @ts-ignore
    const userMap = new Map(projectMembers.map((user: ProjectMembersModel) => [user.userId, user]))

    let ledgerEntries = await FirebaseUsage.getQuery(COLLECTIONS.LEDGER_ENTRY, ['projectId', '==', project!.projectId])
        .then((data) => data.docs.map((el) => el.data() as LedgerEntry));

    function handleBool(bool: boolean) {
        if (bool) return "yes"
        return "no"
    }

    const predStatusConverter = {
        1: "This task is ready to start, all predecessor conditions are met",
        2: "Nearly ready to start, there are some outstanding dependencies",
        3: "Not ready to start, all dependencies are outstanding",
        4: "This task is complete"
    }

    const relationshipTypeConverter = {
        "FS": "Finish to Start",
        "SS": "Start to Start",
        "FF": "Finish to Finish",
        "SF": "Start to Finish"
    }

    const taskTypeConverter = {
        "TT_Task": "Task",
        "TT_FinMile": "Finish Milestone",
        "TT_Mile": "Start Milestone",
        "TT_WBS": "WBS Summary",
        "TT_TASK": "Task",
        "TT_LOE": "Level of Effort",
    }

    const MessageType = {
        MSG: "MSG",
        APN: "APN",
        RST: "RST",
        ISS: "ISS",
        RNM: "RNM",
        IMG: "IMG",
        CST: "CST",
        NEW: "NEW",
        STD: "STD",
        DIR: "DIR",
        BLC: "BLC",
        UNB: "UNB",
        INC: "INC",
        INF: "INF",
        OTH: "OTH",
        RES: "RES",
        DEC: "DEC",
        REJ: "REJ",
        COM: "COM",
        TFJ: "TFJ",
        TFL: "TFL",
        SUS: "SUS",
        EVL: "EVL",
        ATF: "ATF",
        RTF: "RTF",
        WTG: "WTG",
        CHK: "CHK",
        FOR: "FOR",
        TBS: "TBS",
        FIL: "FIL",
    }

    function getConfForMsg(message, user) {
        let text = "";
        switch (message.type) {
            case MessageType.STD:
                text = "Task started";
                break;
            case MessageType.RST:
                text = "Task resumed";
                break;
            case MessageType.MSG:
                text = "Comment";
                break;
            case MessageType.DIR:
                text = "Task suspended due to directive";
                break;
            case MessageType.INC:
                text = "Task suspended due to incident";
                break;
            case MessageType.INF:
                text = "Task suspended due to information";
                break;
            case MessageType.REJ:
                text = "Declaration rejected";
                break;
            case MessageType.RES:
                text = "Task suspended due to resource";
                break;
            case MessageType.DEC:
                text = "Task declared complete";
                break;
            case MessageType.COM:
                text = "Task complete";
                break;
            case MessageType.RNM:
                text = "Task renamed";
                break;
            case MessageType.SUS:
                text = `Task suspended due to ${message.suspendReason}`;
                break;
            case MessageType.ISS:
                text = `Task issue logged due to ${message.suspendReason}`;
                break;
            case MessageType.TFJ:
                text =
                    user?.userEmail === message.userEmail ? "Taskforce joined"
                        : message.userEmail + " has been assigned to this task";
                break;
            case MessageType.TFL:
                text = user?.userEmail === message.userEmail ? "Taskforce left" :
                    message.userEmail + " has been removed from this task";
                break;
            case MessageType.WTG:
                text = "Task waiting to start";
                break;
            case MessageType.BLC:
                text = `Task blocked due to ${message.suspendReason}`;
                break;
            case MessageType.UNB:
                text = "Task unblocked";
                break;
            case MessageType.CHK:
                text = "Checklist complete - task is ready to start";
                break;
            case MessageType.FOR:
                text = "Expected task finish date revised";
                break;
            case MessageType.CST:
                text = "Task start constrained";
                break;
            case MessageType.TBS:
                text = "This task is behind schedule, status report required";
                break;
            case MessageType.IMG:
                if (message.attachments && message.attachments.length > 1) {
                    text = `${message.attachments.length} images attached`;
                    break;
                }
                else {
                    text = "Image attached";
                    break;
                }
            case MessageType.FIL:
                if (message.attachments && message.attachments.length > 1) {
                    text = `${message.attachments.length} files attached`;
                    break;
                }
                else {
                    text = "File attached";
                    break;
                }
        }

        return {text};
    }

    const handleNull = (value) => {
        if (!value) return "not applicable"
        return value
    }

    const tasks =
        [...store.getState().task.tasks.confirmedComplete,
            ...store.getState().task.tasks.declaredComplete,
            ...store.getState().task.tasks.inProgress,
            ...store.getState().task.tasks.pending
        ];

    const taskReferenceMap = new Map(tasks.map(task => [task.task_id, task]))

    let outputData: any = []
    let taskInputData = new Map()
    for (const task of tasks) {
        const { task_code,
            task_name,
            task_id,
            wbs,
            wbsPath,
            status,
            task_type,
            projectId,
            taskListType,
            expiryDate,
            early_start_date,
            early_end_date,
            late_start_date,
            late_end_date,
            blEarlyStartDate,
            blEarlyEndDate,
            blLateStartDate,
            blLateEndDate,
            predStatus,
            act_start_date,
            act_end_date,
            relations,
            remainingDuration,
            targetDuration,
            blTargetDuration,
            blRemainingDuration,
            suspended,
            blocked,
            float,
            index,
            checklist,
            flow,
            taskForce,
        } = task

        const cpmTask: CpmTaskModel = cpmMap.get(task_id)
        const workingHoursDay = projectCalendars.get(`whd:${cpmTask.cal_id}`) || 8

        // prepare data to be read by a LLM model
        const taskRecord = {
            record_type: "task",
            task_code,
            task_name,
            task_id,
            wbs,
            wbs_path: wbsPath,
            completion_status: status,
            task_type: taskTypeConverter[task_type],
            project_id: projectId,
            task_readiness: predStatusConverter[predStatus],
            flow_status: taskListType,
            deadline: handleNull(expiryDate),
            early_start_date: handleNull(early_start_date),
            early_finish_date: handleNull(early_end_date),
            late_start_date: handleNull(late_start_date),
            late_finish_date: handleNull(late_end_date),
            baseline_early_start_date: handleNull(blEarlyStartDate),
            baseline_early_finish_date: handleNull(blEarlyEndDate),
            baseline_late_start_date: handleNull(blLateStartDate),
            baseline_late_finish_date: handleNull(blLateEndDate),
            act_start_date: handleNull(act_start_date),
            act_end_date: handleNull(act_end_date),
            predecessor_relationships: relations
                .filter(rel => rel.task_id === task_id)
                .map(rel => ({
                    predecessor_id: taskReferenceMap.get(rel.pred_task_id)!.task_code,
                    relationship_type: relationshipTypeConverter[rel.pred_type],
                    lag: rel.lag
                })),
            successor_relationships: relations
                .filter(rel => rel.pred_task_id === task_id)
                .map(rel => ({
                    successor_id: taskReferenceMap.get(rel.task_id)!.task_code,
                    relationship_type: relationshipTypeConverter[rel.pred_type],
                    lag: rel.lag
                })),
            remaining_duration_days: remainingDuration / (workingHoursDay * 2),
            original_duration_days: targetDuration / (workingHoursDay * 2),
            baseline_duration_days: handleNull(blTargetDuration ? blTargetDuration / (workingHoursDay * 2) : null),
            baseline_remaining_duration_days: handleNull(blRemainingDuration ? blRemainingDuration / (workingHoursDay * 2) : null),
            task_suspended: handleBool(suspended),
            task_blocked: handleBool(blocked),
            task_critical: handleBool(float <= 1),
            total_float_days: Math.floor(float / (workingHoursDay * 2)),
            priority_order: index ? index : "N/A",
            task_prerequisite_checklist: checklist,
            is_recommended_to_be_worked_on: handleBool(flow),
            task_force_members: taskForce.map(member => {
                const user = userMap.get(member)
                if (!user) return null
                // @ts-ignore
                if (user.displayName) return user.displayName
                // @ts-ignore
                return user.userEmail
            }),
            event_log: [],
        }

        taskInputData.set(task_id, taskRecord)
    }

    ledgerEntries.forEach(entry => {
        const { taskId, userId, timestamp } = entry
        const user = userMap.get(userId) as ProjectMembersModel
        const taskRecord = taskInputData.get(taskId)
        if (!taskRecord) return

        const { text} = getConfForMsg(entry, user)

        const event = {
            timestamp: timestamp,
            user: user ? user.userEmail : null,
            event_description: text,
        }

        taskRecord.event_log.push(event)
        taskRecord.event_log.sort((a, b) => a.timestamp - b.timestamp)

        taskInputData.set(taskId, taskRecord)
    })

    // @ts-ignore
    for (const taskRecord of taskInputData.values()) {
        outputData.push(new Document({
            pageContent: taskRecord.wbs_path + " " + taskRecord.task_code + " " + taskRecord.task_name,
            metadata: taskRecord
        }))
    }

    // @ts-ignore
    for (const projectMember of projectMembers) {
        const { userId, grade, userEmail } = projectMember
        // const user = userMap.get(userId)
        const memberEntry = {
            record_type: "project member",
            user_id: userId,
            grade,
            user_email: userEmail,
            display_name: userEmail,
        }
        outputData.push(new Document({
            pageContent: memberEntry.display_name,
            metadata: memberEntry
        }))
    }

    outputData.push(new Document({
        pageContent: "Project record: " + project!.name,
        metadata: {
            record_type: "project",
            project_id: projectId,
            project_name: project!.name,
            issue_categories: project!.issueCategories,
            suspended_task_list: project!.suspended,
            blocked_task_list: project!.blocked,
            total_task_count: project!.totalTaskCount,
            project_member_count: projectMembers.length,
        }}))

    const vectorStore = new MemoryVectorStore(embeddings)
    await vectorStore.addDocuments(outputData)
    return vectorStore
}
