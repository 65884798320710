import {useDispatch} from "react-redux";
import React, {useState} from 'react';
import styles from '../GoogleMap.module.scss'
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faUndo} from "@fortawesome/pro-light-svg-icons";
import {Icon, Input, Label} from "semantic-ui-react";
import {SearchActions} from "../../../../../store/actions/search.actions";
import {useMapSearchTextSelector} from "../../../../../store/selectors/search.selectors";
import {useActiveGeoSelector, useGeoDataSelector} from "../../../../../store/selectors/project.selectors";
import {Actions} from "../../../../../store/actions/project.actions";


export default function MapFilter(props) {
    const dispatch = useDispatch();
    const {maxDate, minDate, fromDate, toDate, setFromDate, setToDate} = props;
    const [searchBarExpanded, setSearchBarExpanded] = useState<boolean>(false);
    const [toDateChanged, setToDateChanged] = useState<boolean>(false)
    const [fromDateChanged, setFromDateChanged] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [searchValid, setSearchValid] = useState<boolean>(false)
    const activeGeo = useActiveGeoSelector()
    const geoData = useGeoDataSelector()

    const geoDataExists = geoData && geoData.length > 0

    function handleSearchUpdate(e) {
        setSearchText(e.target.value)
        if (e.target.value.length >= 3) {
            setSearchValid(true)
            dispatch(SearchActions.setMapSearchText(e.target.value))
        } else {
            setSearchValid(false)
            dispatch(SearchActions.setMapSearchText(''))
        }
    }

    return (
        <div className={styles.MapFilterContainer}>
            {!geoDataExists ? <p>No map events available, try adding an image to the ledger to get started.</p> :

            activeGeo ? <div>
                    <label>Active filter: </label>
                    <Label className="ActiveFilterChip">{activeGeo.fileName}
                        <Icon name='delete' onClick={() => {
                            dispatch(Actions.setActiveGeo(null))
                        }}/>
                    </Label></div> :
                <div>
            <label style={{color: "#575656"}}>Date range:</label>
            <span className={styles.MapFilterDatePickerContainer}>
                <DatePicker dateFormat="dd-MM-yyyy"
                            className={fromDateChanged ? styles.MapFilterDatePickerChanged : styles.MapFilterDatePicker}
                            selected={fromDate ? fromDate : minDate}
                            enableTabLoop={false}
                            maxDate={toDate ? toDate : maxDate}
                            onChange={date => {
                                setFromDate(date)
                                setFromDateChanged(true)
                            }}/>
                {'  --->  '}
                <DatePicker dateFormat="dd-MM-yyyy"
                            className={toDateChanged ? styles.MapFilterDatePickerChanged : styles.MapFilterDatePicker}
                            selected={toDate ? toDate : new Date()}
                            enableTabLoop={false}
                            minDate={fromDate ? fromDate : minDate}
                            todayButton="Today"
                            onChange={date => {
                                setToDate(date)
                                setToDateChanged(true)
                            }}/>
                <div className={styles.MapFilterButton} onClick={() => {
                    setSearchBarExpanded(prevState => !prevState)
                    const textInput = document.getElementById('text-input2')
                    if (textInput) {
                        textInput.focus()
                    }
                }}
                     style={searchBarExpanded ? {backgroundColor: '#cacbcd'} : {}}>
                    <FontAwesomeIcon icon={faSearch}/>
                </div>
                <div className={styles.MapFilterResetButton} onClick={() => {
                            setFromDate(null)
                            setToDate(null)
                            setFromDateChanged(false)
                            setToDateChanged(false)
                            handleSearchUpdate({target: {value: ''}})
                            setSearchBarExpanded(false)
                                }}>
                    <FontAwesomeIcon icon={faUndo}/>
                </div>
            </span>
            <div style={!searchBarExpanded ?
                {maxHeight: "0px", color: "rgb(255, 255, 255, 0.0", overflow: "hidden"} :
                {paddingBottom: "0px"}}>
                <Input id="text-input2"
                       type="text"
                       placeholder="Key word search"
                       value={searchText}
                       className={styles.MapFilterSearchBar}
                       error={searchValid === false}
                           onChange={(e) => handleSearchUpdate(e)}
                    />
                </div>
            </div>}
                {/*<div>*/}
                {/*    <Input id="text-input2" type="text" placeholder="Key word search" className={styles.MapFilterSearchBar}/>*/}
                {/*</div>*/}
        </div>
    )
}