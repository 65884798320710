
export default function shortestPath(early, late, end) {

    const s = 86400  // time period in seconds
    function getAngle(leftPoint, apexPoint, rightPoint) {
        const xOfLeftApex = leftPoint[0] - apexPoint[0];
        const yOfLeftApex = leftPoint[1] - apexPoint[1];
        let leftAngle = Math.atan(yOfLeftApex / xOfLeftApex) * (180 / Math.PI);

        if (rightPoint === null) {
            return [leftAngle, 0];
        }
        const xOfRightApex = rightPoint[0] - apexPoint[0];
        const yOfRightApex = rightPoint[1] - apexPoint[1];
        let rightAngle = Math.atan(yOfRightApex / xOfRightApex) * (180 / Math.PI);

        return [leftAngle, rightAngle];
    }

    const start = [Math.floor((new Date().getTime() / 1000) / 86400) * 86400, early.get(Math.floor((new Date().getTime() / 1000) / 86400) * 86400)]
    let apex: any = start
    let previousAngle = 180
    let currentLeftPortal: [any[], number] = [start, 0]
    let currentRightPortal: [any[] | null, number] = [null, 0]
    let i = 1
    let startTime = start[0]
    let shortestPath: any[] = [apex]

    // loop through each time period
    while (true) {
        let leftPortal = early.get(startTime + (i * s))
        let rightPortal = late.get(startTime + (i * s))

        if (apex[1] === end[1]) {
            break
        }
        if (startTime + (i * s) >= end[0]) {
            if (apex[1] !== end[1]) {
                shortestPath.push([end[0], end[1]])
                break
            }
            break
        }
        if (rightPortal >= leftPortal) {
            apex = [startTime + (i * s), rightPortal]
            shortestPath.push([startTime + (i * s), rightPortal])
            i += 1
            currentLeftPortal = [[startTime + (i * s), leftPortal], i]
            currentRightPortal = [[startTime + (i * s), rightPortal], i]
            previousAngle = 180
        } else {
            let angles = getAngle([startTime + (i * s), leftPortal], apex, currentRightPortal[0])
            let leftAngle = angles[0]
            let rightAngle = angles[1]
            let totalAngle = leftAngle - rightAngle
            if (totalAngle < previousAngle) {
                // check if left portal cross right portal
                if (leftAngle < rightAngle) {
                    apex = currentRightPortal[0]
                    i = currentRightPortal[1]
                    shortestPath.push(currentRightPortal[0])
                    leftPortal = early.get(startTime + (i * s))
                    rightPortal = late.get(startTime + (i * s))
                    currentLeftPortal = [[startTime + (i * s), leftPortal], i + 1]
                    currentRightPortal = [[startTime + (i * s), rightPortal], i + 1]
                    previousAngle = 180
                } else {
                    // tighten the funnel
                    currentLeftPortal = [[startTime + (i * s), leftPortal], i]
                    previousAngle = totalAngle
                }
            }

            leftPortal = early.get(startTime + (i * s))
            rightPortal = late.get(startTime + (i * s))
            angles = getAngle(currentLeftPortal[0], apex, [startTime + (i * s), rightPortal])
            leftAngle = angles[0]
            rightAngle = angles[1]
            totalAngle = leftAngle - rightAngle

            if (totalAngle < previousAngle) {
                // check if left portal cross right portal
                if (leftAngle < rightAngle) {
                    apex = currentLeftPortal[0]
                    i = currentLeftPortal[1]
                    shortestPath.push(currentLeftPortal[0])
                    leftPortal = early.get(startTime + ((i + 1) * s))
                    rightPortal = late.get(startTime + ((i + 1) * s))
                    currentLeftPortal = [[startTime + (i * s), leftPortal], i + 1]
                    currentRightPortal = [[startTime + (i * s), rightPortal], i + 1]
                    previousAngle = 180
                } else {
                    // tighten the funnel
                    currentRightPortal = [[startTime + (i * s), rightPortal], i]
                    previousAngle = totalAngle
                }
            }
            i += 1
        }
    }

    return shortestPath

}