import {Button, Dropdown, Progress} from "semantic-ui-react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import TrackedMilestoneModel from "../../../../../models/responses/tracked-milestone.model";
import React, {useState} from "react";
import {ProjectThunk} from "../../../../../store/thunk/project.thunk";
import {useDispatch} from "react-redux";
import {useTrackedMilestonesSelector} from "../../../../../store/selectors/project.selectors";
import MonteCarloModal from "../../MonteCarlo/MonteCarloModal";
import TimeViewModal from "../../TimeViewModal/TimeViewModal";

export default function ProgressBar (props) {
    const {
        progressPosition,
        completedTasks,
        activeProject,
        greenMilestoneTasks,
        redMilestoneTasks,
        showDropdown,
        setShowDropdown,
        setFlowChartExpanded,
        setShowDag,
        setGreenMilestoneExpanded,
        setRedMilestoneExpanded,
        setShowTaskPicker,
        setManageMilestones,
        setActiveGreenMilestone,
        setActiveRedMilestone,
        setMilestoneVisibility,
        setShowDatePicker,
        flowChartExpanded,
        activeGreenMilestone,
        activeRedMilestone
    } = props.props;
    const trackedMilestones = useTrackedMilestonesSelector()
    const [showMonteCarlo, setShowMonteCarlo] = useState(false)
    const [showViewDates, setShowViewDates] = useState(false)

    const dispatch = useDispatch()

    return (
        <Progress
        size="tiny"
        className={classNames("progress-bar", {
            "no-progress": !completedTasks,
        })}
        percent={progressPosition}
        children={
            Boolean(activeProject) && (
                <>
                    <MonteCarloModal
                        showModal={showMonteCarlo}
                        setShowModal={setShowMonteCarlo}
                        trackedMilestones={trackedMilestones}
                        projectId={activeProject.projectId}
                    />
                    <TimeViewModal
                        showModal={showViewDates}
                        setShowModal={setShowViewDates}
                    />
                    <div
                        className="rocket-button-wrap"
                        style={{
                            left: `calc(${progressPosition}% - 20px)`,
                            top: "0px",
                        }}>
                        <Dropdown
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setShowDropdown(true);
                            }}
                            open={showDropdown === true}
                            onClose={() => setShowDropdown(false)}
                            className="rocket-button-dropdown"
                            icon={
                                <Button
                                    size="mini"
                                    className="rocket-button"
                                    onClick={() => {
                                        setFlowChartExpanded(!flowChartExpanded);
                                        setShowDag(false);
                                        setGreenMilestoneExpanded(false);
                                        setRedMilestoneExpanded(false);
                                    }}
                                    circular>
                                    <FontAwesomeIcon
                                        className="rocket-button-icon"
                                        icon={faChartSimple}
                                    />
                                </Button>
                            }
                        >
                            <Dropdown.Menu className="rocket-button-dropdown-menu">
                                <Dropdown.Item
                                    className="rocket-button-dropdown-item"
                                    text="Put a watch on a task/milestone"
                                    onClick={() => setShowTaskPicker(true)}
                                />
                                <Dropdown.Item
                                    className="rocket-button-dropdown-item"
                                    text="Manage milestones"
                                    onClick={() => setManageMilestones(true)}
                                />
                                <Dropdown.Item
                                    className="rocket-button-dropdown-item"
                                    text="Customise view"
                                    onClick={() => setShowViewDates(true)}
                                />
                                <Dropdown.Item
                                    disabled
                                    className="rocket-button-dropdown-item"
                                    text="Stress test milestones"
                                    onClick={() => setShowMonteCarlo(true)}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div
                        className="rocket-button-wrap"
                        style={{
                            position: "unset",
                            top: "0px",
                        }}
                    >
                        {greenMilestoneTasks.length > 0 &&
                            greenMilestoneTasks.filter((task: TrackedMilestoneModel) => !task.hidden).map((task: any) => (
                                <Dropdown
                                    key={`g-${task.key}`}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        setShowDropdown(task.taskId);
                                    }}
                                    open={task.taskId === showDropdown}
                                    onClose={() => setShowDropdown(false)}
                                    className="rocket-button-dropdown"
                                    style={{
                                        left: `calc(${task.progressPosition * 100}% - 20px)`,
                                        top: "0px",
                                    }}
                                    icon={
                                        <Button
                                            size="mini"
                                            className="green-button"
                                            onClick={() => {
                                                if (task.key === activeGreenMilestone) {
                                                    setGreenMilestoneExpanded(false)
                                                    setShowDag(false)
                                                    setActiveGreenMilestone(null)
                                                    setActiveRedMilestone(null)
                                                } else {
                                                    setGreenMilestoneExpanded(true)
                                                    setShowDag(false)
                                                    setActiveRedMilestone(null)
                                                    setActiveGreenMilestone(task.key);
                                                }
                                                setRedMilestoneExpanded(false);
                                            }}
                                            circular
                                            style={{
                                                left: `calc(${task.progressPosition * 100}% - 20px)`,
                                                top: "0px",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="green-button-icon"
                                                icon={faLocationDot}
                                            />
                                        </Button>
                                    }
                                >
                                    <Dropdown.Menu className="rocket-button-dropdown-menu">
                                        <Dropdown.Item
                                            className="rocket-button-dropdown-item"
                                            text="Hide this deliverable"
                                            onClick={() => setMilestoneVisibility(task)}
                                        />
                                        <Dropdown.Item
                                            className="rocket-button-dropdown-item"
                                            text="Edit deliverable"
                                            onClick={() => setShowDatePicker(true)}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            ))}
                    </div>
                    <div
                        className="rocket-button-wrap"
                        style={{
                            position: "unset",
                            top: "0px",
                        }}
                    >
                        {redMilestoneTasks.length > 0 &&
                            redMilestoneTasks.filter((task: TrackedMilestoneModel) => !task.hidden).map((task: any) => (
                                <Dropdown
                                    key={`r-${task.key}`}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        setShowDropdown(task.taskId);
                                    }}
                                    open={task.taskId === showDropdown}
                                    onClose={() => setShowDropdown(false)}
                                    className="rocket-button-dropdown"
                                    style={{
                                        left: `calc(${task.progressPosition * 100}% - 20px)`,
                                        top: "0px",
                                    }}
                                    icon={
                                        <Button
                                            size="mini"
                                            className="red-button"
                                            onClick={() => {
                                                if (task.key === activeRedMilestone) {
                                                    setRedMilestoneExpanded(false)
                                                    setShowDag(false)
                                                    setActiveRedMilestone(null)
                                                    setActiveGreenMilestone(null)
                                                } else {
                                                    setRedMilestoneExpanded(true)
                                                    setShowDag(false)
                                                    setActiveRedMilestone(task.key);
                                                    setActiveGreenMilestone(null);
                                                }
                                                setGreenMilestoneExpanded(false);
                                                // setFlowChartExpanded(false);
                                            }}
                                            circular
                                            style={{
                                                left: `calc(${task.progressPosition * 100}% - 20px)`,
                                                top: "0px",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="red-button-icon"
                                                icon={faLocationDot}
                                            />
                                        </Button>
                                    }
                                >
                                    <Dropdown.Menu className="rocket-button-dropdown-menu">
                                        <Dropdown.Item
                                            className="rocket-button-dropdown-item"
                                            text="Hide this deliverable"
                                            onClick={() => setMilestoneVisibility(task)}
                                        />
                                        <Dropdown.Item
                                            className="rocket-button-dropdown-item"
                                            text="Edit deliverable"
                                            onClick={() => setShowDatePicker(true)}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            ))}
                    </div>
                </>
            )
        }
    />
    )
}
