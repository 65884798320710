import React, {useEffect} from "react";
import LoaderPage from "../LoaderPage/LoaderPage";
import {useSelector} from "react-redux";
import {RootStateModel} from "../../store/reducers";
import {AuthorizationSelectors} from "../../store/reducers/authorization";
import RouteNameConstants from "../../constants/route-name.constants";
import {useNavigate} from "react-router-dom";
import {ProjectThunk} from "../../store/thunk/project.thunk";


function PageNotFound () {
    const authorized = useSelector<RootStateModel, boolean>(state => AuthorizationSelectors.authorized(state.authorization));
    const checkedAuth = useSelector<RootStateModel, boolean>(state => state.authorization.checkedAuth);
    const navigate = useNavigate()

    useEffect(() => {
        const activeProjectId = localStorage.getItem('projectId');

        // User is Authorized
        if(authorized && checkedAuth && activeProjectId) {
            ProjectThunk.setActiveProjectId(activeProjectId);
            navigate(`/${activeProjectId}/${RouteNameConstants.FLOW}`);
        } else if(authorized && checkedAuth && !activeProjectId) {
            navigate(`/${RouteNameConstants.CHOOSE_PROJECT}`);
        }

        // User isn't Authorized
        if (!authorized && checkedAuth) {
            navigate(`/${RouteNameConstants.LOGIN}`);
        }
    }, [authorized, checkedAuth]);

    return <LoaderPage/>
}

export default PageNotFound;
