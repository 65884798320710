import React, {useRef, useEffect, useState} from 'react'
import {Input} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowMinimize} from "@fortawesome/free-solid-svg-icons";
import {
    useActiveProjectSelector,
    useChatInitialisedSelector,
    useChatLogSelector, useVectorStoreSelector
} from "../../../../store/selectors/project.selectors";
import {sendChatMessageWithData, sendMessage, sendMessageWithPinecone} from "../../../../utils/chat.utils";
import {useDispatch} from "react-redux";
import * as projectActions from "../../../../store/actions/project.actions";
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import { bouncy, jellyTriangle } from 'ldrs'
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {createVectorStore} from "../../../../utils/vector-store.utils";

jellyTriangle.register()
bouncy.register()


export function Chat(props) {

    const {setShowChat} = props
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)
    const chatLog = useChatLogSelector()
    const dispatch = useDispatch()
    const user = useUserSelector()
    const activeProject = useActiveProjectSelector()
    const vectorStore = useVectorStoreSelector()
    const chatInitialised = useChatInitialisedSelector()
    const [initialisationError, setInitialisationError] = useState(false)

    console.log(vectorStore)
    const chatParent = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if (!chatInitialised && !initialisationError && !vectorStore) {
            // FirebaseUsage.functionsNoCors(`create-chat-context-v2/?projectId=${activeProject!.projectId}`)({projectId: activeProject!.projectId})
        createVectorStore(activeProject!.projectId)
                .then((resp) => {
                    dispatch(projectActions.Actions.setVectorStore(resp))
                    dispatch(projectActions.Actions.setChatInitialised(true))
                })
                .catch(() => setInitialisationError(true))
        }
    }, [chatInitialised]);

    useEffect(() => {
        const domNode = chatParent.current
        if (domNode) {
            domNode.scrollTop = domNode.scrollHeight
        }
    })

    useEffect(() => {
        const element = document.getElementById('flowchat-body')
        if (element) {
            element.scrollTop = element.scrollHeight
        }
    }, [chatLog]);

    const handleSubmit = () => {
        if (content !== '') {
            sendMessageWithPinecone([...chatLog, {role: 'user', content}])
                .then((response: any) => {
                    console.log(response)
                    const newChatLog = [...chatLog,
                        {role: 'user', content},
                        {role: 'assistant', content: response.data}]
                    dispatch(projectActions.Actions.setChatLog(newChatLog))
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("error", error)
                    const newChatLog = [...chatLog,
                        {role: 'user', content},
                        {role: 'assistant', content: 'I am sorry, I am not able to help you with that.'}]
                    dispatch(projectActions.Actions.setChatLog(newChatLog))
                    setLoading(false)
                })
            setLoading(true)
            const newChatLog = [...chatLog, {role: 'user', content}]
            setContent('')
            dispatch(projectActions.Actions.setChatLog(newChatLog))
        }
    }

    return (
        <div className="inner-chat-container">
            <div className="chat-header">
                <h4>FlowBot Chat BETA</h4>
                <FontAwesomeIcon icon={faWindowMinimize}
                                 className="chat-minimise"
                                 onClick={() => setShowChat(false)}
                />
            </div>
            <div className="chat-body" id="flowchat-body">
                {!chatInitialised ?
                    !initialisationError ? (
                        <div className="assistant-message">
                            <p style={{marginRight: "10px"}}>Initialising chat...</p>
                            <l-jelly-triangle
                                size="30"
                                speed="1.75"
                                color="black"
                            ></l-jelly-triangle>
                        </div>
                    ) : (
                        <div className="assistant-message">
                            <p>There was an error initialising the chat.</p>
                        </div>
                    ) :
                chatLog.map((m, index) => (
                    <div key={index}>
                        {m.role === 'user' ? (
                            <div className="user-message">
                                <p>{m.content}</p>
                            </div>
                        ) : (
                            <div className="assistant-message">
                                <p>{m.content}</p>
                            </div>
                        )}
                    </div>
                ))}
                {loading &&
                <div className="assistant-message loading">
                    <l-bouncy
                        size="35"
                        speed="1"
                        color="#374151"
                    ></l-bouncy>
                </div>
                }
            </div>
            <div className="chat-footer">
                <Input type={"text"}
                       className={"chat-input"}
                       value={content}
                       icon="send"
                       action={{
                            onClick: handleSubmit
                       }}
                       onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                 handleSubmit()
                            }
                       }}
                       autoFocus
                       onChange={(e) => setContent(e.target.value)} />
            </div>
        </div>
    )
}