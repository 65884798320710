
export default function createIndexedCalendarArrays(calendars, maxTaskDate, firstTaskDate) {
    try {
        const timeNow = Date.now()
        let exceptionsDict = {}
        const dayList = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        let workHoursDict = {}

        let maxExceptions = 0
        let presetCalendarDict = {}
        calendars.forEach(cal => {
            workHoursDict[cal.calendarId] = cal.working_hours_per_day ? cal.working_hours_per_day : 8
            exceptionsDict[cal.calendarId] = {}
            // const calExceptions = JSON.parse(cal.workday_exceptions)
            const calExceptions = cal.workday_exceptions ? cal.workday_exceptions : []
            calExceptions.forEach(ex => {
                exceptionsDict[cal.calendarId][ex.date] = ex
            })
            presetCalendarDict[cal.calendarId] = ''
            if (calExceptions.length > 0) {
                const maxOfCal = Math.max(calExceptions.map(ex => ex.date))
                maxExceptions = maxExceptions < maxOfCal ? maxOfCal : maxExceptions
            }
        })

        const maxOfMaxes = Math.floor(Math.max(((maxTaskDate / 86400000) + 25569), maxExceptions)) + 1
        const firstTaskDateIndex = Math.floor(((firstTaskDate / 86400000) + 25569))

        let masterCalendarDict = new Map();
        let specificCalendarDict = new Map();
        let specificWorkPatternDict = {}
        let masterWorkPatternDict = new Map();

        for (let key in presetCalendarDict) {
            specificWorkPatternDict[key] = {
                maxDate: 0,
                maxIndex: 0,
                maxWeekIndex: 0,
                maxDayIndex: 0,
                weeklyIndices: new Map()
            }
        }

        for (let i = 0; i < (48 * 7); i++) {
            masterWorkPatternDict.set(i, {...presetCalendarDict})
        }

        for (let x = firstTaskDateIndex - 1; x < (maxOfMaxes + 2); x++) {
            for (let y = 0; y < 48; y++) {
                masterCalendarDict.set(((x - 25569) * 86400) + (y * 1800), {...presetCalendarDict})
            }
        }

        calendars.forEach(cal => {
            const key = cal.calendarId
            specificCalendarDict.set(key, new Map())
            let index = 0
            let refIndex: number | null = null
            let maxDate = 0

            for (let x = firstTaskDateIndex - 1; x < (maxOfMaxes + 2); x++) {
                let workTimes: any[] = []
                let dn = Math.round((((x - 1) / 7) - Math.floor((x - 1) / 7)) * 7)
                let workingHours: any[] | string = []
                if (exceptionsDict[key][x]) {
                   if (exceptionsDict[key][x]['periods'][0].start) {
                        let workTimes: any[] = []
                        exceptionsDict[key][x]['periods'].forEach(period => {
                            workTimes.push(period.start)
                            workTimes.push(period.finish)
                        })
                        workingHours = [workTimes, 's']
                    } else {
                        workingHours = "non-work"
                   }
                } else {
                    if(cal['working_days_of_the_week'][dayList[dn]]) {
                        cal['working_days_of_the_week'][dayList[dn]].forEach(period => {
                            workTimes.push(period.start)
                            workTimes.push(period.finish)
                        })
                        workingHours = [workTimes, 's']
                    } else {
                        workingHours = "non-work"
                    }
                }


                if (workingHours !== "non-work") {
                    for (let z = 0; (z * 2) < workingHours[0].length; z++) {
                        let start
                        let end
                        if (workingHours[1] === 's') {
                            start = workingHours[0][z * 2]
                            end = workingHours[0][(z * 2) + 1]
                            if (end === "00:00") {
                                end = "24:00"
                            }
                            start = parseFloat(start.split(':')[0]) + (parseFloat(start.split(':')[1]) / 60)
                            end = parseFloat(end.split(':')[0]) + (parseFloat(end.split(':')[1]) / 60)
                        } else {
                            start = workingHours[0][(z * 2) + 1]
                            end = workingHours[0][z * 2]
                            if (end === "00:00") {
                                end = "24:00"
                            }
                            start = parseFloat(start.split(':')[0]) + (parseFloat(start.split(':')[1]) / 60)
                            end = parseFloat(end.split(':')[0]) + (parseFloat(end.split(':')[1]) / 60)
                        }
                        for (let i = 0; i < ((end - start) * 2); i++) {
                            refIndex = index
                            specificCalendarDict.get(key).set(refIndex, ((x - 25569) * 86400) + ((start * 3600) + (i * 1800)))
                            const valueToChange = masterCalendarDict.get(((x - 25569) * 86400) + ((start * 3600) + (i * 1800)))
                            valueToChange[key] = refIndex
                            masterCalendarDict.set(((x - 25569) * 86400) + ((start * 3600) + (i * 1800)), valueToChange)
                            maxDate = ((x - 25569) * 86400) + ((start * 3600) + (i * 1800))
                            // masterCalendarDict[((x - 25569) * 86400) + ((start * 3600) + (i * 1800))][key] = refIndex
                            index++
                        }
                        specificWorkPatternDict[key]['maxIndex'] = index > 0 ? index - 1 : 0
                        specificWorkPatternDict[key]['maxDate'] = maxDate
                    }
                }
            }

            // create week generic indices
            let weekIndex = 0
            for (let x = 0; x < 7; x++) {
                let workTimes: any[] = []
                let dn = x
                let workingHours: any[] | string = []
                if(cal['working_days_of_the_week'][dayList[dn]]) {
                    cal['working_days_of_the_week'][dayList[dn]].forEach(period => {
                        workTimes.push(period.start)
                        workTimes.push(period.finish)
                    })
                    workingHours = [workTimes, 's']
                } else {
                    workingHours = "non-work"
                }
                if (workingHours !== "non-work") {
                    for (let z = 0; (z * 2) < workingHours[0].length; z++) {
                        let start
                        let end
                        if (workingHours[1] === 's') {
                            start = workingHours[0][z * 2]
                            end = workingHours[0][(z * 2) + 1]
                            if (end === "00:00") {
                                end = "24:00"
                            }
                            start = parseFloat(start.split(':')[0]) + (parseFloat(start.split(':')[1]) / 60)
                            end = parseFloat(end.split(':')[0]) + (parseFloat(end.split(':')[1]) / 60)
                        } else {
                            start = workingHours[0][(z * 2) + 1]
                            end = workingHours[0][z * 2]
                            if (end === "00:00") {
                                end = "24:00"
                            }
                            start = parseFloat(start.split(':')[0]) + (parseFloat(start.split(':')[1]) / 60)
                            end = parseFloat(end.split(':')[0]) + (parseFloat(end.split(':')[1]) / 60)
                        }
                        for (let i = 0; i < ((end - start) * 2); i++) {
                            specificWorkPatternDict[key]['weeklyIndices'].set(weekIndex, [(start * 2) - 1 + i, dn])
                            masterWorkPatternDict.set(
                                dn * 48 + ((start * 2) - 1 + i),
                                {
                                    ...masterWorkPatternDict.get(dn * 48 + ((start * 2) - 1 + i)),
                                    [key]: weekIndex
                                }
                            )
                            weekIndex++
                        }
                    }
                }
            }
        })

        // fill in gaps for master work pattern dict
        masterWorkPatternDict.forEach((value, key) => {
            for (let cal in value) {
                if (presetCalendarDict[cal] === '') {
                    presetCalendarDict[cal] = 0
                }
                if (value[cal]) {
                    presetCalendarDict[cal] = value[cal]
                } else {
                    if (presetCalendarDict[cal] === 0) {
                        value[cal] = 0
                    } else {
                        value[cal] = (parseInt(presetCalendarDict[cal]) + 1)
                    }
                }
            }
            masterWorkPatternDict.set(key, value)
        })

        for (let cal in presetCalendarDict) {
            presetCalendarDict[cal] = ''
            specificWorkPatternDict[cal].maxWeekIndex = (specificWorkPatternDict[cal].maxDate - 1800 - (3 * 86400) -
                Math.floor((specificWorkPatternDict[cal].maxDate - 1800 - (3 * 86400)) / 604800) * 604800) / 1800
            specificWorkPatternDict[cal].maxDayIndex = specificWorkPatternDict[cal].weeklyIndices.get(masterWorkPatternDict.get(specificWorkPatternDict[cal].maxWeekIndex)[cal])[1]
        }

        // fill in gaps in master calendar dict
        masterCalendarDict.forEach((value, key) => {
                for (let cal in value) {
                    if (presetCalendarDict[cal] === '') {
                        presetCalendarDict[cal] = 0
                    }
                    if (value[cal] !== '') {
                        presetCalendarDict[cal] = value[cal]
                    } else {
                        if (presetCalendarDict[cal] === 0) {
                            value[cal] = 0
                        } else {
                            value[cal] = (parseInt(presetCalendarDict[cal]) + 1)
                        }
                    }
                }
                masterCalendarDict.set(key, value)
            }
        )

        return {
            masterCalendarDict: masterCalendarDict,
            specificCalendarDict: specificCalendarDict,
            specificWorkPatternDict: specificWorkPatternDict,
            masterWorkPatternDict: masterWorkPatternDict,
            workHoursDict
        } as any
    } catch (e) {
        console.log(e)
        return e
    }
}
