import {Divider, Dropdown, SemanticCOLORS} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MessageModel, {MessageType,} from "../../../../../models/responses/message.model";
import React from "react";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {Maybe} from "@martin_hotell/rex-tils";
import {UserDocModel} from "../../../../../models/responses/user.model";
import TaskModel from "../../../../../models/responses/task.model";
import moment from "moment";
// import { useEventLogListSelector } from "../../../../../store/selectors/event-log.selectors";
import {faRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import undoAction from "../../../../../utils/undo.utils";
import {useDispatch} from "react-redux";
import LedgerImagePreview from "./LedgerImagePreview";
import FileAttachmentPreview from "./FileAttachmentPreview";
import ProjectMembersModel from "../../../../../models/responses/project-members.model";

interface InjectProps {
  confForMsg: {
    icon: Maybe<IconDefinition>;
    text: string;
    color: SemanticCOLORS;
    undo: string;
    operator: string | null;
  };
  timeWaypoint: Maybe<string>;
  message: MessageModel;
  user: UserDocModel;
  activeTask: TaskModel;
  ledgerUser: ProjectMembersModel | undefined;
}

type PropsType = InjectProps;

export const HeaderLedgerItem = (props: PropsType) => {
  const { confForMsg,
    timeWaypoint,
    message,
    activeTask,
    user,
    ledgerUser } = props;
  const atTime = message.logTimestamp
    ? moment(message.logTimestamp.toDate()).format("HH:mm")
    : moment(message.timestamp.toDate()).format("HH:mm");
  // const eventLogList = useEventLogListSelector();
  // const event = useMemo(() => (eventLogList.find(event => event.ledgerId === message.ledgerId)), [eventLogList, message.ledgerId]);
  // const eventTimestamp = useMemo(() => event && event.eventTimestamp, [event]);
  const event = message;
  const dispatch = useDispatch();
  const eventTimestamp = message.logTimestamp
    ? message.logTimestamp
    : message.timestamp;
  const eventTimestampForDisplay = message.timestamp;

  return (
    <div className="header-ledger-list">
      {timeWaypoint ? <Divider horizontal>{timeWaypoint}</Divider> : null}
      {message.type === MessageType.MSG ? (
        <React.Fragment>
          <p
            style={
              message.userId === user.userId
                ? { margin: "5px 10px 0 0", textAlign: "right" }
                : { margin: "5px 10px 0 0", textAlign: "left" }
            }
          >
            {message.userEmail ? (
              <React.Fragment>
                <a
                  href={`mailto: ${message.userEmail}?subject=${
                    activeTask!.wbs
                  }, ${activeTask!.task_name}, ${activeTask!.task_code}`}
                >
                  {message.userEmail}
                </a>
                <span className="flow-ledger-timestamp"> at {atTime}</span>
              </React.Fragment>
            ) : null}
          </p>
          <div
            className="header-ledger-list-item"
            style={
              message.userId === user.userId
                ? { margin: "0 0 0 25%" }
                : { margin: "0 25% 0 0" }
            }
          >
            <div className="header-ledger-icon">
              <Dropdown icon={<FontAwesomeIcon icon={faComment} />}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="trash"
                    text="Delete comment"
                    onClick={() => undoAction(message, dispatch)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="flow-ledger-history-list-text">
              {message.type === MessageType.MSG && message.text && (
                <p>{message.text}</p>
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p
            style={
              message.userId === user.userId
                ? { margin: "5px 10px 0 0", textAlign: "right" }
                : { margin: "5px 10px 0 0", textAlign: "left" }
            }
          >
            {message.userEmail ? (
              <React.Fragment>
                <a
                  href={`mailto: ${message.userEmail}?subject=${
                    activeTask!.wbs
                  }, ${activeTask!.task_name}, ${activeTask!.task_code}`}
                >
                  {ledgerUser && ledgerUser.userEmail ? ledgerUser.userEmail
                    : message.userEmail}
                </a>
                <span className="flow-ledger-timestamp"> at {atTime}</span>
              </React.Fragment>
            ) : null}
          </p>
          <div
            className="header-ledger-list-item background-darkgrey"
            style={
              message.userId === user.userId
                ? { margin: "0 0 0 25%", color: "#fff" }
                : { margin: "0 25% 0 0", color: "#fff" }
            }
          >
            <div className="header-ledger-container">
              <div className="header-ledger-list-item-description">
              <div className="header-ledger-icon">
                {message.type !== MessageType.TFL &&
                message.type !== MessageType.TFJ &&
                message.type !== MessageType.WTG &&
                message.type !== MessageType.REJ &&
                user.isAdmin
                  ? confForMsg.icon && (
                      <Dropdown icon={<FontAwesomeIcon icon={confForMsg.icon} />}>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<FontAwesomeIcon icon={faRotateLeft} />}
                            text={` Undo ${confForMsg.undo}`}
                            onClick={() => undoAction(message, dispatch)}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  : confForMsg.icon && <FontAwesomeIcon icon={confForMsg.icon} />}
              </div>
              <div className="flow-ledger-history-list-text">
                {confForMsg.text}{" "}
                {message.type === MessageType.RNM && message.oldName && ` from "${message.oldName}" to "${message.text}" `}
                {confForMsg.operator ? " " + confForMsg.operator + " " :
                eventTimestamp
                  ? moment(new Date(eventTimestamp.seconds * 1000)).format(
                      "DD.MM.YYYY"
                    ) !== moment().format("DD.MM.YYYY")
                    ? "on "
                    : "at "
                  : "at "}
                {eventTimestampForDisplay
                  ? moment(
                      new Date(eventTimestampForDisplay.seconds * 1000)
                    ).format("DD.MM.YYYY") !== moment().format("DD.MM.YYYY")
                    ? moment(
                        new Date(eventTimestampForDisplay.seconds * 1000)
                      ).format("Do MMM YYYY") +
                      " at " +
                      moment(
                        new Date(eventTimestampForDisplay.seconds * 1000)
                      ).format("HH:mm")
                    : moment(
                        new Date(eventTimestampForDisplay.seconds * 1000)
                      ).format("HH:mm")
                  : atTime}
              </div>
              </div>
              {(message.type === MessageType.SUS || message.type === MessageType.BLC || message.type === MessageType.ISS) && message.text &&
                <p style={{marginTop: "5px", marginLeft: "5px"}}>Reason: <i>{message.text}</i></p>}
              {message.type === MessageType.IMG && (
                  <LedgerImagePreview ledgerId={message.ledgerId} />
              )}
              {message.type === MessageType.IMG && message.text && (
                  <p style={{marginTop: "10px", marginLeft: "5px", marginBottom: "5px"}}>Caption: <i>{message.text}</i></p>)}
              {message.type === MessageType.FIL && (
                  <FileAttachmentPreview ledgerId={message.ledgerId} />
              )
              }
              {message.type === MessageType.FIL && message.text && (
                  <p style={{marginTop: "5px", marginLeft: "5px"}}>Caption: <i>{message.text}</i></p>)}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
