
export default function shortestPath(cumulativeMap, start, end, calendars, hasWorkInProgress) {

  const s = 86400  // time period in seconds

  function getAngle(leftPoint, apexPoint, rightPoint) {
    const xOfLeftApex = leftPoint[0] - apexPoint[0];
    const yOfLeftApex = leftPoint[1] - apexPoint[1];
    let leftAngle = Math.atan(yOfLeftApex / xOfLeftApex) * (180 / Math.PI);

    if (rightPoint === null) {
        return [leftAngle, 0];
    }
    const xOfRightApex = rightPoint[0] - apexPoint[0];
    const yOfRightApex = rightPoint[1] - apexPoint[1];
    let rightAngle = Math.atan(yOfRightApex / xOfRightApex) * (180 / Math.PI);

    return [leftAngle, rightAngle];
}

  let apexes = {}
  let previousAngles = {}
  let currentLeftPortals = {}
  let currentRightPortals = {}
  let shortestPaths = {}
  let indices = {}
  let startTime = start[0]
  let calendarList = [...calendars]
  for (const calendar of calendars) {
    apexes[calendar] = [start[0], start[1][calendar]]
    previousAngles[calendar] = 180
    currentLeftPortals[calendar] = [[start[0], cumulativeMap.get(start[0]).early[calendar]], 0]
    currentRightPortals[calendar] = [null, 0]
    shortestPaths[calendar] = [[start[0], start[1][calendar]]]
    indices[calendar] = 1
  }

  // loop through each time period
  while (calendarList.length > 0) {
    for (const calendar of calendarList) {
      let apex = apexes[calendar]
      let previousAngle = previousAngles[calendar]
      let currentLeftPortal = currentLeftPortals[calendar]
      let currentRightPortal = currentRightPortals[calendar]
      let i = indices[calendar]
      let leftPortal = cumulativeMap.get(startTime + (i * s)).early[calendar]
      let rightPortal = cumulativeMap.get(startTime + (i * s)).late[calendar]

      if (apex[1] === end[1][calendar]) {
        calendarList = calendarList.filter((cal) => cal !== calendar)
      }
      if (startTime + (i * s) >= end[0]) {
        if (apex[1] !== end[1][calendar]) {
          shortestPaths[calendar].push([end[0], end[1][calendar]])
        }
        calendarList = calendarList.filter((cal) => cal !== calendar)
      }
      if (rightPortal >= leftPortal) {
        if (hasWorkInProgress[calendar] && rightPortal === 0) {
          indices[calendar] = i + 1
          i = indices[calendar]
          currentLeftPortals[calendar] = [[startTime + (i * s), leftPortal], i]
          currentRightPortals[calendar] = [[startTime + (i * s), rightPortal], i]
          previousAngles[calendar] = 180
        } else {
          apexes[calendar] = [startTime + (i * s), rightPortal]
          shortestPaths[calendar].push([startTime + (i * s), rightPortal])
          indices[calendar] = i + 1
          i = indices[calendar]
          currentLeftPortals[calendar] = [[startTime + (i * s), leftPortal], i]
          currentRightPortals[calendar] = [[startTime + (i * s), rightPortal], i]
          previousAngles[calendar] = 180
        }
      } else {
        let angles = getAngle([startTime + (i * s), leftPortal], apex, currentRightPortal[0])
        let leftAngle = angles[0]
        let rightAngle = angles[1]
        let totalAngle = leftAngle - rightAngle
        if (totalAngle < previousAngle) {
          // check if left portal cross right portal
          if (leftAngle < rightAngle) {
            apexes[calendar] = currentRightPortal[0]
            indices[calendar] = currentRightPortal[1] + 1
            i = indices[calendar]
            shortestPaths[calendar].push(currentRightPortal[0])
            leftPortal = cumulativeMap.get(startTime + (i * s)).early[calendar]
            rightPortal = cumulativeMap.get(startTime + (i * s)).late[calendar]
            currentLeftPortals[calendar] = [[startTime + (i * s), leftPortal], i]
            currentRightPortals[calendar] = [[startTime + (i * s), rightPortal], i]
            previousAngles[calendar] = 180
          } else {
            // tighten the funnel
            currentLeftPortals[calendar] = [[startTime + (i * s), leftPortal], indices[calendar]]
            previousAngles[calendar] = totalAngle
          }
        }

        apex = apexes[calendar]
        previousAngle = previousAngles[calendar]
        currentLeftPortal = currentLeftPortals[calendar]

        i = indices[calendar]
        leftPortal = cumulativeMap.get(startTime + (i * s)).early[calendar]
        rightPortal = cumulativeMap.get(startTime + (i * s)).late[calendar]
        angles = getAngle(currentLeftPortal[0], apex, [startTime + (i * s), rightPortal])
        leftAngle = angles[0]
        rightAngle = angles[1]
        totalAngle = leftAngle - rightAngle

        if (totalAngle < previousAngle) {
            // check if left portal cross right portal
            if (leftAngle < rightAngle) {
                apexes[calendar] = currentLeftPortal[0]
                indices[calendar] = currentLeftPortal[1]
                i = indices[calendar]
                shortestPaths[calendar].push(currentLeftPortal[0])
                leftPortal = cumulativeMap.get(startTime + ((i + 1) * s)).early[calendar]
                rightPortal = cumulativeMap.get(startTime + ((i + 1) * s)).late[calendar]
                currentLeftPortals[calendar] = [[startTime + (i * s), leftPortal], i + 1]
                currentRightPortals[calendar] = [[startTime + (i * s), rightPortal], i + 1]
                previousAngles[calendar] = 180
            } else {
                // tighten the funnel
                currentRightPortals[calendar] = [[startTime + (i * s), rightPortal], indices[calendar]]
                previousAngles[calendar] = totalAngle
            }
        }
        indices[calendar] += 1
      }
    }
  }

return shortestPaths

}