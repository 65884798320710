import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -150px;
  text-align: center;
  width: 450px;
`;

export const Login = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex: 1 1;
`;

export const Terms = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;
