import fetch from "node-fetch";
import ProjectModel from "../models/responses/project.model";
import FirebaseUsage from "../firebase/firebase.usage";
import {COLLECTIONS} from "../firebase/constants";
import {v4 as uuidv4} from "uuid/v4";

export default async function createJiraIssue(requestData: {
    description: string;
    about: string;
    priority: string;
    attachments: any[];
    type: string;
    userEmail: string;
    project: any | ProjectModel | null;
    status: string;
}) {
    const newIssue = FirebaseUsage.getBlankDoc(COLLECTIONS.ISSUE_LOGS);

    let attachmentIds: any[] = [];
    if (requestData.attachments.length > 0) {
        for (const attachment of requestData.attachments) {
            const fileId = uuidv4();
            await FirebaseUsage.uploadFileToStorage(`issue_attachments/${fileId}`, attachment.arrayBuffer)
            attachmentIds.push({id: fileId, fileName: attachment.filename})
        }
    }

    const issueLog = {
        issueId: newIssue.id,
        description: requestData.description,
        about: requestData.about,
        priority: requestData.priority,
        type: requestData.type,
        userEmail: requestData.userEmail,
        projectId: requestData.project?.projectId || "No specific project",
        projectName: requestData.project?.name || "No specific project",
        attachments: attachmentIds,
    }

    await FirebaseUsage.setDocument(COLLECTIONS.ISSUE_LOGS, newIssue.id, issueLog)

    return await fetch('https://us-central1-flowledgerprod.cloudfunctions.net/create-jira-issue-2/?issueId=' + newIssue.id)
        .then((res) => {
            return res.json()
        })
        .catch((err) => {
            console.log(err)
        })

}

