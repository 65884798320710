import * as relationshipsActions from '../../actions/relationships.actions';
import {RelationshipsModel} from "../../../models/responses/relationships.model";

export class StateModel {
    relationshipsList: RelationshipsModel[] = [];
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: relationshipsActions.Actions) {
    switch (action.type) {
        case relationshipsActions.ActionNames.SET_RELATIONSHIPS_LIST: {
            return {
                ...state,
                relationshipsList: action.payload.relationshipsList,
            }
        }
        default:
            return state;
    }
}
