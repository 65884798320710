import * as authorizationActions from '../../actions/authorization.actions';
import { UserDocModel } from "../../../models/responses/user.model";
import ProjectMembersModel from "../../../models/responses/project-members.model";
import { Maybe } from "@martin_hotell/rex-tils";

export class StateModel {
    checkedAuth: boolean = false;
    user: Maybe<UserDocModel> = null;
    membersMap: { [projectId: string]: ProjectMembersModel } = {}
    userSidebarOpen: boolean = false;
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: authorizationActions.Actions) {
    switch (action.type) {
        case authorizationActions.ActionNames.AUTH: {
            return {
                ...state,
                user: action.payload.user,
                checkedAuth: true,
            }
        }
        case authorizationActions.ActionNames.SET_MEMBER_PERMISSIONS: {
            // console.log('👨 state in SET_MEMBER_PERMISSIONS', state)
            // console.log('👨 action in SET_MEMBER_PERMISSIONS', action)
            const membersMap = {};
            action.payload.members.forEach(el => {
                membersMap[el.projectId] = el;
            });
            return {
                ...state,
                membersMap,
            }
        }
        case authorizationActions.ActionNames.NOT_AUTH: {
            return {
                ...state,
                user: null,
                checkedAuth: true
            }
        }
        case authorizationActions.ActionNames.SET_USER_SIDEBAR_OPEN: {
            return {
                ...state,
                userSidebarOpen: action.payload.isOpen
            }
        }
        default:
            return state;
    }
}


export const AuthorizationSelectors = {
    authorized: (state: StateModel) => state.checkedAuth && !!state.user,
    unAuthorized: (state: StateModel) => state.checkedAuth && !state.user,
};





