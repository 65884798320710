import React from "react";
import { Checkbox } from "semantic-ui-react";
import classNames from "classnames";
import { TaskEventModal } from "../../TaskEventModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useActiveTaskSelector } from "../../../../../../../store/selectors/task/task.selectors";
import { CheckList } from "../../../../../../../models/checklist-model";
import EventTextModel from "../../../../../../../models/event-text-model.enum";
import TaskStatusModel from "../../../../../../../models/responses/task-status.model";
import moment from "moment";

interface InjectedProps {
  taskChecklist: CheckList[];
  onChangeHandler: (item: CheckList) => void;
  removeHandler: (itemId: string) => void;
  eventText: EventTextModel;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  activeItem: CheckList | null;
  setActiveItem: React.Dispatch<React.SetStateAction<CheckList | null>>;
  disabled: boolean;
}

const TaskChecklist: React.FC<InjectedProps> = ({
  taskChecklist,
  onChangeHandler,
  removeHandler,
  eventText,
  opened,
  setOpened,
  activeItem,
  setActiveItem,
  disabled
}) => {
  const activeTask = useActiveTaskSelector();

  const formatChecklistDate = (timestamp) => {
    // Convert nanoseconds and seconds to milliseconds
    const milliseconds =
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6);

    // Create a Date object using the milliseconds
    const date = new Date(milliseconds);

    // Format the date according to the specified format: 12.05 01/11/2023
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${hours}.${minutes} ${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <div className="checklist">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {taskChecklist &&
            taskChecklist.length > 0 &&
            taskChecklist.map((item) => (
              <div className="checklist-item-wrapper" key={item.id}>
                <div className="checklist-item-input-wrapper">
                  <Checkbox
                    onChange={(event, data) =>
                      onChangeHandler({
                        ...item,
                        isChecked: Boolean(data.checked),
                      })
                    }
                    className={classNames("checklist-item", {
                      "crossed-out": item.isChecked,
                    })}
                    label={item.name}
                    checked={item.isChecked}
                    disabled={
                      activeTask?.status === TaskStatusModel.COMPLETE ||
                      activeTask?.status ===
                        TaskStatusModel.DECLARED_COMPLETE ||
                      activeTask?.status === TaskStatusModel.IN_PROGRESS ||
                      activeTask?.checkListLock || disabled
                    }
                  />
                  {item.isChecked && item.userEmail && (
                    <p className="checklist-email">
                      {item.userEmail +
                        " at " +
                        formatChecklistDate(item.checkedDate)}
                    </p>
                  )}
                </div>
                {!(
                  activeTask?.status === TaskStatusModel.COMPLETE ||
                  activeTask?.status === TaskStatusModel.DECLARED_COMPLETE ||
                  activeTask?.status === TaskStatusModel.IN_PROGRESS ||
                  activeTask?.checkListLock
                ) && (
                  <div
                    onClick={() => removeHandler(item.id)}
                    className="close-icon"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      {opened && <TaskEventModal
          eventText={eventText}
          opened={opened}
          setOpened={setOpened}
          task={activeTask!}
          suspendReason={"No reason"}
          evaluationResult={"No result"}
          defaultDate=""
          activeChecklistItem={activeItem}
          setActiveItem={setActiveItem}
      />}
    </>
  );
};

export default TaskChecklist;
