import * as searchActions from "../actions/search.actions";
import {ReportItem, ThunkResult} from "../store.types";
import ReportTransactions from "./transactions/report.transactions";
import {SearchParametersModel} from "../reducers/search";
import {subsManager} from "../middleware/subs-manager/subs-manager";

export class SearchThunk {
    static setSearchValue(value: string): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(searchActions.SearchActions.setSearchValue(value));
            dispatch(searchActions.SearchActions.setSearchBarLoading(false));
        }
    }

    static addReportItem(name: string, params: SearchParametersModel): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            const activeProject = getState().project.activeProject;
            if (activeProject) {
                ReportTransactions.addReportItem(activeProject.projectId, name, params).then(() => {
                    subsManager.subscribeReportList(activeProject.projectId)
                })
            }
        }
    }

    static removeReportItem(reportId: string): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            const activeProject = getState().project.activeProject;
            ReportTransactions.removeReportItem(reportId).then(() => {
                subsManager.subscribeReportList(activeProject!.projectId)
            })
        }
    }

    static editReportItem(report: ReportItem): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            const activeProject = getState().project.activeProject;
            ReportTransactions.editReportItem(report).then(() => {
                subsManager.subscribeReportList(activeProject!.projectId)
            })
        }
    }
}
