import React from "react";
import {Dropdown, DropdownDivider} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake, faHandPointLeft, faPlay, faPause, faCheck} from "@fortawesome/pro-light-svg-icons";
import TaskModel from "../../../../../models/responses/task.model";
import TaskStatusModel from "../../../../../models/responses/task-status.model";

export default function TaskDropdown(props: {
    contextTask: {task: TaskModel, x: number, y: number},
    setContextTask: any,
    userId: string}) {
    const {
        contextTask,
        setContextTask,
        userId
    } = props;
    const task = contextTask.task;
    const isTaskForce = task.taskForce?.includes(userId);

  return (
      <Dropdown
          open={contextTask !== null}
          onClose={() => setContextTask(null)}
          icon={null}
          style={{position: "fixed", top: contextTask?.y, left: contextTask?.x}}
      >
          <Dropdown.Menu>
              <Dropdown.Header>Task Options</Dropdown.Header>
              {!isTaskForce && <Dropdown.Item><FontAwesomeIcon icon={faHandshake} style={{marginRight: "8px"}}/>Join taskforce</Dropdown.Item>}
              {isTaskForce && <Dropdown.Item><FontAwesomeIcon icon={faHandPointLeft} style={{marginRight: "8px"}}/>Leave taskforce</Dropdown.Item>}
              {isTaskForce && <DropdownDivider />}
              {isTaskForce && task.status === TaskStatusModel.NOT_STARTED && <Dropdown.Item><FontAwesomeIcon icon={faPlay} style={{marginRight: "8px"}}/>Start task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.IN_PROGRESS && <Dropdown.Item><FontAwesomeIcon icon={faCheck} style={{marginRight: "8px"}}/>Complete task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.IN_PROGRESS && <Dropdown.Item><FontAwesomeIcon icon={faPause} style={{marginRight: "8px"}}/>Suspend task</Dropdown.Item>}
              {isTaskForce && task.status === TaskStatusModel.NOT_STARTED && <Dropdown.Item>Block task</Dropdown.Item>}
          </Dropdown.Menu>
      </Dropdown>
  );
}