export default function checkRelationValidity(
    task,
    predecessorTask,
    relation
) {
    if (relation.pred_type === "PR_FS") {
        if (predecessorTask.status_code === 'TK_NotStart') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }

        if (predecessorTask.status_code === 'TK_Active') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }

        if (predecessorTask.status_code === 'TK_Complete') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }
    } else if (relation.pred_type === 'PR_SS') {
        if (predecessorTask.status_code === 'TK_NotStart') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }

        if (predecessorTask.status_code === 'TK_Active') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }

        if (predecessorTask.status_code === 'TK_Complete') {
            if (task.status_code === 'TK_NotStart') {
                return true;
            } else {
                return false;
            }
        }
    } else if (relation.pred_type === 'PR_FF') {
        if (predecessorTask.status_code === 'TK_NotStart') {
            if (task.status_code === 'TK_Complete') {
                return false;
            } else {
                return true;
            }
        }

        if (predecessorTask.status_code === 'TK_Active') {
            if (task.status_code === 'TK_Complete') {
                return false;
            } else {
                return true;
            }
        }

        if (predecessorTask.status_code === 'TK_Complete') {
            if (task.status_code === 'TK_Complete') {
                return false;
            } else {
                return true;
            }
        }
    }
    // start to finish
    else {
        if (predecessorTask.status_code === 'TK_NotStart') {
            if (task.status_code === 'TK_NotStart' || task.status_code === 'TK_Active') {
                return true;
            } else {
                return false;
            }
        }
        if (predecessorTask.status_code === 'TK_Active') {
            return false;
        }

        if (predecessorTask.status_code === 'TK_Complete') {
            return false;
        }
    }

    return false;
}