import {ActionsUnion, createAction} from "@martin_hotell/rex-tils";
import {WbsModel} from "../../models/responses/wbs.model";

export enum ActionNames {
    SET_WBS_LIST = '[WBS] Set WBS List',
}


export const Actions = {
    setWBSList: (wbsList: WbsModel[]) => createAction(ActionNames.SET_WBS_LIST, {wbsList}),
};

export type Actions = ActionsUnion<typeof Actions>;
