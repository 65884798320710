import { UserDocModel } from "../../models/responses/user.model";
import { MessageType } from "../../models/responses/message.model";
import FirebaseUsage from "../firebase.usage";
import { TaskFunctions } from "../../models/firebase-functions.model";
import { SuspendReasons } from "../../models/suspend-reason.enum";
import { Maybe } from "@martin_hotell/rex-tils";
import TaskModel from "../../models/responses/task.model";
import { LinePathModel } from "../../models/responses/line-path.model";
import { CalendarModel } from "../../models/responses/calendar.model";
import { Omit } from "redux-form";

export default class FirebaseTaskFunctions {
  public static async calcForecast(args: {
    tasks: TaskModel[];
    racingLine: LinePathModel[];
    calendar: Omit<CalendarModel, "project_id">[];
  }) {
    return await FirebaseUsage.functions(TaskFunctions.CALC_FORECAST, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async evaluateCompletionTask(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
    accepted: boolean;
    userEmail: string;
    type: MessageType;
    eventTimestamp: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.EVALUATE_COMPLETE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async completeChecklistNotification(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.COMPLETE_CHECKLIST_NOTIFICATION,
      {
        timeout: 1000000,
      }
    )({
      ...args,
    });
  }

  public static async taskBehindScheduleNotification(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.TASK_BEHIND_SCHEDULE_NOTIFICATION,
      {
        timeout: 1000000,
      }
    )({
      ...args,
    });
  }

  public static async assignedToTaskforceNotification(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.ASSIGNED_TO_TASKFORCE_NOTIFICATION,
      {
        timeout: 1000000,
      }
    )({
      ...args,
    });
  }

  public static async evaluateCompletionTasks(args: {
    user: Maybe<UserDocModel>;
    projectId: string;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.EVALUATE_COMPLETE_TASKS,
      { timeout: 1000000 }
    )({
      ...args,
    });
  }

  public static async cpmCalculation(args: {
    tasks: TaskModel[];
    calendar: Omit<CalendarModel, "project_id">[];
  }) {
    return await FirebaseUsage.functions(TaskFunctions.CPM_CALCULATION, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async declareCompleteTask(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
    userEmail: string;
    type: MessageType;
    eventTimestamp: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.DECLARE_COMPLETE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async runActiveTask(args: {
    taskId: string;
    projectId: string;
    user: UserDocModel;
    userEmail: string;
    type: MessageType;
    eventTimestamp: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.RUN_TASK, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async refreshAfterUndo(args: {
    task: TaskModel;
    projectId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.REFRESH_TASKS, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async setBlockTask(args: {
    taskId: string;
    projectId: string;
    suspendReason: string;
    blocked: boolean;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.BLOCK_TASK, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async suspendActiveTask(args: {
    taskId: string;
    projectId: string;
    suspendReason: SuspendReasons | string;
    user: UserDocModel;
    userEmail: string;
    msgType: MessageType;
    eventTimestamp: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.SUSPEND_TASK, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async changeRemainingDurationOfActiveTask(args: {
    taskId: string;
    projectId: string;
    forcastDate: Date;
    user: UserDocModel;
    userEmail: string;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.CHANGE_REMAINING_DURATION_OF_ACTIVE_TASK,
      {
        timeout: 1000000,
      }
    )({
      ...args,
    });
  }

  public static async sendPost(args: {
    taskId: string;
    text: string;
    user: UserDocModel;
    projectId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.SEND_POST, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async updateUserTaskSeen(args: {
    taskId: string;
    user: UserDocModel;
    seen: boolean;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.UPDATE_USER_TASK_SEEN, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async joinTaskForce(args: {
    taskId: string;
    projectId: string;
    userId: string;
    type: MessageType;
    eventTimestamp: string;
    user: Maybe<UserDocModel>;
    memberId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.JOIN_TASKFORCE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async addTaskForce(args: {
    taskId: string;
    projectId: string;
    memberId: string;
    whoAdded: string;
    type: string;
    groupId: string;
    user: Maybe<UserDocModel>;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.ADD_TASKFORCE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async removeTaskForce(args: {
    taskId: string;
    projectId: string;
    userId: string;
    type: string;
    groupId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.REMOVE_TASKFORCE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async leaveTaskForce(args: {
    taskId: string;
    projectId: string;
    userId: string;
    type: MessageType;
    eventTimestamp: string;
    user: Maybe<UserDocModel>;
    memberId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.LEAVE_TASKFORCE, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async addNewTaskList(args: {
    body: Partial<TaskModel>[];
    projectId: string;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.ADD_NEW_TASKLIST, {
      timeout: 1000000,
    })({
      ...args,
    });
  }

  public static async addCompletedTaskCount(args: {
    projectId: string;
    defaultCompletedTaskCount: number;
  }) {
    return await FirebaseUsage.functions(
      TaskFunctions.ADD_COMPLETED_TASK_COUNT,
      { timeout: 1000000 }
    )({
      ...args,
    });
  }

  public static async addEventLog(args: {
    projectId: string;
    taskId: string;
    type: MessageType;
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.ADD_EVENT_LOG)({
      ...args,
    });
  }

  public static async updateCheckListLock(args: {
    taskId: string;
    memberId: string;
    checkListLock: boolean;
  }) {
    return await FirebaseUsage.functions(TaskFunctions.UPDATE_CHECKLIST_LOCK)({
      ...args,
    });
  }
}
