import * as React from 'react';
import {Button, Dimmer, Loader, Modal, Table} from "semantic-ui-react";
import ProjectMembersModel from "../../../../../models/responses/project-members.model";
import {ModalProps} from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import {faAdjust, faCircle } from "@fortawesome/pro-solid-svg-icons";
import {faCheckCircle, faUser, faUserTie, faUserCrown, faUserHardHat } from "@fortawesome/pro-light-svg-icons";
import {faCircle as faCircleReg} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useActiveProjectSelector} from "../../../../../store/selectors/project.selectors";
import {useUserSelector} from "../../../../../store/selectors/authorization.selectors";
import {MemberOrganizationEnum} from "../../../../../models/member-organization.enum";
import {useDispatch} from "react-redux";
import {TeamThunk} from "../../../../../store/thunk/team.thunk";
import {useLoadingMemberGradeSelector} from "../../../../../store/selectors/team.selectors";
import { useCallback } from 'react';


interface InjectedProps {
    onClose: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void
    open: boolean;
    member?: ProjectMembersModel;
}

type PropsType = InjectedProps;

export const MemberGradeModal: React.FC<PropsType> = props => {
    const activeProject = useActiveProjectSelector();
    const user = useUserSelector();
    const dispatch = useDispatch();
    const loading = useLoadingMemberGradeSelector();

    const changeMemberGrade = useCallback(async (grade: MemberOrganizationEnum) => {
        if (!activeProject || !props.member) {
            return null;
        }
        // @ts-ignore
        dispatch(TeamThunk.changeMemberGrade(grade, props.member, activeProject.projectId));
    }, [activeProject, dispatch, props.member]);


    const getCheckUserGrade = useCallback((grade: MemberOrganizationEnum, disabled?: boolean) => {
        if (!props.member) {
            return null;
        }
        return (
            <Table.Cell textAlign="center" className={props.member.grade === grade ? 'active' : ''}>
                {
                    props.member.grade === grade
                        ? <FontAwesomeIcon icon={faCheckCircle}/>
                        : <Button circular
                                  disabled={disabled}
                                  icon="circle"
                                  onClick={() => changeMemberGrade(grade)}/>
                }
            </Table.Cell>
        );
    }, [changeMemberGrade, props.member]);

    if (!props.member || !activeProject || !user) {
        return null;
    }

    return (
        <Modal closeIcon onClose={props.onClose} open={props.open}>
            <Modal.Header>{activeProject.name} / {props.member.userEmail}</Modal.Header>
            <Modal.Content className="relative">
                {
                    loading &&
                    <div className="task-statuses-preview-loader opacity-7">
                        <Dimmer active inverted>
                            <Loader inverted>Grade is updating...</Loader>
                        </Dimmer>
                    </div>
                }
                <Table celled structured className="member-grade-status-color">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="1">
                                <div className="member-grade-label">
                                    <FontAwesomeIcon icon={faCircle}/>
                                    <span>Available</span>
                                </div>
                                <div className="member-grade-label">
                                    <FontAwesomeIcon icon={faCircleReg}/>
                                    <span>Domain Restricted</span>
                                </div>
                                <div className="member-grade-label">
                                    <FontAwesomeIcon icon={faAdjust}/>
                                    <span>Premium license only</span>
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan='1' className="member-status-actor"><div><FontAwesomeIcon icon={faUser}/><span>Observer</span></div></Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan='1' className="member-status-actor"><div><FontAwesomeIcon icon={faUserHardHat} /><span>Actor</span></div></Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan='1' className="member-status-actor"><div><FontAwesomeIcon icon={faUserTie}/><span>Lead-Actor</span></div></Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan='1' className="member-status-actor"><div><FontAwesomeIcon icon={faUserCrown}/><span>Super-Actor</span></div></Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell textAlign="center">Free</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Free</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Free</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Free</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Add comments</Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>View Tasks</Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Deliver Tasks</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Manage Permission</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircleReg}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Manage membership grades</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircleReg}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Add / remove members</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircleReg}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Upload strategy</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faCircle}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Access database</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faAdjust}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>View archive tasks</Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faAdjust}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faAdjust}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faAdjust}/></Table.Cell>
                            <Table.Cell textAlign="center"><FontAwesomeIcon icon={faAdjust}/></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell/>
                            {getCheckUserGrade(MemberOrganizationEnum.OBSERVER)}
                            {getCheckUserGrade(MemberOrganizationEnum.ACTOR)}
                            {getCheckUserGrade(MemberOrganizationEnum.LEAD_ACTOR)}
                            {getCheckUserGrade(MemberOrganizationEnum.SUPER_ACTOR)}
                            {/*{*/}
                            {/*    userGrade[MemberOrganizationEnum.SUPER_ACTOR] &&*/}
                            {/*    <>*/}
                            {/*        <Table.Cell/>*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.OBSERVER)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.ACTOR)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.LEAD_ACTOR)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.SUPER_ACTOR)}*/}
                            {/*    </>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    userGrade[MemberOrganizationEnum.LEAD_ACTOR] &&
                                    Validations.sameEmailDomain(props.member.userEmail, user && user.userEmail || '') &&*/}
                            {/*    <>*/}
                            {/*        <Table.Cell/>*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.OBSERVER)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.ACTOR)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.LEAD_ACTOR, true)}*/}
                            {/*        {getCheckUserGrade(MemberOrganizationEnum.SUPER_ACTOR, true)}*/}
                            {/*    </>*/}
                            {/*}*/}
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
        </Modal>
    );
};
