import ProjectModel, {
  CausedBy,
} from "../../../models/responses/project.model";
import { Omit } from "@martin_hotell/rex-tils";
import { UserDocModel } from "../../../models/responses/user.model";
import FirebaseProjectFunctions from "../../../firebase/firebase-functions/project-service";
import { CancelUpdating } from "../../../utils/cancel-updating-strategy.model";

export default abstract class ProjectTransactions {
  public static async uploadNewProject(
    project: Omit<
      ProjectModel,
      "enableLogging" | "grade" | "curves" | "racingLine"
    > & { racingLine: string },
    userId: string | null,
    tasksJsonStr: string,
    causedBy: CausedBy | null,
    email: string
  ): Promise<any> {
    return await FirebaseProjectFunctions.uploadNewProject({
      project,
      userId,
      tasksJsonStr,
      causedBy,
      email,
    });
  }

  public static async projectDeletion(
    projectIds: string[],
    user: UserDocModel
  ) {
    return await FirebaseProjectFunctions.projectDeletion({
      projectIds,
      user,
    });
  }

  public static async cancelProjectUploading(
    projectNames: string[],
    user: UserDocModel
  ) {
    return await FirebaseProjectFunctions.cancelProjectUploading({
      projectNames,
      user,
    });
  }

  public static async cancelUploadStrategy(
    keepingData: CancelUpdating,
    projectId: string
  ) {
    return await FirebaseProjectFunctions.cancelUploadStrategy({
      keepingData,
      projectId,
    });
  }

  public static async sendMails(
    email: string,
    firstName: string,
    lastName: string,
    otherData?: {
      phoneNumber?: string;
      jobTitle?: string;
      company?: string;
    }
  ) {
    return await FirebaseProjectFunctions.sendMails({
      email,
      firstName,
      lastName,
      otherData,
    });
  }

  public static async getClients() {
    return await FirebaseProjectFunctions.getClients();
  }
}
