import {Button, Form, FormField, FormGroup, Label, Modal} from "semantic-ui-react";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from './MonteCarloModal.module.scss'
import {ProjectThunk} from "../../../../store/thunk/project.thunk";
import {useDispatch} from "react-redux";
import store from "../../../../store/store";
import {useMonteCarloIndexSelector} from "../../../../store/selectors/project.selectors";
import * as projectActions from "../../../../store/actions/project.actions";


export default function MonteCarloModal(props) {
    const [iterations, setIterations] = useState(1000)
    const [upperLimit, setUpperLimit] = useState(1.5)
    const [lowerLimit, setLowerLimit] = useState(0.8)
    const [simulationModel, setSimulationModel] = useState('Monte Carlo')
    const [simulationRunning, setSimulationRunning] = useState(false)
    const [runFunction, setRunFunction] = useState(false)
    const dispatch = useDispatch()
    const monteCarloIndex = useMonteCarloIndexSelector()
    const [runIndex, setRunIndex] = useState(0)

    const didMount = useRef(false)

    useEffect(() => {
        if(simulationRunning) {
            if (!runFunction) {
                setRunFunction(true)
                dispatch(projectActions.Actions.setMonteCarloIndex(0))
            } else {
                if (monteCarloIndex < iterations) {
                    console.log(monteCarloIndex, "monteCarloIndex")
                    didMount.current = false
                    ProjectThunk.runMonteCarlo(props.projectId, dispatch, props.trackedMilestones, upperLimit, lowerLimit, 1)
                        .then(() => {
                            dispatch(projectActions.Actions.setMonteCarloIndex(monteCarloIndex + 1))
                            setRunIndex(prevState => prevState + 1)
                        })
                        .catch(err => console.log(err))
                } else {
                    setSimulationRunning(false)
                    setRunFunction(false)
                }
            }
        }
    }, [simulationRunning, runFunction, monteCarloIndex]);

    return (
        <Modal closeIcon open={props.showModal} onClose={() => props.setShowModal(false)}>
            <Modal.Header>
                Monte Carlo Simulation
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {simulationRunning ?
                    <div className={styles.Loading}>
                        <p>Running Simulation</p>
                        <p>{`${runIndex} of ${iterations}`}</p>
                        <progress max="100" value={Math.floor((monteCarloIndex / iterations) * 100).toString()}/>
                    </div> :
                    <Form>
                        <div className={styles.SimulationModelForm}>
                            <label style={{padding: "5px"}}>Simulation Model</label>
                            <FormGroup inline className={styles.ThisFormField}>
                                <FormField>
                                    <label>Monte Carlo</label>
                                    <input
                                        type="radio"
                                        id={'Monte Carlo'}
                                        checked={simulationModel === 'Monte Carlo'}
                                        onChange={(e) => setSimulationModel(e.target.id)}
                                    />
                                </FormField>
                                <FormField>
                                    <label>Machine Learning</label>
                                    <input
                                        disabled
                                        type="radio"
                                        id={'Machine Learning'}
                                        checked={simulationModel === 'Machine Learning'}
                                        onChange={(e) => setSimulationModel(e.target.id)}
                                    />
                                </FormField>
                            </FormGroup>
                        </div>
                        <div className={styles.IterationsForm}>
                            <label style={{padding: "5px"}}>Simulation Settings</label>
                            <Form.Group style={{marginTop: "5px", paddingLeft: "10px"}}>
                                <Form.Field>
                                    <label>Number of Iterations</label>
                                    <input
                                        style={{width: "120px"}}
                                        type="number"
                                        min={10}
                                        max={10000}
                                        value={iterations}
                                        onChange={(e) => setIterations(parseInt(e.target.value))}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </div>
                        <div className={styles.UncertaintyForm}>
                            <label>Uncertainty Parameters</label>
                            <Form.Group>
                                <Form.Field>
                                    <label>Generic Upper Limit</label>
                                    <input
                                        type="number"
                                        value={upperLimit}
                                        min={1}
                                        step={0.05}
                                        onChange={(e) => setUpperLimit(parseFloat(e.target.value))}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Generic Lower Limit</label>
                                    <input
                                        type="number"
                                        value={lowerLimit}
                                        min={0}
                                        max={1}
                                        step={0.05}
                                        onChange={(e) => setLowerLimit(parseFloat(e.target.value))}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </div>
                    </Form>
                }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.setShowModal(false)}>Cancel</Button>
                <Button positive onClick={() => {
                    setSimulationRunning(true)
                }}>Run Simulation</Button>
            </Modal.Actions>
        </Modal>
    )
}