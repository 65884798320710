import * as eventLogActions from '../../actions/event-log.actions';
import EventLog from '../../../models/responses/event-log-model';

export class StateModel {
    eventLogList: EventLog[] = [];
}

export const initialState = new StateModel();

export function reducer(state = initialState, action: eventLogActions.Actions) {
    switch (action.type) {
        case eventLogActions.ActionNames.SET_EVENT_LOG_LIST: {
            // console.log('💻 state in SET_EVENT_LOG_LIST', state)
            // console.log('💻 action in SET_EVENT_LOG_LIST', action)
            return {
                ...state,
                eventLogList: action.payload.eventLogList,
            }
        }
        default:
            return state;
    }
}
