import React, {useCallback, useMemo, useRef, useState} from "react";
import { Accordion, Button, Header, Modal } from "semantic-ui-react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  usePendingProjectMemberListSelector,
  useProjectMemberListSelector,
  useTaskforceListSelector,
} from "../../../../../../store/selectors/team.selectors";
import { useDispatch } from "react-redux";
import { TeamThunk } from "../../../../../../store/thunk/team.thunk";
import ProjectMembersModel from "../../../../../../models/responses/project-members.model";
import {
  useUserGradeSelector,
  useUserSelector,
} from "../../../../../../store/selectors/authorization.selectors";
import { MemberOrganizationEnum } from "../../../../../../models/member-organization.enum";
import { useActiveProjectSelector } from "../../../../../../store/selectors/project.selectors";
import { useActiveTaskSelector } from "../../../../../../store/selectors/task/task.selectors";
import Validations from "../../../../../../utils/validations";
import { TaskThunk } from "../../../../../../store/thunk/task.thunk";
import { AddNewMemberFormValue } from "../HeaderMember";
import styles from "./ProjectTeam.module.scss";
import classNames from "classnames";
import { SearchActions } from "../../../../../../store/actions/search.actions";
import {
  faAngleDown,
  faAngleUp,
  faTasks,
  faUserTimes,
  faBalanceScaleLeft,
  faUserPlus,
  faEnvelope,
  faCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faKeySkeleton,
  faGlasses,
  faAt,
  faUser,
  faUserGear,
  faUserGroup,
} from "@fortawesome/pro-solid-svg-icons";
import { GateKeeperEum } from "../../../../../../models/gate-keeper.enum";
import {joinTaskForce, leaveTaskForce} from "../../../../../../utils/taskforce.utils";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";

interface InjectedProps {
  searchValue: string;
  addNewTaskforceMember: (data: AddNewMemberFormValue) => void;
  removeTaskforceMember: (data: AddNewMemberFormValue) => void;
}

type PropsType = InjectedProps;

export const ProjectTeam: React.FC<PropsType> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clickedMember, setClickedMember] = useState<ProjectMembersModel>();
  const taskForceList = useTaskforceListSelector();
  const projectMembersList = useProjectMemberListSelector();
  const user = useUserSelector();
  const activeProject = useActiveProjectSelector();
  const userGrade = useUserGradeSelector();
  const taskForceListChecker = useRef(taskForceList);
  const pendingMembersList = usePendingProjectMemberListSelector()

  const activeTask = useActiveTaskSelector();
  const dispatch = useDispatch();
  const combinedLists = useMemo(() => {
    const allMembers = [...taskForceList, ...projectMembersList, ...pendingMembersList];
    const groupedByGrade = allMembers.reduce((groups, member) => {
      const grade = member.grade;
      if (!groups[grade]) {
        groups[grade] = [];
      }
      groups[grade].push(member);
      return groups;
    }, {});

    const order = [
      MemberOrganizationEnum.SUPER_ACTOR,
      MemberOrganizationEnum.LEAD_ACTOR,
      MemberOrganizationEnum.ACTOR,
      MemberOrganizationEnum.OBSERVER,
      MemberOrganizationEnum.GROUPS,
    ];

    const sortedGroups = order.reduce((sorted, grade) => {
      if (groupedByGrade[grade]) {
        sorted[grade] = groupedByGrade[grade];
      }
      return sorted;
    }, {});

    return sortedGroups;
  }, [taskForceList, projectMembersList]);

  const [selectedMember, setSelectedMember] = useState<string>("");
  const [activeSecondaryTitle, setActiveSecondaryTitle] = useState<{
    index: number;
    isActive: boolean | undefined;
  }>({
    index: -1,
    isActive: false,
  });
  const [activeRole, setActiveRole] = useState<{
    index: number;
    isActive: boolean | undefined;
  }>({
    index: -1,
    isActive: false,
  });
  const [activeGatekeeper, setActiveGatekeeper] = useState<{
    index: number;
    isActive: boolean | undefined;
  }>({
    index: -1,
    isActive: false,
  });

  const projectMember = projectMembersList.find((member) => member.userId === selectedMember);

  const mailToRedirect = useCallback(
    (member: ProjectMembersModel) => {
      if (
        activeTask &&
        user &&
        activeTask.taskForce.some(
          (el) => el === user.userId
        ) /*&& props.type === 'taskforce'*/
      ) {
        window.location.href = `mailto: ${member.userEmail}?subject=${
          activeTask!.wbs
        }&body=${activeTask!.task_name}, ${activeTask!.task_code}`;
      } else {
        window.location.href = `mailto: ${member.userEmail}?subject=${
          activeProject!.name
        }`;
      }
    },
    [activeTask, user, activeProject]
  );

  const getMemberIconName = useCallback(
    (grade: MemberOrganizationEnum): IconDefinition => {
      switch (grade) {
        case MemberOrganizationEnum.OBSERVER: {
          return faGlasses;
        }
        case MemberOrganizationEnum.ACTOR: {
          return faUserGroup;
        }
        case MemberOrganizationEnum.LEAD_ACTOR: {
          return faUser;
        }
        case MemberOrganizationEnum.SUPER_ACTOR: {
          return faUserGear;
        }
        case MemberOrganizationEnum.GROUPS: {
          return faAt;
        }
        default: {
          return faUser;
        }
      }
    },
    []
  );

  const accordionSecondaryTitleClickHandler = useCallback(
    (titleIndex: number, userId: string) => {
      setSelectedMember(userId);
      if (activeSecondaryTitle.index !== titleIndex) {
        setActiveSecondaryTitle({
          index: titleIndex,
          isActive: true,
        });
      } else {
        setActiveSecondaryTitle({
          ...activeSecondaryTitle,
          isActive: !activeSecondaryTitle.isActive,
        });
      }
    },
    [activeSecondaryTitle]
  );

  const roleAccordionClickHandler = useCallback(
    (titleIndex: number) => {
      if (activeRole.index !== titleIndex) {
        setActiveRole({
          index: titleIndex,
          isActive: true,
        });
      } else {
        setActiveRole({
          ...activeRole,
          isActive: !activeRole.isActive,
        });
      }
    },
    [activeRole]
  );

  const gatekeeperAccordionClickHandler = useCallback(
    (titleIndex: number) => {
      if (activeGatekeeper.index !== titleIndex) {
        setActiveGatekeeper({
          index: titleIndex,
          isActive: true,
        });
      } else {
        setActiveGatekeeper({
          ...activeGatekeeper,
          isActive: !activeGatekeeper.isActive,
        });
      }
    },
    [activeGatekeeper]
  );

  const changeMemberGrade = useCallback(
    async (grade: MemberOrganizationEnum) => {
      if (!activeProject || !projectMember) {
        return null;
      }
      // @ts-ignore
      dispatch(TeamThunk.changeMemberGrade(grade, projectMember, activeProject.projectId));
    },
    [activeProject, dispatch, projectMember]
  );

  const changeGatekeeperGrade = useCallback(
    async (grade: GateKeeperEum) => {
      if (!activeProject || !projectMember) {
        return null;
      }
      // @ts-ignore
      dispatch(TeamThunk.changeMemberGateKeeper(grade, projectMember.memberId, activeProject.projectId));
    },
    [activeProject, dispatch, projectMember]
  );

const checkAnotherUserAssigned = useCallback(
  async (member: ProjectMembersModel) => {
    if (user && member.userEmail !== user.userEmail) {
      // @ts-ignore
      dispatch(TaskThunk.completeChecklistNotification(activeTask.task_id));
    }
  },
  [user]
);

  if (!activeProject) {
    return null;
  }

  return (
    <>
      <div className="accordion-wrapper">
        {Object.keys(combinedLists).length === 0 ? (
          <span className="no-tasks">No project list entry</span>
        ) : (
          Object.entries(combinedLists).map(([grade, members]: [any, any]) => (
            <div key={grade}>
              <div className={styles.GradeGroup}>
                <FontAwesomeIcon
                  icon={getMemberIconName(grade)}
                  className={styles.MemberIcon}
                />
                <span>
                  {grade === MemberOrganizationEnum.ACTOR
                    ? "Team-Member"
                    : grade === MemberOrganizationEnum.LEAD_ACTOR
                    ? "Lead"
                    : grade === MemberOrganizationEnum.SUPER_ACTOR
                    ? "Administator"
                    : grade}
                </span>
              </div>
              {
                <Accordion
                  styled
                  panels={members
                    // @ts-ignore
                    .filter(
                      (member, index, self) =>
                        index ===
                          self.findIndex(
                            (m) => m.memberId === member.memberId
                          ) &&
                        (!props.searchValue ||
                          member.userEmail.includes(props.searchValue))
                    )
                    .map((member, index) => {
                      return {
                        key: member.memberId,
                        title: {
                          content: (
                            <div
                              className={classNames(styles.MemberItemWrap)}
                              onClick={() =>
                                accordionSecondaryTitleClickHandler(
                                  member.grade + index,
                                  member.userId
                                )
                              }
                            >
                              {(member.gateKeeper ===
                                GateKeeperEum.LockChecklist ||
                                member.gateKeeper ===
                                  GateKeeperEum.TaskSignOff ||
                                member.gateKeeper === GateKeeperEum.Both) && (
                                <FontAwesomeIcon
                                  className={styles.MemberKeyIcon}
                                  icon={faKeySkeleton}
                                />
                              )}
                              <FontAwesomeIcon
                                className="member-icon"
                                icon={getMemberIconName(member.grade)}
                              />
                              <span className={styles.MemberEmailLabel}>
                                {member.userEmail}
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  activeSecondaryTitle.index ===
                                    member.grade + index &&
                                  activeSecondaryTitle.isActive
                                    ? faAngleUp
                                    : faAngleDown
                                }
                              />
                            </div>
                          ),
                        },
                        content: {
                          content: (
                            <>
                              <div
                                className={classNames("content-option", {
                                  disabled: !Boolean(activeTask),
                                })}
                              >
                                <FontAwesomeIcon icon={faUserPlus} />
                                <span
                                  onClick={() => {
                                    if (activeTask && activeTask.taskForce.includes(member.userId)) {
                                      leaveTaskForce(activeTask.task_id, member.userId, user!.userId!, FirebaseUsage.timestamp())
                                          .catch(e => console.error(e));
                                    } else if (activeTask) {
                                      joinTaskForce(activeTask.task_id, member.userId, user!.userId!, FirebaseUsage.timestamp())
                                            .catch(e => console.error(e));
                                      }
                                    // checkAnotherUserAssigned(member)
                                  }}
                                >{`${ 
                                  activeTask &&
                                  activeTask.taskForce.includes(member.userId)
                                    ? "Remove from"
                                    : "Add to"
                                } taskforce (${
                                  activeTask
                                    ? activeTask.task_code
                                    : "*task code"
                                })`}</span>
                              </div>
                              <div
                                className="content-option"
                                onClick={() => {
                                  dispatch(
                                    SearchActions.setTaskforceFilter([member.userId])
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faTasks} />
                                <span>View Tasks</span>
                              </div>

                              {member.grade ===
                              MemberOrganizationEnum.SUPER_ACTOR ? (
                                <div
                                  className="content-option"
                                  onClick={() =>
                                    dispatch(
                                      // @ts-ignore
                                      TeamThunk.makeEvaluatorToggle(
                                        member.memberId
                                      )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faBalanceScaleLeft} />
                                  <span>
                                    {member.gateKeeper
                                      ? "Remove evaluator priviledge"
                                      : "Make evaluator priviledge"}
                                  </span>
                                </div>
                              ) : null}
                              {/* {(userGrade[
                                  MemberOrganizationEnum.SUPER_ACTOR
                                ] ||
                                  userGrade[
                                    MemberOrganizationEnum.LEAD_ACTOR
                                  ]) &&
                                Validations.sameEmailDomain(
                                  member.userEmail,
                                  user ? user.userEmail : ""
                                ) ? (
                                  <> */}
                              {member.grade !==
                                MemberOrganizationEnum.GROUPS && (
                                <Accordion styled className="sub-accordion">
                                  <Accordion.Title>
                                    <div
                                      onClick={() =>
                                        gatekeeperAccordionClickHandler(
                                          member.grade + index
                                        )
                                      }
                                      className="sub-title"
                                    >
                                      <span className={styles.roleLabel}>
                                        Gatekeeper
                                      </span>
                                      <FontAwesomeIcon
                                        icon={
                                          activeGatekeeper.index ===
                                            member.grade + index &&
                                          activeGatekeeper.isActive
                                            ? faAngleUp
                                            : faAngleDown
                                        }
                                      />
                                    </div>
                                  </Accordion.Title>
                                  <Accordion.Content
                                    active={
                                      activeGatekeeper.index ===
                                        member.grade + index &&
                                      activeGatekeeper.isActive
                                    }
                                    className={
                                      userGrade[
                                        MemberOrganizationEnum.SUPER_ACTOR
                                      ] ||
                                      userGrade[
                                        MemberOrganizationEnum.LEAD_ACTOR
                                      ]
                                        ? ""
                                        : "disabled"
                                    }
                                  >
                                    <div
                                      className="sub-accordion-label"
                                      onClick={() =>
                                        changeGatekeeperGrade(
                                          GateKeeperEum.LockChecklist
                                        )
                                      }
                                    >
                                      <span>Lock checklist</span>
                                      {member.gateKeeper ===
                                        GateKeeperEum.LockChecklist && (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                    </div>
                                    <div
                                      className="sub-accordion-label"
                                      onClick={() =>
                                        changeGatekeeperGrade(
                                          GateKeeperEum.TaskSignOff
                                        )
                                      }
                                    >
                                      <span>Task sign-off</span>
                                      {member.gateKeeper ===
                                        GateKeeperEum.TaskSignOff && (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                    </div>
                                    <div
                                      className="sub-accordion-label"
                                      onClick={() =>
                                        changeGatekeeperGrade(
                                          GateKeeperEum.Both
                                        )
                                      }
                                    >
                                      <span>Both</span>
                                      {member.gateKeeper ===
                                        GateKeeperEum.Both && (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                    </div>
                                    <div
                                      className="sub-accordion-label"
                                      onClick={() =>
                                        changeGatekeeperGrade(
                                          GateKeeperEum.None
                                        )
                                      }
                                    >
                                      <span>None</span>
                                      {member.gateKeeper ===
                                        GateKeeperEum.None && (
                                        <FontAwesomeIcon icon={faCheck} />
                                      )}
                                    </div>
                                  </Accordion.Content>
                                </Accordion>
                              )}
                              {member.grade !== MemberOrganizationEnum.GROUPS &&
                              (userGrade[MemberOrganizationEnum.SUPER_ACTOR] ||
                                userGrade[
                                  MemberOrganizationEnum.LEAD_ACTOR
                                ]) ? (
                                //   &&
                                // Validations.sameEmailDomain(
                                //   member.userEmail,
                                //   user ? user.userEmail : "")
                                <>
                                  <Accordion styled className="sub-accordion">
                                    <Accordion.Title>
                                      <div
                                        onClick={() =>
                                          roleAccordionClickHandler(
                                            member.grade + index
                                          )
                                        }
                                        className="sub-title"
                                      >
                                        <span className={styles.roleLabel}>
                                          Role
                                        </span>
                                        <FontAwesomeIcon
                                          icon={
                                            activeRole.index ===
                                              member.grade + index &&
                                            activeRole.isActive
                                              ? faAngleUp
                                              : faAngleDown
                                          }
                                        />
                                      </div>
                                    </Accordion.Title>
                                    <Accordion.Content
                                      active={
                                        activeRole.index ===
                                          member.grade + index &&
                                        activeRole.isActive
                                      }
                                    >
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.SUPER_ACTOR
                                          )
                                        }
                                      >
                                        <span>Administrator</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.SUPER_ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.LEAD_ACTOR
                                          )
                                        }
                                      >
                                        <span>Lead</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.LEAD_ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.ACTOR
                                          )
                                        }
                                      >
                                        <span>Team member</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.ACTOR && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.OBSERVER
                                          )
                                        }
                                      >
                                        <span>Observer</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.OBSERVER && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                      <div
                                        className="sub-accordion-label"
                                        onClick={() =>
                                          changeMemberGrade(
                                            MemberOrganizationEnum.GROUPS
                                          )
                                        }
                                      >
                                        <span>Groups</span>
                                        {member.grade ===
                                          MemberOrganizationEnum.GROUPS && (
                                          <FontAwesomeIcon icon={faCheck} />
                                        )}
                                      </div>
                                    </Accordion.Content>
                                  </Accordion>
                                </>
                              ) : null}
                              {member.grade !==
                                MemberOrganizationEnum.GROUPS && (
                                <div
                                  className="content-option"
                                  onClick={() => mailToRedirect(member)}
                                >
                                  <FontAwesomeIcon icon={faEnvelope} />
                                  <span>Send an email</span>
                                </div>
                              )}
                              {userGrade[MemberOrganizationEnum.SUPER_ACTOR] ||
                              userGrade[MemberOrganizationEnum.LEAD_ACTOR] ? (
                                // && Validations.sameEmailDomain(
                                //   member.userEmail,
                                //   user ? user.userEmail : ""
                                // )
                                <div
                                  className="content-option"
                                  onClick={() => {
                                    if (
                                      member.userId === user?.userId &&
                                      projectMembersList.length === 1
                                    ) {
                                      setOpenModal(true);
                                      setClickedMember(member);
                                    } else {
                                      dispatch(
                                        // @ts-ignore
                                        TeamThunk.removeMember(
                                          member.memberId,
                                          activeProject.projectId,
                                          member.grade ===
                                            MemberOrganizationEnum.GROUPS
                                            ? "group"
                                            : "user"
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUserTimes} />
                                  <span>Remove from pull system</span>
                                  {/* <span>{index === 0 ? "Remove from taskforce" : 'Remove from pull system'}</span> */}
                                </div>
                              ) : null}
                            </>
                          ),
                        },
                      };
                    })}
                />
              }
            </div>
          ))
        )}
      </div>

      <Modal dimmer="blurring" className="modal-event" open={openModal}>
        <Header
          textAlign="center"
          content={
            <h3>
              You are the only Administrator on this project, assign a
              replacement or the project will be closed
            </h3>
          }
        />
        <Modal.Actions>
          <Button negative onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() => {
              if (clickedMember) {
                dispatch(
                  // @ts-ignore
                  TeamThunk.removeMember(
                    clickedMember?.memberId,
                    activeProject.projectId,
                    clickedMember.grade === MemberOrganizationEnum.GROUPS
                      ? "group"
                      : "user"
                  )
                );
                setOpenModal(false);
              }
            }}
          >
            Close Anyway
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
