import { Dispatch, MiddlewareAPI } from "redux";
import { RootStateModel } from "../../reducers";
import * as taskActions from '../../actions/task.actions';
import { subsManager } from "../subs-manager/subs-manager";
import { TaskListSectionModel } from "../../../models/task-list-section.model";
import * as ProjectActions from "../../actions/project.actions";

let oldTaskId;
let oldCalendarId;
let oldActiveProjectId;
export function taskMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
    return (next: Dispatch) => (action: taskActions.Actions) => {
        const returnValue = next(action);


        switch (action.type) {
            case taskActions.ActionNames.SET_ACTIVE_TASK: {
                subsManager.removeSubscription('calendar');
                api.dispatch(ProjectActions.Actions.setActiveGeo(null))

                if (action.payload.task) {
                    const activeProject = api.getState().project.activeProject;
                    if (oldActiveProjectId !== activeProject?.projectId) {
                        oldTaskId = undefined;
                        oldActiveProjectId = activeProject?.projectId;
                    }
                    if (activeProject && action.payload.task.calendar_id) {
                        if ((!oldTaskId) || oldCalendarId !== action.payload.task.calendar_id) {
                            subsManager.subscribeCalendar(activeProject.projectId, action.payload.task.calendar_id);
                        }
                    }
                }

                oldTaskId = action.payload.task?.task_id;
                oldCalendarId = action.payload.task?.calendar_id;
                break;
            }
            case taskActions.ActionNames.SET_TASK_LIST: {
                const activeTask = api.getState().task.activeTask;
                const taskList = Object.keys(api.getState().task.tasks).reduce((a: any[], key) => {
                    return [...a, ...api.getState().task.tasks[key]];
                }, []);
                if (activeTask) {
                    // console.log(taskList.find(task => task.task_id === activeTask.task_id));
                    api.dispatch(taskActions.Actions.setActiveTask(taskList.find(task => task.task_id === activeTask.task_id)));
                }
                break;
            }
            case taskActions.ActionNames.LOAD_MORE_TASK_LIST: {
                const activeProject = api.getState().project.activeProject;

                switch (action.payload.taskListType) {
                    case TaskListSectionModel.CONFIRMED_COMPLETE: {
                        if (activeProject) {
                            subsManager.removeTaskListSubscription('confirmed-complete-task-list');
                            subsManager.subscribeConfirmedCompleteTaskList({
                                project_id: activeProject.projectId,
                                page: action.payload.page,
                                taskForces: api.getState().search.parameters.taskForces,
                                alert: api.getState().search.parameters.alert,
                            });
                        }
                        break;
                    }
                    case TaskListSectionModel.DECLARED_COMPLETE: {
                        if (activeProject) {
                            subsManager.removeTaskListSubscription('declared-complete-task-list');
                            subsManager.subscribeDeclaredCompleteTaskList({
                                project_id: activeProject.projectId,
                                page: action.payload.page,
                                taskForces: api.getState().search.parameters.taskForces,
                                alert: api.getState().search.parameters.alert,
                            });
                        }
                        break;
                    }
                    case TaskListSectionModel.QUEUED: {
                        if (activeProject) {
                            subsManager.removeTaskListSubscription('queued-task-list');
                            subsManager.subscribeQueuedTaskList({
                                project_id: activeProject.projectId,
                                page: action.payload.page,
                                taskForces: api.getState().search.parameters.taskForces,
                                alert: api.getState().search.parameters.alert
                            });
                        }
                        break;
                    }
                    case TaskListSectionModel.WORK_IN_PROCESS: {
                        if (activeProject) {
                            subsManager.removeTaskListSubscription('in-progress-task-list');
                            subsManager.subscribeInProgressTaskList({
                                project_id: activeProject.projectId,
                                page: action.payload.page,
                                taskForces: api.getState().search.parameters.taskForces,
                                alert: api.getState().search.parameters.alert,
                            });
                        }
                        break;
                    }
                    case TaskListSectionModel.PENDING: {
                            if (activeProject) {
                                subsManager.removeTaskListSubscription('pending-task-list');
                                subsManager.subscribePendingTaskList({
                                    project_id: activeProject.projectId,
                                    page: action.payload.page,
                                    taskForces: api.getState().search.parameters.taskForces,
                                    alert: api.getState().search.parameters.alert,
                                });
                            }
                            break;
                    }
                    default: {
                        break;
                    }
                }
                break;
            }
            case taskActions.ActionNames.OPEN_RELATED_TASK_LIST: {
                const activeProject = api.getState().project.activeProject;
                if (!activeProject) {
                    break;
                }
                switch (action.payload.type) {
                    case "predecessors": {
                        subsManager.removeSubscription('predecessors-list');
                        subsManager.subscribePredecessorsList(activeProject.projectId, action.payload.mainTask.task_id);
                        break;
                    }
                    case "successors": {
                        subsManager.removeSubscription('successors-list');
                        subsManager.subscribeSuccessorsList(activeProject.projectId, action.payload.mainTask.task_id);
                        break;
                    }
                    default: {
                        subsManager.removeSubscription('predecessors-list');
                        subsManager.removeSubscription('successors-list');
                        break;
                    }
                }
                break;
            }
            // case taskActions.ActionNames.SET_RELATED_TASK_LIST: {
            //     const activeProject = api.getState().project.activeProject;
            //     if (!activeProject) {
            //         break;
            //     }
            //     switch (action.payload.type) {
            //         case "predecessors": {
            //             subsManager.removeSubscription('predecessors-list');
            //             subsManager.subscribePredecessorsList(activeProject.projectId, action.payload.mainTask.task_id);
            //             break;
            //         }
            //         case "successors": {
            //             subsManager.removeSubscription('successors-list');
            //             subsManager.subscribeSuccessorsList(activeProject.projectId, action.payload.mainTask.task_id);
            //             break;
            //         }
            //         default: {
            //             subsManager.removeSubscription('predecessors-list');
            //             subsManager.removeSubscription('successors-list');
            //             break;
            //         }
            //     }
            //     break;
            // }
            default: {
                break;
            }
        }


        return returnValue
    }
}
