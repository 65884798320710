import React, {useState, useCallback, useRef} from 'react';
import { Button, Header, Message, Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {generateP6Import} from "../../../../../../utils/generateP6Import";
import {useUserSelector} from "../../../../../../store/selectors/authorization.selectors";


export const NavBarDataDateModal = ({
    opened,
    setOpened,
    project

}) => {

    const user = useUserSelector();
      let fileInput: any = useRef(null);
      function selectFile() {
        fileInput.current.click();
      }

      function showFile(e) {
        e.preventDefault()
        generateP6Import(project, e, startDate, user!.userId, project.projectId)
            .then(() => {
              fileInput.current.value = null
            })
            .catch((err) => console.log(err))
      }


    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [closePicker, setClosePicker] = useState<boolean>(true);
    const [messageHidden, setMessageHidden] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [disabled, setDisabled] = useState<boolean>(false);


    const closeModalHandler = useCallback(() => {
        setOpened(false);
        setMessageHidden(true);
    }, [setOpened, setMessageHidden]);

    return (
        <>
            <Modal dimmer='blurring' className="modal-event" open={opened} closeIcon onClose={() => closeModalHandler()}>
                <Header textAlign="center" content="Select data date for update" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DatePicker
                        todayButton='Today'
                        className='modal-event-dateinput'
                        selected={startDate}
                        onChange={(date: Date) => {
                            setShowConfirm(true);
                            setStartDate(date);
                            setDisabled(false)
                            date && setStartDate(date);
                        }}
                        open={!closePicker}
                        onInputClick={() => {
                            setClosePicker(false);
                        }}
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals = {60}
                        timeCaption ="Hour"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        shouldCloseOnSelect={false}
                        minTime = {moment(new Date()).hours(0).minutes(0).toDate()}
                        maxTime={moment(new Date()).hours(23).minutes(59).toDate()
                        }
                        onClickOutside={(e) => {
                            if (showConfirm) {
                                setClosePicker(false);
                            } else {
                                setClosePicker(true);
                            }
                        }}

                    >
                        {showConfirm ?
                            <button
                                style={{
                                    position: 'absolute',
                                    right: '5px',
                                    padding: '5px',
                                    bottom: '8px',
                                    borderRadius: '4px',
                                    backgroundColor: '#21ba45',
                                    color: '#fff',
                                    textShadow: 'none',
                                    outline: 'none',
                                    border: 'none',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                }}
                                onClick={() => { setClosePicker(true); setShowConfirm(false); }}>confirm</button>
                            : null}
                    </DatePicker>
                    <Message
                        size="small"
                        content="Adjust the time please"
                        warning
                        compact
                        color="yellow"
                        hidden={messageHidden}
                        className="modal-event-message"
                        icon="warning"
                    />
                </div>
                <Modal.Actions>
                    <Button negative onClick={() => closeModalHandler()}>Cancel</Button>
                    <>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInput}
                          accept=".xlsx"
                          onChange={(e) => showFile(e)}
                        />
                        <Button
                            disabled={disabled}
                            positive
                            onClick={selectFile}
                        >
                            Confirm
                        </Button>
                      </>

                </Modal.Actions>
            </Modal>
        </>
    )
}
