import {DefaultTheme} from "styled-components";

const theme: DefaultTheme = {
    colors: {
        primary: '#333333',
        dark: '#040001',
        light: '#767676',
        profile: '#2185d0'
    },
    background: {
        dark: '#484643',
        light: '#d4d4d4',
        main: '#ffffff',
        primary: '#4285f4',
        active: '#cbcccc',
    },
    borderRadius: {
        buttonDefault: '.28571429rem'
    }
};


export default theme;
