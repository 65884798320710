import LoginPage from "./pages/LoginPage/LoginPage";
import { createBrowserRouter } from "react-router-dom";
import RouteNameConstants from "./constants/route-name.constants";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import PageNotFound from "./pages/NotFoundPages/NotFoundPage";
import ChooseProjectPage from "./pages/ProjectPage/pages/ChooseProjectPage/ChooseProjectPage";
import AuthRequired from "./utils/authRequired";
import FlowPage from "./pages/ProjectPage/pages/FlowPage/FlowPage";
// import unauthRequired from "./utils/unauthRequired";
import DeleteProjectPage from "./pages/DeleteProjectPage/DeleteProjectPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import React from "react";

const routingConstants = createBrowserRouter([
    {
        path: '/',
        element: <AuthRequired component={ProjectPage}/>,
        errorElement: <PageNotFound/>,
        children: [
            {
                index: true,
                element: <ChooseProjectPage/>,
            },
            {
                path: `${RouteNameConstants.PROJECT_ID}/${RouteNameConstants.FLOW}`,
                element: <FlowPage/>,
            },
            {
                path: `${RouteNameConstants.CHOOSE_PROJECT}`,
                element: <ChooseProjectPage/>,
            }

        ]
    },
    {
        path: `/${RouteNameConstants.LOGIN}`,
        element: <LoginPage/>,
    },
    {
        path: `/${RouteNameConstants.DELETE_PROJECT}`,
        element: <AuthRequired component={DeleteProjectPage}/>,
    },
    {
        path: `/${RouteNameConstants.LANDING_PAGE}`,
        element: <LandingPage/>,
    },
    {
        path: `/${RouteNameConstants.PROJECT_ID}`,
        element: <ProjectPage/>,
    },
]);


export default routingConstants;
