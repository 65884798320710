import React, {useEffect, useState} from "react";
import {useActiveTaskSelector} from "../../../../../store/selectors/task/task.selectors";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskContainer} from "./Task/TaskContainer";
import TaskModel from "../../../../../models/responses/task.model";
import {Maybe} from "@martin_hotell/rex-tils";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import TaskCheckListWrapper from "./TaskChecklistWrapper/TaskCheckListWrapper";
import ZoomI from "../../../components/DagChart/DagChartZoom";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import * as taskActions from "../../../../../store/actions/task.actions";
import {useDispatch} from "react-redux";

export const TaskPredecessors: React.FC = () => {
    const predecessorsTaskList = useTypedSelector(state => state.task.predecessors.list);
    const activeTask = useActiveTaskSelector();
    const [viewWidth, setViewWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setViewWidth(window.innerWidth)
        })
    }, [])

    useEffect(() => {
        let reduceBy = 0

    }, []);

    const [task, setTask] = useState<Maybe<TaskModel<string>>>(null);
    let activeTaskId = activeTask?.task_id;
    useEffect(() => {
        setTask(activeTask);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTask])

    if (!activeTask || !task) {
        return null;
    }

    return (
        <div className="task-predecessors-container">
            {task.taskListType === TaskListSectionModel.QUEUED ||
            task.taskListType === TaskListSectionModel.WORK_IN_PROCESS ?
                <>
                    <div className='task-predecessor-main'>Driving Path</div>
                    <ZoomI taskId={task.task_id} height={300} width={viewWidth} scope="preds" delay={false}
                           setShowDag={() => {}}
                           setActiveRedMilestone={() => {}}
                           setActiveGreenMilestone={() => {}}
                    />
                </> : null}
            <div className='task-predecessor-main'>Selected Tasks</div>
            <div>
                <TaskContainer task={task} hidePassBtn
                   goToPred={() => {
                       dispatch(taskActions.Actions.setActiveTask(task));
                       dispatch(
                           taskActions.Actions.openRelatedTaskList(
                               task,
                               "predecessors"
                           )
                       );
                   }}
                   goToSuc={() => {
                       dispatch(taskActions.Actions.setActiveTask(task));
                       dispatch(
                           taskActions.Actions.openRelatedTaskList(
                               task,
                               "successors"
                           )
                       );
                   }}
                />
                {
                    task &&
                    task.task_id === activeTask.task_id &&
                    task.status !== TaskStatusModel.COMPLETE &&
                    <TaskCheckListWrapper/>
                }
            </div>
            <div className='task-predecessor-main'>
                <span>Predecessors</span>
                <span>Relationships</span>
            </div>
            <div className="task-predecessors-list">
                <div className="lazy-load-container">
                    <div className="task-list-container">
                        {
                            predecessorsTaskList.map(task =>
                                <React.Fragment key={task.task_id}>
                                    <TaskContainer type="pred" showPredType task={task} hidePassBtn
                                       goToPred={() => {
                                           dispatch(taskActions.Actions.setActiveTask(task));
                                           dispatch(
                                               taskActions.Actions.openRelatedTaskList(
                                                   task,
                                                   "predecessors"
                                               )
                                           );
                                       }}
                                       goToSuc={() => {
                                           dispatch(taskActions.Actions.setActiveTask(task));
                                           dispatch(
                                               taskActions.Actions.openRelatedTaskList(
                                                   task,
                                                   "successors"
                                               )
                                           );
                                       }}
                                    />
                                    {
                                        activeTask &&
                                        task.task_id === activeTask.task_id &&
                                        activeTask.status !== TaskStatusModel.COMPLETE &&
                                        <TaskCheckListWrapper/>
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
