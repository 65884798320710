import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button} from "semantic-ui-react";

export const NavButton = (props: { text: string, icon: IconProp, buttonProps?: Partial<{onClick: (evt) => void}>, classes?: string}) => (
    <Button className={props.classes + ' navigation-bar-dropdown-item'} {...props.buttonProps}>
        <div className="navigation-bar-label-wrap">
            <FontAwesomeIcon icon={props.icon}/>
            <span className="navigation-bar-label">{props.text}</span>
        </div>
    </Button>
);
