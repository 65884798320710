import {useEffect} from "react";
import * as RF from "redux-form";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../store/selectors/selectors.utils";

export type DefaultTypeOfInitialValue = { [field: string]: any } | undefined;


export interface ReduxFormOptions<I = DefaultTypeOfInitialValue> {
    form: string;
    initialValues?: I;
    onSubmit?: (value: I) => void;
}


export function useForm<I = DefaultTypeOfInitialValue>(options: ReduxFormOptions<I>) {
    const {form, initialValues, onSubmit, ...otherMeta} = options;
    const dispatch = useDispatch();
    const store = useTypedSelector(state => state.form);
    const formValues = store[form] && store[form].values;
    const debounceMap: Map<any, any> = new Map();

    useEffect(() => {
        dispatch(RF.initialize(form, initialValues, false, otherMeta));

        return () => {
            dispatch(RF.destroy(form));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        store,
        reset: () => {
            dispatch(RF.reset(form));
        },
        handleSubmit: e => {
            e.preventDefault();
            if (onSubmit) {
                onSubmit(formValues as I);
            }
        },
        useField: (name: string, initialValue?: any, debounce?: number) => {
            useEffect(() => {
                dispatch(RF.registerField(form, name, RF.Field as any));

                if (typeof initialValue !== 'undefined') {
                    dispatch(RF.change(form, name, initialValue))
                }
                return () => {
                    dispatch(RF.unregisterField(form, name));
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps,
            }, []);

            return {
                setValue: value => {
                    dispatch(RF.change(form, name, value))
                },
                input: {
                    value: (formValues && formValues[name]) || (typeof initialValue !== 'undefined' ? initialValue : undefined),
                    onChange: (e: any, val?: any) => {
                        let value: any;
                        if (!val) {
                            value = e.target.value;
                        } else {
                            value = val;
                        }
                        if (debounce) {
                            const currDebounce = debounceMap.get(name);
                            if (currDebounce) {
                                clearTimeout(currDebounce);
                            }
                            debounceMap.set(name, setTimeout(() => {
                                if (e && e.target && typeof value !== "undefined") {
                                    dispatch(RF.change(form, name, value))
                                } else {
                                    dispatch(RF.change(form, name, e))
                                }
                            }, debounce))
                        } else {
                            if (e && e.target && typeof value !== "undefined") {
                                dispatch(RF.change(form, name, value))
                            } else {
                                dispatch(RF.change(form, name, e))
                            }
                        }
                    }
                }
            };
        }
    };
}
