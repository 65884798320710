import {convertDateToIndex, convertIndexToSeconds} from "./handleEvent";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import {TaskListSectionModel} from "../../../../models/task-list-section.model";
import {TaskType} from "../../../../models/task-type";
import {COLLECTIONS} from "../../../../firebase/constants";
import LedgerEntry from "../../../../models/responses/ledger-entry.model";
import {MessageType} from "../../../../models/responses/message.model";
import store from "../../../../store/store";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";

export default async function updatePredStatus(tasks, cpmMap, calendarsMap, targetFronts, projectId, dataDate) {
    const timeStart = new Date().getTime()
    let newCPMMap = new Map(cpmMap)
    function commitBatch(batch) {
        let batchObject = FirebaseUsage.batch()
        batch.forEach((task) => {
            // batchObject.update(FirebaseUsage.getDocumentRef('tasks', task.task_code), task.uploadData)
            if (task.type === "set") {
                batchObject.set(task.docRef, task.uploadData)
            } else {
                batchObject.update(task.docRef, task.uploadData)
            }
        })
        batchObject.commit()
    }

    let batch: any[] = []
    let batchCount = 0

    const handleWorkingTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': 0,
        'TT_Task': 1,
        'TT_Rsrc': 1,
        'TT_WBS': 0,
        'TT_TASK': 1,
        'TT_RSRC': 1,
        'TT_MILE': 0,
        'TT_FINMILE': 0,
    }

    const handleConstraintTypes = {
        'CS_MSOA': 1,
        'CS_MEO': 1,
        'CS_MSO': 1,
        'CS_MEOA': 1,
        'CS_MEOB': 0,
        'CS_MSOB': 0,
        'CS_MANDFIN': 1,
        'CS_MANDSTART': 1,
        'CS_ALAP': 0,
        '': 0
    }

    async function getScoreList(preds) {
        let predData: any[] = []
        for (const pred of preds) {
            const link = cpmMap.get(pred)
            const task = cpmMap.get(link.pred_task_id)
            predData.push({task: task, link: link})
        }
        return predData.map(pred => {
            if ((pred.link.pred_type === "FS" || pred.link.pred_type === "SS")
                && pred.task.status_code === "not started"
            ) {
                return 3;
            } else if (((pred.link.pred_type === "SS" || pred.link.pred_type === "SF") &&
                pred.task.status_code === "in progress") || pred.task.status_code === "completed") {
                return 1;
            } else if (((pred.link.pred_type === "FF" || pred.link.pred_type === "SF") && pred.task.status_code === "not started")
                || ((pred.link.pred_type === "FS" || pred.link.pred_type === "FF") && pred.task.status_code === "in progress")) {
                return 2;
            } else {
                return 1;
            }
        })
    }

    const getPredStatus = (task, scoreList) => {
        let predStatus = 0;
        switch (true) {
            case task.status_code === "completed":
                predStatus = 4;
                break;
            case scoreList.every(el => el === 1) || !scoreList.length:
                predStatus = 1;
                break;
            case scoreList.every(el => el === 3):
                predStatus = 3;
                break;
            default:
                predStatus = 2;
                break;
        }
        return predStatus;
    };

    let incompleteTasks: any[] = []
    for (const task of tasks) {
        const taskData: CpmTaskModel = cpmMap.get(task)
        const preds = cpmMap.get(`${taskData.task_code}:preds`)
        const predStatus = getPredStatus(taskData, await getScoreList(preds))
        if (taskData.predStatus !== predStatus) {
            newCPMMap.set(taskData.task_code, {...taskData, predStatus: predStatus})
            if(predStatus === 1) {
                taskData.waiting = true
                taskData.predStatus = predStatus
            }
        }

        let uploadData: any | null = null
        if (predStatus === 1 &&
        (taskData.task_type === "TT_Mile" || taskData.task_type === "TT_FinMile") &&
        taskData.status_code !== "completed") {
            const dataDateIndex = convertDateToIndex(dataDate, taskData.cal_id, calendarsMap)
            const activeDate = typeof taskData.ad === "number" ? taskData.ad : dataDateIndex
            if (handleConstraintTypes[task.cstr_type] !== 1 || activeDate <= dataDateIndex) {
                let endDate: number | null = null
                if (preds.length !== 0) {
                    for (const pred of preds) {
                        const link = cpmMap.get(pred)
                        const t = cpmMap.get(link.pred_task_id)
                        const taskEndDate = convertIndexToSeconds(t.ef, t.cal_id, calendarsMap)
                        if (!endDate || taskEndDate > endDate) {
                            endDate = taskEndDate
                        }
                    }
                    uploadData = {
                        status: TaskStatusModel.COMPLETE,
                        taskListType: TaskListSectionModel.CONFIRMED_COMPLETE,
                        predStatus: 4,
                        act_start_date: endDate ? FirebaseUsage.timestamp(new Date(endDate * 1000)) :
                            FirebaseUsage.timestamp(new Date()),
                        act_end_date: endDate ? FirebaseUsage.timestamp(new Date(endDate * 1000)) :
                            FirebaseUsage.timestamp(new Date()),
                        }
                    const newLedgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
                    const ledgerData: LedgerEntry = {
                        taskId: taskData.task_code,
                        ledgerId: newLedgerEntry.id,
                        projectId: projectId,
                        timestamp: endDate ? FirebaseUsage.timestamp(new Date(endDate * 1000)) :
                            FirebaseUsage.timestamp(new Date()),
                        logTimestamp: FirebaseUsage.timestamp(new Date()),
                        type: MessageType.DEC,
                        userEmail: "Flowbot",
                        userId: "Flowbot",
                    }
                    batch.push({task_code: newLedgerEntry.id, uploadData: ledgerData, docRef: newLedgerEntry, type: "set"})
                }
            }
        } else {
            uploadData = {
                predStatus: predStatus
            }
        }

        if (taskData.status_code !== TaskStatusModel.COMPLETE && taskData.status_code !== TaskStatusModel.DECLARED_COMPLETE) {
            if (uploadData.status) {
                batch.push({task_code: taskData.task_code, uploadData: uploadData, docRef: taskData.doc_ref})
                batchCount += 1
                if (batchCount === 500) {
                    commitBatch(batch)
                    batch = []
                    batchCount = 0
                }
            } else {
                taskData.predStatus = predStatus
                incompleteTasks.push(taskData)
            }
        } else {
            batch.push({task_code: taskData.task_code, uploadData: uploadData, docRef: taskData.doc_ref})
            batchCount += 1
            if (batchCount === 500) {
                commitBatch(batch)
                batch = []
                batchCount = 0
            }
        }
    }

    incompleteTasks = incompleteTasks.length > 0 ? incompleteTasks.sort((a, b) =>
        a.predStatus - b.predStatus)
    .sort((a, b) =>
        convertIndexToSeconds(a.lf, a.cal_id, calendarsMap) - convertIndexToSeconds(b.lf, b.cal_id, calendarsMap))
    .sort((a, b) => convertIndexToSeconds(a.ls, a.cal_id, calendarsMap) - convertIndexToSeconds(b.ls, b.cal_id, calendarsMap)) : []

    // console.log({...targetFronts})
    let index: number = 0
    for (const task of incompleteTasks) {
        if (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) {
            index += 1
        }
        let uploadData: {} | null = null
        if (targetFronts[task.cal_id] > 0 && handleWorkingTypes[task.task_type] === 1) {
            uploadData = {
                // taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                predStatus: task.predStatus,
                flow: true,
                index: index
            }
            targetFronts[task.cal_id] -= 1
            if (task.waiting) {
                console.log("task is waiting", task.task_code)
                // const newLedgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
                // const ledgerData: LedgerEntry = {
                //     taskId: task.task_code,
                //     ledgerId: newLedgerEntry.id,
                //     projectId: projectId,
                //     timestamp: FirebaseUsage.timestamp(new Date()),
                //     logTimestamp: FirebaseUsage.timestamp(new Date()),
                //     type: MessageType.WTG,
                //     userEmail: "Flowbot",
                //     userId: "Flowbot",
                // }
                // batch.push({task_code: newLedgerEntry.id, uploadData: ledgerData, docRef: newLedgerEntry, type: "set"})
                // batchCount += 1
                // if (batchCount === 500) {
                //     commitBatch(batch)
                //     batch = []
                //     batchCount = 0
                // }
            }
        } else if (task.status_code === TaskStatusModel.IN_PROGRESS || task.status_code === TaskStatusModel.SUSPENDED) {
            uploadData = {
                // taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                predStatus: task.predStatus,
                flow: false,
                index: index
            }
        } else {
            uploadData = {
                // taskListType: task.pending ? TaskListSectionModel.PENDING : TaskListSectionModel.QUEUED,
                predStatus: task.predStatus,
                flow: false,
                index: index
            }
        }
        // console.log(task, uploadData, targetFronts[task.cal_id])
        batch.push({task_code: task.task_code, uploadData: uploadData, docRef: task.doc_ref})
        batchCount += 1
        if (batchCount === 500) {
            commitBatch(batch)
            batch = []
            batchCount = 0
        }
    }

    if (batch.length > 0) {
        commitBatch(batch)
    }

    store.dispatch({type: "SET_CPM_MAP", payload: newCPMMap})

    console.log("Time taken to update pred statuses", new Date().getTime() - timeStart, "ms")
    console.log("all predecessor statuses updated")
}