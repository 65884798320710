import { Dispatch, MiddlewareAPI } from "redux";
import { RootStateModel } from "../../reducers";
import * as searchActions from "../../actions/search.actions";
import { subsManager } from "../subs-manager/subs-manager";
import { getType } from "typesafe-actions";
import { SearchActions } from "../../actions/search.actions";
import TaskStatusModel from "../../../models/responses/task-status.model";

export function searchMiddleware(api: MiddlewareAPI<Dispatch, RootStateModel>) {
  return (next: Dispatch) => (action: searchActions.Actions) => {
    const returnValue = next(action);
    switch (action.type) {
      case getType(SearchActions.setTaskforceFilter):
      case getType(SearchActions.removeIdFromTaskforecFilter):
      case getType(SearchActions.toggleAlert): {
        const activeProject = api.getState().project.activeProject;
        // console.log("#".repeat(100));
        // console.log("activeProject", activeProject);
        const page = api.getState().task.page;
        // console.log("page", page);
        // const statuses = api.getState().search.chipsList.filter(chip => chip.type === 'status').map(el => el.text);
        const searchParameters = api.getState().search.parameters;
        if (activeProject) {
          const parameters: any = {
            project_id: activeProject.projectId,
            page,
            taskForces: searchParameters.taskForces,
            alert: searchParameters.alert,
            searchValue: searchParameters.searchText,
          };
          // if (searchParameters.alert) {
          // parameters.statuses = [
          //   TaskStatusModel.SUSPENDED,
          //   TaskStatusModel.BLOCK,
          //   TaskStatusModel.IN_PROGRESS,
          // ];
          // }
          // console.log("#".repeat(100));
          // subsManager.removeSubscription('task-list');
          // subsManager.subscribeTaskList(activeProject.project_id, page, statusChipsList);

          // subsManager.removeTaskListSubscription("in-progress-task-list");
          // subsManager.removeTaskListSubscription("declared-complete-task-list");
          // subsManager.removeTaskListSubscription("confirmed-complete-task-list");
          // subsManager.removeTaskListSubscription("queued-task-list");
          // subsManager.removeTaskListSubscription("pending-task-list");
          //
          // subsManager.subscribeInProgressTaskList(parameters);
          // subsManager.subscribeConfirmedCompleteTaskList(parameters);
          // subsManager.subscribeDeclaredCompleteTaskList(parameters);
          // subsManager.subscribeQueuedTaskList(parameters);
          // subsManager.subscribePendingTaskList(parameters);
        }
        break;
      }
      default: {
        break;
      }
    }

    return returnValue;
  };
}
