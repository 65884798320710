import {TaskListSectionModel} from "../../../models/task-list-section.model";

export const getTaskListType = (status) => {
    if (status === 'TK_Complete') {
        return TaskListSectionModel.CONFIRMED_COMPLETE;
    } else if (status === 'TK_NotStart') {
        return TaskListSectionModel.QUEUED;
    } else if (status === 'TK_Active') {
        return TaskListSectionModel.WORK_IN_PROCESS;
    } else {
        return TaskListSectionModel.WORK_IN_PROCESS;
    }
};