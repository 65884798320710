import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, CheckboxProps, FormTextArea, Header, Message, Modal} from 'semantic-ui-react';
import FirebaseUsage from '../../../../../firebase/firebase.usage';
import {Timestamp} from "firebase/firestore";
import EventTextModel from '../../../../../models/event-text-model.enum';
import {useDispatch} from 'react-redux';
import {TaskThunk} from '../../../../../store/thunk/task.thunk';
import TaskModel from '../../../../../models/responses/task.model';
import {useUserSelector} from '../../../../../store/selectors/authorization.selectors';
import {SuspendReasons} from '../../../../../models/suspend-reason.enum';
import {EvaluationOptions} from '../../../../../models/evaluation-options.enum';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {CheckList} from '../../../../../models/checklist-model';
import {
    useActiveTaskChecklistSelector,
    useActiveTaskSelector,
    useCompleteTaskListSelector,
    useDeclaredCompleteTaskListSelector,
    useInProgressTaskListSelector,
    useQueuedTaskListSelector
} from '../../../../../store/selectors/task/task.selectors';
import {TaskType} from '../../../../../models/task-type';
import moment from 'moment';
// import { useProjectCalculationsInProcessSelector } from '../../../../../store/selectors/project.selectors';
import {
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector
} from "../../../../../store/selectors/project.selectors";
import {getSecsDuration} from "../../../../../utils/date.utils";
import CpmFunctionsController from "../../../../../utils/cpm-functions/cpm-functions.controller";
import {
    convertDateToIndex,
    convertIndexToSeconds
} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {CpmTaskModel} from "../../../../../models/responses/cpm-task.model";

const TaskChecklistCheckboxes: React.FC<{
    onValid: (val: boolean) => void,
    disabledChecklist: CheckList[],
    setDisabledChecklist: React.Dispatch<React.SetStateAction<CheckList[]>>
}> = ({ onValid, disabledChecklist, setDisabledChecklist }) => {

    const activeChecklist = useActiveTaskChecklistSelector();
    useEffect(() => {
        const filteredChecklist = activeChecklist.filter(el => !el.isChecked);
        const allUnchecked = filteredChecklist.every(el => {
            const myEl = disabledChecklist.find(item => item.id === el.id);
            if (myEl) {
                return el.id === myEl.id
            }
            return false;
        })
        onValid((disabledChecklist.every(el => el.isChecked) && disabledChecklist.length > 0 && allUnchecked) ||
            (disabledChecklist.length === 0 && activeChecklist.every(el => el.isChecked)));
    }, [disabledChecklist, onValid]);

    const changeChecklistHandler = useCallback((item: CheckList, data: CheckboxProps) => {
        data.checked = !data.checked
        const newArr = [...disabledChecklist];
        if (newArr.some(el => el.id === item.id)) {
            newArr.map(el => {
                if (el.id === item.id) {
                    el.isChecked = !data.checked;
                }
                return el;
            })
        } else {
            newArr.push({
                ...item,
                isChecked: !data.checked
            });
        }
        setDisabledChecklist(newArr);
    }, [disabledChecklist, setDisabledChecklist]);

    return (
        <>
            <h4
                className='deselect-header'
            >
                Deselect checklist items
            </h4>
            <div
                className='deselect-checkboxes'
            >
                {activeChecklist
                    .map((item: CheckList) =>
                        <div key={item.id}>
                            <Checkbox
                                defaultChecked={item.isChecked}
                                label={item.name}
                                onChange={(_, data) => changeChecklistHandler(item, data)}
                            />
                        </div>
                    )
                }
            </div>
        </>
    );
}

interface PropsModel {
    eventText: EventTextModel,
    opened: boolean,
    setOpened: any,
    task: TaskModel,
    suspendReason: any,
    evaluationResult: EvaluationOptions | 'No result',
    defaultDate: string,
    activeChecklistItem?: CheckList | null,
    setActiveItem?: React.Dispatch<React.SetStateAction<CheckList | null>>;
}

export const TaskEventModal: React.FC<PropsModel> = ({
    eventText,
    opened,
    setOpened,
    task,
    suspendReason,
    evaluationResult,
    defaultDate,
    activeChecklistItem,
    setActiveItem
}) => {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [closePicker, setClosePicker] = useState<boolean>(true);
    const [messageHidden, setMessageHidden] = useState<boolean>(true);
    const [eventTimestamp, setEventTimestamp] = useState<Timestamp | null>(null);
    const dispatch = useDispatch();
    const user = useUserSelector();
    const cpmMap = useCpmMapSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    // const isCalculationsModalOpened = useProjectCalculationsInProcessSelector();
    const taskCalendar: CpmTaskModel | null = cpmMap.get(task.task_id)?.cal_id || null;

    const returnPlaceholderDate = () => {
        const dateNow = new Date()
        switch (eventText) {
            case EventTextModel.START_TASK:
                return taskCalendar ?
                    new Date(convertIndexToSeconds(convertDateToIndex((Math.floor(dateNow.getTime() / 86400000) * 86400000) / 1000,
                        taskCalendar, projectCalendars), taskCalendar, projectCalendars) * 1000 + (dateNow.getTimezoneOffset() * 60 * 1000)) : dateNow;
            case EventTextModel.RESUME_TASK:
                return new Date()
            case EventTextModel.COMPLETE_TASK:
                return taskCalendar ?
                    new Date((convertIndexToSeconds(convertDateToIndex((Math.ceil(dateNow.getTime() / 86400000) * 86400000) / 1000,
                        taskCalendar, projectCalendars) - 1, taskCalendar, projectCalendars) + 1800) * 1000 + (dateNow.getTimezoneOffset() * 60 * 1000)) : dateNow
            case EventTextModel.UNBLOCK_TASK:
                return dateNow
            case EventTextModel.BLOCK_TASK:
                return dateNow
            case EventTextModel.SUSPEND_TASK:
                return dateNow
            case EventTextModel.LOG_ISSUE:
                return dateNow
            case EventTextModel.REFORECAST_TASK:
                return taskCalendar && task.expiryDate ?
                    new Date((convertIndexToSeconds(convertDateToIndex((Math.ceil(task.expiryDate.toDate().getTime() / 86400000) * 86400000) / 1000,
                        taskCalendar, projectCalendars) - 1, taskCalendar, projectCalendars) + 1800) * 1000 + (dateNow.getTimezoneOffset() * 60 * 1000)) : dateNow
            case EventTextModel.EVALUATE_TASK:
                return task.declaredCompleteTimestamp?.toDate() || dateNow
            default:
                return dateNow
        }
    }

    const [startDate, setStartDate] = useState<Date | null>(
        returnPlaceholderDate()
    );
    const activeTask = useActiveTaskSelector();
    const activeChecklist = useActiveTaskChecklistSelector();
    const activeProject = useActiveProjectSelector();
    const [disabledChecklist, setDisabledChecklist] = useState<CheckList[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isMilestoneModalOpened, setIsMilestoneModalOpened] = useState<boolean>(false);
    const queuedTasks = useQueuedTaskListSelector()
    const completedTasks = useCompleteTaskListSelector()
    const inProgressTasks = useInProgressTaskListSelector()
    const declaredCompletedTasks = useDeclaredCompleteTaskListSelector();
    const [reason, setReason] = useState<string | number | undefined>('');
    const allTasks = useMemo(() => {
        return [...queuedTasks, ...completedTasks, ...declaredCompletedTasks, ...inProgressTasks]
    }, [completedTasks, queuedTasks, declaredCompletedTasks, inProgressTasks])

    const milestoneTask = useMemo(() => {
        const predsTaskIds = task.relations.filter(rel =>
            rel.task_id === task.task_id).map(el => el.pred_task_id);

        const milestoneTasks = allTasks.filter(task =>
            predsTaskIds.some(el => el === task.task_id && task.task_type !== TaskType.TT_TASK));
        return milestoneTasks.length ? milestoneTasks[0] : null;
    }, [allTasks, task.relations, task.task_id])

    // const allPredTasksCompleted = useMemo(() => {
    //     const predTasksIds = milestoneTask && milestoneTask.relations.filter(rel => rel.task_id === milestoneTask.task_id).map(rel => rel.pred_task_id);
    //     const predTasks = predTasksIds && predTasksIds.length ? allTasks.filter(task => predTasksIds.some(id => id === task.task_id)) : null;
    //     // predTasks && predTasks.length && console.log(predTasks.filter(el => el.task_id !== task.task_id), 'predTasks');
    //     if (predTasks && predTasks.length) {
    //         const filteredPredTasks = predTasks.filter(el => el.task_id !== task.task_id);
    //         return filteredPredTasks.every(task => task.status === TaskStatusModel.COMPLETE);
    //     } else {
    //         return true;
    //     }
    // }, [allTasks, milestoneTask, task.task_id])

    async function runEvent(taskId: string, eventText: string, eventTimestamp: Timestamp, expiryDate?, suspendReason?: string) {
        try {
            if (activeProject && user) {
                CpmFunctionsController.runTaskCPM({
                    project_id: activeProject.projectId,
                    task_id: taskId,
                    event_type: eventText,
                    seconds: eventTimestamp.seconds,
                    user_id: user.userId!,
                    user_email: user.userEmail,
                    expiry_date: expiryDate ? expiryDate.toString() : eventTimestamp.seconds.toString(),
                    suspend_reason: suspendReason || "null",
                    reason_text: reason || "No reason given"
                    },
                dispatch
                ).then(() => {
                    console.log(`Task ${taskId} ${eventText} at ${eventTimestamp} successful`);
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (eventTimestamp) {
            switch (eventText) {
                case EventTextModel.START_TASK:
                    const expiryDate = eventTimestamp.seconds
                    runEvent(task.task_id, "start", eventTimestamp, expiryDate).catch((e) => console.log(e));
                    break;
                case EventTextModel.RESUME_TASK:
                    runEvent(task.task_id, "resume", eventTimestamp, "none").catch((e) => console.log(e));
                    break;
                case EventTextModel.COMPLETE_TASK:
                    runEvent(task.task_id, "finish", eventTimestamp).catch((e) => console.log(e));
                    break;
                case EventTextModel.UNBLOCK_TASK:
                    runEvent(task.task_id, "unblock", eventTimestamp, eventTimestamp, "none").catch((e) => console.log(e));
                    break;
                case EventTextModel.BLOCK_TASK:
                    runEvent(task.task_id, "block", eventTimestamp, eventTimestamp, suspendReason).catch((e) => console.log(e));
                    break;
                case EventTextModel.SUSPEND_TASK:
                    runEvent(task.task_id, "suspend", eventTimestamp, eventTimestamp, suspendReason).catch((e) => console.log(e));
                    break;
                case EventTextModel.LOG_ISSUE:
                    runEvent(task.task_id, "issue", eventTimestamp, eventTimestamp, suspendReason).catch((e) => console.log(e));
                    break;
                case EventTextModel.REFORECAST_TASK:
                    const calendar2 = activeProject?.calendars.find(el => el.calendar_id === task.calendar_id);
                    const remainingDuration = getSecsDuration(task.act_start_date!.toDate(), eventTimestamp.toDate(), calendar2)
                    runEvent(task.task_id, "duration", eventTimestamp, remainingDuration, "none").catch((e) => console.log(e));
                    break;
                case EventTextModel.EVALUATE_TASK:
                    if (evaluationResult === EvaluationOptions.APPROVED) {
                        runEvent(task.task_id, "confirm", eventTimestamp).catch((e) => console.log(e));
                    } else {
                        runEvent(task.task_id, "reject", eventTimestamp).catch((e) => console.log(e));
                    }
                    break;
                default:
                    break;
            }
            const newChecklist: CheckList[] = Array.isArray(task.checklist)
                ? task.checklist.map(el => disabledChecklist.find(item => item.id === el.id) || el)
                : [];

            // @ts-ignore
            activeTask && !disabled && evaluationResult === EvaluationOptions.REJECTED && dispatch(TaskThunk.updateChecklist(newChecklist, task.task_id));
            setMessageHidden(true);
            setOpened(false);
            setDisabledChecklist([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventTimestamp]);

    const onTaskProceedWithStatusClickHandler = useCallback(() => {
        defaultDate
            ? startDate
                ? setEventTimestamp(FirebaseUsage.timestamp(startDate))
                : setEventTimestamp(FirebaseUsage.timestamp(new Date(defaultDate)))
            : startDate
                ? setEventTimestamp(FirebaseUsage.timestamp(startDate))
                : setMessageHidden(false);
    }, [defaultDate, startDate])

    // useEffect(() => {
    //     console.log(isMilestoneModalOpened, 'milestone modal')
    // }, [isMilestoneModalOpened])


    useEffect(() => {
        if(eventText === EventTextModel.EVALUATE_TASK){
            setStartDate(activeTask?.declaredCompleteTimestamp ? new Date(activeTask.declaredCompleteTimestamp.seconds * 1000) : new Date())
        }
    }, [])

    const onModalButtonClickHandler = useCallback(() => {
        // // console.log(allPredTasksCompleted, 'allPredTasksCompleted')
        // if (milestoneTask) {
        //     const milestoneTaskSuccessors = milestoneTask.relations.filter(rel => rel.task_id === task.task_id);
        //     if (milestoneTask.constraint_type) {
        //         if (
        //             milestoneTaskSuccessors.length &&
        //             eventText === EventTextModel.START_TASK
        //         ) {
        //             setIsMilestoneModalOpened(true);
        //         } else if (
        //             milestoneTaskSuccessors.length &&
        //             eventText === EventTextModel.START_TASK
        //         ) {
        //             onTaskProceedWithStatusClickHandler();
        //             // @ts-ignore
        //             dispatch(TaskThunk.evaluateActiveTaskCompletion(milestoneTask.task_id, EvaluationOptions.APPROVED,
        //                 (user && user.userEmail) || '', MessageType.EVL, FirebaseUsage.timestamp()));
        //         } else {
        //             onTaskProceedWithStatusClickHandler();
        //         }
        //     } else if (
        //         !milestoneTask.constraint_type &&
        //         eventText === EventTextModel.START_TASK
        //     ) {
        //         onTaskProceedWithStatusClickHandler();
        //         // @ts-ignore
        //         dispatch(TaskThunk.evaluateActiveTaskCompletion(milestoneTask.task_id, EvaluationOptions.APPROVED,
        //             (user && user.userEmail) || '', MessageType.EVL, FirebaseUsage.timestamp()));
        //     } else {
        //         onTaskProceedWithStatusClickHandler();
        //     }
        // } else {
        onTaskProceedWithStatusClickHandler();
        // }
    }, [dispatch, milestoneTask, user, onTaskProceedWithStatusClickHandler, eventText, task.task_id])

    const closeModalHandler = useCallback(() => {
        setDisabledChecklist([]);
        setOpened(false);
        setMessageHidden(true);
        if (
            (
                eventText === EventTextModel.COMPLETE_TASK &&
                activeTask
            ) ||
            (
                eventText === EventTextModel.START_TASK &&
                activeTask
            )
        ) {
            const checklist: CheckList[] = [...Array.isArray(activeTask.checklist) ? activeTask.checklist : []];
            let newChecklist: CheckList[] = [];
            if (activeChecklistItem) {
                newChecklist = checklist.map(el => el.id === activeChecklistItem.id ? {
                    ...activeChecklistItem,
                    isChecked: false
                } : el);
            } else {
                newChecklist = checklist.map(el => el.id === checklist[checklist.length - 1].id ? {
                    ...checklist[checklist.length - 1],
                    isChecked: false
                } : el);
            }
            // @ts-ignore
            dispatch(TaskThunk.updateChecklist(newChecklist, task.task_id));
            // setActiveItem && setActiveItem(null);
        }
    }, [activeTask, dispatch, activeChecklistItem, eventText, setOpened, task.task_id, setActiveItem])

    console.log(eventText)

    function showDatePicker() {
        switch (eventText) {
            case EventTextModel.LOG_ISSUE:
                return false;
            default:
                return true;
        }
    }

    function eventTextHandler() {
        switch (eventText) {
            case EventTextModel.LOG_ISSUE:
                return `Task overran due to "${suspendReason}"`;
            case EventTextModel.BLOCK_TASK:
                return `Task blocked due to "${suspendReason}"`;
            case EventTextModel.SUSPEND_TASK:
                return `Task suspended due to "${suspendReason}"`;
            default:
                return eventText;
        }
    }

    function datePromptHandler() {
        switch (eventText) {
            case EventTextModel.BLOCK_TASK:
                return "Confirm when this occurred";
            case EventTextModel.SUSPEND_TASK:
                return "Confirm when this occurred";
            default:
                return ""
        }
    }

    return (
        <>
            <Modal dimmer='blurring' className="modal-event" open={opened} closeIcon onClose={() => closeModalHandler()}>
                <Header textAlign="center" content={eventTextHandler()} />
                {
                    evaluationResult === EvaluationOptions.REJECTED &&
                    activeChecklist.length > 0 &&
                    <TaskChecklistCheckboxes
                        disabledChecklist={disabledChecklist}
                        setDisabledChecklist={setDisabledChecklist}
                        onValid={(val) => setDisabled(val)}
                    />
                }
                <div style={{
                    display: 'flex',
                    flexDirection: 'column', margin: "auto", padding: "10px"
                }}>
                    {showDatePicker() && datePromptHandler()}
                    {showDatePicker() && <DatePicker
                        todayButton='Today'
                        className='modal-event-dateinput'
                        selected={startDate}
                        onChange={(date: Date) => {
                            setShowConfirm(true);
                            // setStartDate(date);
                            date && setStartDate(date);
                        }}
                        open={!closePicker}
                        onInputClick={() => {
                            setClosePicker(false);
                        }}
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals = {60}
                        timeCaption ="Hour"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        shouldCloseOnSelect={false}
                        minDate={eventText === EventTextModel.REFORECAST_TASK ? new Date() : undefined}
                        maxDate={eventText === EventTextModel.REFORECAST_TASK ? undefined : new Date()}
                        minTime = {moment(new Date()).hours(0).minutes(0).toDate()}
                        maxTime={moment(new Date()).hours(23).minutes(59).toDate()}
                        onClickOutside={(e) => {
                            if (showConfirm) {
                                setClosePicker(false);
                            } else {
                                setClosePicker(true);
                            }
                        }}
                    >
                        {showConfirm ?
                            <button
                                style={{
                                    position: 'absolute',
                                    right: '5px',
                                    padding: '5px',
                                    bottom: '8px',
                                    borderRadius: '4px',
                                    backgroundColor: '#21ba45',
                                    color: '#fff',
                                    textShadow: 'none',
                                    outline: 'none',
                                    border: 'none',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    cursor: 'pointer'
                                }}
                                onClick={() => { setClosePicker(true); setShowConfirm(false); }}>confirm</button>
                            : null}
                    </DatePicker>}
                    <Message
                        size="small"
                        content="Adjust the time please"
                        warning
                        compact
                        color="yellow"
                        hidden={messageHidden}
                        className="modal-event-message"
                        icon="warning"
                    />
                    {showDatePicker() && <br/>}
                    {(eventText === EventTextModel.SUSPEND_TASK || eventText === EventTextModel.BLOCK_TASK || eventText === EventTextModel.LOG_ISSUE) &&
                        <FormTextArea placeholder={'Add comment...'}
                                  style={{margin: 'auto', width: '310px', borderColor: '#bfbfbf'}}
                                  value={reason !== '' ? reason : undefined}
                                  onChange={(e, {value}) => setReason(value)}
                    />}
                </div>
                <Modal.Actions>
                    <Button negative onClick={() => closeModalHandler()}>Cancel</Button>
                    <Button
                        disabled={evaluationResult === EvaluationOptions.REJECTED && disabled}
                        positive
                        onClick={onModalButtonClickHandler}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
                <Modal className="modal-event" onOpen={() => setIsMilestoneModalOpened(true)} onClose={() => setIsMilestoneModalOpened(false)} open={isMilestoneModalOpened}>
                    <Header textAlign="center" content='Milestone Completion' />
                    {
                        milestoneTask &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            Please confirm that milestone ${milestoneTask.task_name} (${milestoneTask.task_code}) has been satisfied
                        </div>
                    }
                    <Modal.Actions>
                        <Button
                            positive
                            onClick={
                                () => {
                                    onTaskProceedWithStatusClickHandler()
                                    setIsMilestoneModalOpened(false);
                                }
                            }
                        >
                            Confirm
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Modal>
            {/* <Modal
                basic
                open={
                    activeTask
                        ? isCalculationsModalOpened && activeTask!.task_id === task.task_id
                        : isCalculationsModalOpened
                }
            >
                <Loader
                    indeterminate
                >
                    Calculations in process...
                </Loader>
            </Modal> */}
        </>
    )
}
